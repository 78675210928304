import {Component, Input} from '@angular/core';
import {KpiOptions} from '@theme/components/chart-kpi/chart-kpi.component';

@Component({
    selector: 'ngx-empty-kpi-chart',
    templateUrl: './kpi-chart.component.html',
    styleUrls: ['../../components/chart-kpi/chart-kpi.component.scss', './kpi-chart.component.scss'],
})
export class EmptyKpiChartComponent {
    @Input() wide = false;

    emptyData = {
        title: 'empty title',
        subtitle: 'subtitle',
        kpiDirection: KpiOptions.Increasing,
        deltaPercent: 0,
        deltaValue: 0,
        unit: {
            prefix: '',
            suffix: '',
            symbol: '',
        },
    };
}
