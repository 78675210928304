import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, shareReplay, takeUntil} from 'rxjs/operators';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FiltersDynamicOptionsStore,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {AssetRegistryService} from '@core/interfaces/asset-registry';
import {UsersStore} from '@store/common/users.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {AssetColumn} from '@core/interfaces/common/asset';
import {isEqual} from 'lodash';

@Injectable()
export class AssetRegistryStore extends Unsubscribable {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    readonly resultsLoading$ = this.resultsLoading.asObservable();
    public selectedFilters = new BehaviorSubject<Filter[]>([]);
    public selectedFilters$ = this.selectedFilters.asObservable();
    public selectedAssetId = new BehaviorSubject<string>(null);
    public selectedAssetId$ = this.selectedAssetId.asObservable();

    public availableColumns = new BehaviorSubject<AssetColumn[]>([]);
    public availableColumns$ = this.availableColumns.asObservable().pipe(shareReplay(1));

    public genericFilters$ = this.assetRegistryService.getFilterOptions().pipe(
        map((res: APIResponse<TableFilterConfig[]>) => res.response),
        shareReplay(1),
    );

    public filtersDynamicOptions: BehaviorSubject<FiltersDynamicOptionsStore> = new BehaviorSubject<any>([]);
    public filtersDynamicOptions$: Observable<FiltersDynamicOptionsStore> = this.filtersDynamicOptions
        .asObservable()
        .pipe(shareReplay(1));

    loaders = {
        changeHistory: false,
    };

    loading: BehaviorSubject<any> = new BehaviorSubject(this.loaders);
    loading$: Observable<any> = this.loading.asObservable();

    constructor(private assetRegistryService: AssetRegistryService, private userStore: UsersStore) {
        super();

        this.setInitialFiltersFromLocalStorage();
        this.getAvailableColumns();
    }

    setSelectedFilters(filters: Filter[], requiredChange = false) {
        if (!requiredChange && isEqual(filters, this.selectedFilters.value)) return;
        this.selectedFilters.next(filters);

        let _key = `selectedFiltersAssetRegistry_${this.userStore.getCurrentUser().id}`;
        localStorage.setItem(_key, JSON.stringify(filters));
    }

    setInitialFiltersFromLocalStorage() {
        this.userStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((user) => !!user),
            )
            .subscribe((currUser) => {
                let _key = `selectedFiltersAssetRegistry_${currUser.id}`;
                let _filtersJSON = localStorage.getItem(_key);
                if (_filtersJSON) this.selectedFilters.next(JSON.parse(_filtersJSON));
            });
    }

    getAvailableColumns() {
        this.assetRegistryService
            .getAvailableColumns()
            .pipe(map((res: APIResponse<AssetColumn[]>) => res.response))
            .subscribe((columns: AssetColumn[]) => this.availableColumns.next(columns));
    }

    getLoaders() {
        return this.loading.value;
    }

    setFiltersDynamicOptions(value: FiltersDynamicOptionsStore) {
        return this.filtersDynamicOptions.next(value);
    }

    getOptionsDynamically(data: FilterFieldOptionRequest) {
        this.setFiltersDynamicOptions({
            ...this.filtersDynamicOptions.value,
            [data.fieldKey]: {
                scope: data.filterParams,
                options: this.assetRegistryService.getFilterFieldOptions(data).pipe(
                    map((res) => res.response?.data),
                    shareReplay(1),
                ),
            },
        });
    }
}
