import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {Document, DocumentAssetImage, DocumentCreate, DocumentService} from '@core/interfaces/common/document';
import {DocumentApi} from '@core/backend/common/api/document.api';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class DocumentServiceImpl extends DocumentService {
    constructor(private api: DocumentApi) {
        super();
    }

    listFiles(assetId: string): Observable<DataSource> {
        return this.api.listFiles(assetId);
    }

    /*
     * Operations
     */
    create(document: DocumentCreate): Observable<APIResponse<Document>> {
        return this.api.create(document);
    }

    update(documentId: number, document: any): Observable<APIResponse<Document>> {
        return this.api.update(documentId, document);
    }

    delete(documentId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(documentId);
    }

    getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>> {
        return this.api.getPresignedUrl(fileName, fileFormat);
    }

    uploadToPresignedUrl(url: string, fileData: File): Observable<any> {
        return this.api.upload(`${url}`, fileData);
    }

    /*
     * Retrieval
     */
    getAssetImageDocuments(assetId: string): Observable<APIResponse<DocumentAssetImage[]>> {
        return this.api.getAssetImageDocuments(assetId);
    }

    findOne(documentId: string): Observable<APIResponse<Document>> {
        return this.api.findOne(documentId);
    }

    downloadDocumentsByIds(documentIds: number[]): Observable<APIResponse<string[]>> {
        return this.api.downloadDocumentsByIds(documentIds);
    }

    downloadDocumentsById(documentId: number): Observable<string> {
        return this.api.downloadDocumentsById(documentId);
    }

    getSearchTag(searchString: string): Observable<string[]> {
        return this.api.getSearchTag(searchString);
    }
    getAllTags(assetId: string): Observable<APIResponse<string[]>> {
        return this.api.getAllTags(assetId);
    }
}
