import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, PagingResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {
    InvestmentOverviewDto,
    OutcomeChartRequest,
    OutcomeChartResponse,
    OutcomeKpiResponse,
    Program,
    ProgramBudget,
    ProgramBudgetPerYearDto,
    ProgramBudgetUpdate,
    ProgramCreateDto,
    ProgramFullDto,
    ProgramOutcomes,
} from '@core/interfaces/engin/program-management/pm-program';
import {TableService} from '@core/utils/table.service';

@Injectable()
export class PmProgramApi {
    private readonly prefix = 'programs';

    constructor(private api: HttpService) {}

    // Program
    getProgramsList(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }

    getProgramsListDto(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    getPrograms(params: Filter[]): Observable<PagingResponse<Program>> {
        let paramQuery = TableService.createFiltersParamsString(params);
        return this.api.get(`${this.prefix}/list?${paramQuery}`);
    }

    getProgramById(programId: number): Observable<APIResponse<Program>> {
        return this.api.get(`${this.prefix}/${programId}`);
    }

    getFullProgramDtoById(programId: number): Observable<APIResponse<ProgramFullDto>> {
        return this.api.get(`${this.prefix}/dto-full/${programId}`);
    }

    createProgram(program: ProgramCreateDto): Observable<APIResponse<Program>> {
        return this.api.post(`${this.prefix}`, program);
    }

    editProgramDetails(program: Program): Observable<APIResponse<Program>> {
        return this.api.put(`${this.prefix}/${program.id}`, program);
    }

    editProgramBudget(program: ProgramBudgetUpdate): Observable<APIResponse<ProgramFullDto>> {
        return this.api.put(`${this.prefix}/update/budget`, program);
    }

    deleteProgram(programId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${programId}`);
    }

    searchProgramName(programName: string): Observable<APIResponse<any>> {
        return this.api
            .get(`${this.prefix}/list`, {
                params: new HttpParams().set('filterByname_EQUAL', programName || ''),
            })
            .pipe(map((res) => res.items));
    }

    validateProgramName(programName: string): Observable<boolean> {
        return this.api
            .get(`${this.prefix}/validate`, {
                params: new HttpParams().set('filterByname_EQUAL', programName || ''),
            })
            .pipe(map((res: APIResponse<boolean>) => res.response));
    }

    findAllBySegment(segmentId: number): Observable<APIResponse<Program[]>> {
        return this.api.get(`${this.prefix}/find/by-segment/${segmentId}`);
    }

    findProgramKpis(
        programId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>> {
        return this.api.get(`${this.prefix}/details/kpi/${programId}/study/${workflowItemId}/${sensitivityId}`);
    }

    downloadProgramsListCsv(param: Map<string, any>): Observable<APIResponse<DownloadCSVUrl>> {
        let paramQuery = '';
        param.forEach((value: boolean, key: string) => {
            paramQuery += `filterBy${key}_EQUAL=${value}&`;
        });
        return this.api.get(`${this.prefix}/dto/list/csv?${paramQuery}`);
    }

    downloadProgramAssets(programId: number, workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/download/assets/${programId}/study/${workflowItemId}`);
    }

    getKpiOptions(): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/options/kpis`);
    }

    //AS InvestmentPriority
    getInvestmentPriorityOptions(): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/options/priority`);
    }
    getProgramBudgetByOutcomes(
        param: ProgramOutcomes,
        workflowItemId,
    ): Observable<APIResponse<ProgramBudgetPerYearDto[]>> {
        return this.api.post(`${this.prefix}/program-budgets/list-by-outcomes/${workflowItemId}`, param);
    }

    getBudgetChart(param: ProgramBudget[], workflowItemId): Observable<APIResponse<SimpleMultiSeries<number>>> {
        return this.api.post(`${this.prefix}/program-budgets/chart/${workflowItemId}`, param);
    }

    getInvestmentOverview(param: InvestmentOverviewDto): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.post(`${this.prefix}/investment/overview`, param);
    }

    getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-plan/spending`, request);
    }
    getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-plan/outcomes`, request);
    }
    getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/proactive`, request);
    }
    getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/investment-forecast/reactive`, request);
    }
    getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/failure`, request);
    }
    getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/failure-forecast/outage`, request);
    }
    getChartDataRiskForecastByImpact(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/impact`, request);
    }
    getChartDataRiskForecastByCategory(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.post(`${this.prefix}/charts/risk-forecast/category`, request);
    }
}
