<ngx-icon-box
    class="chart-settings"
    icon="more-vertical-outline"
    [nbPopover]="settings"
    nbPopoverTrigger="hover"
    nbPopoverPlacement="left"
    [class.disabled-link]="!(chartOptions && chartTypeValid())"
></ngx-icon-box>

<ng-template #settings>
    <ngx-chart-settings-popup
        [unitOptions]="fullUnitOptions"
        [unitItem]="unitItem"
        [labelOptions]="labelOptions"
        [graphId]="graphId"
        (itemChanged)="changed($event)"
        [disableDataDownload]="disableDataDownload"
        [csvFileName]="csvFileName"
        [chartOptions]="chartOptions"
        [chartType]="chartType"
        [preparedDownloadData]="preparedDownloadData"
        [chartInstance]="chartInstance"
        [axisOptions]="axisOptions"
        [axisChangeFunction]="axisChangeFunction"
        [customSettings]="customSettings"
        [customSelected]="customSelected"
        [customSettingsLabel]="customSettingsLabel"
        (customSettingsValueChanged)="customSettingsValueChanged.emit($event)"
    ></ngx-chart-settings-popup>
</ng-template>
