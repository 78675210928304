import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardBodyType, IconDetails} from '@core/interfaces/common/pages';
import {NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-dashboard-card',
    templateUrl: './dashboard-card.component.html',
    styleUrls: ['./dashboard-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardComponent {
    @Input() icon: IconDetails;
    @Input() iconColor: string;
    @Input() title: string;
    @Input() subtitle: string;
    @Input() displayAction: boolean = false;
    @Input() body?: any;

    public readonly NbTrigger = NbTrigger;
    public DashboardBodyType = DashboardBodyType;
}
