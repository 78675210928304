import {Component, Input} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';

@Component({
    selector: 'ngx-file-cell',
    template: `
        <ngx-display-file
            [file]="rowData"
            [showFileSize]="!!rowData.fileSize"
            [showFileFormat]="true"
        ></ngx-display-file>
    `,
})
export class FileCellComponent implements ViewCell {
    @Input() value: any;
    @Input() rowData: any;

    constructor() {}
}
