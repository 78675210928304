import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigurationStore} from '@store/config/configuration.store';
import {filter, takeUntil} from 'rxjs/operators';
import {TenantUnits} from '@core/interfaces/common/configuration';

@Component({
    selector: 'ngx-table-settings',
    templateUrl: './table-settings.component.html',
    styleUrls: ['./table-settings.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TableSettingsComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
/**
 * Combined table settings component including:
 * - Unit selections
 * - Data download (CSV)
 * Unit selections is disabled by default; enabled if [unitOptions, formControlName] is provided to this component
 * Data download is enabled by default; disabled if either [disableDataDownload] is true, or if no data is provided
 */
export class TableSettingsComponent extends Unsubscribable implements ControlValueAccessor, OnChanges, OnInit {
    // Data binding for unit selector functionality
    @Input() unitOptions: string[];
    @Input() graphId: string; // used for indexing unitOptions
    /*
     * Data download component is enabled by default, and expects tableOptions and chartType data sources
     * - Optional input to disable download button
     * - Optional input of custom CSV file name
     * - Optional input of custom data object
     */
    @Input() disableDataDownload?: boolean;
    @Output() downloadFile = new EventEmitter();

    private defaultUnitOptions: string[] = []; // empty by default
    public fullUnitOptions: {value: string; label: string}[];
    private _unitItem: any;

    propagateChange: any = () => {};

    constructor(private cd: ChangeDetectorRef, private configurationStore: ConfigurationStore) {
        super();
    }

    ngOnInit() {
        this.configurationStore.units$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => d && !!d),
            )
            .subscribe((units: TenantUnits) => {
                const finalUnitOptions: string[] = this.unitOptions ? this.unitOptions : this.defaultUnitOptions;
                const fullUnitOptions = finalUnitOptions.map((o) => {
                    return units.graphUnits.find((g) => g.value === o);
                });
                this.fullUnitOptions = fullUnitOptions;
            });
    }

    get unitItem(): any {
        return this._unitItem;
    }

    set unitItem(val: any) {
        this._unitItem = val;
    }

    writeValue(value: any): void {
        if (value) {
            this.unitItem = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.propagateChange(this.unitItem);
    }

    registerOnTouched() {}

    changed(value) {
        this.unitItem = {...this.unitItem, unit: value};
        this.propagateChange(this.unitItem);
        this.cd.markForCheck();
    }

    downLoadTableFile() {
        this.downloadFile.emit();
    }
}
