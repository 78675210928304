import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    OptimizerService,
    OptimizerChartRequest,
    OptimizerChartResponse,
    OptimizerKpiResponse,
    OptimizerAnalysisRequest,
} from '../../../interfaces/engin/optimizer';
import {OptimizerApi} from '../api/optimizer.api';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class OptimizerServiceImpl extends OptimizerService {
    constructor(private api: OptimizerApi) {
        super();
    }

    checkOptimizerResultsReady(request: OptimizerAnalysisRequest): Observable<APIResponse<boolean>> {
        return this.api.checkOptimizerResultsReady(request);
    }

    getChartDataSummarySpending(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        // return this.api.getChartDataSummarySpending(request);
        return this.api.getChartDataSummarySpending(request);
    }

    getChartDataSummaryOutcomes(request: OptimizerChartRequest): Observable<APIResponse<OptimizerKpiResponse>> {
        return this.api.getChartDataSummaryOutcomes(request);
    }

    getChartDataProactiveForecast(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataProactiveForecast(request);
    }

    getChartDataReactiveForecast(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataReactiveForecast(request);
    }

    getChartDataFailureForecast(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataFailureForecast(request);
    }

    getChartDataOutageForecast(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataOutageForecast(request);
    }

    getChartDataRiskForecastByImpact(request: OptimizerChartRequest): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataRiskForecastByImpact(request);
    }

    getChartDataRiskForecastByCategory(
        request: OptimizerChartRequest,
    ): Observable<APIResponse<OptimizerChartResponse>> {
        return this.api.getChartDataRiskForecastByCategory(request);
    }

    // Support for HR toggle
    public getRiskOptionSupported(req: OptimizerChartRequest): Observable<APIResponse<boolean>> {
        return this.api.getRiskOptionSupported(req);
    }
}
