import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '@core/backend/common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {ReportConfig, ReportTemplate} from '@core/interfaces/engin/reporting/report-config';

@Injectable()
export class ReportConfigApi {
    private prefix = 'reporting/config';

    constructor(private api: HttpService) {}

    public getReportConfigOptions(): Observable<APIResponse<ReportConfig>> {
        return this.api.get(`${this.prefix}/`);
    }

    public listReportTemplates(): Observable<APIResponse<ReportTemplate[]>> {
        return this.api.get(`${this.prefix}/templates`);
    }

    public getOneReportTemplate(templateCode: string): Observable<APIResponse<ReportTemplate>> {
        return this.api.get(`${this.prefix}/templates/${templateCode}`);
    }
}
