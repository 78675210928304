import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, of} from 'rxjs';
import {shareReplay, switchMap, tap, takeUntil, map, filter} from 'rxjs/operators';
import {StudiesStore} from '../common/studies.store';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {PagesStore} from '../config/pages.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';
import {
    OutcomeChartRequest,
    OutcomeChartResponse,
    OutcomeKpiResponse,
    PmProgramService,
} from '@core/interfaces/engin/program-management/pm-program';
import {ProgramManagementSensitivityStore} from './program-management-sensitivity.store';
import {Filter} from '@core/interfaces/system/system-common';

@Injectable()
export class OutcomesStore extends Unsubscribable {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    public pageFilters = new BehaviorSubject<Filter[]>([]);
    public pageFilters$ = this.pageFilters.asObservable();

    constructor(
        private programService: PmProgramService,
        private studiesStore: StudiesStore,
        private pmSensitivityStore: ProgramManagementSensitivityStore,
        protected toastrService: NbToastrService,
        private pagesStore: PagesStore,
    ) {
        super();
    }

    // TODO: get current status, filter for true; get data load, filter for false
    public combineChartRequestInfo$ = combineLatest<
        Observable<number>,
        Observable<number>,
        Observable<DisplaySettings>,
        Observable<Filter[]>
    >([
        this.studiesStore.activeStudyIdRisk$,
        this.pmSensitivityStore.sensitivityId$,
        this.pagesStore.currentDisplay$,
        this.pageFilters$,
    ]).pipe(
        shareReplay(1),
        filter(([studyId, sensitivityId]) => studyId !== null && sensitivityId != null),
        switchMap(([studyId, sensitivityId, displaySettings, filters]): Observable<OutcomeChartRequest> => {
            return of(this.prepareOutcomesChartRequest(studyId, displaySettings, sensitivityId, filters));
        }),
    );

    // Investment plan
    readonly chartDataSummarySpending$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataSummarySpending(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );
    readonly chartDataSummaryOutcomes$: Observable<OutcomeKpiResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataSummaryOutcomes(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );

    // Investment forecast breakdown
    readonly chartDataProactiveForecast$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataProactiveForecast(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );
    readonly chartDataReactiveForecast$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataReactiveForecast(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );

    // Failure forecast breakdown
    readonly chartDataFailureForecast$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataFailureForecast(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );
    readonly chartDataOutageForecast$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataOutageForecast(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );

    // Risk forecast breakdown
    readonly chartDataRiskForecastByImpact$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataRiskForecastByImpact(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );
    readonly chartDataRiskForecastByCategory$: Observable<OutcomeChartResponse> = this.combineChartRequestInfo$.pipe(
        switchMap((req: OutcomeChartRequest) => {
            return this.programService.getChartDataRiskForecastByCategory(req).pipe(map((res) => res.response));
        }),
        takeUntil(this.unsubscribe$),
        shareReplay(1),
    );

    private prepareOutcomesChartRequest(
        studyId: number,
        displaySettings: DisplaySettings,
        sensetivityId: number,
        filters: Filter[],
    ): OutcomeChartRequest {
        return {
            filters: filters,
            graphList: displaySettings.graphList,
            sensitivityId: sensetivityId,
            workflowItemId: studyId,
        } as OutcomeChartRequest;
    }

    public setFilters(filter: Filter[]) {
        this.pageFilters.next(filter);
    }
}
