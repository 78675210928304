import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {Observable} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {DateFormatPipe} from '@theme/pipes';
import {FormatsService} from '@core/utils';
import {FormControl} from '@angular/forms';
import {updateOptionsOutput} from '@theme/components';
import {
    Filter,
    FilterFieldOption,
    FilterOperatorOption,
    FilterOperatorType,
    FilterType,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {isEqual} from 'lodash';

@Component({
    selector: 'ngx-filter-field',
    templateUrl: './filter-field.component.html',
    styleUrls: ['./filter-field.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterFieldComponent extends Unsubscribable implements OnInit, OnChanges {
    @ViewChild('buttonTarget') buttonTarget: any;

    @Input() filter: TableFilterConfig;
    @Input() selected?: Filter;
    @Input() clearFilter$: Observable<string>;
    @Input() options$?: Observable<FilterFieldOption[]>;
    @Output() valueChange: EventEmitter<Filter> = new EventEmitter<Filter>();
    @Output() updateOptions: EventEmitter<updateOptionsOutput> = new EventEmitter<updateOptionsOutput>();

    readonly FilterType = FilterType;

    isDropdownDisplayed: boolean = false;

    previousSelectionModel: FilterOperatorOption;
    selectionModel: FilterOperatorOption;

    date: Date = null;
    range = {
        start: null,
        end: null,
    };

    selectedMultiSelectControl = new FormControl([]);
    selectedMultiSelectAutocompleteControl = new FormControl([]);

    searchString: string = '';
    validationMessage: string = '';
    maxRangeNumber: number = 100000;
    minRangeNumber: number = 0;

    constructor(private dateFormatPipe: DateFormatPipe, private cd: ChangeDetectorRef) {
        super();
    }

    get filterOperatorValuesPreview(): string {
        if (this.selectionModel && this.selectionModel.filterType === FilterType.None) return '';
        if (this.selectionModel.filterType === FilterType.SingleSelect) {
            let _label: string = this.filter.options.find(
                (option: FilterFieldOption) => option.name === this.selectionModel.value,
            )?.key;
            return `: ${_label}`;
        }
        if ([FilterType.MultiSelect, FilterType.MultiSelectAutocomplete].includes(this.selectionModel.filterType)) {
            let _labels: string = '';

            if (this.filter.options) {
                if (this.selectionModel.values?.length === this.filter.options?.length) _labels = ': All';
                else
                    _labels = this.selectionModel.values?.reduce((acc: string, curr) => {
                        if (acc !== ': ') acc += ', ';
                        return (acc += this.filter.options?.find((option) => option.name === curr.name || curr)?.key);
                    }, ': ');
            }

            return _labels;
        }
        if (this.selectionModel?.value) return ': ' + this.selectionModel.value;
        if (this.selectionModel?.values) {
            if ([FilterType.DateRange, FilterType.NumericRange].includes(this.selectionModel.filterType))
                return `: from ${this.selectionModel.values[0]} to ${this.selectionModel.values[1]}`;
            else return ': ' + this.selectionModel.values.toString();
        }
        return '';
    }

    ngOnInit(): void {
        this.clearFilter$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value: string) => !!value && value === this.filter.fieldKey),
            )
            .subscribe((value) => {
                this.reset();
            });

        this.selectedMultiSelectControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
            this.setMultiSelect(value);
        });

        this.selectedMultiSelectAutocompleteControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value) => {
                this.setMultiSelect(value);
            });

        if (this.filter.fieldOperatorOptions[0].filterOperator === FilterOperatorType.BETWEEN) {
            if (this.filter.min === null || this.filter.max === null) {
                console.error(`${this.filter.fieldName}: Missing min or max value, please set values in config.`);
            }
            this.minRangeNumber = this.filter.min ? this.filter.min : this.minRangeNumber;
            this.maxRangeNumber = this.filter.max ? this.filter.max : this.maxRangeNumber;
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.selected?.currentValue) {
            this.selectedMultiSelectAutocompleteControl.setValue([]);
            this.selectedMultiSelectControl.setValue([]);

            this.setSelected();
        }
    }

    setSelected() {
        let _option: FilterOperatorOption = this.filter?.fieldOperatorOptions.find(
            (item: FilterOperatorOption) => item.filterOperator === this.selected.operator,
        );
        this.previousSelectionModel = {...this.selectionModel};
        this.selectionModel = {..._option, value: this.selected.value, values: this.selected.values};

        if (this.selectionModel.filterType === FilterType.DateRange) {
            this.range.start = new Date(this.selectionModel.values[0]);
            this.range.end = new Date(this.selectionModel.values[1]);
        } else if (this.selectionModel.filterType === FilterType.Date) {
            this.date = new Date(this.selectionModel.value);
        } else if (this.selectionModel.filterType === FilterType.MultiSelect) {
            this.selectedMultiSelectControl.setValue(this.selectionModel.values, {emitEvent: false});
        } else if (this.selectionModel.filterType === FilterType.MultiSelectAutocomplete) {
            this.selectedMultiSelectAutocompleteControl.setValue(this.selectionModel.values, {
                emitEvent: false,
            });
        }
        this.cd.detectChanges();
    }

    onSearch(searchString: string): void {
        this.searchString = searchString;
        this.onUpdateOptions(true);
    }

    onUpdateOptions(dynamicSearch: boolean = false): void {
        this.updateOptions.emit({
            dynamicSearch,
            data: {
                fieldKey: this.filter.fieldKey,
                searchString: this.searchString,
            },
        });
    }

    toggle(): void {
        this.isDropdownDisplayed = !this.isDropdownDisplayed;

        const scopeModal = document.getElementById('form-scope-modal');
        if (scopeModal) {
            scopeModal.style.setProperty('overflow-y', this.isDropdownDisplayed ? 'hidden' : 'auto');
        }

        if (this.isDropdownDisplayed && !this.filter.options) this.onUpdateOptions();

        if (this.isDropdownDisplayed && this.selectionModel) {
            setTimeout(() => {
                const elem = document.querySelectorAll(
                    `[operator=${this.selectionModel.filterOperator}]`,
                )?.[0] as HTMLElement;
                elem?.click();

                if (
                    [FilterType.SingleSelect, FilterType.MultiSelect, FilterType.MultiSelectAutocomplete].includes(
                        this.selectionModel.filterType,
                    )
                ) {
                    const button = elem.getElementsByTagName('button')?.[0] as HTMLElement;
                    button?.click();
                }
            });
        }
    }

    closeStepTwo(): void {
        this.buttonTarget.nativeElement.click();
        this.isDropdownDisplayed = true;
    }

    onClickOutside(event): void {
        if (!this.buttonTarget.nativeElement.contains(event as Element)) {
            this.isDropdownDisplayed = false;

            if (!isEqual(this.previousSelectionModel, this.selectionModel)) {
                if (this.selectionModel && !(this.selectionModel.value || this.selectionModel.values?.length))
                    return this.reset();

                this.valueChange.emit({
                    fieldKey: this.filter.fieldKey,
                    operator: this.selectionModel?.filterOperator,
                    fieldType: this.filter.fieldType,
                    value: this.selectionModel?.value,
                    values: this.selectionModel?.values,
                });
            }
        }
    }

    reset() {
        this.previousSelectionModel = {...this.selectionModel};
        this.selectionModel = null;

        this.selectedMultiSelectAutocompleteControl.setValue([], {emitEvent: false});
        this.selectedMultiSelectControl.setValue([], {emitEvent: false});
        this.date = null;
        this.range = {
            start: null,
            end: null,
        };

        if (this.isDropdownDisplayed) {
            this.closeStepTwo();
        } else {
            this.valueChange.emit({
                fieldKey: this.filter.fieldKey,
                operator: null,
                fieldType: this.filter.fieldType,
            });
        }

        this.cd.detectChanges();
    }

    getIsOptionDisabled(option: FilterOperatorOption): boolean {
        if (!this.selectionModel) return false;
        else if (this.selectionModel?.filterType === FilterType.None) return false;
        else if (!this.selectionModel?.value && !this.selectionModel?.values?.length) return false;
        return this.selectionModel?.filterOperator !== option.filterOperator;
    }

    selectNoneOption(option: FilterOperatorOption): void {
        this.previousSelectionModel = {...this.selectionModel};
        this.selectionModel = {...option, value: null};

        this.isDropdownDisplayed = false;

        this.valueChange.emit({
            fieldKey: this.filter.fieldKey,
            operator: this.selectionModel?.filterOperator,
            fieldType: this.filter.fieldType,
            value: true,
            values: this.selectionModel?.values,
        });
    }

    selectOption(option: FilterOperatorOption): void {
        if (this.selectionModel && (this.selectionModel.value || this.selectionModel.values?.length)) return;
        this.previousSelectionModel = {...this.selectionModel};
        this.selectionModel = {
            ...option,
            value: null,
            values: null,
        };

        this.selectedMultiSelectAutocompleteControl.setValue([], {emitEvent: false});
        this.selectedMultiSelectControl.setValue([], {emitEvent: false});
    }

    setSingleSelect(value) {
        if (this.selectionModel) this.selectionModel.value = value;
        this.cd.detectChanges();
    }

    setMultiSelect(value) {
        if (!this.selectionModel && value?.length) this.selectionModel = this.previousSelectionModel;
        if (this.selectionModel) this.selectionModel.values = value;
        this.cd.detectChanges();

        if (!this.isDropdownDisplayed) {
            if (this.selectionModel && !(this.selectionModel.value || this.selectionModel.values?.length))
                return this.reset();

            this.valueChange.emit({
                fieldKey: this.filter.fieldKey,
                operator: this.selectionModel?.filterOperator,
                fieldType: this.filter.fieldType,
                value: this.selectionModel?.value,
                values: this.selectionModel?.values,
            });
        }
    }

    setValue(value) {
        this.selectionModel.value = value;
        this.cd.detectChanges();
    }

    setMinNumeric(min, max) {
        if (+max < +min) this.selectionModel.values = [+min || 0, +min || 0];
        else this.selectionModel.values = [+min || 0, +max || 0];
        this.cd.detectChanges();
    }

    setMaxNumeric(min, max) {
        if (+max < +min) this.selectionModel.values = [+max || 0, +max || 0];
        else this.selectionModel.values = [+min || 0, +max || 0];
        this.cd.detectChanges();
    }

    private validationTimeout;

    validateInput(min, max, e): void {
        if (this.validationTimeout) {
            clearTimeout(this.validationTimeout);
        }
        this.validationTimeout = setTimeout(() => {
            const inputElement = e.target as HTMLInputElement;
            let valueValid = true;

            if (+max > this.maxRangeNumber || +min > this.maxRangeNumber) {
                inputElement.value = this.maxRangeNumber + '';
                this.selectionModel.values = [
                    +min > this.maxRangeNumber ? this.maxRangeNumber : +min,
                    +max > this.maxRangeNumber ? this.maxRangeNumber : +max,
                ];
                valueValid = false;
            }
            if (+max < this.minRangeNumber || +min < this.minRangeNumber) {
                inputElement.value = this.minRangeNumber + '';
                this.selectionModel.values = [
                    +min < this.minRangeNumber ? this.minRangeNumber : +min,
                    +max < this.minRangeNumber ? this.minRangeNumber : +max,
                ];
                valueValid = false;
            }

            if (!valueValid) {
                this.validationMessage = `Minimum number: ${this.minRangeNumber} and maximum number: ${this.maxRangeNumber}.`;
                this.cd.detectChanges();
                return;
            }
            this.validationMessage = '';
        }, 500);
    }

    setDate(event) {
        this.selectionModel.value = this.dateFormatPipe.transform(event, FormatsService.SHORT_DATE_FORMAT);
    }

    setRangeDate(event) {
        this.selectionModel.values = [this.dateFormatPipe.transform(event.start, FormatsService.SHORT_DATE_FORMAT)];
        event.end &&
            this.selectionModel.values.push(this.dateFormatPipe.transform(event.end, FormatsService.SHORT_DATE_FORMAT));
    }
}
