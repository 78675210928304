import {Component, Input} from '@angular/core';
import {FormatsService} from '../../../../@core/utils';
import {NumberWithCommasPipe} from '../../../pipes';
import {ValueType} from '../../../../@core/interfaces/common/dataset';

// Forced two-level table structure
export class TableSection {
    label: string;
    value: string;
    valueType: ValueType;
    rows: {
        items: {
            value: string;
            valueType: ValueType;
        }[];
    }[];
}
@Component({
    selector: 'ngx-mini-data-table',
    templateUrl: './mini-data-table.component.html',
    styleUrls: [
        './mini-data-table.component.scss',
        '../mini-data-table-card.scss',
        '../../data-table/cells/status-cell/status-cell.component.scss',
    ],
})
export class MiniDataTableComponent {
    @Input() headers: string[];
    @Input() sections: TableSection[];

    constructor(private numberWithCommasPipe: NumberWithCommasPipe) {}

    public prepareValue(value: string, valueType: ValueType) {
        if (valueType == ValueType.INTEGER || valueType == ValueType.LONG) {
            return this.numberWithCommasPipe.transform(Number(Number(value).toFixed(0)));
        } else if (valueType == ValueType.DECIMAL) {
            return FormatsService.prepareValue(Number(value), '', '');
        }
        return value;
    }
}
