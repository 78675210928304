import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {
    DatasetNote,
    DatasetNoteRequest,
    EvaluationAuditChartRequest,
    EvaluationAuditComparisonRequest,
    EvaluationAuditComparisonResponse,
    EvaluationAuditResponse,
    Parameter,
} from '../../../../interfaces/engin/data-audit/evaluation-data-audit';
import {APIResponse} from '../../../../interfaces/system/system-common';

@Injectable()
export class EvaluationDataAuditApi {
    private readonly prefix = 'evaluation-audit';

    constructor(private api: HttpService) {}

    // Retrieve core results
    getEvaluationAssetData(req: EvaluationAuditChartRequest): Observable<APIResponse<EvaluationAuditResponse>> {
        return this.api.post(`${this.prefix}/results/asset`, req);
    }

    getEvaluationGlobalData(req: EvaluationAuditChartRequest): Observable<APIResponse<EvaluationAuditResponse>> {
        return this.api.post(`${this.prefix}/results/global`, req);
    }

    getComparisonData(
        req: EvaluationAuditComparisonRequest,
    ): Observable<APIResponse<EvaluationAuditComparisonResponse>> {
        return this.api.post(`${this.prefix}/results/comparison`, req);
    }

    // Support
    getDatasetNoteList(req: DatasetNoteRequest): Observable<APIResponse<DatasetNote[]>> {
        return this.api.post(`${this.prefix}/note/list/`, req);
    }

    uploadNoteData(note: DatasetNote): Observable<APIResponse<DatasetNote>> {
        return this.api.post(`${this.prefix}/note/`, note);
    }

    editDatasetNote(id: number, note: DatasetNote): Observable<APIResponse<DatasetNote>> {
        return this.api.put(`${this.prefix}/note/${id}`, note);
    }

    deleteDatasetNote(id: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/note/${id}`);
    }

    getAssetParameterList(): Observable<APIResponse<Parameter[]>> {
        return this.api.get(`${this.prefix}/support/asset-parameters`);
    }

    getGlobalParameterList(): Observable<APIResponse<Parameter[]>> {
        return this.api.get(`${this.prefix}/support/global-parameters`);
    }
}
