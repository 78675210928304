import {Observable} from 'rxjs';

export interface StringSet {
    [param: string]: string;
}

export class ForecasterRequest {
    investmentGroups: {
        id: string;
        sliders: {
            id: string;
            effectiveness: number;
            values: number[];
        }[];
    }[];
    stateChanged: boolean;
    constrained: boolean;
    sensitivityStudy: {
        studyId: number;
        userId: string;
        currentYear: number;
        evaluationPeriod: number;
        sensitivityParams: [StringSet];
    };

    constructor(investmentGroups: any, stateChanged: any, constrained: boolean) {
        this.investmentGroups = investmentGroups;
        this.stateChanged = stateChanged;
        this.sensitivityStudy = null;
        this.constrained = constrained;
    }
}

export interface ForecasterResponse {
    chartData: {
        code: string;
        name: string;
        years: {
            year: number;
            total: number;
            values: {
                code: string;
                name: string;
                value: number;
            }[];
        }[];
    };
}

export interface AvailabilityData {
    code: string;
    name: string;
    data: AnnualData[];
    breakdown: OutageType[];
    children?: AvailabilityData[];
}

export interface AnnualData {
    year: number;
    value: number;
    unit: Unit;
}

export interface OutageType {
    code: string;
    name: string;
    count: number;
    countUnit: Unit;
    duration: number;
    durationUnit: Unit;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

export abstract class ForecasterService {
    abstract getForecasterData(request: ForecasterRequest, studyId: number): Observable<ForecasterResponse[]>;

    abstract getStationAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData>;

    abstract getLineAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData>;
}
