import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ngx-upload-file-card',
    templateUrl: './upload-file-card.component.html',
    styleUrls: ['./upload-file-card.component.scss'],
})
export class UploadFileCardComponent {
    @Input() fileFormats: string[] = [];
    @Input() multiple: boolean = true;
    @Input() additionalText: string;
    @Input() disabled?: boolean = false;
    @Input() fileUploaded: boolean;
    @Input() fileList: any[] = [];
    @Output() onFileAdded: EventEmitter<any> = new EventEmitter<any>();
}
