import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';

@Injectable()
export class S3Api {
    constructor(private api: HttpService) {}

    getPresignedUrl(fileName: string, fileFormat: string, endpoint: string): Observable<APIResponse<PresignedURL>> {
        return this.api.post(`${endpoint}/presigned-url`, {fileName, fileFormat});
    }
    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': fileData.type,
            },
        });
    }
}
