import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {PersistedData, PersistedDataService} from '@core/interfaces/common/persisted-data';
import {PersistedDataApi} from '../api/persisted-data.api.service';

@Injectable()
export class PersistedDataServiceImpl extends PersistedDataService {
    constructor(private api: PersistedDataApi) {
        super();
    }

    findOne(id: string): Observable<APIResponse<PersistedData>> {
        return this.api.findOne(id);
    }

    listFiles(): Observable<DataSource> {
        return this.api.listFiles();
    }
}
