import {ViewCell} from '@mominsamir/ngx-smart-table';
import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {NbTooltipDirective, NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-truncated-text-cell',
    template: `
        <div #textElement>
            <ng-container>
                <span
                    class="truncated-text"
                    (mouseenter)="checkEllipsis()"
                    [nbTooltip]="value"
                    [nbTooltipTrigger]="textTruncated ? NbTrigger.HOVER : NbTrigger.NOOP"
                >
                    {{ value }}
                </span>
            </ng-container>
        </div>
    `,
    styleUrls: ['./truncated-text-cell.component.scss'],
})
export class TruncatedTextCellComponent implements ViewCell {
    @ViewChild('textElement', {static: true}) textElement: ElementRef;
    @ViewChild(NbTooltipDirective, {static: false}) tooltipDirective: NbTooltipDirective;

    @Input() value: any;
    @Input() rowData: any;

    public textTruncated: boolean;
    public readonly NbTrigger = NbTrigger;

    checkEllipsis(): void {
        const element = this.textElement.nativeElement;
        if (element) {
            this.textTruncated = element.offsetWidth < element.scrollWidth;
            if (this.textTruncated) this.tooltipDirective.show();
        }
    }
}
