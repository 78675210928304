<nb-card>
    <nb-card-header class="spb">
        <p class="primary-header">Documents</p>

        <button (click)="uploadFile()" nbButton status="primary">
            <nb-icon icon="plus-outline"></nb-icon>
            Add new
        </button>
    </nb-card-header>

    <nb-card-body
        [class.pointer-none]="loadingRefresh$ | async"
        [nbSpinner]="loadingRefresh$ | async"
        nbSpinnerSize="medium"
    >
        <ngx-data-table [reload]="reloadDataTable" [settings]="tableSettings" [source]="dataSource$"></ngx-data-table>
    </nb-card-body>
</nb-card>
