import {Injectable} from '@angular/core';
import {finalize, map, share} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Note, NoteService} from '@core/interfaces/common/note';

@Injectable()
export class NotesStore {
    public assetNotes = new BehaviorSubject<Note[]>([]);
    public assetNoteData$: Observable<Note[]> = this.assetNotes.asObservable().pipe(share());

    loaders = {
        notes: {
            all: false,
        },
    };

    loading: BehaviorSubject<any> = new BehaviorSubject(this.loaders);
    loading$: Observable<any> = this.loading.asObservable();

    constructor(private noteService: NoteService) {}

    getLoaders() {
        return this.loading.value;
    }

    public getAssetNotes(assetId: string) {
        let _loaders = this.getLoaders();

        this.loading.next({
            ..._loaders,
            notes: {
                ..._loaders.notes,
                all: true,
            },
        });
        this.noteService
            .getAssetNotes(assetId)
            .pipe(
                map((res: APIResponse<Note[]>) => res.response),
                finalize(() => {
                    _loaders = this.getLoaders();

                    this.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            all: false,
                        },
                    });
                }),
            )
            .subscribe((data) => this.setNotes(data));
    }

    setNotes(data: Note[]) {
        this.assetNotes.next(data);
    }
}
