import {ReportConfig, ReportConfigService, ReportTemplate} from '@core/interfaces/engin/reporting/report-config';
import {Injectable} from '@angular/core';
import {ReportConfigApi} from '@core/backend/engin/api/reporting/report-config.api';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class ReportConfigServiceImpl extends ReportConfigService {
    constructor(private api: ReportConfigApi) {
        super();
    }

    public getReportConfigOptions(): Observable<APIResponse<ReportConfig>> {
        return this.api.getReportConfigOptions();
    }

    public listReportTemplates(): Observable<APIResponse<ReportTemplate[]>> {
        return this.api.listReportTemplates();
    }

    public getOneReportTemplate(templateCode: string): Observable<APIResponse<ReportTemplate>> {
        return this.api.getOneReportTemplate(templateCode);
    }
}
