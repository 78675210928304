import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {APIResponse} from '@core/interfaces/system/system-common';
import {IntegrationFile, IntegrationFileRenameDto, LandingZoneService} from '@core/interfaces/landing-zone';
import {LandingZoneApi} from '@core/backend/engin/api/landing-zone.api';
import {GuideInfo} from '@theme/components';

@Injectable()
export class LandingZoneServiceImpl extends LandingZoneService {
    constructor(private api: LandingZoneApi) {
        super();
    }

    getIntegrationFileById(integrationFileId: number): Observable<APIResponse<IntegrationFile>> {
        return this.api.getIntegrationFileById(integrationFileId);
    }

    getListCurrent(): Observable<DataSource> {
        return this.api.getListCurrent();
    }

    getListHistory(): Observable<DataSource> {
        return this.api.getListHistory();
    }

    public getGuideInfoCurrent(): Observable<APIResponse<GuideInfo[]>> {
        return this.api.getGuideInfoCurrent();
    }

    public getGuideInfoHistory(): Observable<APIResponse<GuideInfo[]>> {
        return this.api.getGuideInfoHistory();
    }

    downloadIntegrationFile(integrationFileId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadIntegrationFile(integrationFileId);
    }

    renameIntegrationFile(integrationFileId: number, name: string): Observable<APIResponse<any>> {
        return this.api.renameIntegrationFile(integrationFileId, name);
    }

    retryIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>> {
        return this.api.retryIntegrationFileProcess(integrationFileId);
    }

    deleteIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>> {
        return this.api.deleteIntegrationFileProcess(integrationFileId);
    }
}
