<nb-card class="card card-full-page">
    <nb-card-header class="card-header">
        <label class="primary-header">{{ title }}</label>
        <label class="sub-header">
            {{ subTitle }}
        </label>
    </nb-card-header>

    <!-- show static values from uploaded files -->
    <nb-card-body class="table-card-body">
        <table class="full-page-table">
            <!-- Header -->
            <thead>
                <tr>
                    <th *ngFor="let header of tableColumns" [ngStyle]="{width: header.width}">
                        {{ header.title }}
                    </th>
                </tr>
            </thead>

            <!-- One row per file -->
            <tbody>
                <tr *ngFor="let file of fileList.value; let i = index">
                    <!-- File name, render file details cell -->
                    <td>
                        <ngx-display-file [file]="file"></ngx-display-file>
                    </td>

                    <!-- File type -->
                    <td>
                        <p class="default-text">{{ file.fileTypeLabel }}</p>
                    </td>

                    <!-- Validation status -->
                    <td class="m-3"><ngx-status-cell [value]="file.validationStatus"></ngx-status-cell></td>

                    <!-- Actions -->
                    <td class="file-action-col">
                        <ngx-file-action-column
                            (action)="onActionClick($event, i)"
                            [file]="file"
                        ></ngx-file-action-column>
                    </td>
                </tr>
            </tbody>
        </table>
    </nb-card-body>
</nb-card>
