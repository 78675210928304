import {Directive, HostListener, Input} from '@angular/core';

@Directive({
    selector: '[ngxOnlyNumber]',
})
export class OnlyNumberDirective {
    @Input() integer: boolean = true;

    @HostListener('keydown', ['$event']) onKeyDown(event) {
        const isCtrlOrCmd = event.ctrlKey || event.metaKey;

        // Allow Ctrl/Cmd + V (paste)
        if ((isCtrlOrCmd && event.key === 'v') || event.key === 'c') {
            return;
        }
        //Allow Arrows, delete, backspace, tab, enter
        if ([8, 9, 13, 37, 38, 39, 40, 46].includes(event.keyCode)) return;
        if ((this.integer && !/\d/.test(event.key)) || (!this.integer && !/[0-9,\.]/.test(event.key))) {
            event.preventDefault();
        }
    }

    // Listen for the "paste" event (to validate pasted values)
    @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
        let pastedData = event.clipboardData?.getData('text') || '';

        // Regular expression to check for valid number (integer or decimal based on input)
        const isValidNumber = this.integer ? /^\d+$/.test(pastedData) : /^[0-9]+(\.[0-9]*)?$/.test(pastedData);

        if (!isValidNumber) {
            event.preventDefault();
        } else {
            event.preventDefault(); // Prevent the default paste behavior

            const target = event.target as HTMLInputElement;
            const currentValue = target.value;

            // Get the caret (cursor) position
            const start = target.selectionStart || 0;
            const end = target.selectionEnd || 0;

            // Replace the selected text with the pasted data
            target.value = currentValue.slice(0, start) + pastedData + currentValue.slice(end);

            // Set the caret position to after the pasted data
            target.setSelectionRange(start + pastedData.length, start + pastedData.length);
            target.dispatchEvent(new Event('input'));
        }
    }
}
