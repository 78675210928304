<div class="dialog-container">
    <nb-card>
        <!-- Header -->
        <nb-card-header>
            <div class="container-fluid">
                <div class="row justify-content-between">
                    <div class="col-3">
                        {{ dialogTitle }}
                    </div>
                    <div class="col-1" (click)="onClose()">
                        <nb-icon icon="close" class="close"></nb-icon>
                    </div>
                </div>
            </div>
        </nb-card-header>

        <!-- Body, list of details with title and value -->
        <nb-card-body class="p-0">
            <nb-list class="p-0" *ngIf="aboutInfo$ | async as aboutInfo">
                <nb-list-item *ngFor="let info of aboutInfo">
                    <div class="container-fluid">
                        <div class="row justify-content-center">
                            <div class="col-4 title">{{ info.title }}</div>
                            <div class="col-7 value">{{ info.value }}</div>
                        </div>
                    </div>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
</div>
