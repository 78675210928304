import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    forwardRef,
    Input,
    ViewChild,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'ngx-custom-slider',
    template: `
        <div class="slider-container">
            <input
                type="range"
                class="progress"
                [(ngModel)]="sliderValue"
                #slider
                (input)="setValue(+slider.value)"
                [attr.min]="minValue"
                [attr.max]="maxValue"
            />
        </div>
    `,
    styleUrls: ['./slider.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SliderComponent),
            multi: true,
        },
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SliderComponent implements ControlValueAccessor, AfterViewInit {
    @Input('min') minValue: number;
    @Input('max') maxValue: number;

    @ViewChild('slider') slider: ElementRef;

    private onChange: any = () => {};

    constructor(private cd: ChangeDetectorRef) {}

    public ngAfterViewInit() {
        this.colorSlider();
    }

    get progressWidth(): number {
        return Math.round((this.sliderValue - this.minValue) / ((this.maxValue - this.minValue) / 100));
    }

    private _sliderValue: number = 0;

    get sliderValue(): number {
        return this._sliderValue;
    }

    @Input('value')
    set sliderValue(value: number) {
        this._sliderValue = value;
        this.onChange(value);
        this.cd.markForCheck();
    }

    setValue(value: number) {
        this.sliderValue = +value;

        this.colorSlider();
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {}

    writeValue(value: number): void {
        this.setValue(value);
    }

    colorSlider() {
        if (this.slider?.nativeElement)
            this.slider.nativeElement.style.background = `linear-gradient(to right, #fe9339 ${this.progressWidth}%, #DDE1EB ${this.progressWidth}%)`;
    }
}
