import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PowerMeterReadingApi} from '@core/backend/engin/api/load-profile-analysis/power-meter-reading.api';
import {PowerMeterReadingService} from '@core/interfaces/engin/load-profile-analysis/power-meter-reading';
import {SimpleSeries} from '@core/interfaces/system/system-common';
import {LpInfrastructureRequest} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';

@Injectable()
export class PowerMeterReadingServiceImpl extends PowerMeterReadingService {
    constructor(private api: PowerMeterReadingApi) {
        super();
    }

    public getInfrastructureNetLoad(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]> {
        return this.api.getInfrastructureNetLoad(batchNumber, data);
    }
}
