import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {SignaturePad} from 'angular2-signaturepad';

@Component({
    selector: 'ngx-form-field-signature-dialog',
    templateUrl: './signature-dialog.component.html',
    styleUrls: ['./signature-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureDialogComponent {
    // https://www.npmjs.com/package/angular2-signaturepad
    @ViewChild('signaturePad')
    public signaturePad: SignaturePad;

    preparedImage;
    // TODO: dynamic height/width from parent component
    public signaturePadOptions: Object = {
        minWidth: 5,
        canvasWidth: 400,
        canvasHeight: 250,
    };

    constructor(protected ref: NbDialogRef<SignatureDialogComponent>, private changeDetectorRef: ChangeDetectorRef) {}

    // Triggered when draw event starts i.e. on mouse up? or some other action?
    // TODO: does not seem to actually be triggered
    drawComplete() {
        this.preparedImage = this.signaturePad.toDataURL('image/png');
        this.changeDetectorRef.detectChanges();
    }

    // Triggered when draw event starts i.e. on mouse down? or or on first draw to empty canvas only?
    // TODO: does not seem to actually be triggered
    drawStart() {}

    clear() {
        this.signaturePad.clear();
        this.preparedImage = null;
    }

    save() {
        this.preparedImage = this.signaturePad.toDataURL('image/png');
        this.ref.close({contents: this.preparedImage});
    }

    cancel() {
        this.ref.close();
    }
}
