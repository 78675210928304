<nav class="navbar navbar-dark bg-dark">
    <!--  position: absolute; top: 0; z-index: 999
    <button nbButton status='success' style='margin-right: 15px'
            (click)='printPage()'>
      <nb-icon icon='printer-outline'></nb-icon>
    </button>-->
    <button nbButton status="primary" class="mr-3" (click)="savePDF(reportContainer)">
        <nb-icon icon="download-outline"></nb-icon>
    </button>
</nav>
