import {Injectable} from '@angular/core';
import {SurvivalAnalysisApi} from './survival-analysis.api';
import {Observable} from 'rxjs';
import {SurvivalAnalysisService} from '../../../@core/interfaces/engin/survival-analysis';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {CurveCalibrationDetails} from './pojo/curve-calibration-outputs.pojo';

@Injectable()
export class SurvivalAnalysisServiceImpl extends SurvivalAnalysisService {
    constructor(private api: SurvivalAnalysisApi) {
        super();
    }

    getAnalysisHistoryData(): Observable<DataSource> {
        return this.api.getAnalysisHistoryData();
    }

    getCurveCalibrationList(workflowItemId: number): Observable<DataSource> {
        return this.api.getCurveCalibrationList(workflowItemId);
    }

    getCurveCalibrationData(workflowItemId: number, curveId: number): Observable<APIResponse<CurveCalibrationDetails>> {
        return this.api.getCurveCalibrationData(workflowItemId, curveId);
    }

    getWorkflowDtoFull(workflowItemId: number): Observable<APIResponse<any>> {
        return this.api.getWorkflowDtoFull(workflowItemId);
    }

    acceptCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>> {
        return this.api.acceptCurve(workflowItemId, curveId, comment);
    }
    rejectCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>> {
        return this.api.rejectCurve(workflowItemId, curveId, comment);
    }
}
