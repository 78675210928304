import {ExtraOptions, PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {NgModule} from '@angular/core';
import {NbLogoutComponent} from '@nebular/auth';
import {AuthGuard} from '@auth/auth.guard';
import {PagesComponent} from './pages/pages.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {LoginComponent} from './pages/auth/login-form/login.component';
import {VerifyEmailComponent} from './pages/auth/verify-email/verify-email.component';
import {UpdatePasswordComponent} from './pages/auth/update-password/update-password.component';
import {ForgotPasswordComponent} from './pages/auth/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/auth/reset-password/reset-password.component';
import {SelectOrganizationComponent} from './pages/auth/select-organization/select-organization.component';
import {BrowserUtils} from '@azure/msal-browser';
import {SensitiveInfoDisclosureLoginComponent} from '@theme/components/sensitive-info-disclosure/sensitive-info-disclosure-login/sensitive-info-disclosure-login.component';
import {CustomNbAuthComponent} from '@theme/components/auth/nb-auth/custom-nb-auth.component';

const config: ExtraOptions = {
    useHash: true,
    preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    relativeLinkResolution: 'legacy',
    initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled',
};

const routes: Routes = [
    {
        path: '',
        canActivate: [AuthGuard],
        component: PagesComponent,
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full',
            },
            {
                path: 'dashboard',
                component: DashboardComponent,
            },
            // Modules
            {
                path: 'analyzer',
                loadChildren: () => import('./pages/analyzer/analyzer.module').then((m) => m.AnalyzerModule),
            },
            {
                path: 'optimizer',
                loadChildren: () => import('./pages/optimizer/optimizer.module').then((m) => m.OptimizerModule),
            },
            {
                path: 'program-management',
                loadChildren: () =>
                    import('./pages/program-management/program-management.module').then(
                        (m) => m.ProgramManagementModule,
                    ),
            },
            {
                path: 'forecaster',
                loadChildren: () => import('./pages/forecaster/forecaster.module').then((m) => m.ForecasterModule),
            },
            {
                path: 'geospatial',
                loadChildren: () =>
                    import('./pages/geospatial-viewer/geospatial-viewer.module').then((m) => m.GeospatialViewerModule),
            },
            {
                path: 'live',
                loadChildren: () => import('./pages/live/live.module').then((m) => m.LiveModule),
            },
            {
                path: 'load-forecast',
                loadChildren: () =>
                    import('./pages/load-forecast/load-forecast.module').then((m) => m.LoadForecastModule),
            },
            {
                path: 'data-preparation',
                loadChildren: () =>
                    import('./pages/data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
            },
            {
                path: 'data-prep-tool',
                loadChildren: () =>
                    import('./pages/data-preparation/data-preparation.module').then((m) => m.DataPreparationModule),
            },
            {
                path: 'maintenance-planning',
                loadChildren: () =>
                    import('./pages/maintenance-planning/maintenance-planning.module').then(
                        (m) => m.MaintenancePlanningModule,
                    ),
            },
            // Tools & Add-ons
            {
                path: 'survival-analysis',
                loadChildren: () =>
                    import('./pages/survival-analysis/survival-analysis.module').then((m) => m.SurvivalAnalysisModule),
            },
            {
                path: 'data-audit',
                loadChildren: () => import('./pages/data-audit/data-audit.module').then((m) => m.DataAuditModule),
            },
            {
                path: 'connectivity-analysis',
                loadChildren: () =>
                    import('./pages/connectivity-analysis/connectivity-analysis.module').then(
                        (m) => m.ConnectivityAnalysisModule,
                    ),
            },
            {
                path: 'reporting-tool',
                loadChildren: () => import('./pages/reporting-tool/reports.module').then((m) => m.ReportsModule),
            },
            {
                path: 'asset-registry',
                loadChildren: () =>
                    import('./pages/asset-registry/asset-registry.module').then((m) => m.AssetRegistryModule),
            },
            {
                path: 'load-profile',
                loadChildren: () =>
                    import('./pages/load-profile-analysis/load-profile-analysis.module').then(
                        (m) => m.LoadProfileAnalysisModule,
                    ),
            },
            // Archive
            {
                path: 'data-management',
                loadChildren: () =>
                    import('./pages/data-management/data-management.module').then((m) => m.DataManagementModule),
            },
            {
                path: 'persisted-data-management',
                loadChildren: () =>
                    import('./pages/live-data-management/data-management.module').then(
                        (m) => m.LiveDataManagementModule,
                    ),
            },
            {
                path: 'landing-zone',
                loadChildren: () => import('./pages/landing-zone/landing-zone.module').then((m) => m.LandingZoneModule),
            },

            {
                path: 'study-history',
                loadChildren: () =>
                    import('./pages/study-history/study-history.module').then((m) => m.StudyHistoryModule),
            },
            {
                path: 'workflow-validation',
                loadChildren: () =>
                    import('./pages/study-validation/workflow-validation.module').then(
                        (m) => m.WorkflowValidationModule,
                    ),
            },
            // Configuration
            {
                path: 'user-management',
                loadChildren: () => import('./pages/users/user-management.module').then((m) => m.UserManagementModule),
            },
            {
                path: 'mobile-admin',
                loadChildren: () => import('./pages/mobile-admin/mobile-admin.module').then((m) => m.MobileAdminModule),
            },
            // Demos
            {
                path: 'planner',
                loadChildren: () => import('./pages/demo/planner/planner.module').then((m) => m.PlannerModule),
            },
            {
                path: 'apm',
                loadChildren: () => import('./pages/demo/apm/apm.module').then((m) => m.ApmModule),
            },
            {
                path: 'health-index-tool',
                loadChildren: () =>
                    import('./pages/demo/health-builder/health-builder.module').then((m) => m.HealthBuilderModule),
            },
        ],
    },

    {
        path: 'auth',
        component: CustomNbAuthComponent,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full',
            },
            {
                path: 'login',
                component: LoginComponent,
            },
            {
                path: 'select-organization',
                canActivate: [AuthGuard],
                component: SelectOrganizationComponent,
            },
            {
                path: 'sensitive-info-disclosure',
                canActivate: [AuthGuard],
                component: SensitiveInfoDisclosureLoginComponent,
            },
            {
                path: 'update-password',
                canActivate: [AuthGuard],
                component: UpdatePasswordComponent,
            },
            {
                path: 'logout',
                canActivate: [AuthGuard],
                component: NbLogoutComponent,
            },
            {
                path: 'forgot-password',
                component: ForgotPasswordComponent,
            },
            {
                path: 'reset-password',
                component: ResetPasswordComponent,
            },
            {
                path: 'verify-email',
                component: VerifyEmailComponent,
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
