import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';

export enum APM {
    PLAY,
    PAUSE,
}

@Injectable()
export class FlaskService {
    constructor(private http: HttpClient) {}

    get apiUrl(): string {
        /* Alternative URLs
         * Demo new: 'http://3.97.75.152:9092/engin/v2/python'
         * Demo old: 'http://3.92.158.175:9091/engin/v2/python'
         * Local: Local: 'http://127.0.0.1:2978/engin/v2/python'
         */
        let pythonUrl = environment.PYTHON_API_URL
            ? environment.PYTHON_API_URL
            : 'http://3.97.75.152:9092/engin/v2/python';
        pythonUrl = pythonUrl + '/apm';
        return pythonUrl;
    }

    public getCurrent(endpoint: string): Observable<any> {
        return this.get(endpoint + '/current');
    }

    public getNext(endpoint: string): Observable<any> {
        return this.get(endpoint + '/next');
    }

    public get(endpoint: string, options?): Observable<any> {
        return this.http.get(`${this.apiUrl}/${endpoint}`, options);
    }

    resetData(endpoint: string, time?): Observable<any> {
        /*
          return timer(0, time).pipe(
              concatMap(_ => this.http.delete(`${this.apiUrl}/${endpoint}`)),
          );
         */
        return this.http.delete(`${this.apiUrl}/${endpoint}`);
    }
}
