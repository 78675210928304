<input [formControl]="inputFormControl" [nbAutocomplete]="autoControl" fullWidth nbInput type="text" />
<nb-autocomplete #autoControl (selectedChange)="onSelectedChange($event)" [handleDisplayFn]="viewHandle">
    <nb-option *ngFor="let option of optionsList.asObservable() | async" [value]="option" class="custom-default-option">
        <ng-template *ngTemplateOutlet="customTemplate || default; context: {$implicit: option}"></ng-template>
    </nb-option>
</nb-autocomplete>

<ng-template #default let-option>
    <div class="option">
        <b class="mb-1">{{ option.name }}</b>
        <div class="info ellipsis">{{ option.description }}</div>
    </div>
</ng-template>
