<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>
    <!-- Main content -->
    <!-- Note: This components does not support "previous" values -->
    <div>
        <ng-container [ngSwitch]="viewMode">
            <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
                <div class="photo-container" *ngIf="mediaLoaded | async">
                    <ng-container *ngIf="signature.value as signatureFile">
                        <nb-card class="file-upload-card" *ngIf="signatureFile.url">
                            <nb-card-body class="file-upload-body">
                                <div *ngIf="pageMode === FormMode.EDIT" class="trash-button-container">
                                    <button ghost status="basic" nbButton (click)="remove()">
                                        <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                                    </button>
                                </div>
                                <!-- Image should always be "edit mode" i.e. allow events to open carousel -->
                                <img
                                    [src]="signatureFile.url"
                                    [alt]="signatureFile.fileName"
                                    (click)="openCarousel(signatureFile)"
                                    (error)="handleImageError(signatureFile)"
                                    class="photo file-upload-image"
                                />
                            </nb-card-body>
                        </nb-card>
                    </ng-container>
                </div>

                <div class="mt-2" *ngIf="!signature?.value?.url">
                    <button
                        fullWidth
                        nbButton
                        status="basic"
                        size="small"
                        (click)="addSignature()"
                        [ngClass]="{
                            'view-mode': pageMode === FormMode.VIEW,
                            'edit-mode': pageMode === FormMode.EDIT,
                            'required-btn': fieldForm?.touched && fieldForm?.hasError('required')
                        }"
                    >
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD SIGNATURE
                    </button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
                <div class="photo-container" *ngIf="mediaLoaded | async">
                    <nb-card *ngIf="signature.value as signatureFile" class="file-upload-card">
                        <nb-card-body class="file-upload-body">
                            <div *ngIf="pageMode === FormMode.EDIT" class="trash-button-container">
                                <button ghost status="basic" nbButton (click)="remove()">
                                    <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                                </button>
                            </div>
                            <img
                                [src]="signatureFile.url"
                                [alt]="signatureFile.fileName"
                                (click)="openCarousel(signatureFile)"
                                class="photo file-upload-image"
                            />
                        </nb-card-body>
                    </nb-card>
                </div>
                <div class="mt-2" *ngIf="!signature?.value?.url">
                    <button disabled fullWidth nbButton size="small" status="basic" class="disable-btn">
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD SIGNATURE
                    </button>
                </div>
            </ng-container>

            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
                <div class="mt-2">
                    <button disabled fullWidth nbButton status="basic" size="small" class="disable-btn">
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD SIGNATURE
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
