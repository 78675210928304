<form [formGroup]="form" class="password-form" aria-labelledby="title" *ngIf="validation$ | async as validation">
    <!-- Expanded layout; input and validation within single column -->
    <div class="row" *ngIf="!layoutCompressed">
        <div class="form-control-group w-100">
            <!-- New password -->
            <div class="col-12">
                <label class="section-label" for="input-new-password">New Password</label>
                <ngx-input-password
                    controlName="password"
                    id="input-new-password"
                    fieldSize="medium"
                    [fc]="password"
                    (onInputChanges)="onPasswordChanged($event)"
                    name="password"
                    [pageMode]="pageMode"
                    [placeholder]="'New Password'"
                    [passValidation]="validation?.validation"
                ></ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="password"></ngx-input-error>
            </div>

            <div class="col-12 requirements-container">
                <div class="row">
                    <span class="section-label">Password requirements:</span>
                </div>
                <div class="row" *ngFor="let message of validation?.messages">
                    <ng-container [ngSwitch]="message.validation">
                        <div *ngSwitchCase="true" class="col-1 passed">
                            <nb-icon icon="checkmark-circle-2"></nb-icon>
                        </div>
                        <div *ngSwitchCase="false" class="col-1 failed"><nb-icon icon="close-circle"></nb-icon></div>
                    </ng-container>
                    <div class="col-11 password-requirement">
                        <label>{{ message.description }}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Compressed layout; side-by-side input to validation -->
    <div class="row" *ngIf="layoutCompressed">
        <div class="col-12">
            <div class="form-control-group">
                <!-- New password -->
                <label class="section-label" for="input-new-password2">
                    New Password{{ indicateRequired ? "*" : "" }}
                </label>
                <ngx-input-password
                    controlName="password"
                    id="input-new-password2"
                    fieldSize="medium"
                    [fc]="password"
                    (onInputChanges)="onPasswordChanged($event)"
                    name="password"
                    [pageMode]="pageMode"
                    [placeholder]="'New Password'"
                    [passValidation]="validation?.validation"
                ></ngx-input-password>
                <ngx-input-error [formGroup]="form" fieldName="password"></ngx-input-error>
            </div>
        </div>

        <div class="col-12 requirements-container">
            <div class="row">
                <span class="section-label">Password requirements:</span>
            </div>
            <div class="row" *ngFor="let message of validation?.messages">
                <ng-container [ngSwitch]="message.validation">
                    <div *ngSwitchCase="true" class="col-1 passed">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                    </div>
                    <div *ngSwitchCase="false" class="col-1 failed"><nb-icon icon="close-circle"></nb-icon></div>
                </ng-container>
                <div class="col-11 password-requirement">
                    <label>{{ message.description }}</label>
                </div>
            </div>
        </div>
    </div>
</form>
