import {Component} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {ThemeService} from '@core/utils/theme.service';

@Component({
    selector: 'ngx-accessibility-switcher',
    templateUrl: './accessibility-switcher.component.html',
    styleUrls: ['./accessibility-switcher.component.scss'],
})
export class AccessibilitySwitcherComponent {
    private currentTheme: string;

    constructor(private nbThemeService: NbThemeService) {
        this.nbThemeService.getJsTheme().subscribe((theme) => {
            this.currentTheme = theme.name;
        });
    }

    checkStatus() {
        return ThemeService.isAccessibility(this.currentTheme);
    }

    changeValue() {
        // Opposite of current selections
        const newAccessibility = !this.checkStatus();
        this.currentTheme = ThemeService.constructTheme(ThemeService.getThemeTone(this.currentTheme), newAccessibility);
        this.nbThemeService.changeTheme(this.currentTheme);
    }
}
