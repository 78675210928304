export enum ChartColors {
    color1 = '#ff708d', // figma color 11
    color2 = '#ffb660', // figma color 9
    color3 = '#fad36D', // figma color 2
    color4 = '#a0de92', // figma color 8
    color5 = '#00b383', // figma color 1
    color6 = '#a6aebd', // figma color 6
    color7 = '#776eff', // figma color 14
    color8 = '#ffabab', // figma color 3
    color9 = '#e3e88b', // figma color 7
    color10 = '#c853ff', // figma color 13
    color11 = '#579fe6', // figma color 4
    color12 = '#b7db00', // figma color 12
    color13 = '#34cad3', // figma color 15
    color14 = '#c36aed', // figma color 5
    color15 = '#4ca5ff', // figma color 10
}
export enum NamedColors {
    empty = '#9fa9bd',
}
export enum GradientCategoryColors {
    color1 = '#84db2c',
    color2 = '#b7db00',
    color3 = '#ffd500',
    color4 = '#ffaa00',
    color5 = '#ff5e29',
}
export enum GradientNumericColors {
    color1 = '#1996d2',
    color2 = '#69b9df',
    color3 = '#ffc776',
    color4 = '#f384ab',
    color5 = '#eb4e76',
}

export enum AccessibilityChartColors {
    color1 = '#f19f64', // figma color 11
    color2 = '#b22216', // figma color 9
    color3 = '#1554ea', // figma color 2
    color4 = '#0087fe', // figma color 8
    color5 = '#0e38a4', // figma color 1
    color6 = '#a6aebd', // figma color 6
    color7 = '#f6bd8a', // figma color 14
    color8 = '#489fef', // figma color 3
    color9 = '#0f2163', // figma color 7
    color10 = '#fff8d0', // figma color 13
    color11 = '#8BCEFF', // figma color 4 // waring color
    color12 = '#dbcd65', // figma color 12
    color13 = '#fff38b', // figma color 15
    color14 = '#cfe3fd', // figma color 5
    color15 = '#e94133', // figma color 10
}
export enum AccessibilityNamedColors {
    empty = '#9fa9bd',
}
export enum AccessibilityGradientCategoryColors {
    color1 = '#0088ff',
    color2 = '#6ebcff',
    color3 = '#ffe667',
    color4 = '#ffb728',
    color5 = '#ff5e29',
}
export enum AccessibilityGradientNumericColors {
    color1 = '#1996d2',
    color2 = '#7dc7e0',
    color3 = '#ffc776',
    color4 = '#ff9e9e',
    color5 = '#eb4e76',
}
