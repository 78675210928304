import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Router} from '@angular/router';
import {PagesStore, StudiesStore} from '../../../@store';
import {SupportService} from '@core/interfaces/common/support';
import {takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {GeospatialViewerService} from '../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {GeospatialViewerApi} from '../../../pages/geospatial-viewer/api/geospatial-viewer.api';
import {User} from '@core/interfaces/common/users';
import {UsersStore} from '@store/common/users.store';
import {HttpCancelService} from '@core/backend/common/services/http-cancel.service';
import {NbDialogService} from '@nebular/theme';
import {SystemInfoDialogComponent} from '@theme/components/system-info-dialog/system-info-dialog.component';
import {HelpersService} from '@core/utils/helpers.service';

@Component({
    selector: 'ngx-header-settings-dialog',
    templateUrl: './header-settings-dialog.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [GeospatialViewerService, GeospatialViewerApi],
})
export class HeaderSettingsDialogComponent extends Unsubscribable {
    @Input() user: User;
    showOrganizationList = false;
    hoveringOrganizationList = false;
    constructor(
        private router: Router,
        private studiesStore: StudiesStore,
        private usersStore: UsersStore,
        private pagesStore: PagesStore,
        private supportService: SupportService,
        private httpCancelService: HttpCancelService,
        private dialogService: NbDialogService,
        private helpersService: HelpersService,
    ) {
        super();
    }

    logout() {
        this.router.navigate(['/auth/logout']);
        this.studiesStore.clearStudyInfo();
        localStorage.removeItem('auth_app_token');

        this.httpCancelService.cancelRequests();

        if (!this.studiesStore.getActiveWorkflows()) {
            this.httpCancelService.forceCancelRequests();
        }

        this.usersStore.clearCurrentUser();

        this.pagesStore.resetPages();
    }

    switchOrganization() {
        this.router.navigate(['/auth/select-organization']);

        this.httpCancelService.cancelRequests();

        if (!this.studiesStore.getActiveWorkflows()) {
            this.httpCancelService.forceCancelRequests();
        }
        this.studiesStore.clearStudyInfo();

        this.pagesStore.resetPages();
    }

    // Generate URL and reroute user
    public navigateUrl() {
        this.supportService
            .accessKnowledgeBase()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res) => {
                // Open URL in new window
                if (res) {
                    this.helpersService.openPopup(res);
                }
            });
    }
    goToUserProfile() {
        this.router.navigate(['/user-management/user/view'], {queryParams: {username: this.user.username}});
    }
    openAbout() {
        this.dialogService.open(SystemInfoDialogComponent, {closeOnBackdropClick: false});
    }
}
