import {NbToastrService} from '@nebular/theme';
import {Injectable} from '@angular/core';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

@Injectable()
export class HelpersService {
    private firstBlockedPopUpMessageFlag: boolean = true;

    constructor(private toastrService: NbToastrService) {}

    public openPopup(url: string): void {
        const newWindow = window.open(url, '_blank');
        if (newWindow === null && this.firstBlockedPopUpMessageFlag) {
            // Pop-up was blocked
            this.toastrService.danger('Pop-up was blocked by the browser.', 'Please enable pop-ups for this site.', {
                duration: 10000,
            });

            this.firstBlockedPopUpMessageFlag = false;
            setTimeout(() => (this.firstBlockedPopUpMessageFlag = true), 10000);
        }
    }

    public downloadPDF(htmlElement: HTMLElement, fileName: string) {
        const pdf = new jsPDF();
        const h = htmlElement.clientHeight;
        const w = htmlElement.clientWidth;
        html2canvas(htmlElement).then((canvas) => {
            const contentWidth = canvas.width;
            const contentHeight = canvas.height;
            const pageHeight = (contentWidth / 592.28) * 841.89;
            let leftHeight = contentHeight;
            let position = 0;
            const imgWidth = 595.28;
            const imgHeight = (592.28 / contentWidth) * contentHeight;
            const pageData = canvas.toDataURL('image/jpeg', 1.0);
            const pdf = new jsPDF('p', 'pt', 'a4');
            let totalPage = 0;
            if (leftHeight < pageHeight) {
                pdf.addImage(pageData, 'JPEG', 0, 0, imgWidth, imgHeight);
            } else {
                while (leftHeight > 0) {
                    pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight);
                    leftHeight -= pageHeight;
                    position -= 841.89;
                    totalPage += 1;
                    if (leftHeight > 0) {
                        pdf.addPage();
                    }
                }
            }
            pdf.deletePage(totalPage);
            pdf.save(fileName);
        });
    }
    public formatFileSize(bytes: number): string {
        const MBSize = 1048576; // 1 MB = 1048576 bytes
        const BSize = 1024; // 1 KB = 1024 bytes
        const bytesSize = 1004;
        if (bytes >= MBSize) {
            return (bytes / MBSize).toFixed(0) + ' MB';
        } else if (bytes >= bytesSize) {
            return (bytes / BSize).toFixed(0) + ' KB';
        } else {
            return bytes + ' bytes';
        }
    }

    public parseFileSize(size: string): number {
        const sizeRegex = /^(\d+(?:\.\d+)?)\s?(bytes|KB|MB)$/i;
        const match = size.match(sizeRegex);

        if (!match) {
            throw new Error('Invalid size format');
        }

        const value = parseFloat(match[1]);
        const unit = match[2].toUpperCase();

        switch (unit) {
            case 'BYTES':
                return value;
            case 'KB':
                return value * 1024;
            case 'MB':
                return value * 1048576;
            default:
                throw new Error('Unsupported unit');
        }
    }
}
