import Field from '@arcgis/core/layers/support/Field';

export class RoadMoratoriumFieldsService {
    public getCustomMapFields(): Field[] {
        return this._fields.map((e) => new Field(e));
    }

    private _fields: any[] = [
        // Unique identifiers
        {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
        },
        // Data
        {
            name: 'closureType',
            alias: 'type',
            type: 'string',
        },
        {
            name: 'closureName',
            alias: 'name',
            type: 'string',
        },
        {
            name: 'closureDescription',
            alias: 'description',
            type: 'string',
        },
        {
            name: 'reason',
            alias: 'reason',
            type: 'string',
        },
        {
            name: 'status',
            alias: 'status',
            type: 'string',
        },
        {
            name: 'streetName',
            alias: 'streetName',
            type: 'string',
        },
        {
            name: 'streetFrom',
            alias: 'streetFrom',
            type: 'string',
        },
        {
            name: 'streetTo',
            alias: 'streetTo',
            type: 'string',
        },
        {
            name: 'dateFrom',
            alias: 'dateFrom',
            type: 'string',
        },
        {
            name: 'dateTo',
            alias: 'dateTo',
            type: 'string',
        },
    ];
}
