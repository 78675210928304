import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, combineLatest, of} from 'rxjs';
import {distinctUntilChanged, filter, mergeMap, shareReplay, switchMap, take, tap} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {User} from '@core/interfaces/common/users';
import {PmSensitivityDto, PmSensitivityService} from '@core/interfaces/engin/program-management/pm-sensitivity';

@Injectable()
export class ProgramManagementSensitivityStore {
    protected _sensitivityGlobal = new BehaviorSubject<boolean>(true);
    protected _sensitivityId = new BehaviorSubject<number>(null);
    readonly sensitivityId$ = this._sensitivityId.asObservable();

    constructor(
        private pmSensitivityService: PmSensitivityService,
        private usersStore: UsersStore,
        private studiesStore: StudiesStore,
    ) {
        this.studiesStore.activeWorkflows$
            .pipe(
                filter((v) => v !== null && v.length !== 0),
                filter((res) => !!this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY)),
                take(1),
                mergeMap(() => of(this.getSensitivity(true))),
            )
            .subscribe();
    }

    public getSensitivity(global: boolean = false): any {
        this._sensitivityGlobal.next(global);
        combineLatest<Observable<User>, Observable<number>>([
            this.usersStore.currentUser,
            of(this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY)),
        ])
            .pipe(
                filter(([user, activeStudy]) => user !== null && activeStudy !== undefined && activeStudy !== -1),
                switchMap(([user, activeStudy]: [User, number]) => {
                    return this.pmSensitivityService.getSensitivity({
                        userId: user.id,
                        workflowItemId: activeStudy,
                        global: this._sensitivityGlobal.value,
                    });
                }),
            )
            .subscribe((res: APIResponse<PmSensitivityDto>) => this._sensitivityId.next(res.response.id));

        return true;
    }

    get sensitivityId() {
        return this._sensitivityId.getValue();
    }

    get sensitivityGlobal() {
        return this._sensitivityGlobal.getValue();
    }
}
