<ng-container *ngIf="summaryChart$ | async as summaryChart">
    <div *ngIf="!ignoreFormControl">
        <nb-card class="summary-card">
            <nb-card-body class="chart-card">
                <div class="chart-card-container" *ngIf="graphsFormGroup" [formGroup]="graphsFormGroup">
                    <ngx-chart-settings
                        *ngIf="chartInstance"
                        [chartInstance]="chartInstance"
                        [unitOptions]="unitOptions"
                        [labelOptions]="labelOptions"
                        [graphId]="graphId"
                        [formControlName]="summaryChart?.metadata.graphId"
                        [chartOptions]="summaryChart?.options"
                        [chartType]="EC_TYPE_LOCAL"
                    ></ngx-chart-settings>
                </div>
                <ngx-chart
                    [options]="summaryChart?.options"
                    (onChartInstanceInit)="chartInstance = $event"
                    height="150px"
                ></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="height-100" *ngIf="ignoreFormControl">
        <nb-card class="summary-card">
            <nb-card-body class="chart-card">
                <ngx-chart [options]="summaryChart?.options" height="150px"></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>
</ng-container>
