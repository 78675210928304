<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <!-- Main content -->
    <ng-container *ngIf="cellType === FormCellType.PREVIOUS; else currentField">
        <nb-checkbox disabled [checked]="getFormValue()">Select</nb-checkbox>
    </ng-container>

    <ng-template #currentField [ngSwitch]="viewMode">
        <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
            <div>
                <nb-checkbox
                    [formControl]="fieldForm"
                    (checkedChange)="checkedChange($event)"
                    [ngClass]="{
                        'view-mode': pageMode === FormMode.VIEW,
                        'edit-mode': pageMode === FormMode.EDIT,
                        required: fieldForm?.touched && fieldForm?.hasError('required')
                    }"
                >
                    Select
                </nb-checkbox>
            </div>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
            <ng-container>
                <div>
                    <nb-checkbox [checked]="getFormValue()">Select</nb-checkbox>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
            <nb-checkbox disabled>Select</nb-checkbox>
        </ng-container>
    </ng-template>
</div>
