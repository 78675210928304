import {Observable} from 'rxjs';

// Data audit tool
export enum DATA_AUDIT_VIEW_OPTIONS {
    DATA_LOAD = 'data_load',
    ASSET_CLASS = 'asset_class',
    DATA_POINT = 'data_point',
    ANALYTIC = 'analytic',
}

// Request
export class DataAuditRequest {
    filterList: {
        activeView: string;
        fieldPhysical: string;
        options: [BooleanSet];
        stringFlag: boolean;
    }[];

    constructor(filterList: any) {
        this.filterList = filterList;
    }
}

// Data structure for main chart
export class DataAuditChartResponse {
    graphId: string;
    graphTitle: string;
    series: SimpleSeries[];
}

// Data structure for recent history
export class DataAuditHistoryResponse {
    measures: DataMeasureHistory[];
}

export class DataMeasureHistory {
    series: SimpleSeries;
    trend: string;
}

// Data structure for context data
export class DataAuditContextResponse {
    sections: DataMeasureContext[];
}

export class DataMeasureContext {
    code: string;
    depth: number;
    data: ContextItem[];
    unit: Unit;
    dataTotal?: ContextItem;
}

export class ContextItem {
    name: string;
    isCollapsed: boolean;
    value?: number;
    children?: ContextItem[];
}

// Common/shared data formats
export interface BooleanSet {
    [code: string]: boolean;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

export class SimpleSeries {
    code: string;
    legend: string;
    labels: string[];
    data: number[];
    unit: Unit;
}

// Response
export interface DataAuditResponse {
    // === View
    viewSummary: AccordionCountData[];

    // === Data Load
    dataLoadChartData: ChartResponse;

    // === Asset
    assetChartData: ChartResponse;

    // === Data Point
    dataPointChartData: ChartResponse;
}

export class ChartResponse {
    title: string;
    categoryList: string[];
    chartDataList: ChartData[];
}

export class ChartData {
    legend: string;
    data: any[];
    trend: string;
}

export class AccordionCountData {
    title: string;
    isCollapsed: boolean;
    number: string;
    dataTotal: AccordionCountData;
    children: AccordionCountData[];
}

// Data quality widget
export enum QUALITY_STATUS_TYPE {
    DISABLED = 'disabled',
    POOR = 'poor',
    FAIR = 'fair',
    GOOD = 'good',
}

export interface DataAuditStudyDataQuality {
    qualityStatus: QUALITY_STATUS_TYPE;
    data: DataQualityItem[];
}

export interface DataQualityItem {
    code: string;
    name: string;
    trend: string;
    value: number;
    unit: Unit;
}

export abstract class AssetDataAuditService {
    // Data audit tool
    abstract getContextData(req: DataAuditRequest): Observable<DataAuditContextResponse>;

    abstract getRecentHistory(req: DataAuditRequest): Observable<DataAuditHistoryResponse>;

    abstract getMetricChartByLoad(req: DataAuditRequest): Observable<DataAuditChartResponse>;

    abstract getMetricChartByAsset(req: DataAuditRequest): Observable<DataAuditChartResponse>;

    abstract getMetricChartByPoint(req: DataAuditRequest): Observable<DataAuditChartResponse>;

    abstract getMetricChartByAnalytic(req: DataAuditRequest): Observable<DataAuditChartResponse>;

    // Data quality widget
    abstract getWorkflowDataQuality(workflowId: string, req: DataAuditRequest): Observable<DataAuditStudyDataQuality>;
}
