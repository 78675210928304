import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Page} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-submenu-popover',
    template: `
        <div>
            <div [id]="id" class="submenu-container">
                <div class="header-container">
                    <span>{{ item.title }}</span>
                </div>
                <ngx-submenu [items]="item.children"></ngx-submenu>
            </div>
            <div class="pointer"></div>
        </div>
    `,
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubmenuPopoverComponent implements OnInit, AfterViewInit {
    @Input() item: Page;

    id: string;

    public ngOnInit() {
        this.id = this.item.title.trim().split(' ').join('');
    }

    public ngAfterViewInit() {
        setTimeout(() => {
            const submenu = document.getElementById(this.id);

            // Get the bounding box of the submenu
            const rect = submenu.getBoundingClientRect();

            // Check if the submenu is overflowing the viewport (window)
            const isOverflowing = rect.bottom > window.innerHeight;

            // If overflowing, adjust the position
            if (isOverflowing) {
                const overlap = rect.bottom - window.innerHeight;
                submenu.style.top = `-${overlap + 26}px`; // Move the submenu upwards by the overlap amount
            }
        }, 0);
    }
}
