import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ConfirmDialogComponent, EditNoteDialogComponent} from '@theme/components';
import {filter, finalize, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {Note, NoteCreate, NoteService} from '@core/interfaces/common/note';
import {NbDialogService, NbMenuService} from '@nebular/theme';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Observable} from 'rxjs';
import {NotesStore} from '@store/common/notes.store';

@Component({
    selector: 'ngx-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./notes.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() assetId: string;

    _actions = [
        {title: 'Edit', name: 'EDIT', icon: 'edit-outline'},
        {title: 'Delete', name: 'DELETE', icon: 'trash-2-outline'},
    ];

    assetNoteData$: Observable<Note[]> = this.notesStore.assetNoteData$;
    pendingNotes$: Observable<boolean> = this.notesStore.loading$.pipe(map((loaders) => loaders.notes));

    constructor(
        private nbMenuService: NbMenuService,
        private dialogService: NbDialogService,
        private noteService: NoteService,
        private notesStore: NotesStore,
    ) {
        super();
    }

    ngOnInit(): void {
        this.nbMenuService
            .onItemClick()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(({tag}) => ['context-menu-note-action'].includes(tag)),
                map(({item}) => item),
            )
            .subscribe((item) => this.menuSelectedActionClick(item));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId) {
            this.refreshNotes();
        }
    }

    addNote() {
        this.dialogService
            .open(EditNoteDialogComponent, {
                context: {},
                closeOnBackdropClick: false,
                closeOnEsc: true,
            })
            .onClose.pipe(
                filter((res) => res),
                mergeMap((res) => {
                    let _loaders = this.notesStore.getLoaders();
                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            notes: {
                                ..._loaders.notes,
                                all: true,
                            },
                        },
                    });

                    const newNote: NoteCreate = {
                        documentType: 'ASSET',
                        relatedEntity: this.assetId,
                        note: res.note,
                    };
                    return this.noteService.create(newNote);
                }),
                finalize(() => {
                    let _loaders = this.notesStore.getLoaders();

                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            notes: {
                                ..._loaders.notes,
                                all: false,
                            },
                        },
                    });
                }),
            )
            .subscribe(() => this.refreshNotes());
    }

    getActions(note: Note) {
        return this._actions.map((item) => ({...item, param: note}));
    }

    menuSelectedActionClick = (data: any) => {
        switch (data.name) {
            case 'EDIT':
                this.editNote(data['param']);
                return;
            case 'DELETE':
                this.deleteNote(data['param']);
                return;
            default:
                throw new Error('Option not implemented yet ' + data.name);
        }
    };

    editNote(data: Note) {
        this.dialogService
            .open(EditNoteDialogComponent, {
                context: {noteData: data},
                closeOnBackdropClick: false,
                closeOnEsc: true,
            })
            .onClose.pipe(
                filter((res) => res),
                tap(() => {
                    let _loaders = this.notesStore.getLoaders();
                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            notes: {
                                ..._loaders.notes,
                                [data.id]: true,
                            },
                        },
                    });
                }),
                mergeMap((res) => this.noteService.update(data.id, {...data, note: res.note})),
                finalize(() => {
                    let _loaders = this.notesStore.getLoaders();

                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            notes: {
                                ..._loaders.notes,
                                [data.id]: false,
                            },
                        },
                    });
                }),
            )
            .subscribe(() => this.refreshNotes());
    }

    deleteNote(data: Note) {
        let confirmParam: any = {
            title: `Delete Note`,
            message: `Are you sure you want to delete the note?`,
        };
        return this.dialogService
            .open(ConfirmDialogComponent, {
                context: confirmParam,
                closeOnBackdropClick: false,
            })
            .onClose.pipe(
                filter((res) => !!res.confirm),
                mergeMap(() => {
                    let _loaders = this.notesStore.getLoaders();
                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            [data.id]: true,
                        },
                    });
                    return this.noteService.delete(data.id);
                }),
                finalize(() => {
                    let _loaders = this.notesStore.getLoaders();

                    this.notesStore.loading.next({
                        ..._loaders,
                        notes: {
                            ..._loaders.notes,
                            [data.id]: false,
                        },
                    });
                }),
            )
            .subscribe(() => this.refreshNotes());
    }

    refreshNotes() {
        this.notesStore.getAssetNotes(this.assetId);
    }
}
