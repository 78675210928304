import {ChangeDetectionStrategy, Component, Input, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

export interface GuideInfo {
    elementType: GuideInfoElementTypes;
    value?: string;
    children?: GuideInfo[];
}

export enum GuideInfoElementTypes {
    Section = 'SECTION', //value is a section title
    Paragraph = 'PARAGRAPH', //value is paragraph text
    OrderedList = 'ORDERED_LIST', //value is a title for ordered list
    UnorderedList = 'UNORDERED_LIST', //value is a title for unordered list
    ListElement = 'LIST_ELEMENT', //value is a list element's text
}

@Component({
    selector: 'ngx-guide-dialog',
    templateUrl: './guide-dialog.component.html',
    styleUrls: ['./guide-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GuideDialogComponent {
    @Input() title?: string = 'Data Translation Guide';
    @Input() guideData: GuideInfo[];

    GuideInfoElementTypes = GuideInfoElementTypes;

    constructor(@Optional() public dialogRef: NbDialogRef<any>) {}
}
