import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export interface SidebarStatus {
    expanded: boolean;
    isMainPage: boolean;
}

@Injectable()
export class SidebarStore {
    /**
     * expanded is toggle between expanded and collapsed sidebar
     * isMainPage is toggle between normal layout (with header, sidebar) or not
     */
    private sidebarStatus = new BehaviorSubject<SidebarStatus>({
        expanded: false,
        isMainPage: true,
    });
    readonly sidebarStatus$: Observable<SidebarStatus> = this.sidebarStatus.asObservable();

    toggleSidebarState(expanded: boolean) {
        this.sidebarStatus.next({
            expanded: expanded,
            isMainPage: true,
        });
    }

    setMainPage(isMainPage: boolean) {
        this.sidebarStatus.next({
            expanded: this.sidebarStatus.getValue().expanded,
            isMainPage: isMainPage,
        });
    }
}
