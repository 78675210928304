import {ErrorHandler, ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NbAuthModule} from '@nebular/auth';

import {throwIfAlreadyLoaded} from './module-import-guard';
import {ChartsService, ColorsService, FontsService, FormatsService, TimezoneService} from './utils';

import {CommonBackendModule} from './backend/common/common-backend.module';
import {EnginBackendModule} from './backend/engin/engin-backend.module';

import {EnginErrorHandlerService} from './error-handling/engin-error-handler.service';
import {NbToastrService} from '@nebular/theme';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {GlobalErrorHandler} from '@core/error-handling/global-error-handler';
import {HelpersService} from '@core/utils/helpers.service';

export const NB_CORE_PROVIDERS = [
    ...CommonBackendModule.forRoot().providers,

    ...EnginBackendModule.forRoot().providers,

    ChartsService,
    ColorsService,
    FontsService,
    HelpersService,
    FormatsService,
    TimezoneService,
    NbToastrService,
    UsageAnalyticsService,
];

@NgModule({
    imports: [CommonModule],
    exports: [NbAuthModule],
    declarations: [],
    providers: [EnginErrorHandlerService, {provide: ErrorHandler, useClass: GlobalErrorHandler}],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [...NB_CORE_PROVIDERS],
        };
    }
}
