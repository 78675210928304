import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
    selector: 'ngx-help-support-side-bar',
    templateUrl: './help-support-side-bar.component.html',
    styleUrls: ['./help-support-side-bar.component.scss'],
})
export class HelpSupportSideBarComponent {
    @Input() expanded;
    @Input() text;
    @Output() openSupportDialog = new EventEmitter();

    constructor() {}
}
