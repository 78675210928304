/**
 * API Exception DTO
 */
export function isAPIException(error: any): error is APIException {
    return error && typeof error === 'object' && 'name' in error && error.name === 'APIException';
}

export interface APIException {
    name: string;
    message: string;
    stack?: string;
    code?: any;
    exceptions: ExceptionDto[];
}

export interface ExceptionDto {
    code: string;
    message: string;
    exceptionDetail: string;
    field: string;
    errorDisplayType: 'GLOBAL' | 'FIELD';
    errorLevel: ExceptionDisplayLevel;
}

export enum ExceptionDisplayLevel {
    DANGER = 'DANGER',
    WARNING = 'WARNING',
    INFO = 'INFO',
    NONE = 'NONE',
}
