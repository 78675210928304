import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {OutputReportSource, ReportRequest, Study, StudyCollection} from '@core/interfaces/engin/jobs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {HttpParams} from '@angular/common/http';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class JobsApi {
    private readonly prefix = 'study';

    constructor(private api: HttpService) {}

    getStudyCollectionsDataSource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/collections/datasource`);
    }

    getStudyCollections(): Observable<StudyCollection[]> {
        return this.api.get(`${this.prefix}/collections/list`);
    }

    getJobAssetInfo(studyId: string, assetId: string): Observable<any> {
        return this.api.get(`analyzer/studyAssets/assetDetails/${studyId}/${assetId}`);
    }

    getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource> {
        return this.api.post(`${this.prefix}/report/generic`, reportRequest);
    }

    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any> {
        const params = new HttpParams()
            .set('useDefault', latestAsDefault.toString())
            .set('defaultCollectionId', defaultCollectionId);
        return this.api.post(`${this.prefix}/updateDefaultStudyStrategy`, null, {params: params});
    }

    getLatestCompletedStudyCollection(): Observable<StudyCollection> {
        return this.api.get(`${this.prefix}/getLatestCompletedStudyCollection`);
    }

    /* deprecated */
    legacyExecuteNewStudyCollection(automated: boolean, dataType?: string, date?: string): Observable<any> {
        const description = (automated ? 'Data refresh ' : 'Run on ') + date.slice(0, 10);
        const name = automated ? 'Automated multi-study run' : 'Manual multi-study run';
        const body = {
            automatedExecution: automated,
            name: name,
            description: description,
        };
        return this.api.post(`${this.prefix}/legacy-execute`, body);
    }

    executeNewStudyFromSnapshot(snapshotId: number, automated: boolean, date?: string): Observable<any> {
        const description = (automated ? 'Data refresh ' : 'Run on ') + date.slice(0, 10);
        const name = automated ? 'Automated multi-study run' : 'Manual multi-study run';
        const body = {
            snapshotId: snapshotId,
            automatedExecution: automated,
            name: name,
            description: description,
        };
        return this.api.post(`${this.prefix}/execute/snapshot`, body);
    }

    /* deprecated */
    legacyExecuteHealthIndexStudy(automated: boolean, date?: string): Observable<any> {
        const description = (automated ? 'Data refresh ' : 'Run on ') + date.slice(0, 10);
        const name = automated ? 'Automated HI calculation' : 'Manual HI calculation';
        const body = {
            automatedExecution: automated,
            name: name,
            description: description,
        };
        return this.api.post(`${this.prefix}/legacy-execute/hi`, body);
    }

    getStudyCollectionById(collectionId: string): Observable<StudyCollection> {
        return this.api.get(`${this.prefix}/collection/${collectionId}`);
    }

    getStudyById(studyId: string): Observable<Study> {
        return this.api.get(`${this.prefix}/study/${studyId}`);
    }

    updateStudyCollectionLabel(studyId: string, label: string): Observable<any> {
        return this.api.post(`${this.prefix}/updateStudyCollectionLabel`, {
            studyId: studyId,
            label: label,
        });
    }

    findBySnapshotId(id: string): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/find/snapshot/${id}`);
    }
}
