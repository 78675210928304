import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {DownloadURL, PeriodFilter} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LoadProfileInfo} from '@core/interfaces/engin/load-forecast/meter';
import {TransformerInfo} from '@core/interfaces/engin/load-forecast/dx-transformer';

@Injectable()
export class DxTransformerLoadForecastApi {
    private readonly PREFIX_DXTX = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for dx transformer(s)
     */
    public getDxTransformerInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<TransformerInfo[]> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/info`,
        );
    }

    public getDxTransformerPivot(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/pivot?${params}`,
        );
    }

    public getDxTransformerPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('filterByseasonId_IN', seasonIds.join('|'))
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getLoadProfileInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<LoadProfileInfo> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/load-profile/info`,
        );
    }

    public getLoadProfileRaw(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<SimpleSeries<Date, number>> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/load-profile/raw`,
        );
    }

    public getLoadProfileTypicalDay(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        groupBy: string,
    ): Observable<SimpleMultiSeries<number>> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/load-profile/typical-day/${groupBy}`,
        );
    }

    public getDxTransformerInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/infrastructure/list`,
        );
    }

    public getDxTransformerInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_DXTX}/${workflowItemId}/scenarios/${scenarioId}/dx-transformers/${transformerId}/infrastructure/list/csv`,
        );
    }
}
