export class RoadMoratoriumPopupService {
    /*
     * Render popup for road closure feature. Works for point or line features equivalently.
     */
    getCustomPopoutFunction = (feature) => {
        const div = document.createElement('div');

        const type = feature.graphic.attributes.type;
        const description = feature.graphic.attributes.description;

        const reason = feature.graphic.attributes.reason;
        const status = feature.graphic.attributes.status;

        const streetName = feature.graphic.attributes.streetName;
        const streetFrom = feature.graphic.attributes.streetFrom;
        const streetTo = feature.graphic.attributes.streetTo;

        const dateFrom = feature.graphic.attributes.dateFrom;
        const dateTo = feature.graphic.attributes.dateTo;

        div.innerHTML = `<div class='container'>
                         <div class="row">
                            <div class="col">
                            <p>${type}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            <p style="font-size: 12px; margin-bottom: 8px;">Road Name: ${streetName}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Start Date: ${dateFrom}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">End Date: ${dateTo}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Street From: ${streetFrom}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Street To: ${streetTo}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Status: ${status}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Reason: ${reason}</p>
                            <p style="font-size: 12px; margin-bottom: 8px;">Description: ${description}</p>
                            </div>
                        </div>
                      </div>`;
        return div;
    };
}
