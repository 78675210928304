import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Observable} from 'rxjs';
import {AssetColumn} from '@core/interfaces/common/asset';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-validation-message',
    styleUrls: ['./validation-message.component.scss'],
    template: `
        <p class="caption status-danger" *ngIf="showMinLength">
            Min {{ label }} length is {{ minLength }} {{ characterName }}
        </p>
        <p class="caption status-danger" *ngIf="showMaxLength">
            Max {{ label }} length is {{ maxLength }} {{ characterName }}
        </p>
        <p class="caption status-danger" *ngIf="showPattern">Incorrect {{ label }}</p>
        <p class="caption status-danger" *ngIf="showRequired">{{ label }} is required</p>
        <p class="caption status-danger" *ngIf="showMin">Min value of {{ label }} is {{ min }}</p>
        <p class="caption status-danger" *ngIf="showMax">Max value of {{ label }} is {{ max }}</p>
        <ng-container *ngIf="errorMessage">
            <p class="caption status-danger">{{ errorMessage }}</p>
        </ng-container>
    `,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NgxValidationMessageComponent),
            multi: true,
        },
    ],
})
export class NgxValidationMessageComponent extends Unsubscribable implements OnInit {
    @Input()
    characterName: string = 'symbols';

    @Input()
    label: string = '';

    @Input()
    showRequired?: boolean;

    @Input()
    min?: number;

    @Input()
    showMin?: boolean;

    @Input()
    max?: number;

    @Input()
    showMax: boolean;

    @Input()
    minLength?: number;

    @Input()
    showMinLength?: boolean;

    @Input()
    maxLength?: number;

    @Input()
    showMaxLength?: boolean;

    @Input()
    showPattern?: boolean;

    @Input() customValidator: FormControl;

    errorMessage: string;
    ngOnInit(): void {
        this.customValidator?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((error) => {
            switch (error.errorType) {
                case 'projectNameAlreadyExists':
                    this.errorMessage = error.message;
                    break;
                default:
                    this.errorMessage = null;
                    break;
            }
        });
    }
}
