import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {StudiesStore} from '../../../@store';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {GeospatialViewerService} from './geospatial-viewer.service';
import {debounceTime, filter, map, shareReplay} from 'rxjs/operators';
import {GeospatialViewerControlsStore} from './geospatial-viewer-controls.store';
import {MapValueFilter, Measure, Metric, ValueType} from '../model/metric';
import {GeospatialRequest, GeospatialResponse} from '../model/api';
import {GeospatialFilterStore} from './geospatial-filter.store';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {FormBuilder, FormControl} from '@angular/forms';
import {Project, ProjectInfoData} from '../model/project';
import {User, UsersService} from '@core/interfaces/common/users';
import {VisualizationType} from '../model/visualization';
import {UsersStore} from '@store/common/users.store';
import {Filter} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerStore {
    // Projects
    public projectEditBoundaryFlag = new BehaviorSubject<boolean>(false); // able to edit project boundary
    private refreshProjectData: number = null;
    private reloadProjectData: BehaviorSubject<number> = new BehaviorSubject(0);
    readonly reloadProjectData$: Observable<number> = this.reloadProjectData.asObservable();
    public existingCustomDrawingDataForm = this.fb.group({
        queryData: new FormControl(''),
        geometry: new FormControl(''),
    });
    public cachedProjectData: Project = null;

    constructor(
        private fb: FormBuilder,
        private geospatialViewerService: GeospatialViewerService,
        private controlsStore: GeospatialViewerControlsStore,
        public geospatialFilterStore: GeospatialFilterStore,
        private studiesStore: StudiesStore,
        private usersService: UsersService,
        private usersStore: UsersStore,
    ) {}

    public onRefreshProjectData() {
        if (this.refreshProjectData === null) this.refreshProjectData = 0;
        else this.refreshProjectData++;

        this.reloadProjectData.next(this.refreshProjectData);
    }

    // API data sources
    private combineMapControls$ = combineLatest<
        Observable<MapValueFilter>,
        Observable<any>,
        Observable<VisualizationType>,
        Observable<Measure>,
        Observable<Metric>,
        Observable<number>
    >([
        this.controlsStore.mapValueFilter$,
        this.controlsStore.valueFilterChangeFlag.asObservable(),
        this.controlsStore.selectedVisualizationType$,
        this.controlsStore.selectedMeasure$,
        this.controlsStore.selectedMetric$,
        this.studiesStore.activeStudyIdRisk$,
    ]);

    // API Request Object
    readonly mapGeoDataReq$: Observable<GeospatialRequest> = combineLatest<
        Observable<any>,
        Observable<Filter[]>,
        Observable<GeospatialViewerConfiguration>
    >([this.combineMapControls$, this.geospatialFilterStore.currentFilters$, this.controlsStore.config$]).pipe(
        filter(
            ([[_0, _1, visualizationType, measureSelected, metricSelected, studyId], _10, _20]: [
                [MapValueFilter, any, VisualizationType, Measure, Metric, string],
                any,
                any,
            ]) => {
                return (
                    measureSelected != null && metricSelected != null && visualizationType != null && studyId != null
                );
            },
        ),
        filter(([_0, _1, config]: [any, any, GeospatialViewerConfiguration]) => {
            return (
                config != null &&
                config.centerCoordinate != null &&
                config.centerCoordinate.longitude != null &&
                config.centerCoordinate.latitude != null
                // config.centerCoordinate.xmin != null &&
                // config.centerCoordinate.xmax != null &&
                // config.centerCoordinate.ymin != null &&
                // config.centerCoordinate.ymax != null
            );
        }),
        debounceTime(500),
        map(
            ([
                [mapValueFilter, filterChangedFlag, visualizationType, measureSelected, metricSelected, studyId],
                filterList,
                config,
            ]: [
                [MapValueFilter, any, VisualizationType, Measure, Metric, number],
                Filter[],
                GeospatialViewerConfiguration,
            ]) => {
                if (!measureSelected || !metricSelected) return null;
                // Create specific data filters
                let valueFilter;
                if (metricSelected.valueType === ValueType.CATEGORY) {
                    valueFilter = {
                        stringFilter: ['ALL'],
                    };
                    if (mapValueFilter && mapValueFilter.categoryList && mapValueFilter.categoryList.length > 0) {
                        valueFilter = {
                            stringFilter: mapValueFilter.categoryList,
                        };
                    }
                } else if (metricSelected.valueType === ValueType.NUMERIC) {
                    valueFilter = {
                        numberMin: null,
                        numberMax: null,
                    };
                    if (mapValueFilter && mapValueFilter.range) {
                        valueFilter = {
                            numberMin: mapValueFilter.range.min,
                            numberMax: mapValueFilter.range.max,
                        };
                    }
                }

                let req: GeospatialRequest = {
                    visualizationType: visualizationType,
                    measure: measureSelected.code,
                    metric: metricSelected.code,
                    valueType: metricSelected.valueType,
                    filterList: filterList,
                    valueFilter: valueFilter,
                    studyId: studyId,
                    centerConfig: config.centerCoordinate,
                };
                return req;
            },
        ),
        shareReplay(1),
    );

    // API calls data
    readonly mapGeoData$: Observable<GeospatialResponse> = this.mapGeoDataReq$.pipe(
        switchMap((req: GeospatialRequest) => {
            return this.geospatialViewerService.getMapGeoData(req.studyId, req);
        }),
        shareReplay(1),
    );

    readonly projectInfoData$: Observable<ProjectInfoData> = combineLatest<
        Observable<User>,
        Observable<User[]>,
        Observable<GeospatialViewerConfiguration>,
        Observable<Measure>
    >([
        this.usersStore.currentUser$,
        this.usersService.getUsersListData(),
        this.controlsStore.initialConfig$, // does not need to follow updates after init
        this.controlsStore.selectedMeasure$,
    ]).pipe(
        map(
            ([currentUser, userList, config, currentMeasure]: [
                User,
                User[],
                GeospatialViewerConfiguration,
                Measure,
            ]) => {
                return {
                    currentUser: currentUser,
                    userList: userList,
                    config: {
                        ...config,
                        currentMeasure: currentMeasure.code,
                    },
                };
            },
        ),
    );

    // TODO: map popover chart
    readonly abstractChartData$: Observable<any> = this.combineMapControls$.pipe(
        switchMap(() => {
            return null;
        }),
    );
}
