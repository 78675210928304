import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    AvailabilityData,
    ForecasterRequest,
    ForecasterResponse,
    ForecasterService,
} from '../../../interfaces/engin/forecaster';
import {ForecasterApi} from '../api/forecaster.api';

@Injectable()
export class ForecasterServiceImpl extends ForecasterService {
    constructor(private api: ForecasterApi) {
        super();
    }

    getForecasterData(request: ForecasterRequest, studyId: number): Observable<ForecasterResponse[]> {
        return this.api.getForecasterData(request, studyId);
    }

    getStationAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData> {
        return this.api.getStationAvailabilityData(request);
    }

    getLineAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData> {
        return this.api.getLineAvailabilityData(request);
    }
}
