import {Injectable} from '@angular/core';
import {ReportDataApi} from '@core/backend/engin/api/reporting/report-data.api';
import {ReportDataService} from '@core/interfaces/engin/reporting/report-data';
import {AnalyzerRequest, AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {Observable} from 'rxjs';

@Injectable()
export class ReportDataServiceImpl extends ReportDataService {
    constructor(private api: ReportDataApi) {
        super();
    }

    public getAnalyzerAbstractGraphData(req: AnalyzerRequest, workflowItemId: number): Observable<AnalyzerResponse[]> {
        return this.api.getAnalyzerAbstractGraphData(req, workflowItemId);
    }

    public getAnalyzerCurrentDataQuality(req: AnalyzerRequest, workflowItemId: number): Observable<AnalyzerResponse[]> {
        return this.api.getAnalyzerCurrentDataQuality(req, workflowItemId);
    }
}
