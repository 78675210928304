import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {User, UsersService} from '@core/interfaces/common/users';
import {BehaviorSubject} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {AuthService} from '@core/interfaces/common/auth';
import {finalize, map, mergeMap, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NbAuthOAuth2Token, NbTokenService} from '@nebular/auth';
import {UsersStore} from '@store/common/users.store';
import {StudiesStore} from '@store/common/studies.store';
import {NbToastrService} from '@nebular/theme';
import {PagesStore} from '@store/config/pages.store';
import {PagesService} from '@core/interfaces/common/pages';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'ngx-select-organization',
    templateUrl: './select-organization.component.html',
    styleUrls: ['./select-organization.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectOrganizationComponent extends Unsubscribable implements OnInit {
    private sensitiveInfoLogin: boolean = false;

    public organizationList = new BehaviorSubject<{value: string; label: string}[]>(null);
    public loginButtonDisabled = new BehaviorSubject<boolean>(false);
    public currentOrganization: string;
    public initOrganization: string;
    public infoToastr;
    public requestsPending$ = this.studiesStore.requestsPending$.pipe(
        tap((pending) => {
            this.loginButtonDisabled.next(pending);
            if (pending && !this.infoToastr)
                this.infoToastr = this.toastrService.info(
                    'Completing the logout process of the previous user, this should only take a moment. Please do not leave this page...',
                    'Please, wait.',
                );
            else if (!pending && this.infoToastr?.isOpen) this.infoToastr?.close();
        }),
    );

    constructor(
        public pagesStore: PagesStore,
        private router: Router,
        private userService: UsersService,
        private userStore: UsersStore,
        private studiesStore: StudiesStore,
        private authService: AuthService,
        private injector: Injector,
        private toastrService: NbToastrService,
        private pagesService: PagesService,
    ) {
        super();
    }

    ngOnInit() {
        this.sensitiveInfoLogin = environment?.SENSITIVE_INFO_LOGIN;

        this.userService.getCurrentUser().subscribe((user: User) => {
            const tenantList: {value: string; label: string}[] = user.tenants.map((tenant) => {
                return {
                    value: tenant,
                    label: tenant,
                };
            });
            this.organizationList.next(tenantList);
            this.currentOrganization = user.activeTenantId;
            this.initOrganization = user.activeTenantId;
        });
    }

    onOrganizationChange(value) {
        this.currentOrganization = value;
    }

    login() {
        if (this.currentOrganization) {
            this.loginButtonDisabled.next(true);
            this.authService
                .switchOrganization(this.currentOrganization)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    tap((resp: any) => {
                        // Manually reset the auth token

                        const token = new NbAuthOAuth2Token(resp, 'email');
                        this.nbTokenService.set(token);
                    }),
                    mergeMap(() => {
                        return this.userStore.refreshUser();
                    }),
                    switchMap(() => {
                        // add validator for tenant setting.
                        return this.pagesService.getPages().pipe(
                            takeUntil(this.unsubscribe$),
                            map((groups) => {
                                if (groups.length !== 0) {
                                    return this.router.navigateByUrl('/dashboard');
                                } else {
                                    this.toastrService.danger(
                                        `Login failed with ${this.currentOrganization}`,
                                        'Please contact your administrator',
                                    );
                                    this.rollBackOrganizationChanges();
                                }
                            }),
                        );
                    }),
                    finalize(() => this.loginButtonDisabled.next(false)),
                )
                .subscribe(() => {
                    if (this.sensitiveInfoLogin) {
                        this.router.navigate(['/auth/sensitive-info-disclosure']);
                    } else {
                        this.router.navigateByUrl('/dashboard');
                    }
                });
        }
    }

    rollBackOrganizationChanges() {
        if (this.initOrganization) {
            this.authService
                .switchOrganization(this.initOrganization)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    tap((resp: any) => {
                        // Manually reset the auth token
                        const token = new NbAuthOAuth2Token(resp, 'email');
                        this.nbTokenService.set(token);
                    }),
                    mergeMap(() => {
                        return this.userStore.refreshUser();
                    }),
                )
                .subscribe();
        }
    }
    protected get nbTokenService(): NbTokenService {
        return this.injector.get(NbTokenService);
    }
}
