import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DashboardCardBody, PageGroup} from '@core/interfaces/common/pages';
import {WorkflowInfo} from '@core/interfaces/common/users';

@Injectable()
export class PagesApi {
    private readonly apiController: string = 'pages';

    constructor(private api: HttpService) {}

    getPages(): Observable<PageGroup[]> {
        return this.api.get(`${this.apiController}/`);
    }

    getDashboardCardBodyData(activeWorkflows: WorkflowInfo[] = []): Observable<DashboardCardBody[]> {
        const req = {
            activeWorkflows: activeWorkflows,
        };
        return this.api.post(`${this.apiController}/dashboard`, req);
    }
}
