import {Component, Input, OnInit} from '@angular/core';
import {DashboardCardBodyTable, TableValueType} from '@core/interfaces/common/pages';
import {FormatsService} from '@core/utils';
import {NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-dashboard-card-table',
    templateUrl: './dashboard-card-table.component.html',
    styleUrls: ['./dashboard-card-table.component.scss'],
})
export class DashboardCardTableComponent implements OnInit {
    @Input() body: DashboardCardBodyTable;

    public readonly TableValueType = TableValueType;
    public readonly NbTrigger = NbTrigger;

    public rows;
    public tableType: TableValueType;

    protected readonly FormatsService = FormatsService;

    ngOnInit() {
        if (this.body?.tableType === TableValueType.NumberValues) {
            this.rows = this.body?.rows.map((row) => ({
                ...row,
                value: row.valueInteger
                    ? FormatsService.prepareIntegerNumber(row.value)
                    : FormatsService.prepareValueFromUnit(row.value, row.unit),
            }));

            this.tableType = TableValueType.NumberValues;
        } else {
            this.rows = this.body?.rows;

            this.tableType = TableValueType.StringValues;
        }
    }
}
