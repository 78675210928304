import {GeospatialService} from '../../helper/geospatial-service';
import {SpatialEntityData} from '../../../../../../pages/geospatial-viewer/model/api';
import {AssetFieldsService} from '../../fields/asset-fields.service';
import {Injectable} from '@angular/core';
import {AssetPolygonRendererService} from '../../renderers/asset-polygon-renderer.service';
import {AssetGroupPopupService} from '../../popups/asset-group-popup.service';
import Graphic from '@arcgis/core/Graphic';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Polygon from '@arcgis/core/geometry/Polygon';

@Injectable()
export class PolygonLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private assetGroupPopupService: AssetGroupPopupService,
        private rendererService: AssetPolygonRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    /**
     * Create polygon layer for asset population
     * @return FeatureLayer
     */
    public preparePolygonLayer(polygonData: SpatialEntityData[], id: string, theme: string): FeatureLayer {
        const polygonSource: Graphic[] = this.createPolygonGraphics(polygonData);

        const polygonAssetLayer: FeatureLayer = new FeatureLayer({
            id: id ? id : 'asset_polygon_layer',
            source: polygonSource,
            renderer: this.rendererService.layerRenderer(theme),
            fields: this.fieldsService.getMapFields(),
            popupTemplate: {
                outFields: ['*'],
                content: this.assetGroupPopupService.getAssetGroupPopupFunction.bind(this.assetGroupPopupService),
            },
        });

        return polygonAssetLayer;
    }

    /*
     * Create array of Graphics, type = point. Return Graphic[]
     */
    private createPolygonGraphics(areaData: SpatialEntityData[]): Graphic[] {
        return areaData.map((area: SpatialEntityData) => {
            return new Graphic({
                geometry: new Polygon({
                    rings: area.geoJsonGeometry.coordinates,
                }),
                attributes: {
                    // Unique identifiers
                    id: area.id,
                    classCode: area.classCode,
                    // Data
                    displayCategory: area.displayCategory,
                    data: area.data,
                    count: area.count,
                },
            });
        });
    }
}
