<nb-card>
    <nb-card-body class="p-0 m-0">
        <table class="mini-data-table">
            <thead>
                <tr>
                    <th *ngFor="let header of headers">{{ header }}</th>
                </tr>
            </thead>
            <!-- Body for each section -->
            <tbody *ngFor="let section of sections">
                <ng-container *ngIf="sections.length > 1">
                    <tr>
                        <td [attr.colspan]="headers.length - 1">
                            {{ section.label }}
                        </td>
                        <td>{{ prepareValue(section.value, section.valueType) }}</td>
                    </tr>
                </ng-container>
                <tr *ngFor="let row of section.rows; let i = index">
                    <td
                        class="values"
                        *ngIf="i < headers.length - row.items.length"
                        [attr.rowspan]="section.rows.length"
                    ></td>
                    <ng-container *ngFor="let item of row.items">
                        <td>{{ prepareValue(item.value, item.valueType) }}</td>
                    </ng-container>
                </tr>
            </tbody>
        </table>
    </nb-card-body>
</nb-card>
