import {Injectable} from '@angular/core';
import {ReportingApi} from '../api/reporting.api';
import {OutputReportSource, ReportingService} from '@core/interfaces/engin/reporting';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class ReportingServiceImpl extends ReportingService {
    constructor(private api: ReportingApi) {
        super();
    }

    getOutputReportGeneric(reportRequest): Observable<OutputReportSource> {
        return this.api.getOutputReportGeneric(reportRequest);
    }

    getOutputReportAnalyzer(studyId: number, filters: string[]): Observable<OutputReportSource> {
        return this.api.getOutputReportAnalyzer(studyId, filters);
    }
    /* public initSettingsByWorkflowItem(workflowItemId: number): Observable<LoadForecastSettings> {
        return this.api.initSettingsByWorkflowItem(workflowItemId);
    }*/

    public getDataExtractPreview(
        workflowItemId: number,
        outputCategory: string,
        outputType: string,
    ): Observable<DataSource> {
        return this.api.getDataExtractPreview(workflowItemId, outputCategory, outputType);
    }

    public getDataExtractCsv(
        workflowItemId: number,
        outputCategory: string,
        outputType: string,
        outputResult: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getDataExtractCsv(workflowItemId, outputCategory, outputType, outputResult);
    }
}
