<div class="horizontal-scroll-container">
    <ng-container *ngIf="loading">
        <ngx-empty-kpi-chart
            *ngFor="let number of wide ? [0, 1, 2, 3] : [0, 1, 2, 3, 4, 5]"
            [wide]="wide"
        ></ngx-empty-kpi-chart>
    </ng-container>

    <ng-container *ngIf="!loading && data">
        <ngx-chart-kpi *ngFor="let chart of data" [data]="chart" [wide]="wide"></ngx-chart-kpi>
    </ng-container>
</div>
