import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ChartSortOption, ChartSortOptionOrder, ChartSortOptionSeries} from './chart-data-sort.interface';
import {IconDetails} from '../../../@core/interfaces/common/pages';

@Component({
    selector: 'ngx-chart-data-sort',
    templateUrl: './chart-data-sort.component.html',
    styleUrls: ['./chart-data-sort.component.scss'],
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ChartDataSortComponent), multi: true}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDataSortComponent implements ControlValueAccessor, OnInit {
    @Input() seriesArray: ChartSortOptionSeries[];
    @Input() sortOptionsArray: ChartSortOptionOrder[];
    @Input() selection: ChartSortOption;
    @Output() activeSort = new EventEmitter<ChartSortOption>();

    public _options: ChartSortOption[];
    public _selection: ChartSortOption;

    public iconDetails: IconDetails = {
        icon: 'sort-indicator',
        pack: 'engin',
    };

    constructor(private cd: ChangeDetectorRef) {}

    ngOnInit(): void {
        if (
            this.seriesArray != null &&
            this.seriesArray.length > 0 &&
            this.sortOptionsArray != null &&
            this.sortOptionsArray.length > 0
        ) {
            this._options = this.createChartSortOptions(this.seriesArray, this.sortOptionsArray);
            this._selection = this.selection;
        }
    }

    public changeSortSelection(uniqueCode: string) {
        const newSelection: ChartSortOption = this._options.filter((o) => o.uniqueCode === uniqueCode)[0];
        this._selection = newSelection;
        this.activeSort.emit(newSelection);
        this.cd.markForCheck();
    }

    private createChartSortOptions(series: ChartSortOptionSeries[], sortOptions: ChartSortOptionOrder[]) {
        const res = [];
        series.forEach((s) => {
            sortOptions.forEach((o) => {
                res.push(new ChartSortOption(s, o));
            });
        });
        return res;
    }

    /*
     * ControlValueAccessor methods could be cleaned-up
     */
    registerOnChange(fn) {
        this.changeSortSelection = fn;
    }

    registerOnTouched() {}

    writeValue(value: any): void {
        if (value) {
            this.selection = value;
        }
    }
}
