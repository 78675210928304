import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {APIResponse, PagingResponse} from '@core/interfaces/system/system-common';
import {Dataset, DatasetCreator, DatasetType, DatasetPreview} from '@core/interfaces/common/dataset';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {map} from 'rxjs/operators';

@Injectable()
export class DatasetApi {
    private readonly prefix = 'data/dataset';

    constructor(private api: HttpService) {}

    findOne(id: string): Observable<APIResponse<Dataset>> {
        return this.api.get(`${this.prefix}/${id}`);
    }

    findMany(ids: string[]): Observable<PagingResponse<Dataset>> {
        return this.api.get(`${this.prefix}/list/?filterByid_IN=${ids.join(',')}`);
    }

    getLatestDatasetConfig(dataSetTypes: string): Observable<APIResponse<Dataset[]>> {
        return this.api.get(`${this.prefix}/latest/${dataSetTypes}`);
    }

    create(data: DatasetCreator): Observable<APIResponse<Dataset>> {
        return this.api.post(`${this.prefix}/`, data);
    }

    update(id: string, data: Dataset): Observable<APIResponse<Dataset>> {
        return this.api.put(`${this.prefix}/${id}`, data);
    }

    delete(datasetId: string): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${datasetId}`, {});
    }

    reversePendingDelete(datasetId: string): Observable<APIResponse<boolean>> {
        return this.api.put(`${this.prefix}/reverse-pending-delete/${datasetId}`, {});
    }

    listLatestDataSet(pageSize: number, datasetType: string): Observable<Dataset[]> {
        return this.api
            .get(
                `${this.prefix}/list?pageNumber=1&pageSize=${pageSize}&filterBydatasetType_EQUAL=${datasetType}&sortBy=version&orderBy=desc`,
            )
            .pipe(map((resp) => resp.items));
    }

    listFiles(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }

    listFilesByType(dataType: DatasetType): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list?filterBydatasetType_EQUAL=${dataType}`);
    }

    listFilesByTypeAndStatus(dataType: DatasetType, status: string): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.prefix}/list?filterBydatasetType_EQUAL=${dataType}&filterBystatus_EQUAL=${status}`,
        );
    }

    getDatasetPreviewByIds(datasetIds: number[]): Observable<APIResponse<DatasetPreview[]>> {
        return this.api.post(`${this.prefix}/preview/results`, {datasetIds: datasetIds});
    }
}
