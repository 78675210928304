import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {APIResponse, Filter, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {
    InvestmentOverviewDto,
    OutcomeChartRequest,
    OutcomeChartResponse,
    OutcomeKpiResponse,
    PmProgramService,
    Program,
    ProgramBudget,
    ProgramBudgetPerYearDto,
    ProgramBudgetUpdate,
    ProgramCreateDto,
    ProgramFullDto,
    ProgramOutcomes,
} from '@core/interfaces/engin/program-management/pm-program';
import {PmProgramApi} from '../../api/program-management/pm-program.api';
import {map} from 'rxjs/operators';

@Injectable()
export class PmProgramServiceImpl extends PmProgramService {
    constructor(private api: PmProgramApi) {
        super();
    }
    // Program
    getProgramsList(): Observable<DataSource> {
        return this.api.getProgramsList();
    }

    getPrograms(params: Filter[]): Observable<Program[]> {
        return this.api.getPrograms(params).pipe(map((data) => data.items));
    }

    getProgramsListDto(): Observable<DataSource> {
        return this.api.getProgramsListDto();
    }

    getProgramById(programId: number): Observable<APIResponse<Program>> {
        return this.api.getProgramById(programId);
    }

    getFullProgramDtoById(programId: number): Observable<APIResponse<ProgramFullDto>> {
        return this.api.getFullProgramDtoById(programId);
    }

    createProgram(program: ProgramCreateDto): Observable<APIResponse<Program>> {
        return this.api.createProgram(program);
    }

    editProgramBudget(program: ProgramBudgetUpdate): Observable<APIResponse<ProgramFullDto>> {
        return this.api.editProgramBudget(program);
    }

    editProgramDetails(program: Program): Observable<APIResponse<Program>> {
        return this.api.editProgramDetails(program);
    }

    deleteProgram(programId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteProgram(programId);
    }

    validateProgramName(programName: string): Observable<boolean> {
        return this.api.validateProgramName(programName);
    }

    findProgramKpis(
        programId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>> {
        return this.api.findProgramKpis(programId, workflowItemId, sensitivityId);
    }

    findAllBySegment(segmentId: number): Observable<APIResponse<Program[]>> {
        return this.api.findAllBySegment(segmentId);
    }

    downloadProgramsListCsv(param: Map<string, any>): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadProgramsListCsv(param);
    }

    downloadProgramAssets(programId: number, workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadProgramAssets(programId, workflowItemId);
    }

    getKpiOptions(): Observable<APIResponse<string[]>> {
        return this.api.getKpiOptions();
    }

    getInvestmentPriorityOptions(): Observable<APIResponse<any>> {
        return this.api.getInvestmentPriorityOptions();
    }

    getProgramBudgetByOutcomes(
        param: ProgramOutcomes,
        workflowItemId: number,
    ): Observable<APIResponse<ProgramBudgetPerYearDto[]>> {
        return this.api.getProgramBudgetByOutcomes(param, workflowItemId);
    }

    getBudgetChart(param: ProgramBudget[], workflowItemId: number): Observable<APIResponse<SimpleMultiSeries<number>>> {
        return this.api.getBudgetChart(param, workflowItemId);
    }

    getInvestmentOverview(param: InvestmentOverviewDto): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.getInvestmentOverview(param);
    }

    getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataSummarySpending(request);
    }
    getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>> {
        return this.api.getChartDataSummaryOutcomes(request);
    }
    getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataProactiveForecast(request);
    }
    getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataReactiveForecast(request);
    }
    getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataFailureForecast(request);
    }
    getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataOutageForecast(request);
    }
    getChartDataRiskForecastByImpact(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataRiskForecastByImpact(request);
    }
    getChartDataRiskForecastByCategory(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>> {
        return this.api.getChartDataRiskForecastByCategory(request);
    }
}
