import {Observable} from 'rxjs';
import {SimpleUnit} from '@core/interfaces/system/system-common';
import {SensitivityConfiguration} from '@store/load-forecast/sensitivity-analysis.store';

export interface LoadForecastSettings {
    period: PeriodFilter;
    scenario: Setting;
    season: Setting;
    subsystem: Setting;
    station: Setting;
    feeder: Setting;
    vault: Setting;
    lineSection: Setting;
    dxTransformer: Setting;
    meter: Setting;
}

export interface PeriodFilter {
    min: string;
    max: string;
    current?: string;
}

export interface Setting {
    disabled: boolean;
    defaultKey: string;
    options: [StringSet];
}

export interface StringSet {
    [key: string]: string;
}

export interface SimpleSeriesOld {
    code?: string;
    legend: string;
    labels: string[];
    data: number[];
    unit?: SimpleUnit;
}

/*
 * DTOs: information
 */
export interface InfoBlock {
    parameter: string;
    value: string;
}

export interface InfrastructureForecastBySeasons {
    threshold: number;
    data: SimpleSeriesOld[];
}

/*
 * Enums
 */
export enum DemandFactorSeasonEnum {
    OrganicGrowthSummer = 'ORGANIC_GROWTH_S',
    OrganicGrowthWinter = 'ORGANIC_GROWTH_W',
    OrganicGrowthAnnual = 'ORGANIC_GROWTH_ALL',
    LoadTransfersSummer = 'LOAD_TRANSFER_S',
    LoadTransfersWinter = 'LOAD_TRANSFER_W',
    LoadTransfersAnnual = 'LOAD_TRANSFER_ALL',
    LargeLoadAdditionsSummer = 'LLA_S',
    LargeLoadAdditionsWinter = 'LLA_W',
    LargeLoadAdditionsAnnual = 'LLA_ALL',
    ElectricVehiclesSummer = 'ELECTRIC_VEHICLE_S',
    ElectricVehiclesWinter = 'ELECTRIC_VEHICLE_W',
    ElectricVehiclesAnnual = 'ELECTRIC_VEHICLE_ALL',
    PhotovoltaicsSummer = 'PHOTOVOLTAIC_S',
    PhotovoltaicsWinter = 'PHOTOVOLTAIC_W',
    PhotovoltaicsAnnual = 'PHOTOVOLTAIC_ALL',
}
export const DemandFactorSeasonLabel = {
    [DemandFactorSeasonEnum.OrganicGrowthSummer]: 'Organic Growth - S',
    [DemandFactorSeasonEnum.OrganicGrowthWinter]: 'Organic Growth - W',
    [DemandFactorSeasonEnum.OrganicGrowthAnnual]: 'Organic Growth - Annual',
    [DemandFactorSeasonEnum.LoadTransfersSummer]: 'Load Transfers - S',
    [DemandFactorSeasonEnum.LoadTransfersWinter]: 'Load Transfers - W',
    [DemandFactorSeasonEnum.LoadTransfersAnnual]: 'Load Transfers - Annual',
    [DemandFactorSeasonEnum.LargeLoadAdditionsSummer]: 'Large Load Additions - S',
    [DemandFactorSeasonEnum.LargeLoadAdditionsWinter]: 'Large Load Additions - W',
    [DemandFactorSeasonEnum.LargeLoadAdditionsAnnual]: 'Large Load Additions - Annual',
    [DemandFactorSeasonEnum.ElectricVehiclesSummer]: 'Electric Vehicles - S',
    [DemandFactorSeasonEnum.ElectricVehiclesWinter]: 'Electric Vehicles - W',
    [DemandFactorSeasonEnum.ElectricVehiclesAnnual]: 'Electric Vehicles - Annual',
    [DemandFactorSeasonEnum.PhotovoltaicsSummer]: 'Photovoltaics - S',
    [DemandFactorSeasonEnum.PhotovoltaicsWinter]: 'Photovoltaics - W',
    [DemandFactorSeasonEnum.PhotovoltaicsAnnual]: 'Photovoltaics - Annual',
};
export enum DemandFactorEnum {
    OrganicGrowth = 'ORGANIC_GROWTH',
    LoadTransfers = 'LOAD_TRANSFER',
    LargeLoadAdditions = 'LLA',
    ElectricVehicles = 'ELECTRIC_VEHICLE',
    Photovoltaics = 'PHOTOVOLTAIC',
}
export const DemandFactorLabel = {
    [DemandFactorEnum.OrganicGrowth]: 'Organic Growth',
    [DemandFactorEnum.LoadTransfers]: 'Load Transfers',
    [DemandFactorEnum.LargeLoadAdditions]: 'Large Load Additions',
    [DemandFactorEnum.ElectricVehicles]: 'Electric Vehicles',
    [DemandFactorEnum.Photovoltaics]: 'Photovoltaics',
};

export enum ScenarioEnum {
    Actual = 'ACTUAL',
    Low = 'LOW',
    Medium = 'MED',
    High = 'HIGH',
}

export const ScenarioLabel = {
    [ScenarioEnum.Actual]: 'Actual',
    [ScenarioEnum.Low]: 'Low',
    [ScenarioEnum.Medium]: 'Medium',
    [ScenarioEnum.High]: 'High',
};

export enum SeasonEnum {
    Summer = 'S',
    Winter = 'W',
    Annual = 'ALL',
}

export const SeasonLabel = {
    [SeasonEnum.Summer]: 'Summer',
    [SeasonEnum.Winter]: 'Winter',
    [SeasonEnum.Annual]: 'Annual',
};

export interface OutputReportSource {
    links: [string];
}

export interface DownloadURL {
    url: string;
}

export abstract class LoadForecastService {
    abstract initSettingsByWorkflowItem(workflowItemId: number): Observable<LoadForecastSettings>;

    abstract executeSensitivityAnalysis(workflowItemId: number, req: SensitivityConfiguration): Observable<any>;
}
