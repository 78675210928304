import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {HydrogenGasTrendingDashboardData, HydrogenUnits} from '@core/interfaces/live/hydrogen-tending';
import {LatestModelStatus} from '@core/interfaces/live/live';

@Injectable()
export class HydrogenTrendingApi {
    private readonly PREFIX = 'live/output/hydrogen-trending';

    constructor(private api: HttpService) {}

    public getHydrogenGasTrendingUnits(): Observable<HydrogenUnits> {
        return this.api.get(`${this.PREFIX}/units`);
    }

    public getHydrogenGasTrendingDatasource(id: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX}/list?filterByassetId=${id}`);
    }

    public getCountResults(assetId: string, uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX}/count`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    public getHydrogenGasTrendingLatestModelStatus(
        assetId: string,
        uploadDate?: string,
    ): Observable<LatestModelStatus> {
        return this.api
            .get(`${this.PREFIX}/latest`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<LatestModelStatus>) => res.response));
    }

    public getHydrogenGasTrendingDashboardData(
        chart: string,
        assetId: string,
        uploadDate?: string,
    ): Observable<HydrogenGasTrendingDashboardData> {
        return this.api
            .get(`${this.PREFIX}/data/${chart}`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<HydrogenGasTrendingDashboardData>) => res.response));
    }
}
