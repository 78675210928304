<nb-card *ngIf="document">
    <nb-card-header class="justify-content-between">
        <div class="primary-header" [nbTooltip]="fileName(document)" [nbTooltipTrigger]="NbTrigger.HOVER">
            {{ fileName(document) }}
        </div>

        <button
            [disabled]="(loadingDelete$ | async) || (loadingRefresh$ | async)"
            [nbContextMenuPlacement]="'bottom'"
            [nbContextMenu]="actions"
            class="table-action-btn"
            nbButton
            nbContextMenuTag="context-menu-upload-document-action"
        >
            <nb-icon icon="more-vertical"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-header>
        <div class="row w-100">
            <div class="col-4">Description</div>
            <div class="col-8 subheader" [nbTooltip]="document.description" [nbTooltipTrigger]="NbTrigger.HOVER">
                {{ document.description }}
            </div>
        </div>
    </nb-card-header>

    <nb-card-body [nbSpinner]="loadingRefresh$ | async" nbSpinnerSize="medium">
        <img *ngIf="!icon && documentUrl" [alt]="document.tag" [src]="documentUrl" class="main-img" />

        <div *ngIf="icon" class="not-image">
            <nb-icon [icon]="icon.icon" [pack]="icon.pack" class="file-icon"></nb-icon>

            <button (click)="downloadFile()" [disabled]="loadingDelete$ | async" class="mt-3" nbButton status="primary">
                <nb-icon icon="download-outline"></nb-icon>
                Download
            </button>
        </div>
    </nb-card-body>
</nb-card>
