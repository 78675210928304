import {Component, Input} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {HelpSupportDialogComponent} from '@theme/components/help-support/help-support-dialog/help-support-dialog.component';

@Component({
    selector: 'ngx-help-support',
    templateUrl: './help-support.component.html',
    styleUrls: ['./help-support.component.scss', '../menu/menu.component.scss'],
})
export class HelpSupportComponent extends Unsubscribable {
    @Input() expanded: boolean;

    constructor(private dialogService: NbDialogService) {
        super();
    }

    submitted = true;

    // Open support dialog
    public openSupportDialog() {
        this.dialogService.open(HelpSupportDialogComponent);
    }
}
