import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormBuilder} from '@angular/forms';
import {MapValueFilter, Measure, Metric} from '../model/metric';
import {QUICK_FILTER_LIST, QuickFilterCategory} from '../model/filter';
import {GeospatialFilterStore} from './geospatial-filter.store';
import {AdditionalMapLayer} from '../model/layers';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {VisualizationType} from '../model/visualization';
import {GeospatialViewerControlsService} from './geospatial-viewer-controls.service';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {UsersStore} from '@store/common/users.store';
import {filter, shareReplay, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {User} from '@core/interfaces/common/users';

@Injectable()
export class GeospatialViewerControlsStore extends Unsubscribable {
    constructor(
        protected fb: FormBuilder,
        private geospatialFilterStore: GeospatialFilterStore,
        private controlsService: GeospatialViewerControlsService,
        private usersStore: UsersStore,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();

        this.controlsService.getConfig().subscribe((conf: GeospatialViewerConfiguration) => {
            this.config.next(conf);

            if (this.initialConfig.value === null) {
                this.initialConfig.next(conf);
                this.refreshControls();
            }
        });
        this.usersStore.currentUser$
            .pipe(
                filter((u: User) => u && !!u),
                takeUntil(this.unsubscribe$),
            )
            .subscribe((user) => {
                this.currentUserId.next(user.id);
            });
    }

    private initialConfig: BehaviorSubject<GeospatialViewerConfiguration> =
        new BehaviorSubject<GeospatialViewerConfiguration>(null);
    readonly initialConfig$: Observable<GeospatialViewerConfiguration> = this.initialConfig.asObservable();
    private config: BehaviorSubject<GeospatialViewerConfiguration> = new BehaviorSubject<GeospatialViewerConfiguration>(
        null,
    );
    readonly config$: Observable<GeospatialViewerConfiguration> = this.config.asObservable().pipe(shareReplay(1));

    /*
     * UI elements
     */
    // Control Panel > Measures; map controls > measure/metric
    private currentUserId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    public selectedMeasure: BehaviorSubject<Measure> = new BehaviorSubject<Measure>(null);
    readonly selectedMeasure$: Observable<Measure> = this.selectedMeasure.asObservable();
    public selectedMetric: BehaviorSubject<Metric> = new BehaviorSubject<Metric>(null);
    readonly selectedMetric$: Observable<Metric> = this.selectedMetric.asObservable();
    // Control Panel > Measures > specific filters; map control > specific filters
    private mapValueFilter: BehaviorSubject<MapValueFilter> = new BehaviorSubject<MapValueFilter>(null);
    readonly mapValueFilter$: Observable<MapValueFilter> = this.mapValueFilter.asObservable();
    // Control panel > View; map controls > visualization type
    private selectedVisualizationType = new BehaviorSubject(null);
    public selectedVisualizationType$: Observable<VisualizationType> = this.selectedVisualizationType.asObservable();
    // Control panel > Filter > quick filters; map control > quick filters
    private quickFilters: BehaviorSubject<QuickFilterCategory[]> = new BehaviorSubject<QuickFilterCategory[]>(null);
    readonly quickFilters$: Observable<QuickFilterCategory[]> = this.quickFilters.asObservable();
    // Control panel > Project > filters
    private projectListFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly projectListFilter$: Observable<any> = this.projectListFilter.asObservable();
    // Control panel > View > project layer
    private projectLayerActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    readonly projectLayerActive$: Observable<boolean> = this.projectLayerActive.asObservable();
    private projectLayerFilter: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly projectLayerFilter$: Observable<any> = this.projectLayerFilter.asObservable();
    // Control panel > View > additional map layers
    private additionalMapLayers: BehaviorSubject<AdditionalMapLayer[]> = new BehaviorSubject<AdditionalMapLayer[]>(
        null,
    );
    readonly additionalMapLayers$: Observable<AdditionalMapLayer[]> = this.additionalMapLayers.asObservable();
    public addLayerEvent = new EventEmitter();
    public removeLayerEvent = new EventEmitter();

    public projectInEditMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public readyToCreateProject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false); // default value should be false

    // public projectListIsEmpty = new BehaviorSubject<boolean>(false);

    readonly activeMetric: BehaviorSubject<Metric> = new BehaviorSubject<Metric>(null);

    public valueFilterChangeFlag = new BehaviorSubject<Boolean>(false);
    public selectedRange = this.fb.group({
        min: this.fb.control[''],
        max: this.fb.control[''],
    });

    // Controls for configuration
    public updateMapViewWindow(updateObj: any) {
        const config: GeospatialViewerConfiguration = this.config.getValue();
        const newConfig = {
            ...config,
            centerCoordinate: {
                ...config.centerCoordinate,
                ...updateObj,
            },
        };
        if (!this.isEquivalent(this.config.value.centerCoordinate, newConfig.centerCoordinate)) {
            this.config.next(newConfig);
        }
    }

    /*
     * External functions for managing controls
     */
    public refreshControls() {
        if (this.initialConfig.value) {
            const config = this.initialConfig.value;

            const activeMeasure: Measure = this.getDefaultMeasure(config.measures);
            const activeMetric: Metric = this.getDefaultMetric(activeMeasure);
            const activeVisualizationType: VisualizationType = VisualizationType.INDIVIDUAL;

            this.selectedMeasure.next(activeMeasure);
            this.selectedMetric.next(activeMetric);
            this.selectedVisualizationType.next(activeVisualizationType);
            // Reset filters
            this.resetQuickFilters();
            this.geospatialFilterStore.resetGeospatialFilters();

            this.resetMapLayers();
        }
    }

    public resetQuickFilters() {
        this.quickFilters.next(QUICK_FILTER_LIST);
    }

    private resetMapLayers() {
        if (this.initialConfig.value) {
            const initConfig = this.initialConfig.value.layers;
            initConfig.forEach((layer: AdditionalMapLayer) => {
                if (layer.selected === undefined) {
                    layer.selected = false;
                }
            });
            this.additionalMapLayers.next(initConfig);
        }
    }

    // Update selected measure, and reset selected metric to nothing
    public updateMeasure(newMeasure: Measure) {
        if (newMeasure && this.selectedMeasure.value.code !== newMeasure.code) {
            this.selectedMetric.next(null); // force null
            this.selectedMeasure.next(newMeasure);
        }
    }

    public updateMetric(newMetric: Metric) {
        if (newMetric && (this.selectedMetric.value == null || this.selectedMetric.value.code !== newMetric.code)) {
            this.selectedMetric.next(newMetric);
            const metricName = newMetric.code.includes('_') ? newMetric.code.split('_').join(' ') : newMetric.code;
            this.usageAnalyticsService.logView('Geospatial', {$metric_drop_down_value: metricName});
            this.resetMapValueFilter(); // reset mapValueFilter when changing metrics
        }
    }

    public getMeasure(): Measure {
        return this.selectedMeasure.value;
    }

    public getMetric(): Metric {
        return this.selectedMetric.value;
    }

    // Update visualization type
    public updateVisualizationType(newVisualizationType: VisualizationType) {
        if (newVisualizationType) {
            this.selectedVisualizationType.next(newVisualizationType);
        }
    }

    // Update map value ("specific") filter
    public updateMapValueFilter(newFilter: MapValueFilter) {
        if (newFilter) {
            this.mapValueFilter.next(newFilter);
        }
    }

    // Toggle quick filter option
    public quickFilterOptionToggle(group: string, option: string) {
        if (group && option) {
            const newQuickFilters = this.quickFilters.value.map((g) => {
                return {
                    ...g,
                    options: g.options.map((o) => {
                        return {
                            ...o,
                            selected: g.code === group && o.code === option ? !o.selected : o.selected,
                        };
                    }),
                };
            });
            this.quickFilters.next(newQuickFilters);
            // Quick filters simply pre-selects certain general filter options
            this.propgateQuickFilters(newQuickFilters);
        }
    }

    // Update for project layer
    public updateProjectLayerActive(val: boolean) {
        if (this.projectLayerActive.value !== val) {
            this.projectLayerActive.next(val);
        }
    }

    public updateProjectLayerFilter(val: any) {
        this.projectLayerFilter.next(val);
    }

    public updateProjectListFilter(val: any) {
        this.projectListFilter.next(val);
    }

    // Toggle map layers
    public toggleMapLayer(layer: string, child?: string) {
        const findLayer: AdditionalMapLayer = this.findNestedLayer(layer, child);
        const currentStatus: boolean = findLayer.selected;

        // If current status is selected then remove
        if (currentStatus) {
            // Remove any children if parent was disabled; note: parent doesn't have a layer in this case
            if (findLayer.children) {
                findLayer.children.filter((c) => c.selected).forEach((c) => this.removeMapLayer(c));
            } else {
                this.removeMapLayer(findLayer);
            }
        } else {
            // Else add layer to map; note: parent doesn't have layer to be added
            if (findLayer.children === undefined || findLayer.children.length === 0) this.addMapLayer(findLayer);
        }

        this.toggleMapLayerSelected(layer, child);
    }

    public addMapLayer(layer: any) {
        if (layer) {
            this.addLayerEvent.emit(layer);
        }
    }

    public removeMapLayer(layer: any) {
        if (layer) {
            this.removeLayerEvent.emit(layer);
        }
    }

    private resetMapValueFilter() {
        this.mapValueFilter.next(null);
    }

    /*
     * Public getters
     */
    public getActiveMetric(): Metric {
        return this.selectedMetric.value;
    }

    public getQuickFilters(): QuickFilterCategory[] {
        return this.quickFilters.value;
    }

    /*
     * Support for quick filtering
     */

    // Update geospatial filters based on quick filter selections
    private propgateQuickFilters(quickFilters: QuickFilterCategory[]) {
        const currentSettings = this.geospatialFilterStore.getActiveFilterSettings();
        // Quick filters will turn certain asset class combinations on/off
        const newFilterSettings = {
            ...currentSettings,
            filters: currentSettings.filters.map((group) => {
                if (group.fieldKey.toLowerCase() === 'assetclass') {
                    return this.applyQuickFilters('class', group, quickFilters);
                } else if (group.fieldKey.toLowerCase() === 'assetclasscode') {
                    return this.applyQuickFilters('code', group, quickFilters);
                } else {
                    return {
                        ...group,
                    };
                }
            }),
        };

        this.geospatialFilterStore.updateFilterSettings(newFilterSettings);
    }

    private applyQuickFilters(type: string, group: any, quickFilters: QuickFilterCategory[]) {
        const lineEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'line');
        const pointEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'shape', 'point');
        const ohEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'overhead');
        const ugEnabled: boolean = this.checkQuickFilterEnabled(quickFilters, 'orientation', 'underground');
        return {
            ...group,
            options: group.options.map((option) => {
                return {
                    ...option,
                    selected:
                        type === 'class'
                            ? this.setSelectedByClass(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled)
                            : this.setSelectedByClassCode(option.key, lineEnabled, pointEnabled, ohEnabled, ugEnabled),
                };
            }),
        };
    }

    /*
     * Quick filter is enabled if:
     * a) Option is selected
     * b) All options in this group are unselected (i.e. no filtering is applied)
     */
    private checkQuickFilterEnabled(quickFilters: QuickFilterCategory[], group: string, option: string): boolean {
        const findGroup = quickFilters.filter((g) => g.code.toLowerCase() === group.toLowerCase());
        if (findGroup.length > 0 && findGroup[0] != null) {
            const noOptionsSelected: boolean =
                findGroup[0].options.filter((o) => !o.selected).length === findGroup[0].options.length;
            const findOption = findGroup[0].options.filter((o) => o.code.toLowerCase() === option.toLowerCase());
            if (findOption.length > 0 && findOption[0] != null) {
                return findOption[0].selected ? findOption[0].selected : noOptionsSelected;
            }
        }
        return false;
    }

    private setSelectedByClassCode(
        code: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = code.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('ug_')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }

    private setSelectedByClass(
        className: string,
        lineEnabled: boolean,
        pointEnabled: boolean,
        ohEnabled: boolean,
        ugEnabled: boolean,
    ): boolean {
        const c = className.toLowerCase();
        // All underground classes include "ug_" in class code
        if (c.includes('underground') || c.includes('u/g')) {
            // Check linear codes
            if (c.includes('cable')) {
                return ugEnabled && lineEnabled;
            }
            // Else assume point asset
            return ugEnabled && pointEnabled;
        }

        // Else code is overhead
        if (c.includes('cond') || c.includes('conductor')) {
            return ohEnabled && lineEnabled;
        }
        // Else assume point asset
        return ohEnabled && pointEnabled;
    }

    /*
     * Support for additional layers
     */
    private findNestedLayer(layer: string, child?: string): AdditionalMapLayer {
        const currentLayers = this.additionalMapLayers.value;
        if (layer) {
            const findLayer = currentLayers.filter((l) => l.code === layer);

            if (child) {
                const findChild = findLayer[0].children.filter((c) => c.code === child);
                if (findChild.length > 0) return findChild[0];
            }
            if (findLayer.length > 0) return findLayer[0];
        }
    }

    private toggleMapLayerSelected(layer: string, child?: string) {
        const newLayers = this.additionalMapLayers.value.map((l) => {
            // Note: toggle status of the immediate element only (don't toggle parent off because child was selected)
            const children = l.children
                ? l.children.map((c) => {
                      return {
                          ...c,
                          selected: child ? (c.code === child ? !c.selected : c.selected) : c.selected,
                      };
                  })
                : null;
            return {
                ...l,
                children: children,
                selected: child
                    ? children && children.filter((c) => c.selected).length > 0
                    : l.code === layer
                    ? !l.selected
                    : l.selected,
            };
        });
        this.additionalMapLayers.next(newLayers);
    }
    public constructFilterString(filterOptions: any): string {
        /*
         * Manually construct query string for each parent filter option.
         * - If ALL or NONE are selected; no filter.
         * - If some options are selected, apply appropriate filters.
         */
        const queryStringList = [];
        const owner = filterOptions.options?.find((parent) => parent.code === 'ownerId');
        const ownerOptionsChecked = owner.options.filter((child) => child.checked);

        if (!owner.options.filter((child) => child.code === 'all').checked && ownerOptionsChecked.length > 0) {
            if (ownerOptionsChecked[0].code === 'me') {
                queryStringList.push('filterByownerId_EQUAL=' + this.currentUserId.value);
            } else if (ownerOptionsChecked[0].code === 'other') {
                queryStringList.push('filterByownerId_NOT_EQUAL=' + this.currentUserId.value);
            }
        }

        const status = filterOptions.options?.find((parent) => parent.code === 'projectStatus');
        const statusOptionsChecked = status.options.filter((child) => child.checked);
        if (!status.options.filter((child) => child.code === 'all').checked && statusOptionsChecked.length > 0) {
            queryStringList.push('filterByprojectStatus_IN=' + statusOptionsChecked.map((c) => c.code).join('|'));
        }

        if (filterOptions.searchString !== '') {
            queryStringList.push('filterByprojectName_CONTAIN=' + filterOptions.searchString);
        }

        return queryStringList.join('&');
    }

    // Helpers for default measure/metric
    private getDefaultMeasure(measures: Measure[]): Measure {
        // Find default measure
        const findFirstDefault: Measure[] = measures.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measures.filter((m) => !m.disabled)[0];
    }
    private getDefaultMetric(measure: Measure): Metric {
        // Find default measure
        const findFirstDefault: Metric[] = measure.metrics.filter((m) => m.selectDefault);
        if (findFirstDefault.length > 0) {
            return findFirstDefault[0];
        }
        // Else find first non-disabled measure
        return measure.metrics.filter((m) => !m.disabled)[0];
    }

    // Helper
    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }
}
