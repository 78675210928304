import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {Segment, SegmentOption} from '@core/interfaces/engin/program-management/pm-segment';
import {PmSensitivity} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {TableService} from '@core/utils/table.service';

@Injectable()
export class PmSegmentApi {
    private readonly prefix = 'segments';

    constructor(private api: HttpService) {}

    //Sensitivity
    getSensitivity(data: PmSensitivity): Observable<APIResponse<any>> {
        return this.api.post(`sensitivity/find`, data);
    }

    // Segment
    getSegmentList(params: Filter[]): Observable<Segment[]> {
        let paramQuery = TableService.createFiltersParamsString(params);

        return this.api.get(`${this.prefix}/dto/list?${paramQuery}`).pipe(map((data) => data.items));
    }

    getSegmentDtoList(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    findSegmentKpis(
        segmentId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>> {
        return this.api.get(`${this.prefix}/details/kpi/${segmentId}/study/${workflowItemId}/${sensitivityId}`);
    }

    getSegmentKpiOptions(): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/options/kpis`);
    }

    getSegmentById(segmentId: number): Observable<APIResponse<Segment>> {
        return this.api.get(`${this.prefix}/${segmentId}`);
    }

    createSegment(segment): Observable<APIResponse<any>> {
        return this.api.post(`${this.prefix}/`, segment);
    }

    editSegment(segment: Segment): Observable<APIResponse<Segment>> {
        return this.api.put(`${this.prefix}/${segment.id}`, segment);
    }

    deleteSegment(segmentId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${segmentId}`);
    }

    searchSegmentName(segmentName: string): Observable<APIResponse<Segment[]>> {
        return this.api.get(`${this.prefix}/search`, {
            params: new HttpParams().set('filterByname', segmentName || ''),
        });
    }

    getSegmentOptions(): Observable<APIResponse<SegmentOption[]>> {
        return this.api.get(`${this.prefix}/options/segments`);
    }
    validateSegmentName(programName: string): Observable<boolean> {
        return this.api
            .get(`${this.prefix}/validate`, {
                params: new HttpParams().set('filterByname_EQUAL', programName || ''),
            })
            .pipe(map((res: APIResponse<boolean>) => res.response));
    }
    findAllBySegment(segmentId: number): Observable<APIResponse<Segment[]>> {
        return this.api.get(`${this.prefix}/find/by-segment/${segmentId}`);
    }

    downloadAssetsListDtoCsvSegment(
        segmentId: number,
        workflowItemId: number,
    ): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/download/assets/${segmentId}/study/${workflowItemId}`);
    }

    downloadTableCsvFile(): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/dto/list/csv`);
    }
}
