<ngx-file-drop
    dropZoneClassName="drop-zone"
    [directory]="false"
    [multiple]="true"
    [accept]="acceptFileTypes"
    [disabled]="disabled"
    (onFileDrop)="dropFile($event)"
>
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">
        <div class="drop-zone-container">
            <nb-icon icon="cloud-upload-outline"></nb-icon>
            <div>
                <p class="upload-heading">
                    Drag and drop, or
                    <span class="browse-link" (click)="openFileSelector()">browse files</span>
                    to upload
                </p>
                <p>{{ formatsString | uppercase }} format only</p>
                <p *ngIf="additionalText">{{ additionalText }}</p>
            </div>
        </div>
    </ng-template>
</ngx-file-drop>
