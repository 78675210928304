import {Observable} from 'rxjs';
import {APIResponse} from '../../system/system-common';
import {SimpleSeriesScatter, SimpleTrendData, SimpleUnit} from '../../system/system-common';

/*
 * Request
 */
export interface EvaluationAuditChartRequest {
    parameterCode: string;
    metricCode: string;
    assetClassCode?: string;
    assetSubclassCode?: string;
}

export interface EvaluationAuditComparisonRequest {
    datasetList: number[];
}

export interface DatasetNoteRequest {
    datasetId?: number;
    typeCode: EvaluationType;
}

/*
 * Response
 */
export class EvaluationAuditResponse {
    data: SimpleSeriesScatter;
    trend: SimpleTrendData;
}

export class EvaluationAuditComparisonResponse {
    [datasetId: string]: EvaluationAuditComparisonRow[];
}

/*
 * Shared / common types
 */
export interface EvaluationAuditComparisonRow {
    evaluationDataLoadCode: string;
    typeCode: string;
    metricLevel1: string;
    metricLevel2: string;
    value: number;
    unit: SimpleUnit;
}

// Hierarchy for evaluation data audit parameters
export interface Parameter {
    code: string;
    name: string;
    metricList: Metric[];
}
export interface Metric {
    code: string;
    name: string;
    assetClassList?: AssetClass[]; // Asset Class is required for asset type but not for global type
}
export interface AssetClass {
    code: string;
    name: string;
    assetSubclassList: AssetSubclass[]; // When asset class is required, asset subclass is required
}
export interface AssetSubclass {
    code: string;
    name: string;
}

// Dataset note
export interface DatasetNote {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
    lastUpdatedBy?: string;
    lastUpdatedByName?: string;
    datasetId: number;
    datasetVersion: number;
    typeCode: string;
    parameterCode: string;
    metricCode: string;
    assetClassCode?: string;
    assetSubclassCode?: string;
    note: string;
}

export enum EvaluationType {
    ASSET = 'ASSET',
    GLOBAL = 'GLOBAL',
}

export abstract class EvaluationDataAuditService {
    // Retrieve core results
    abstract getEvaluationAssetData(req: EvaluationAuditChartRequest): Observable<APIResponse<EvaluationAuditResponse>>;

    abstract getEvaluationGlobalData(
        req: EvaluationAuditChartRequest,
    ): Observable<APIResponse<EvaluationAuditResponse>>;

    abstract getComparisonData(
        req: EvaluationAuditComparisonRequest,
    ): Observable<APIResponse<EvaluationAuditComparisonResponse>>;

    // Support
    abstract getDatasetNoteList(req: DatasetNoteRequest): Observable<APIResponse<DatasetNote[]>>;

    abstract createDatasetNote(note: DatasetNote): Observable<APIResponse<DatasetNote>>;

    abstract editDatasetNote(id: number, note: DatasetNote): Observable<APIResponse<DatasetNote>>;

    abstract deleteDatasetNote(id: number): Observable<APIResponse<boolean>>;

    abstract getAssetParameterList(): Observable<APIResponse<Parameter[]>>;

    abstract getGlobalParameterList(): Observable<APIResponse<Parameter[]>>;
}
