import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApmModelType, LiveService} from '@core/interfaces/live/live';
import {map, tap} from 'rxjs/operators';

@Injectable()
export class LiveSearchStore {
    private headerFilterTouched = new BehaviorSubject<boolean>(true);
    readonly headerFilterTouched$: Observable<boolean> = this.headerFilterTouched.asObservable();

    public predictedElement = new BehaviorSubject<boolean>(false);
    readonly predictedElement$ = this.predictedElement.asObservable();

    public searchStringAssetId$ = new BehaviorSubject<string>('');
    public searchStringModel$ = new BehaviorSubject<string>('');
    public assetIds = new BehaviorSubject<string[]>([]);
    public models = new BehaviorSubject<ApmModelType[]>([]);
    readonly assetIds$ = this.assetIds.asObservable();
    readonly models$ = this.models.asObservable();

    private assetId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    private model: BehaviorSubject<ApmModelType> = new BehaviorSubject<ApmModelType>(null);
    private uploadDate: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly assetId$: Observable<string> = this.assetId.asObservable();
    readonly model$: Observable<ApmModelType> = this.model.asObservable();
    readonly uploadDate$: Observable<any> = this.uploadDate.asObservable();

    constructor(private liveService: LiveService) {}

    searchAssetId(assetId: string) {
        this.liveService
            .getSearchAssetIds(assetId, this.model.value)
            .pipe(
                map((data) => data.response),
                tap((assetIds) => {
                    this.assetIds.next(assetIds);
                    this.searchStringAssetId$.next(assetId);
                }),
            )
            .subscribe();
    }

    searchModel(model: string) {
        this.liveService
            .getSearchModels(this.assetId.value, model)
            .pipe(
                map((data) => data.response),
                tap((models: string[]) => {
                    const modelsApm: ApmModelType[] = models.map((m) => ApmModelType[m]);
                    this.models.next(modelsApm);
                    this.searchStringModel$.next(model);
                }),
            )
            .subscribe();
    }

    public setAssetId(assetId: string) {
        this.assetId.next(assetId);
    }

    public setModel(model: ApmModelType) {
        this.model.next(model);
    }

    public setUploadDate(uploadDate: any) {
        this.uploadDate.next(uploadDate);
    }

    public getAssetId() {
        return this.assetId.value;
    }

    public getModel() {
        return this.model.value;
    }

    public getUploadDate() {
        return this.uploadDate.value;
    }

    public setPredictedElement(value: boolean) {
        this.predictedElement.next(value);
    }

    public getPredictedElement() {
        return this.predictedElement.value;
    }

    public getHeaderFilterTouched(): boolean {
        return this.headerFilterTouched.value;
    }

    public setHeaderFilterTouched(val: boolean) {
        this.headerFilterTouched.next(val);
    }
}
