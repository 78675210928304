import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ColorsService} from '@core/utils';
import {take} from 'rxjs/operators';
import {ConfigurationStore} from '@store/config/configuration.store';

@Component({
    selector: 'ngx-alert-status',
    template: `
        <ng-container *ngIf="icon && iconLabel">
            <span class="status-label">{{ iconLabel }}</span>
            <nb-icon pack="eva" [icon]="icon" [style.background-color]="iconColor"></nb-icon>
        </ng-container>
    `,
    styleUrls: ['./alert-status.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertStatusComponent implements OnInit {
    iconLabel: string;
    iconColor: string;

    @Input() value: any;
    @Input() icon?: string;

    @Output() iconColorChange: EventEmitter<string> = new EventEmitter<string>();

    constructor(private colorsService: ColorsService, private configurationStore: ConfigurationStore) {}

    ngOnInit(): void {
        this.colorsService.colorsMapShared$.pipe(take(1)).subscribe((colors) => {
            switch (this.value) {
                case 'REACTIVE_PRIORITY1':
                case 'REACTIVE_PRIORITY2':
                case 'REACTIVE_PRIORITY3':
                case 'REACTIVE_PRIORITY4':
                case 'REACTIVE_PRIORITY5':
                case 'PREDICT_1MONTH':
                case 'PREDICT_3MONTH':
                case 'PREDICT_6MONTH':
                case 'PREDICT_1YEAR':
                case 'PREDICT_3YEAR':
                case 'PREDICT_5YEAR':
                    this.icon = this.icon || 'alert-triangle';
                    break;
                case 'INFORMATION':
                    this.icon = this.icon || 'alert-circle';
                    break;
                case 'NONE':
                    this.icon = this.icon || 'checkmark-circle-2';
                    break;
                default:
                    this.icon = null;
                    this.iconLabel = null;
                    this.iconColor = null;
            }

            this.iconLabel = this.configurationStore.statusToLabel(this.value);
            this.iconColor = this.iconLabel ? colors['Alert Statuses'][this.value] : null;
            this.iconColorChange.emit(this.iconColor);
        });
    }
}
