import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'twoLevelLoops',
})
export class TwoLevelLoopsPipe implements PipeTransform {
    transform(value: any, property: string): any {
        return value.map((item) => item);
    }
}
