import {Injectable} from '@angular/core';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SectionLoadForecastApi} from '../../api/load-forecast/line-section.api';
import {SectionInfo, SectionLoadForecastService} from '@core/interfaces/engin/load-forecast/line-section';

@Injectable()
export class SectionLoadForecastServiceImpl extends SectionLoadForecastService {
    constructor(private api: SectionLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for line section(s)
     */
    public getSectionPivot(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getSectionPivot(workflowItemId, scenarioId, sectionId, period, seasonIds, unit, sensitivityId);
    }

    public getSectionPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSectionPivotCSV(
            workflowItemId,
            scenarioId,
            sectionId,
            period,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getSectionInfo(workflowItemId: number, scenarioId: string, sectionId: string): Observable<SectionInfo[]> {
        return this.api.getSectionInfo(workflowItemId, scenarioId, sectionId);
    }

    public getSectionForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.getSectionForecastBySeasons(workflowItemId, scenarioId, sectionId, period, unit, sensitivityId);
    }

    public getSectionForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getSectionForecastByFactors(workflowItemId, scenarioId, sectionId, period, unit, sensitivityId);
    }

    public getSectionInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<DataSource> {
        return this.api.getSectionInfrastructureList(workflowItemId, scenarioId, sectionId);
    }

    public getSectionInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getSectionInfrastructureListCSV(workflowItemId, scenarioId, sectionId);
    }
}
