<div class="page-header">
    <ngx-icon-box [title]="_title | titlecase" [iconDetails]="_iconDetails"></ngx-icon-box>

    <ng-container *ngTemplateOutlet="waitingTemplate">waiting</ng-container>

    <!-- Data quality widget, if not disabled -->
    <ng-container *ngIf="!_widgetHidden">
        <ngx-data-audit-widget [disableWidget]="false" [popoutState$]="popoutState$"></ngx-data-audit-widget>
    </ng-container>
</div>
