import {Measure, MeasureTypeLabel, Metric, ValueType} from '../../../../../pages/geospatial-viewer/model/metric';
import {FormatsService} from '@core/utils';
import {MapColoursService} from '../renderers/map-colours.service';
import {
    GeospatialGroupPopupRequest,
    GeospatialGroupPopupResponse,
    Unit,
} from '../../../../../pages/geospatial-viewer/model/api';
import {Injectable} from '@angular/core';
import {StudiesStore} from '@store/common/studies.store';
import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {GeospatialViewerControlsStore} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer-controls.store';
import {NbThemeService} from '@nebular/theme';
import {WorkflowType} from '@core/interfaces/engin/workflow';

@Injectable()
export class AssetGroupPopupService {
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private controlsStore: GeospatialViewerControlsStore,
        private nbThemeService: NbThemeService,
    ) {}
    /*
     * Render popup for single asset feature. Works for point or line features equivalently.
     */
    getAssetGroupPopupFunction(feature): Promise<any> {
        // From feature
        const groupId: string = feature.graphic.attributes.id;
        const groupCode: string = feature.graphic.attributes.classCode;
        // From stores
        const studyId: number = this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY);
        const measure: Measure = this.controlsStore.getMeasure();
        const metric: Metric = this.controlsStore.getMetric();

        const popupRequest: GeospatialGroupPopupRequest = {
            measureType: measure.code,
            metricType: metric.code,
            valueType: metric.valueType,
            groupId: groupId,
            groupCode: groupCode,
        };

        const currentTheme = this.nbThemeService.currentTheme;

        return this.geospatialViewerService
            .getAssetGroupPopupInfo(studyId, popupRequest)
            .toPromise()
            .then((res: GeospatialGroupPopupResponse) => {
                return this.renderGroupPopup(feature, res, currentTheme);
            });
    }

    renderGroupPopup(feature: any, info: GeospatialGroupPopupResponse, theme: string) {
        const measureName: string = MeasureTypeLabel[info.measureType];
        const data: string | number = info.data;
        const interventionCost: number = info.interventionCost;
        const count: number = info.count;
        const bcRatio: number = info.bcRatio;
        const groupCode: string = info.groupCode;
        const groupName: string = info.groupName;
        const dataUnit: Unit = info.dataUnit;
        const costUnit: Unit = info.costUnit;

        const displayCategory: string = feature.graphic.attributes.displayCategory;
        const valueType: ValueType = info.valueType;

        const displayColour = MapColoursService.getMapCategoryColour(displayCategory, theme).color;
        const formattedCostValue = FormatsService.prepareCostValue(interventionCost, costUnit.prefix, costUnit.suffix);
        let formattedValue = data;
        if (valueType === ValueType.NUMERIC) {
            formattedValue = FormatsService.prepareValue(Number(data), dataUnit.prefix, dataUnit.suffix);
        }

        // layer = 'c' is street view; 'cbll' is lat/long for streetview; 'll' is lat/long for default map
        const lat = info?.latitude;
        const long = info?.longitude;
        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        const extraHtml =
            lat && long
                ? `
        <div class='row' style='padding-top:16px'>
            <p>External links (opens new tab)</p>
        </div>
        <div class='row'>
            <a class='col-6' href="${streetViewUrl}" target="_blank">
              Google Street View
            </a>
            <a class='col-6' href="${mapsUrl}" target="_blank">
              Google Maps
            </a>
        </div>
        `
                : '';

        // Create and return tooltip in div
        const div = document.createElement('div');
        div.innerHTML = `
        <div class='container'>
            <div class='row'>
                <p>${groupCode}: ${groupName}</p>
            </div>
            <div class='row'>
                <div class='col-7'>
                    ${measureName}
                </div>
                <div class='col-5'>
                    ${formattedValue}
                    <svg height="15" width="15" style="vertical-align:baseline; ">
                        <circle cx="9" cy="10" r="5" fill="${displayColour}"/>
                    </svg>
                </div>
            </div>
            <div class='row'>
                <div class='col-7'>
                  10-year risk B/C
                </div>
                <div class='col-5'>
                    ${bcRatio}
                </div>
            </div>
            <div class='row'>
                <div class='col-7'>
                  Asset Count
                </div>
                <div class='col-5'>
                    ${count}
                </div>
            </div>
            <div class='row'>
                <div class='col-7'>
                  Replacement Value
                </div>
                <div class='col-5'>
                    ${formattedCostValue}
                </div>
            </div>
            ${extraHtml}
        </div>
    `;
        return div;
    }
}
