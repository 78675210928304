import {
    AccessibilityGradientCategoryColors,
    AccessibilityGradientNumericColors,
    AccessibilityNamedColors,
    GradientCategoryColors,
    GradientNumericColors,
    NamedColors,
} from '@theme/styles/theme-colors';
import {ThemeService} from '@core/utils/theme.service';

export class MapColor {
    value: string;
    color: string;
    type?: string;
    colorList: number[];
}

export class MapColoursService {
    public static getMapCategoryColour(value: string, theme: string): MapColor {
        const r: MapColor[] = MapColoursService.list(theme).filter((e) => e.value === value);
        if (r && r[0]) {
            return r[0];
        } else {
            return MapColoursService.getNoColour(theme);
        }
    }

    public static list(theme: string): MapColor[] {
        const accessibility: boolean = ThemeService.isAccessibility(theme);
        const categoryColours = accessibility ? AccessibilityGradientCategoryColors : GradientCategoryColors;
        const numericColours = accessibility ? AccessibilityGradientNumericColors : GradientNumericColors;
        const result = [
            // Category: 1-5 tone colour scale (1 = good, 5 = bad)
            {
                value: 'CATEGORY_1',
                color: categoryColours.color1,
                colorList: MapColoursService.hexToRGB(categoryColours.color1),
            },
            {
                value: 'CATEGORY_2',
                color: categoryColours.color2,
                colorList: MapColoursService.hexToRGB(categoryColours.color2),
            },
            {
                value: 'CATEGORY_3',
                color: categoryColours.color3,
                colorList: MapColoursService.hexToRGB(categoryColours.color3),
            },
            {
                value: 'CATEGORY_4',
                color: categoryColours.color4,
                colorList: MapColoursService.hexToRGB(categoryColours.color4),
            },
            {
                value: 'CATEGORY_5',
                color: categoryColours.color5,
                colorList: MapColoursService.hexToRGB(categoryColours.color5),
            },
            // Numeric: 1-5 tone colour scale (not necessarily good/bad tones)
            {
                value: 'NUMERIC_1',
                color: numericColours.color1,
                colorList: MapColoursService.hexToRGB(numericColours.color1),
            },
            {
                value: 'NUMERIC_2',
                color: numericColours.color2,
                colorList: MapColoursService.hexToRGB(numericColours.color2),
            },
            {
                value: 'NUMERIC_3',
                color: numericColours.color3,
                colorList: MapColoursService.hexToRGB(numericColours.color3),
            },
            {
                value: 'NUMERIC_4',
                color: numericColours.color4,
                colorList: MapColoursService.hexToRGB(numericColours.color4),
            },
            {
                value: 'NUMERIC_5',
                color: numericColours.color5,
                colorList: MapColoursService.hexToRGB(numericColours.color5),
            },
        ];
        return result;
    }

    private static getNoColour(theme: string): MapColor {
        const accessibility: boolean = ThemeService.isAccessibility(theme);
        const namedColours = accessibility ? AccessibilityNamedColors : NamedColors;
        return {
            value: 'NONE',
            color: namedColours.empty,
            colorList: MapColoursService.hexToRGB(namedColours.empty),
        };
    }

    static hexToRGB(hex: string): number[] {
        const r = parseInt(hex.slice(1, 3), 16),
            g = parseInt(hex.slice(3, 5), 16),
            b = parseInt(hex.slice(5, 7), 16);
        return [r, g, b];
    }
}
