export interface ImpactOfFailureData {
    sensData: any;
    chartsData: {
        existingAsset: {
            failureModes: any;
        };
        newAsset: {
            failureModes: any;
        };
        totalImpact: any;
    };
}

export const assetFailureModesArrProps = [
    'fmcalculationresultfm1',
    'fmcalculationresultfm2',
    'fmcalculationresultfm3',
    'fmcalculationresultfm4',
    'fmcalculationresultfm5',
    'fmcalculationresultfm6',
    'fmcalculationresultfm7',
];

export function ImpactOfFailure(intCalculationsAssets) {
    // Remove failure modes with probability = 0% -- not working properly
    // intCalculationsAssets = formatInternalCalc(intCalculationsAssets);

    const failureModesExisting = getFailureModes('existingAsset');

    const failureModesNew = getFailureModes('newAsset');

    return {
        sensData: getSensData(),
        chartsData: {
            existingAsset: {
                failureModes: failureModesExisting,
            },
            newAsset: {
                failureModes: failureModesNew,
            },
            totalImpact: getTotalImpact(),
        },
    };

    /*
    function formatInternalCalc(calc) {
      const zeroIndexesExisting = [];
      const zeroIndexesNew = [];
      for (let i = 0; i <= calc.existingAsset.PR.length; i++) {
        if (!calc.existingAsset.PR[i] || calc.existingAsset.PR[i] === 0) {
          zeroIndexesExisting.push(i);
        }
        if (!calc.newAsset.PR[i] || calc.newAsset.PR[i] === 0) {
          zeroIndexesNew.push(i);
        }
      }

      zeroIndexesExisting.forEach(index => {
        for (const key of Object.keys(calc.existingAsset)) {
          calc.existingAsset[key].splice(index, 1);
        }
      });

      zeroIndexesNew.forEach(index => {
        for (const key of Object.keys(calc.newAsset)) {
          calc.newAsset[key].splice(index, 1);
        }
      });

      return calc;
    }
     */

    function getSensData() {
        const existingAsset = {};
        const newAsset = {};

        intCalculationsAssets.existingAsset.name.forEach((item, i) => {
            if (item) {
                existingAsset[item] = {
                    relativeProbability: intCalculationsAssets.existingAsset.PR[i],
                    customerImpact: intCalculationsAssets.existingAsset.Impact_Cust[i].toFixed(2),
                    financialImpact: intCalculationsAssets.existingAsset.Impact_Fin[i].toFixed(2),
                    envImpact: intCalculationsAssets.existingAsset.Impact_Env[i].toFixed(2),
                    CDImpact: intCalculationsAssets.existingAsset.Impact_CD[i].toFixed(2),
                };
            }
        });

        intCalculationsAssets.newAsset.name.forEach((item, i) => {
            if (item) {
                newAsset[item] = {
                    relativeProbability: intCalculationsAssets.newAsset.PR[i],
                    customerImpact: intCalculationsAssets.newAsset.Impact_Cust[i],
                    financialImpact: intCalculationsAssets.newAsset.Impact_Fin[i],
                    envImpact: intCalculationsAssets.newAsset.Impact_Env[i],
                    CDImpact: intCalculationsAssets.newAsset.Impact_CD[i],
                };
            }
        });
        return {
            existingAsset: existingAsset,
            newAsset: newAsset,
        };
    }

    function getFailureModes(asset) {
        const modes = {};
        intCalculationsAssets[asset].name.forEach((item, i) => {
            if (item) {
                modes[item] = intCalculationsAssets[asset].PR[i];
            }
        });
        return modes;
    }

    function getTotalImpact() {
        const totalImpact = {};

        intCalculationsAssets.existingAsset.name.forEach((item, i) => {
            if (item) {
                totalImpact[item] = {
                    existingAsset: {
                        customerImpact: intCalculationsAssets.existingAsset.Impact_Cust[i],
                        financialImpact: intCalculationsAssets.existingAsset.Impact_Fin[i],
                        envImpact: intCalculationsAssets.existingAsset.Impact_Env[i],
                        collateralImpact: intCalculationsAssets.existingAsset.Impact_CD[i],
                    },
                    newAsset: {
                        customerImpact: intCalculationsAssets.newAsset.Impact_Cust[i],
                        financialImpact: intCalculationsAssets.newAsset.Impact_Fin[i],
                        envImpact: intCalculationsAssets.newAsset.Impact_Env[i],
                        collateralImpact: intCalculationsAssets.newAsset.Impact_CD[i],
                    },
                };
            }
        });
        totalImpact['x'] = intCalculationsAssets.existingAsset.name;

        return totalImpact;
    }
}
