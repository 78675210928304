<div class="m-3">
    <!-- Title -->
    <p class="labels">
        {{ field.label }}
        <span *ngIf="fieldRequired()" class="red">*</span>
    </p>

    <!-- Main content -->
    <!-- Note: This components does not support "previous" values -->
    <div>
        <ng-container [ngSwitch]="viewMode">
            <!-- Form is in edit mode, with or without results -->
            <ng-container *ngSwitchCase="FormViewModeEnum.EDIT_RESULTS">
                <!-- Show thumbnails in the form -->
                <div class="photo-container" *ngIf="mediaLoaded | async">
                    <ng-container *ngFor="let file of fileList.value">
                        <nb-card class="file-upload-card" *ngIf="file.url">
                            <nb-card-body class="file-upload-body">
                                <!-- Delete button, top corner of the thumbnail -->
                                <div *ngIf="pageMode === FormMode.EDIT" class="trash-button-container">
                                    <button ghost status="basic" nbButton (click)="remove(file)">
                                        <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                                    </button>
                                </div>
                                <!-- Media is video -->
                                <video
                                    *ngIf="enableVideo && mediaIsVideo(file)"
                                    (click)="openCarousel(file)"
                                    src="{{ file.url }}"
                                    class="photo file-upload-image"
                                ></video>
                                <!-- Media is image -->
                                <img
                                    *ngIf="enableImage && mediaIsImage(file)"
                                    [src]="file.url"
                                    [alt]="file.fileName"
                                    (click)="openCarousel(file)"
                                    (error)="handleImageError(file)"
                                    class="photo file-upload-image"
                                />
                            </nb-card-body>
                        </nb-card>
                    </ng-container>
                </div>

                <!-- Show "Add" button for new media -->
                <div class="mt-2">
                    <button
                        fullWidth
                        nbButton
                        status="basic"
                        size="small"
                        (click)="addContents()"
                        [ngClass]="{
                            'view-mode': pageMode === FormMode.VIEW,
                            'edit-mode': pageMode === FormMode.EDIT,
                            'required-btn': fieldForm?.touched && fieldForm?.hasError('required')
                        }"
                    >
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD
                    </button>
                </div>
            </ng-container>

            <!-- Form is in view mode, with results -->
            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_RESULTS">
                <!-- Show thumbnails in the form -->
                <div class="photo-container" *ngIf="mediaLoaded | async">
                    <nb-card *ngFor="let file of fileList.value" class="file-upload-card">
                        <nb-card-body class="file-upload-body">
                            <div *ngIf="pageMode === FormMode.EDIT" class="trash-button-container">
                                <button ghost status="basic" nbButton (click)="remove(file)">
                                    <nb-icon icon="trash-2" status="basic" class="trash-icon"></nb-icon>
                                </button>
                            </div>

                            <!-- Media is video -->
                            <video
                                *ngIf="enableVideo && mediaIsVideo(file)"
                                (click)="openCarousel(file)"
                                src="{{ file.url }}"
                                class="photo file-upload-image"
                            ></video>
                            <!-- Media is image -->
                            <img
                                *ngIf="enableImage && mediaIsImage(file)"
                                [src]="file.url"
                                [alt]="file.fileName"
                                (click)="openCarousel(file)"
                                (error)="handleImageError(file)"
                                class="photo file-upload-image"
                            />
                        </nb-card-body>
                    </nb-card>
                </div>

                <!-- Show "Add" button for new media, disabled -->
                <div class="mt-2">
                    <button disabled fullWidth nbButton size="small" status="basic" class="disable-btn">
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD
                    </button>
                </div>
            </ng-container>

            <!-- Form in view mode, no results -->
            <ng-container *ngSwitchCase="FormViewModeEnum.VIEW_CONFIG">
                <div class="mt-2">
                    <button disabled fullWidth nbButton status="basic" size="small" class="disable-btn">
                        <nb-icon icon="plus-outline"></nb-icon>
                        ADD
                    </button>
                </div>
            </ng-container>
        </ng-container>
    </div>
</div>
