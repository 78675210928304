import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {GuideInfo} from '@theme/components';

export interface IntegrationFile {
    id: number;
    createdOn: string;
    processCounter: number;
    fileName: string;
    fileFormat: string;
    integrationErrorInfoList: IntegrationFileError[];
}

export interface IntegrationFileError {
    id: number;
    createdOn: string;
    updatedOn: string;
    integrationFileId: string; //FileFormat
    integrationFileProcessCounter: number;
    integrationErrorType: string; //IntegrationErrorType
    errorMessage: string;
    errorCount: number;
}

export interface IntegrationFileRenameDto {
    name: string;
    userId?: string;
    userName?: string;
}

export abstract class LandingZoneService {
    abstract getIntegrationFileById(integrationFileId: number): Observable<APIResponse<IntegrationFile>>;
    abstract getListCurrent(): Observable<DataSource>;
    abstract getListHistory(): Observable<DataSource>;
    abstract getGuideInfoCurrent(): Observable<APIResponse<GuideInfo[]>>;
    abstract getGuideInfoHistory(): Observable<APIResponse<GuideInfo[]>>;
    abstract downloadIntegrationFile(integrationFileId: number): Observable<APIResponse<DownloadCSVUrl>>;
    abstract renameIntegrationFile(integrationFileId: number, name: string): Observable<APIResponse<any>>;
    abstract retryIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>>;
    abstract deleteIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>>;
}
