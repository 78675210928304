import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {SystemViewRequest} from '../../../interfaces/engin/planner/system-view-request';
import {SystemViewResponse} from '../../../interfaces/engin/planner/system-view-response';
import {ProjectViewResponse} from '../../../interfaces/engin/planner/project-view-response';
import {HttpClient} from '@angular/common/http';
import {ServerDataSource} from '@mominsamir/ngx-smart-table';

@Injectable()
export class PlannerApi {
    private readonly prefix = 'planner';

    constructor(private api: HttpService, private http: HttpClient) {}

    getSystemView(request: SystemViewRequest): Observable<SystemViewResponse> {
        return this.api.post(`${this.prefix}/getSystemView`, request);
    }

    getSystemViewDataSource() {
        return new ServerDataSource(this.http, {
            endPoint: `${this.api.apiUrl}/getSystemView`,
            totalKey: 'totalCount',
            dataKey: 'items',
            pagerPageKey: 'pageNumber',
            pagerLimitKey: 'pageSize',
            filterFieldKey: 'filterBy#field#',
            sortFieldKey: 'sortBy',
            sortDirKey: 'orderBy',
        });
    }

    getProjectView(projectNo: number, alternativeNo: number, request): Observable<ProjectViewResponse> {
        return this.api.post(`${this.prefix}/getProjectView/${projectNo}/${alternativeNo}`, request);
    }
}
