import {MapColoursService} from './map-colours.service';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import ClassBreaksRenderer from '@arcgis/core/renderers/ClassBreaksRenderer';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import HeatmapRenderer from '@arcgis/core/renderers/HeatmapRenderer';
import SizeVariable from '@arcgis/core/renderers/visualVariables/SizeVariable';
import Renderer from '@arcgis/core/renderers/Renderer';

export class AssetRendererService {
    public layerRenderer(type, theme, outlineFlag?) {
        const outline = {
            color: 'white',
            width: 1,
        };
        return new UniqueValueRenderer({
            field: 'displayCategory', // 'statusColor',
            uniqueValueInfos: MapColoursService.list(theme).map((item) => {
                return {
                    value: item.value,
                    symbol: {
                        type: type,
                        color: item.color,
                        width: 5,
                        outline: outlineFlag ? outline : '',
                    },
                };
            }),
        });
    }

    public pointLayerOverlapRenderer() {
        return new ClassBreaksRenderer({
            field: 'count',
            classBreakInfos: [
                {
                    minValue: 2,
                    maxValue: 2,
                    symbol: new SimpleMarkerSymbol({
                        color: 'transparent',
                        outline: {
                            color: 'black',
                            width: 1,
                        },
                    }),
                },
            ],
        });
    }

    /*
     * Colour based on value, size based on 'count' property.
     * - Size increases for every 10x count; min: 16px; max: 32px.
     */
    public bubbleLayerRenderer(theme: string) {
        // Keep same colour but different size based on 'count' property
        return new UniqueValueRenderer({
            field: 'displayCategory', // 'statusColor',
            uniqueValueInfos: MapColoursService.list(theme).map((item) => {
                return {
                    value: item.value,
                    symbol: {
                        type: 'simple-marker',
                        color: item.color,
                        width: 5,
                        outline: {
                            color: 'white',
                            width: 1,
                        },
                    },
                };
            }),
            visualVariables: [
                new SizeVariable({
                    field: 'count',
                    stops: [
                        {
                            value: 1,
                            size: 12,
                        },
                        {
                            value: 10,
                            size: 16,
                        },
                        {
                            value: 100,
                            size: 20,
                        },
                        {
                            value: 1000,
                            size: 24,
                        },
                        {
                            value: 10000,
                            size: 28,
                        },
                        {
                            value: 100000,
                            size: 32,
                        },
                    ],
                }),
            ],
        });
    }

    public heatmapRenderer(category: any, theme: string, zoom: number): Renderer {
        let colors = [];
        const baseColor: number[] = MapColoursService.getMapCategoryColour(category, theme).colorList;

        // Create 10-tone gradient
        for (let i = 0; i < 10; i++) {
            const alpha = 1.0 - i * 0.1;
            colors.push(baseColor.concat([alpha]));
        }

        return new HeatmapRenderer({
            field: 'count',
            colorStops: [{color: [133, 193, 200, 0], ratio: 0}] // transparent value doesn't work here
                .concat(
                    colors.map((c, i) => {
                        return {
                            color: c,
                            ratio: 0.2 + (i * 0.8) / 9,
                        };
                    }),
                ),
            referenceScale: zoom < 10 ? 325000 : 250000,
            // Default render settings
            maxDensity: zoom < 10 ? 0.2 : 0.15,
            minDensity: 0,
            radius: zoom < 10 ? 75 : 150,
        });
    }
}
