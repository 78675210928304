<div class="header-settings-container">
    <!-- Section 1: interface settings -->
    <div class="menu-section">
        <label class="display-flex align-items-center justify-content-between">
            DARK MODE
            <ngx-theme-switcher></ngx-theme-switcher>
        </label>
        <label class="display-flex align-items-center justify-content-between">
            ACCESSIBILITY MODE
            <ngx-accessibility-switcher></ngx-accessibility-switcher>
        </label>
        <label (click)="goToUserProfile()" i>PROFILE SETTINGS</label>
    </div>

    <!-- Section 2: profile and knowledge base -->
    <div class="menu-section">
        <label (click)="navigateUrl()" class="label-with-navigation">KNOWLEDGE BASE</label>
    </div>

    <!-- Section 3: organization -->
    <div class="menu-section" *ngIf="user.tenants.length > 1">
        <ngx-icon-box title="SWITCH ORGANIZATION" icon="sync-outline" (click)="switchOrganization()"></ngx-icon-box>
    </div>

    <!-- Section 4: logout -->
    <div class="menu-section">
        <ngx-icon-box title="LOG OUT" (click)="logout()" icon="power"></ngx-icon-box>
    </div>

    <!-- Section 5: about -->
    <div class="menu-section">
        <ngx-icon-box title="ABOUT" (click)="openAbout()" icon="info-outline"></ngx-icon-box>
    </div>
</div>
