import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, PeriodFilter} from './load-forecast';

export interface TransformerInfo extends InfoBlock {}

export interface LoadProfileInfo {
    analysisInfo: InfoBlock[];
    metadataInfo: InfoBlock[];
}

export abstract class DxTransformerLoadForecastService {
    /*
     * Infrastructure: results for dx transformer(s)
     */
    abstract getDxTransformerInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<TransformerInfo[]>;

    abstract getDxTransformerPivot(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getDxTransformerPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getLoadProfileInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<LoadProfileInfo>;

    abstract getLoadProfileRaw(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<SimpleSeries<Date, number>>;

    abstract getLoadProfileTypicalDay(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        groupBy: string,
    ): Observable<SimpleMultiSeries<number>>;

    abstract getDxTransformerInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<DataSource>;

    abstract getDxTransformerInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
