import {Pipe, PipeTransform} from '@angular/core';
import {SimpleUnit} from '../../@core/interfaces/system/system-common';
import {FormatsService} from '../../@core/utils';

@Pipe({
    name: 'unit',
})
export class UnitPipe implements PipeTransform {
    transform(value: any, unit: SimpleUnit) {
        return value ? `${FormatsService.prepareValue(value, unit.prefix, unit.suffix)}` : '';
    }
}
