import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {StudiesStore} from '../common/studies.store';
import {
    AvailabilityData,
    ForecasterRequest,
    ForecasterResponse,
    ForecasterService,
} from '@core/interfaces/engin/forecaster';
import {ForecasterPopoutStore} from './forecaster-popout-store';
import {User, WorkflowItemInfo} from '@core/interfaces/common/users';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';
import {UsersStore} from '@store/common/users.store';

@Injectable()
export class ForecasterStore extends Unsubscribable {
    // Current endpoint is loading
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    // System forecast page
    private combineForecasterRequestInformation$ = combineLatest<
        Observable<any>,
        Observable<WorkflowItemInfo>,
        Observable<User>
    >([
        this.forecasterPopoutStore.serverPopout$,
        this.studiesStore.activeStudyRisk$.pipe(filter((d) => !!d)),
        this.usersStore.currentUser$,
    ]);

    readonly forecasterChartData$: Observable<ForecasterResponse[]> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeWorkflowItemInfo, user]) => {
            const userId = user?.id;
            const studyId = activeWorkflowItemInfo.workflowItemId;
            const req = this.prepareForecasterRequest(popoutValue, activeWorkflowItemInfo, userId);
            return this.forecasterService.getForecasterData(req, studyId);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(1),
    );

    readonly stationAvailabilityData$: Observable<AvailabilityData> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeStudy, user]) => {
            const userId = user?.id;
            const req = this.prepareForecasterRequest(popoutValue, activeStudy, userId);
            return this.forecasterService.getStationAvailabilityData(req);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(),
    );

    readonly lineAvailabilityData$: Observable<AvailabilityData> = this.combineForecasterRequestInformation$.pipe(
        tap(() => {
            this.resultsLoading.next(true);
        }),
        switchMap(([popoutValue, activeStudy, user]) => {
            const userId = user?.id;
            const req = this.prepareForecasterRequest(popoutValue, activeStudy, userId);
            return this.forecasterService.getLineAvailabilityData(req);
        }),
        takeUntil(this.unsubscribe$),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(),
    );

    private prepareForecasterRequest(
        popoutValue: ForecasterRequest,
        activeWorkflowItemInfo: WorkflowItemInfo,
        userId: string,
    ): ForecasterRequest {
        const req = popoutValue;
        req.stateChanged = false;
        req.sensitivityStudy = {
            studyId: activeWorkflowItemInfo.workflowItemId,
            userId: userId,
            sensitivityParams: null,
            // TODO:: current year calcualation
            currentYear: activeWorkflowItemInfo.currentYear,
            evaluationPeriod: 10,
        };
        return req;
    }

    constructor(
        private forecasterService: ForecasterService,
        private studiesStore: StudiesStore,
        private forecasterPopoutStore: ForecasterPopoutStore,
        protected toastrService: NbToastrService,
        private usersStore: UsersStore,
    ) {
        super();
    }
}
