<div class="legend-container">
    <div class="legends" *ngFor="let legend of legendColorArray">
        <div [ngSwitch]="legend.type">
            <div *ngSwitchCase="'line'">
                <span [ngStyle]="{borderTop: '2px solid', borderColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'dashed'">
                <span [ngStyle]="{borderTop: '3px dotted', borderColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'circle'">
                <span class="circle" [ngStyle]="{backgroundColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'triangle'">
                <span class="textSymbol" [ngStyle]="{color: legend.color}">&#9660;</span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'star'">
                <span class="textSymbol" [ngStyle]="{color: legend.color}">&#10041;</span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'diamond'">
                <span class="diamond" [ngStyle]="{backgroundColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'square'">
                <span class="square" [ngStyle]="{backgroundColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
            <div *ngSwitchCase="'default'">
                <span class="default" [ngStyle]="{backgroundColor: legend.color}"></span>
                <p>{{ legend.name }}</p>
            </div>
        </div>
    </div>
</div>
