import {ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DocumentAssetImage, DocumentService} from '@core/interfaces/common/document';
import {TagOptionsCallback} from '@theme/components';

@Component({
    selector: 'ngx-asset-documents',
    templateUrl: './asset-documents.component.html',
    styleUrls: ['./asset-documents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetDocumentsComponent implements OnChanges {
    @Input() assetId: string;
    @Input() acceptedFileTypes: string[] = ['jpeg', 'jpg', 'png', 'csv', 'xlsx', 'pdf'];

    displayedFile: DocumentAssetImage | null;

    refresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
    refresh$: Observable<boolean> = this.refresh.asObservable();

    constructor(private documentService: DocumentService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId && changes.assetId.currentValue !== changes.assetId.previousValue) {
            this.displayedFile = null;
        }
    }

    refreshList() {
        this.refresh.next(true);
    }

    displayFile(file: DocumentAssetImage) {
        this.displayedFile = file;
    }

    deleteFile() {
        this.refreshList();
        this.displayedFile = null;
    }

    searchTagOptions: TagOptionsCallback = (searchString: string): Observable<string[]> => {
        return this.documentService.getSearchTag(searchString);
    };
}
