import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormatsService} from '@core/utils';
import {Observable, Subject} from 'rxjs';
import {StudiesStore} from '../../../@store';
import {takeUntil} from 'rxjs/operators';
import {AssetDataAuditStore} from '@store/data-audit/asset-data-audit.store';
import {DataAuditStudyDataQuality, QUALITY_STATUS_TYPE, Unit} from '@core/interfaces/engin/data-audit/asset-data-audit';

@Component({
    selector: 'ngx-data-audit-widget',
    templateUrl: './data-audit-widget.component.html',
    styleUrls: ['./data-audit-widget.component.scss'],
})
export class DataAuditWidgetComponent implements OnInit, OnDestroy {
    @ViewChild('icon', {static: false}) icon: ElementRef;
    /*
     * @Input disableWidget: boolean, optional. If true, widget will be displayed on page in disabled stated. Optional
     * parameter may be used if the widget is added directly to the page, rather than throuh page header component.
     */
    @Input() disableWidget: boolean = false;
    @Input() popoutState$: Observable<any>;

    destroy$: Subject<boolean> = new Subject<boolean>();
    QUALITY_STATUS_TYPE = QUALITY_STATUS_TYPE;
    isCollapsed = false;

    constructor(public studiesStore: StudiesStore, public dataAuditStore: AssetDataAuditStore) {}

    ngOnInit() {
        if (this.popoutState$) {
            this.popoutState$.pipe(takeUntil(this.destroy$)).subscribe((d) => {
                this.dataAuditStore.setStudyDataQualityPopout(d);
            });
        }
    }

    public dataQuality$: Observable<DataAuditStudyDataQuality> = this.dataAuditStore.studyDataQuality$;

    public getFormattedValue(value: number, unit: Unit): string {
        return FormatsService.prepareValue(value, unit.prefix[0], unit.suffix[0]);
    }

    getButtonIcon(iconType: QUALITY_STATUS_TYPE) {
        switch (iconType) {
            case QUALITY_STATUS_TYPE.DISABLED:
            case QUALITY_STATUS_TYPE.POOR:
                return 'alert-circle-outline';
            case QUALITY_STATUS_TYPE.FAIR:
                return 'alert-triangle-outline';
            case QUALITY_STATUS_TYPE.GOOD:
                return 'checkmark-circle-2-outline';
        }
    }

    getButtonColor(iconType) {
        switch (iconType) {
            case QUALITY_STATUS_TYPE.POOR:
                return 'danger';
            case QUALITY_STATUS_TYPE.FAIR:
                return 'primary';
            case QUALITY_STATUS_TYPE.GOOD:
                return 'success';
        }
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
