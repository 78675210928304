<nb-card [class.pointer-none]="!selectedReportType">
    <div class="degradation-card degradation-factors">
        <nb-card-header class="pb-1">2. Report Type</nb-card-header>
        <nb-card-body class="chart-card-body degradation-card">
            <div class="factor-item" *ngFor="let type of reportTypes">
                <div class="display-flex">
                    <ngx-icon-box
                        class="icon"
                        icon="checkmark-circle-2-outline"
                        [class.icon-selected]="type === selectedReportType"
                    ></ngx-icon-box>
                    <span>{{ getLabel(type) }}</span>
                </div>
                <ng-container *ngIf="reportRequestComposer.studySelected | async">
                    <div class="selector" *ngIf="type === selectedReportType">
                        <div class="line"></div>
                        <ngx-icon-box class="arrow-right" icon="arrow-right"></ngx-icon-box>
                    </div>
                </ng-container>
            </div>
        </nb-card-body>
    </div>
</nb-card>
