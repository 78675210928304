import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {Injectable} from '@angular/core';

@Injectable()
export class SupportApi {
    private readonly prefix: string = 'support';

    constructor(private api: HttpService) {}

    createTicket(ticket): Observable<any> {
        return this.api.post(`${this.prefix}/create-ticket`, ticket);
    }

    accessKnowledgeBase(): Observable<any> {
        return this.api.get(`${this.prefix}/knowledge-base`, {responseType: 'text'});
    }
}
