import {
    Directive,
    Input,
    ComponentFactoryResolver,
    Type,
    ViewContainerRef,
    ComponentRef,
    OnChanges,
    SimpleChanges,
} from '@angular/core';

/**
 * Directive for render component in runtime
 *
 * ### Usage
 *
 * <ng-template
 *  ngxDynamicComponent
 *  [component]="{type of component for render}"
 *  [params]="{object with properties names like component input parameters}">
 * </ng-template>
 */
@Directive({
    selector: '[ngxDynamicComponent]',
})
export class DynamicComponentDirective implements OnChanges {
    @Input() component: Type<any>;
    @Input() params: any;

    constructor(private viewContainerRef: ViewContainerRef, private factoryResolver: ComponentFactoryResolver) {}

    ngOnChanges(changes: SimpleChanges): void {
        this.viewContainerRef.clear();
        const factory = this.factoryResolver.resolveComponentFactory(this.component);
        const component = factory.create(this.viewContainerRef.injector);
        this.setInputParameters(component);
        this.viewContainerRef.insert(component.hostView);
    }

    private setInputParameters(component: ComponentRef<any>): void {
        if (this.params) {
            const paramProps = Object.keys(this.params);
            paramProps.forEach((prop) => {
                component.instance[prop] = this.params[prop];
            });
        }
    }
}
