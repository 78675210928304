import {Component, Input} from '@angular/core';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';

@Component({
    selector: 'ngx-chart-kpi-container',
    templateUrl: './chart-kpi-container.component.html',
    styleUrls: ['./chart-kpi-container.component.scss'],
})
export class ChartKpiContainerComponent {
    @Input() data: KpiData[];
    @Input() loading: boolean;
    @Input() wide: boolean = false;
}
