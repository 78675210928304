<h1 id="title" class="title">Verify Email Registration</h1>
<p class="sub-title"></p>

<nb-alert *ngIf="showMessages.error && errors?.length" outline="danger" role="alert">
    <p class="alert-title"><b>Failed!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
    </ul>
</nb-alert>

<nb-alert *ngIf="showMessages.success && messages?.length" outline="success" role="alert">
    <p class="alert-title"><b>Verified!</b></p>
    <ul class="alert-message-list">
        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
    </ul>
</nb-alert>

<ng-template #defaultDesign>
    <nb-alert outline="danger" role="alert">
        <p class="alert-title"><b>URL Expired!</b></p>
        <ul class="alert-message-list">
            <li class="alert-message">Invalid or expired URL provided</li>
        </ul>
    </nb-alert>
</ng-template>

<section class="sign-in-or-up" aria-label="Sign in">
    <p><a class="text-link" routerLink="../login">Back to Login</a></p>
</section>
