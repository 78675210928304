<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">{{file ? 'Edit' : 'Upload'}} Document</p>

        <button (click)="cancel()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div *ngIf="fileForm.value.file || fileForm.value.url; else dragDrop" class="file-upload">
            <ngx-display-file
                *ngIf="documentIsData(fileForm.value.file.fileFormat)"
                [file]="fileForm.value.file"
                [showFileFormat]="true"
            ></ngx-display-file>

            <div *ngIf="documentIsImage(fileForm.value.file.fileFormat)" class="file-info">
                <img [src]="fileForm.value.url" alt="" class="photo" />

                <div class="file-info-row">
                    <div class="file-name">
                        <span>{{ fileForm.value.fileName || fileForm.value.file.fileName }}</span>
                    </div>
                    <div class="file-size">{{ fileForm.value.size }} MB</div>
                </div>
            </div>

            <button (click)="unstageImage()" *ngIf="!file" ghost nbButton>
                <nb-icon class="icon" icon="trash-2-outline"></nb-icon>
            </button>
        </div>

        <ngx-input-error [formGroup]="fileForm" fieldName="file"></ngx-input-error>

        <form [formGroup]="fileForm">
            <label for="fileName">Name</label>

            <div class="d-flex align-items-center mb-3">
                <input formControlName="fileName" fullWidth id="fileName" nbInput placeholder="Name" />
                <span class="label ml-2">.{{ FileFormatTypeLabels[fileForm.value.file?.fileFormat] }}</span>
            </div>
            <ngx-input-error [formGroup]="fileForm" fieldName="fileName"></ngx-input-error>

            <label>Tag</label>
            <nb-form-field>
                <input
                    fullWidth
                    #tagInput
                    formControlName="tag"
                    nbInput
                    [nbAutocomplete]="tag"
                    [focusInputOnValueChange]="false"
                />
                <button nbSuffix nbButton ghost (click)="tagInput.focus()">
                    <nb-icon icon="chevron-down-outline"></nb-icon>
                </button>
            </nb-form-field>
            <nb-autocomplete #tag>
                <nb-option *ngFor="let item of tagOptions$ | async" [value]="item">{{ item }}</nb-option>
            </nb-autocomplete>
            <ngx-input-error [formGroup]="fileForm" fieldName="tag"></ngx-input-error>

            <label for="description">Description (optional)</label>
            <textarea formControlName="description" fullWidth id="description" nbInput></textarea>
            <ngx-input-error [formGroup]="fileForm" fieldName="description"></ngx-input-error>

            <div class="btn-container">
                <button (click)="cancel()" nbButton status="basic">
                    <nb-icon icon="close-circle-outline"></nb-icon>
                    Cancel
                </button>

                <button
                    (click)="save()"
                    [disabled]="fileForm.invalid && saveBtnPressed"
                    class="ml-3"
                    nbButton
                    status="primary"
                >
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                    {{ !file ? "Upload" : "Save" }}
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>

<ng-template #dragDrop>
    <ngx-drop-file
        (onFileAdded)="onFileAdded($event)"
        [fileFormats]="acceptedFileTypes"
        [multiple]="false"
    ></ngx-drop-file>
</ng-template>
