<div class="value-box-container">
    <label class="value-box-title">{{ title }}</label>
    <label class="value-box-value" *ngIf="!formattedValue">
        {{ value | ngxNumberWithCommas }}
    </label>
    <label class="value-box-value" *ngIf="formattedValue">
        {{ formattedValue }}
    </label>
    <div class="value-box-border"></div>
</div>
