<!--<div class="input-btns">-->
<!--    <input nbInput fullWidth fieldSize="small" [formControl]="inputItemFormControl" />-->
<!--    <div class="btns">-->
<!--        <button (click)="plus()">-->
<!--            <nb-icon class="icon" icon="arrow-ios-upward-outline"></nb-icon>-->
<!--        </button>-->
<!--        <button (click)="minus()">-->
<!--            <nb-icon class="icon" icon="arrow-ios-downward-outline"></nb-icon>-->
<!--        </button>-->
<!--    </div>-->
<!--</div>-->
<div class="input-btns">
    <input nbInput fullWidth fieldSize="small" [formControl]="inputItemFormControl" />
    <div class="btns">
        <button (click)="plus()">
            <nb-icon class="icon" icon="arrow-ios-upward-outline"></nb-icon>
        </button>
        <button (click)="minus()">
            <nb-icon class="icon" icon="arrow-ios-downward-outline"></nb-icon>
        </button>
    </div>
</div>
