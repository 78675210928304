import {Observable} from 'rxjs';
import {APIResponse, Filter, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {Graph} from '@core/interfaces/common/pages';

export interface ProgramCreateDto {
    name: string;
    description: string;
    segmentId: number;
    ownerId: string;
    ownerName: string;
    kpiList: string[];
    assetClassList: string[];
    investmentPriority: InvestmentPriority;
    budgetMethod: ProgramBudgetType;
    budgetRows: ProgramBudgetCreateDto[];
    sensitivityId: number;
}

export interface ProgramBudgetCreateDto {
    sensitivityId: number;
    programId: number;
    startYear: number;
    endYear: number;
    simpleUnitType: SimpleUnitType;
    assetClassList: string[];
    value: number;
}

export interface ProgramBudgetUpdateDto {
    sensitivityId: number;
    id: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    scope: string[];
    value: number;
}

export interface Program {
    id: number;
    name: string;
    description: string;
    segmentId: string;
    segmentName: string;
    scope: Filter[];
}

export interface ProgramBudgetPerYearDto {
    id: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    scope: string[];
    value: number;
}

export interface ProgramOutcomes {
    assetClassList: string[];
    investmentPriority: InvestmentPriority;
    outcomeMaintainType: OutcomeMaintainType;
    belowPercent: number;
    startYear: number;
}
export enum InvestmentPriority {
    ECONOMIC = 'ECONOMIC',
    HEALTH = 'HEALTH',
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
}

export enum OutcomeMaintainType {
    PAST = 'PAST',
    APPROACHING = 'APPROACHING',
}

export enum SimpleUnitType {
    CURRENCY = 'CURRENCY',
    COUNT = 'COUNT',
    PERCENT = 'PERCENT',
    TEMPERATURE = 'TEMPERATURE',
    PRESSURE = 'PRESSURE',
    POWER = 'POWER',
    LENGTH = 'LENGTH',
    CONCENTRATION = 'CONCENTRATION',
    RATE = 'RATE',
    NONE = 'NONE',
}

export enum ProgramBudgetType {
    UNIFORM = 'UNIFORM',
    UNIQUE = 'UNIQUE',
}

export enum KpiType {
    DELTA_10YEAR_RISK = 'DELTA_10YEAR_RISK',
    DELTA_10YEAR_FAILURE_COUNT = 'DELTA_10YEAR_FAILURE_COUNT',
    DELTA_10YEAR_PROACTIVE_COUNT = 'DELTA_10YEAR_PROACTIVE_COUNT',
    DELTA_10YEAR_FAILURE_COST = 'DELTA_10YEAR_FAILURE_COST',
    DELTA_10YEAR_PROACTIVE_COST = 'DELTA_10YEAR_PROACTIVE_COST',
}

export interface ProgramParam {
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    value: number;
    scope: Filter[];
}

export interface ProgramBudget {
    id: number;
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    value: number;
    scope: Filter[];
}

export interface ProgramFullDto {
    id: number;
    name: string;
    description: string;
    segmentId: string;
    segmentName: string;
    ownerId: string;
    ownerName: string;
    scope: Filter[];
    kpiList: KpiType[];
    params: ProgramParam[];
    budgets: ProgramBudget[];
    investmentPriority: InvestmentPriority;
    budgetMethod: ProgramBudgetType;
}

export interface ProgramBudgetUpdate {
    programId: number;
    sensitivityId: number;
    budgetMethod: ProgramBudgetType;
    investmentPriority: InvestmentPriority;
    budgetsRows: ProgramBudgetUpdateDto[];
}

export interface InvestmentOverviewDto {
    workflowItemId: number;
    sensitivityId: number;
    aggregateBy: 'SEGMENT' | 'PROGRAM' | 'PROJECT' | 'YEAR';
    filterList: Filter[];
}

export interface keyValue {
    key: any;
    value: any;
}

export interface PmHeaderFilterData {
    program: [];
    segment: [];
    project: [];
}

// Optimizer request for charts
export interface OutcomeChartRequest {
    sensitivityId: number;
    workflowItemId: number;
    graphList: Graph[];
    filters: Filter[];
}

export interface StringSet {
    [param: string]: string;
}

// Generic chart response object contains data for constrained and unconstrained views
export interface OutcomeChartResponse {
    constrained: ClusteredSeries[];
    unconstrained: ClusteredSeries[];
}

export interface ClusteredSeries {
    code: string;
    name: string;
    graphTitle: string;
    graphId: string;
    legend: string[];
    labels: string[];
    data: number[][];
    unit: Unit;
}

export interface Unit {
    prefix: string[];
    suffix: string[];
    symbol: string[];
}

// Specific chart response for reporting summary KPIs
export interface OutcomeKpiResponse {
    constrained: KpiDataResp[];
    unconstrained: KpiDataResp[];
}

export interface KpiDataResp {
    title: string;
    subtitle: string;
    unit: string;
    residualValue: number;
    delta: number;
}

export abstract class PmProgramService {
    // Program
    abstract getProgramsList(): Observable<DataSource>;
    abstract getProgramsListDto(): Observable<DataSource>;
    abstract getPrograms(params: Filter[]): Observable<Program[]>;
    abstract getProgramById(programId: number): Observable<APIResponse<Program>>;
    abstract getFullProgramDtoById(programId: number): Observable<APIResponse<ProgramFullDto>>;
    abstract createProgram(program: ProgramCreateDto): Observable<APIResponse<Program>>;
    abstract editProgramBudget(program: ProgramBudgetUpdate): Observable<APIResponse<ProgramFullDto>>;
    abstract editProgramDetails(program: Program): Observable<APIResponse<Program>>;
    abstract deleteProgram(programId: number): Observable<APIResponse<boolean>>;

    abstract validateProgramName(programName: string): Observable<boolean>;
    abstract findProgramKpis(
        programId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>>;

    abstract findAllBySegment(segmentId: number): Observable<APIResponse<Program[]>>;

    abstract downloadProgramsListCsv(param: Map<string, any>): Observable<APIResponse<DownloadCSVUrl>>;

    abstract downloadProgramAssets(programId: number, workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getKpiOptions(): Observable<APIResponse<string[]>>;

    abstract getInvestmentPriorityOptions(): Observable<APIResponse<any>>;

    abstract getInvestmentOverview(param: InvestmentOverviewDto): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getProgramBudgetByOutcomes(
        param: ProgramOutcomes,
        workflowItemId: number,
    ): Observable<APIResponse<ProgramBudgetPerYearDto[]>>;

    abstract getBudgetChart(
        param: ProgramBudget[],
        workflowItemId: number,
    ): Observable<APIResponse<SimpleMultiSeries<number>>>;

    //Outcome Api

    abstract getChartDataSummarySpending(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataSummaryOutcomes(request: OutcomeChartRequest): Observable<APIResponse<OutcomeKpiResponse>>;

    abstract getChartDataProactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataReactiveForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataFailureForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataOutageForecast(request: OutcomeChartRequest): Observable<APIResponse<OutcomeChartResponse>>;

    abstract getChartDataRiskForecastByImpact(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>>;
    abstract getChartDataRiskForecastByCategory(
        request: OutcomeChartRequest,
    ): Observable<APIResponse<OutcomeChartResponse>>;
}
