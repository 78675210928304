import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {FormsService} from '@core/interfaces/common/forms';
import {FormScopeModalComponent} from '../../../../../pages/mobile-admin/components/form-scope-modal/form-scope-modal.component';
import {filter, mergeMap} from 'rxjs/operators';
import {Filter} from '@core/interfaces/system/system-common';

@Component({
    selector: 'ngx-scope-cell',
    templateUrl: './scope-cell.component.html',
    styleUrls: ['./scope-cell.component.scss'],
})
export class ScopeCellComponent {
    @Input() value: any;

    @Output() scopeSaved: EventEmitter<void> = new EventEmitter();

    constructor(private dialogService: NbDialogService, private formsData: FormsService) {}

    openScopeSelectionDialog(): void {
        this.dialogService
            .open(FormScopeModalComponent, {
                context: {
                    formId: this.value.id,
                },
            })
            .onClose.pipe(
                filter((res) => !!res),
                mergeMap((res: Filter[]) => this.formsData.setFormScope(this.value.id, res)),
            )
            .subscribe(() => this.scopeSaved.emit());
    }
}
