import {Observable} from 'rxjs';
import {APIResponse} from '../system/system-common';
import {DataOperationType} from './data-file';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

export const uploadSourceMap = {MANUAL: 'Manual', AUTO: 'Automated'} as const;
export type UploadSource = keyof typeof uploadSourceMap;

export const persistedDataStatusMap = {CREATED: 'Created', SUCCESS: 'Success', FAILED: 'Failed'} as const;
export type PersistedDataStatus = keyof typeof persistedDataStatusMap;

export interface PersistedData {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    completedOn: Date;
    uploadSource: UploadSource;
    sourceName: string;
    uploadFileId: number;
    countAdded: number;
    countUpdated: number;
    countDeleted: number;
    archiveFileFormat: string;
    archiveFileName: string;
    archiveBucketName: string;
    archiveFileKey: string;
    tableName: string;
    dataOperationType: DataOperationType;
    status: PersistedDataStatus;
}

export abstract class PersistedDataService {
    abstract findOne(id: string): Observable<APIResponse<PersistedData>>;

    abstract listFiles(): Observable<DataSource>;
}
