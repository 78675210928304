import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'ngxSentenceCase'})
export class SentenceCasePipe implements PipeTransform {
    // TODO: limitation; intended for camelCase conversion.
    transform(s: string): string {
        const result = s.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }
}
