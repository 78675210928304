import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl, WorkflowValidationService} from '../../../interfaces/engin/workflow-validation';
import {WorkflowValidationApi} from '../api/workflow-validation.api';
import {APIResponse} from '../../../interfaces/system/system-common';

@Injectable()
export class WorkflowValidationServiceImpl extends WorkflowValidationService {
    constructor(private api: WorkflowValidationApi) {
        super();
    }

    getValidationDetailDataSource(workflowId: number, cause: string, subcause: string): Observable<DataSource> {
        return this.api.getValidationDetailDataSource(workflowId, cause, subcause);
    }

    getValidationOverviewDataSource(workflowId: number): Observable<DataSource> {
        return this.api.getValidationOverviewDataSource(workflowId);
    }

    downloadValidationDetailCsv(
        workflowId: number,
        cause: string,
        subcause: string,
        additionalQueryParam: string,
    ): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadValidationDetailCsv(workflowId, cause, subcause, additionalQueryParam);
    }

    downloadValidationCsv(workflowId: number, additionalQueryParam: string): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadValidationCsv(workflowId, additionalQueryParam);
    }
}
