import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PagesStore, SidebarStore} from '../../../@store';
import {UsersStore} from '../../../@store/common/users.store';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'ngx-header',
    styleUrls: ['./header.component.scss'],
    templateUrl: './header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    @Input() currentUser: any;

    constructor(public pagesStore: PagesStore, public sidebarStore: SidebarStore, public userStore: UsersStore) {}

    staging: boolean = environment.STAGING;
}
