import {Component, Input, OnInit, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
    selector: 'ngx-full-image-dialog',
    templateUrl: './full-image-dialog.component.html',
    styleUrls: ['./full-image-dialog.component.scss'],
})
export class FullImageDialogComponent {
    @Input() img: string;
    @Input() imgName: string;

    constructor(@Optional() private dialogRef: NbDialogRef<FullImageDialogComponent>) {}

    close() {
        this.dialogRef.close();
    }
}
