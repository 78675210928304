import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {AssetDto} from '@core/interfaces/common/asset';

export class StudyAsset {
    assetId: string;
    assetClassCode: string;
    assetSubclassCode: string;
    circuit: string;
    prid: string;
    neighborhoodId: string;
    effectiveAge: number;
    oitYear: number;
    aitYear: number;
    fitYear: number;
    healthIndex: string;
    sensitivityCic: string;
    sensitivityInsp: string;
}

export class AssetHealthIndex {
    info: {
        assetId: string;
        assetClass: string;
        assetSubclass: string;
        methodology: string;
    };
    resultsFinal: {
        minScore: number;
        maxScore: number;
        totalScore: number;
        gatewayMultiplier: number;
        hi: number;
        dataAvailability: number;
        dataUnavailability: number;
        dataValidity: number;
        availTotalScore: number;
        validTotalScore: number;
        availValidMaxScore: number;
        normalizeRequired: string;
        lowScorePoor: string;
    };
    resultsDetail: DegradationFactor[];
    gateway: GatewayDetails;
    hazardRate: number[];
    history: AssetHistoryItem[];
    valueMap: PreProcessItem[];
}

export class GatewayDetails {
    gatewayComparison: string;
    gatewayCondition: string;
    gatewayFactor: string;
    gatewayMultiplier: number;
    appliedGatewayMultiplier: number;
}

export class DegradationFactor {
    degNum: number;
    degWeight: number;
    degradation: number;
    degFactor: string;
    degFactorScoring: string;

    inspValueStr: string;
    inspValueNumeric: number;
    dataAvailable: boolean;
    dataValid: boolean;

    score: string;
    numericScore: number;
    numericScoreMin: number;
    numericScoreMax: number;
    weightedScore: number;
    weightedScoreMin: number;
    weightedScoreMax: number;
    possibleValues: DegradationFactorScoring[];
    gateway: GatewayDetails;
}

export class DegradationFactorScoring {
    score: string;
    value?: string;
    lowerBound?: number;
    upperBound?: number;
}

export class AssetHistoryItem {
    date: string;
    healthIndex: number;
}

export class AssetSensitivityData {
    shapeExisting: number;
    shapeNew: number;
    scaleExisting: number;
    scaleNew: number;
    impactExisting: number;
    impactNew: number;
    maintenanceExisting: number;
    maintenanceNew: number;
    intCost: number;
    existingAsset: any[];
    newAsset: any[];
    intType: string;
}

export class PreProcessItem {
    name: string;
    value: string;
}

export abstract class AssetsService {
    // Study Assets
    abstract getStudyAssetsDataSource(studyId: number, filters: Filter[]): Observable<DataSource>;

    abstract searchStudyAssets(studyId: number, assetName: string, filters: Filter[]): Observable<string[]>;

    abstract getStudyAsset(studyId: number, assetId: string): Observable<StudyAsset>;

    // Asset Details
    abstract getAssetDetails(studyId: number, assetId: string): Observable<any>;

    abstract getAssetDetailsHealthIndex(studyId: number, assetId: string): Observable<AssetHealthIndex>;

    abstract getOneAssetForStudy(workflowItemId: number, assetId: string): Observable<APIResponse<AssetDto>>;
}
