import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {DashboardCardBodyText} from '@core/interfaces/common/pages';
import {NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-dashboard-card-text',
    templateUrl: './dashboard-card-text.component.html',
    styleUrls: ['./dashboard-card-text.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardTextComponent {
    @Input() body: DashboardCardBodyText;

    public readonly NbTrigger = NbTrigger;
}
