import {Inject, Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {combineLatest, Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {NB_AUTH_INTERCEPTOR_HEADER, NB_AUTH_TOKEN_INTERCEPTOR_FILTER, NbAuthService, NbAuthToken} from '@nebular/auth';
import {MsalService} from '@azure/msal-angular';

@Injectable()
export class CustomAuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private msalAuthService: MsalService,
        @Inject(NB_AUTH_TOKEN_INTERCEPTOR_FILTER) protected filter,
        @Inject(NB_AUTH_INTERCEPTOR_HEADER) protected headerName: string = 'Authorization',
    ) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // do not intercept request whose urls are filtered by the injected filter
        if (!this.filter(req)) {
            return this.authService.isAuthenticatedOrRefresh().pipe(
                switchMap((authenticated) => {
                    if (authenticated) {
                        return this.authService.getToken().pipe(
                            switchMap((token: NbAuthToken) => {
                                if (token && token.getValue()) {
                                    req = req.clone({
                                        setHeaders: {
                                            [this.headerName]: `Bearer ${token.getValue()}`,
                                        },
                                    });
                                }
                                return next.handle(req);
                            }),
                        );
                    } else {
                        // // Check SSO Token validation.
                        const accountKeys = localStorage.getItem('msal.account.keys');
                        if (accountKeys) {
                            const keyForAccount = JSON.parse(accountKeys)[0];
                            const accountList = this.msalAuthService.instance.getAllAccounts();
                            const currentAccountInfo = JSON.parse(localStorage.getItem(keyForAccount));
                            const currentSSOAccount = accountList.find(
                                (account) => account.homeAccountId === currentAccountInfo.homeAccountId,
                            );
                            if (currentSSOAccount) {
                                return combineLatest<Observable<NbAuthToken>, Observable<boolean>>([
                                    this.authService.getToken(),
                                    this.checkSSOToken(currentSSOAccount),
                                ]).pipe(
                                    switchMap(([token, SSOTokenValid]: [NbAuthToken, boolean]) => {
                                        if (SSOTokenValid) {
                                            if (token && token.getValue()) {
                                                req = req.clone({
                                                    setHeaders: {
                                                        [this.headerName]: `Bearer ${token.getValue()}`,
                                                    },
                                                });
                                            }
                                        }
                                        return next.handle(req);
                                    }),
                                );
                            }
                        }
                        // Request is sent to server without authentication so that the client code
                        // receives the 401/403 error and can act as desired ('session expired', redirect to login, aso)
                        return next.handle(req);
                    }
                }),
            );
        } else {
            return next.handle(req);
        }
    }

    checkSSOToken(currentSSOAccount): Observable<boolean> {
        return this.msalAuthService
            .acquireTokenSilent({
                account: currentSSOAccount,
                scopes: ['user.read'],
            })
            .pipe(
                map((res) => {
                    const decodedToken = JSON.parse(atob(res.accessToken.split('.')[1]));

                    const expirationTime = decodedToken.exp;
                    const currentTime = Math.floor(Date.now() / 1000);

                    return currentTime <= expirationTime;
                }),
            );
    }

    protected get authService(): NbAuthService {
        return this.injector.get(NbAuthService);
    }
}
