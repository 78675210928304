import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';

export enum ApiType {
    MAINTENANCE_PLANNING = 'maintenance',
}
export abstract class S3Service {
    abstract getPresignedUrl(
        fileName: string,
        fileFormat: string,
        endpoint: string,
    ): Observable<APIResponse<PresignedURL>>;

    abstract uploadToPresignedUrl(url: string, data: File);
}
