<div class="form-container" [class.position-relative]="withAmount">
    <nb-select
        fullWidth
        multiple
        [formControl]="selectControl"
        [placeholder]="placeholder"
        [optionsPanelClass]="optionsPanelClass"
    >
        <nb-select-label *ngIf="selectLabel">
            {{ selectLabel }}
        </nb-select-label>
        <nb-select-label *ngIf="!selectLabel">
            {{ isAllSelected ? "All" : getLabels(selectControl.value) }}
        </nb-select-label>
        <nb-option [value]="''" class="search" (click)="withStopPropagation && $event.stopPropagation()">
            <nb-form-field>
                <input
                    #filterInput
                    nbInput
                    fullWidth
                    [formControl]="filterControl"
                    (keydown.space)="$event.stopImmediatePropagation()"
                    placeholder="Search..."
                />
                <button nbSuffix nbButton ghost (click)="filterControl.reset()">
                    <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"></nb-icon>
                </button>
            </nb-form-field>
        </nb-option>
        <nb-option
            *ngIf="!dynamicSearch && options.length && options.length === (filteredOptions$ | async).length"
            [value]="0"
            (click)="withStopPropagation && $event.stopPropagation(); toggleSelectAll()"
        >
            Select All
        </nb-option>
        <nb-option
            (click)="withStopPropagation && $event.stopPropagation()"
            *ngFor="let option of filteredOptions$ | async"
            [value]="option"
        >
            {{ option.name }}
        </nb-option>
    </nb-select>
    <span class="amount" *ngIf="selectControl?.value?.length && withAmount">
        {{ isAllSelected ? selectControl.value.length - 1 : selectControl.value.length }}
    </span>
</div>
