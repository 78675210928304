import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable, of} from 'rxjs';
import {APIResponse, Filter, FilterFieldOption, PresignedURL} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    ChangeHistoryDto,
    InspectionResultCorrectionDto,
    InspectionResultDto,
    InspectionResultReviewDto,
    Rule,
} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {InspectionResultFullDto} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {HttpParams} from '@angular/common/http';

@Injectable()
export class MaintenancePlanningApi {
    private readonly prefix = 'maintenance';

    constructor(private api: HttpService) {}

    listDto(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    findOneDto(resultId: number): Observable<InspectionResultDto> {
        return this.api.get(`${this.prefix}/dto/${resultId}`);
    }

    findOneFullDto(resultId: number): Observable<InspectionResultFullDto> {
        return this.api.get(`${this.prefix}/dto-full/${resultId}`);
    }

    getChangeHistory(resultId: number): Observable<ChangeHistoryDto[]> {
        return this.api.get(`${this.prefix}/history/${resultId}`);
    }

    submitReview(resultId: number, req: InspectionResultReviewDto): Observable<InspectionResultDto> {
        return this.api.post(`${this.prefix}/review/${resultId}`, req);
    }

    submitCorrection(resultId: number, req: InspectionResultCorrectionDto): Observable<InspectionResultFullDto> {
        return this.api.post(`${this.prefix}/correction/${resultId}`, req);
    }

    listDtoCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.post(`${this.prefix}/dto/list/csv`, {filterParams});
    }

    findLatestInspectionResult(resultId: number): Observable<APIResponse<InspectionResultDto>> {
        return this.api.get(`${this.prefix}/latest/${resultId}`);
    }

    getFilterOptions(fieldKey: string, searchString: string): Observable<FilterFieldOption[]> {
        return this.api.post(`${this.prefix}/filter-options`, {
            fieldKey,
            searchString,
        });
    }
    getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>> {
        return this.api.post(`${this.prefix}/presigned-url`, {fileName, fileFormat});
    }
    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': fileData.type,
            },
        });
    }
    listRule(): Observable<Rule[]> {
        // return this.api.get(`${this.prefix}/inspection/rules`);
        return of([
            {
                id: 10,
                name: 'Rule name 1',
                description: 'If Single-select /"Label/" value is /"Good/", then set status /"APPROVED/"',
                isActive: true,
                ruleType: 'AUTOMATIC',
            },
            {
                id: 11,
                name: 'Rule name 2',
                description:
                    'If Single-select “Label” value is “Good” OR “Very Good”, then set status “Approved” OR Text field “Comment” value is empty OR Inspected By [Gary, Bill, John] mock text mock text mock text mock text mock text mock text mock text mock text last',
                isActive: false,
                ruleType: 'AUTOMATIC',
            },
            {
                id: 12,
                name: 'Rule name 3',
                description: 'If Single-select /"Label/" value is /"Good/", then set status /"APPROVED/"',
                ruleType: 'MANUAL',
            },
            {
                id: 14,
                name: 'Rule name 4',
                description:
                    'If Single-select /"Label/" value is /"Good/", then set status /"APPROVED/"mock text mock text mock text mock text mock text mock text mock text mock text lastmock text mock text mock text mock text mock text mock text mock text mock text lastmock text mock text mock text mock text mock text mock text mock text mock text last',
                ruleType: 'MANUAL',
            },
            {
                id: 15,
                name: 'Rule name 4',
                description:
                    'If Single-select /"Label/" value is /"Good/", then set status /"APPROVED/"mock text mock text mock text mock text mock text mock text mock text mock text lastmock text mock text mock text mock text mock text mock text mock text mock text lastmock text mock text mock text mock text mock text mock text mock text mock text last',
                ruleType: 'MANUAL',
            },
        ]);
    }
}
