import {AbstractControl, ValidationErrors} from '@angular/forms';
import {UsersService} from '@core/interfaces/common/users';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

export class UserEmailValidator {
    static createEmailValidator(usersService: UsersService) {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value) return of(null);

            const id = control.parent.controls['id'].value;

            return usersService
                .userEmailAvailable(control.value, id)
                .pipe(map((result: APIResponse<boolean>) => (!result.response ? {emailAlreadyExists: true} : null)));
        };
    }
}
