<div class="flex">
    <div class="form-field">
        <label for="min" class="label">>=</label>
        <input
            nbInput
            fullWidth
            fieldSize="small"
            type="number"
            [min]="options.min"
            [max]="options.max"
            id="min"
            autofocus
            class="p-1"
            [formControl]="minControl"
            (keydown.e)="$event.preventDefault()"
            (keydown.enter)="max.focus()"
        />
    </div>

    <span class="defis">—</span>

    <div class="form-field">
        <label for="max" class="label"><=</label>
        <input
            nbInput
            fullWidth
            fieldSize="small"
            type="number"
            [min]="options.min"
            [max]="options.max"
            id="max"
            #max
            class="p-1"
            [formControl]="maxControl"
            (keydown.e)="$event.preventDefault()"
        />
    </div>
</div>
