import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleMultiSeries, SimpleUnit, ThresholdLinear, ThresholdStatic} from '@core/interfaces/system/system-common';
import {LatestModelStatus, LiveModelRequest} from './live';

export interface Sf6GasLeakUnits {
    temperatureUnit: SimpleUnit;
    pressureUnit: SimpleUnit;
}

/*
 * Response DTOs
 */
export interface Sf6GasLeakLatestStatusResponse {
    status: string;
    infoList: {label: string; value: string}[];
    currentPressure: number;
    currentSlope: number;
    yearsToAlarm: number;
}

export interface Sf6GasLeakComparisonResponse {
    data: SimpleMultiSeries<Date>;
    thresholds: ThresholdLinear[];
}

export interface Sf6GasLeakRawResponse {
    data: SimpleMultiSeries<Date>;
}

export interface Sf6GasLeakCorrectedResponse {
    data: SimpleMultiSeries<Date>;
    thresholds: ThresholdStatic[];
}

/*
 * Supporting DTOs
 */
export interface Sf6RawDataItem {
    temperature: number; //temperature
    pressure: number; //pressure
    date: string; //date
    heaterStatus: string; //? 'on'/'off'
}

export abstract class Sf6GasLeakService {
    abstract getSf6GasLeakUnits(): Observable<Sf6GasLeakUnits>;

    abstract getSf6GasLeakDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getSf6GasLeakLatestStatusDetails(request: LiveModelRequest): Observable<LatestModelStatus>;

    abstract getSf6GasLeakChartDataComparison(request: LiveModelRequest): Observable<Sf6GasLeakComparisonResponse>;

    abstract getSf6GasLeakChartDataRaw(request: LiveModelRequest): Observable<Sf6GasLeakRawResponse>;

    abstract getSf6GasLeakChartDataCorrected(request: LiveModelRequest): Observable<Sf6GasLeakCorrectedResponse>;
}
