import {Component, Input, ChangeDetectionStrategy} from '@angular/core';
import {IconDetails} from '../../../@core/interfaces/common/pages';

@Component({
    selector: 'ngx-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    @Input() items: any[];
    @Input() expanded: boolean;

    constructor() {}

    public getIconDetails(active: boolean, icon: IconDetails) {
        let retIcon = icon;
        if (!active) {
            retIcon = {
                ...icon,
                icon: icon.icon + '-outline',
            };
        }
        return retIcon;
    }
}
