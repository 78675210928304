import Field from '@arcgis/core/layers/support/Field';

export class DistrictOverlayFieldsService {
    public getCustomMapFields(): Field[] {
        return this._fields.map((e) => new Field(e));
    }

    private _fields: any[] = [
        // Unique identifiers
        {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
        },
        // Data
        {
            name: 'districtName',
            alias: 'name',
            type: 'string',
        },
        {
            name: 'districtType',
            alias: 'type',
            type: 'string',
        },
        {
            name: 'districtDescription',
            alias: 'description',
            type: 'string',
        },
    ];
}
