<nb-accordion [formGroup]="form" [multi]="true" class="accordion">
    <nb-accordion-item *ngFor="let filterItem of filtersList">
        <nb-accordion-item-header>
            <span class="ml-4">{{ filterItem.fieldName }}</span>

            <span [class.num]="FilterFieldType.isNumericFieldType(filterItem.fieldType)" class="selected-number">
                {{ getFilterSelectedNumber(filterItem) }}
            </span>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
            <div
                *ngIf="!filterItem.fieldType || filterItem.fieldType === FilterFieldType.STRING"
                class="checkbox-group"
            >
                <ngx-control-panel-checkbox-group
                    [formControlName]="filterItem.fieldKey"
                    [options]="filterItem.options"
                ></ngx-control-panel-checkbox-group>
            </div>
            <div *ngIf="isNumeric(filterItem.fieldType)">
                <ngx-analyzer-numeric-range-control
                    [formControlName]="filterItem.fieldKey"
                    [options]="{min: filterItem?.min, max: filterItem?.max}"
                ></ngx-analyzer-numeric-range-control>
            </div>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
