import {Injectable} from '@angular/core';

export enum Theme {
    LIGHT = 'default',
    DARK = 'dark',
    LIGHT_ACCESSIBILITY = 'default_accessibility',
    DARK_ACCESSIBILITY = 'dark_accessibility',
}

@Injectable()
export class ThemeService {
    private static lightThemes: string[] = [Theme.LIGHT, Theme.LIGHT_ACCESSIBILITY];
    private static darkThemes: string[] = [Theme.DARK, Theme.DARK_ACCESSIBILITY];
    private static accessibilityThemes: string[] = [Theme.LIGHT_ACCESSIBILITY, Theme.DARK_ACCESSIBILITY];
    /**
     * Parse theme into components
     * @param fullTheme string
     * @return string[] length 1 or 2
     */
    public static parseTheme(fullTheme: string): string[] {
        return fullTheme.split('_');
    }

    public static getThemeTone(fullTheme: string): string {
        return ThemeService.parseTheme(fullTheme)[0];
    }

    public static isLight(fullTheme: string): boolean {
        return ThemeService.lightThemes.includes(fullTheme);
    }

    public static isDark(fullTheme: string): boolean {
        return ThemeService.darkThemes.includes(fullTheme);
    }

    public static isAccessibility(fullTheme: string): boolean {
        return ThemeService.accessibilityThemes.includes(fullTheme);
    }

    public static constructTheme(tone: string, accessibility: boolean): Theme {
        if (accessibility) {
            if (ThemeService.isLight(tone)) return Theme.LIGHT_ACCESSIBILITY;
            else return Theme.DARK_ACCESSIBILITY;
        } else {
            if (ThemeService.isLight(tone)) return Theme.LIGHT;
            else return Theme.DARK;
        }
    }
}
