import {Observable} from 'rxjs';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    SimpleSeries,
} from '@core/interfaces/system/system-common';
import {TableFilterConfig} from '../system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {AssetColumn} from '../common/asset';
import {DataExplorerLineChart} from '../../../pages/survival-analysis/api/pojo/survival-study.pojo';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

export interface ChartRequest {
    groupByCol: string[];
    filterParams: Filter[];
    sortOderMap: Record<string, 'asc' | 'desc'>;
}

export abstract class StatusChangeService {
    abstract getStatusChangeList(filterParams: Filter[]): Observable<DataSource>;
    abstract getStatusChangeListCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>>;
    abstract getChartByDate(chartRequest: ChartRequest): Observable<APIResponse<DataExplorerLineChart>>;
    abstract getChartByCategory(chartRequest: ChartRequest): Observable<APIResponse<SimpleSeries<string, number>>>;
    abstract getTableColumns(): Observable<APIResponse<AssetColumn[]>>;
    abstract getGroupOptions(): Observable<APIResponse<AssetColumn[]>>;
    abstract getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>>;
    abstract getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>>;
}
