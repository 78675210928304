<h1 id="title" class="title">Reset Password</h1>
<p class="sub-title"></p>

<ng-container *ngIf="tokenValid; else defaultDesign">
    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">
        <p class="alert-title"><b>Password reset failed!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>
        </ul>
    </nb-alert>

    <nb-alert *ngIf="showMessages.success && messages?.length && submitted" outline="success" role="alert">
        <p class="alert-title"><b>Success!</b></p>
        <ul class="alert-message-list">
            <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>
        </ul>
    </nb-alert>

    <ng-container *ngIf="passwordValidation$; else defaultDesign">
        <ngx-password-reset
            [form]="resetPasswordForm"
            [validation$]="passwordValidation$"
            (onInputChanges)="onInputChanges($event)"
        ></ngx-password-reset>
    </ng-container>

    <button
        *ngIf="!showLoginRedirect"
        nbButton
        fullWidth
        status="primary"
        size="large"
        [disabled]="!(formReady | async) || submitted"
        (click)="submit()"
    >
        Save
    </button>
    <button *ngIf="showLoginRedirect" nbButton fullWidth status="primary" size="large" routerLink="../login">
        Back to Login
    </button>
</ng-container>

<ng-template #defaultDesign>
    <nb-alert outline="danger" role="alert">
        <p class="alert-title"><b>URL Expired!</b></p>
        <ul class="alert-message-list">
            <li class="alert-message">Invalid or expired URL provided</li>
        </ul>
    </nb-alert>
</ng-template>

<section class="sign-in-or-up" aria-label="Sign in">
    <p><a class="text-link" routerLink="../login">Back to Login</a></p>
</section>
