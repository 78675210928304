import {ChangeDetectionStrategy, Component, Input, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {ChartDataDownloadService} from '@theme/components/chart-settings/chart-data-download-service';
import {UsageAnalyticsService} from '@core/utils';
import {Observable} from 'rxjs';

interface Error {
    errorMessage: string;
    tableName: string;
    columnName: string;
    errorCount: string | number;
}

@Component({
    selector: 'ngx-log-dialog',
    templateUrl: './log-dialog.component.html',
    styleUrls: ['./log-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogDialogComponent {
    @Input() tableSettings: any = {
        editable: false,
        enableGlobalSearch: false,
        sort: false,
        hideSubHeader: true,
        actions: {
            position: 'right',
            add: false,
            edit: false,
            delete: false,
            select: false,
        },
        columns: {
            errorMessage: {
                title: 'Error Message',
                width: '40%',
                sort: false,
            },
            tableName: {
                title: 'Table',
                width: '20%',
                type: 'string',
                sort: false,
            },
            columnName: {
                title: 'Column',
                width: '25%',
                type: 'string',
                sort: false,
            },
            errorCount: {
                title: 'Error Count',
                width: '15%',
                type: 'number',
                sort: false,
            },
        },
    };
    @Input() errorList$: Observable<Error[] | any[]>;
    @Input() headerToDownload: string[] = ['Error Message', 'Table Name', 'Column Header', 'Error Count'];
    @Input() fileNameToDownload: string;
    @Input() downloadNameAnalyticsService?: string;
    @Input() fileName?: string;

    constructor(
        @Optional() private dialogRef: NbDialogRef<LogDialogComponent>,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {}

    downloadFile(errorList) {
        ChartDataDownloadService.downloadChartData(
            {
                header: this.headerToDownload,
                data: errorList,
            },
            `${this.fileNameToDownload}.csv`,
        );

        this.downloadNameAnalyticsService &&
            this.usageAnalyticsService.logDownload(this.fileNameToDownload, this.downloadNameAnalyticsService);
    }

    close() {
        this.dialogRef.close();
    }
}
