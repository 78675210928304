import {Component} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {Theme, ThemeService} from '@core/utils/theme.service';

@Component({
    selector: 'ngx-theme-switcher',
    styleUrls: ['./theme-switcher.component.scss'],
    templateUrl: './theme-switcher.component.html',
})
export class ThemeSwitcherComponent {
    private firstValue: string = Theme.LIGHT;
    private secondValue: string = Theme.DARK;

    private currentTheme: string;

    constructor(private nbThemeService: NbThemeService) {
        this.nbThemeService.getJsTheme().subscribe((theme) => {
            this.currentTheme = theme.name;
        });
    }

    isFirstValue() {
        return ThemeService.getThemeTone(this.currentTheme) === this.firstValue;
    }

    isSecondValue() {
        return ThemeService.getThemeTone(this.currentTheme) === this.secondValue;
    }

    changeValue() {
        // Opposite of current selections
        const newTone = this.isFirstValue() ? this.secondValue : this.firstValue;
        this.currentTheme = ThemeService.constructTheme(newTone, ThemeService.isAccessibility(this.currentTheme));
        this.nbThemeService.changeTheme(this.currentTheme);
    }
}
