import {AbstractControl, ValidationErrors} from '@angular/forms';
import {UsersService} from '@core/interfaces/common/users';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

export class UserNameValidator {
    static createUserNameValidator(usersService: UsersService) {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!control.value) return of(null);

            const id = control.parent.controls['id'].value;
            if (id) {
                // Do not validate for existing users, since existing users cannot change their username
                return of(null);
            }
            return usersService
                .userNameAvailable(control.value)
                .pipe(map((result: APIResponse<boolean>) => (!result.response ? {usernameAlreadyExists: true} : null)));
        };
    }
}
