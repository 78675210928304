import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PopoutApi} from '../api/popout.api';
import {
    AnalyzerPopoutSettings,
    PopoutService,
    ForecasterPopoutSettings,
    OptimizerPopoutSettings,
    DataAuditPopoutSettings,
} from '@core/interfaces/common/popout';
import {APIResponse, FilterFieldOption} from '@core/interfaces/system/system-common';

@Injectable()
export class PopoutServiceImpl extends PopoutService {
    constructor(private api: PopoutApi) {
        super();
    }

    getAnalyzerPopoutSettings(): Observable<AnalyzerPopoutSettings> {
        return this.api.getAnalyzerPopoutSettings();
    }

    getFilterOptions(workflowItemId, physicalField, search?, filters?): Observable<FilterFieldOption[]> {
        return this.api.getFilterOptions(workflowItemId, physicalField, search, filters);
    }

    getOptimizerPopoutSettings(studyId: number, panelConfig: number): Observable<APIResponse<OptimizerPopoutSettings>> {
        return this.api.getOptimizerPopoutSettings(studyId, panelConfig);
    }

    getForecasterPopoutSettings(): Observable<ForecasterPopoutSettings> {
        return this.api.getForecasterPopoutSettings();
    }

    getDataAuditToolPopoutSettings(): Observable<DataAuditPopoutSettings> {
        return this.api.getDataAuditToolPopoutSettings();
    }
}
