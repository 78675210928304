import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../../../interfaces/system/system-common';
import {DownloadCSVUrl} from '../../../interfaces/engin/workflow-validation';

@Injectable()
export class WorkflowValidationApi {
    private readonly prefix = 'workflow/validation';

    constructor(private api: HttpService) {}

    public getValidationDetailDataSource(workflowId: number, cause: string, subcause: string): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.prefix}/detail/list?filterByworkflowId_EQUAL=${workflowId}&filterByworkflowExceptionCause_EQUAL=${cause}&filterByworkflowExceptionSubcause_EQUAL=${subcause}`,
        );
    }

    public downloadValidationDetailCsv(
        workflowId: number,
        cause: string,
        subcause: string,
        additionalQueryParam: string,
    ): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(
            `${this.prefix}/detail/csv?filterByworkflowId_EQUAL=${workflowId}&filterByworkflowExceptionCause_EQUAL=${cause}&filterByworkflowExceptionSubcause_EQUAL=${subcause}&${additionalQueryParam}`,
        );
    }

    public downloadValidationCsv(
        workflowId: number,
        additionalQueryParam: string = '',
    ): Observable<APIResponse<DownloadCSVUrl>> {
        const params = additionalQueryParam === '' ? '' : '&' + additionalQueryParam;
        return this.api.get(`${this.prefix}/detail/csv?filterByworkflowId_EQUAL=${workflowId}${params}`);
    }

    public getValidationOverviewDataSource(workflowId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/overview/list/${workflowId}`);
    }
}
