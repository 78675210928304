import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {SimpleTableData} from '../../../../pages/connectivity-analysis/history/details/simple-table/simple-table.component';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {ProfileEntities} from '@core/backend/engin/services/connectivity.service';

@Injectable({
    providedIn: 'root',
})
export class ConnectivityApi {
    private readonly prefix = 'connectivity';

    constructor(private api: HttpService) {}

    // Main
    getSummaryDetails(workflowId: number): Observable<APIResponse<SimpleTableData>> {
        return this.api.get(`${this.prefix}/${workflowId}/details`);
    }

    getAssetDemographicsChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>>> {
        return this.api.get(`${this.prefix}/${workflowId}/asset-demographics/chart`);
    }

    getCustomerDemographicsChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>> {
        return this.api.get(`${this.prefix}/${workflowId}/customer-demographics/chart`);
    }

    getRegionDetails(workflowId: number): Observable<APIResponse<SimpleTableData>> {
        return this.api.get(`${this.prefix}/${workflowId}/regions`);
    }

    getRegionChart(workflowId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>> {
        return this.api.get(`${this.prefix}/${workflowId}/regions/chart`);
    }

    // Feeder
    getFeederList(workflowId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${workflowId}/feeders/list`);
    }

    getFeederListCsv(workflowId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/${workflowId}/feeders/list/csv`);
    }

    public getFeederMatches(workflowId: number, partialFeederId: string): Observable<APIResponse<string[]>> {
        let params = partialFeederId != null ? '?feederId=' + partialFeederId : '';
        return this.api.get(`${this.prefix}/${workflowId}/feeders/search${params}`);
    }

    // Entity
    getEntityListByFeeder(workflowId: number, feederId: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${workflowId}/entities/feeder/${feederId}/list`);
    }

    getEntityListByFeederCsv(workflowId: number, feederId: string): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/${workflowId}/entities/feeder/${feederId}/list/csv`);
    }

    getProfileEntitiesByFeeder(workflowId: number, feederId: string): Observable<APIResponse<ProfileEntities>> {
        return this.api.get(`${this.prefix}/${workflowId}/entities/feeder/${feederId}/profile`);
    }
}
