import {StudiesStore} from '@store/common/studies.store';
import {GeospatialViewerService} from '../../../../../pages/geospatial-viewer/api/geospatial-viewer.service';
import {ProjectDto, ProjectPopupRequest} from '../../../../../pages/geospatial-viewer/model/project';
import {Injectable} from '@angular/core';
import {UsersStore} from '@store/common/users.store';
import {filter} from 'rxjs/operators';
import {User} from '@core/interfaces/common/users';
import {WorkflowType} from '@core/interfaces/engin/workflow';
import {APIResponse} from '@core/interfaces/system/system-common';
import {FormatsService} from '@core/utils';

@Injectable()
export class ProjectPopupService {
    /*
     * Render popup for road closure feature. Works for point or line features equivalently.
     */
    constructor(
        private studiesStore: StudiesStore,
        private geospatialViewerService: GeospatialViewerService,
        private userStore: UsersStore,
    ) {}
    getCustomPopoutFunction = (feature) => {
        // From feature
        const projectId: string = feature.graphic.attributes.ObjectID;

        // From stores
        const popupRequest: ProjectPopupRequest = {
            projectId: projectId,
            studyId: this.studiesStore.getActiveStudyIdByType(WorkflowType.STUDY),
        };
        let currentUserId;
        this.userStore.currentUser$.pipe(filter((u: User) => u && !!u)).subscribe((x) => {
            currentUserId = x.id;
        });
        return this.geospatialViewerService
            .getProjectPopupInfo(popupRequest)
            .toPromise()
            .then((res: APIResponse<ProjectDto>) => {
                return this.renderProjectPopup(feature, res.response, currentUserId);
            });
    };

    renderProjectPopup(feature: any, project: ProjectDto, currentUserId: string) {
        const div = document.createElement('div');

        const owner = project.ownerId === currentUserId ? 'me' : project.ownerName;

        // layer = 'c' is street view; 'cbll' is lat/long for streetview; 'll' is lat/long for default map
        const lat = feature?.graphic?.geometry?.centroid?.latitude;
        const long = feature?.graphic?.geometry?.centroid?.longitude;
        const streetViewUrl = `http://maps.google.com/maps?layer=c&cbll=${lat},${long}`;
        const mapsUrl = `http://maps.google.com/maps?ll=${lat},${long}`;
        const extraHtml =
            lat && long
                ? `
        <div class='row' style='padding-top:16px'>
            <p>External links (opens new tab)</p>
        </div>
        <div class='row'>
            <a class='col-6' href="${streetViewUrl}" target="_blank">
              Google Street View (centroid)
            </a>
            <a class='col-6' href="${mapsUrl}" target="_blank">
              Google Maps (centroid)
            </a>
        </div>
        ` : '';

        const formattedCostValue = FormatsService.prepareCostValue(project.adjustedTotalCost, '$', '');
        const formattedDiscountPercent = FormatsService.preparePercentageLabelFromDecimal(project.costDiscountPercent, 1);
        const formattedBcRisk = FormatsService.prepareCostValue(project.bcRatioRisk, '', '');
        const formattedBcDeferral = FormatsService.prepareCostValue(project.bcRatioDeferral, '', '');

        div.innerHTML = `
                        <div class='container projectPopup'>
                            <div class="row">
                                <p><b>Name:</b> ${project.projectName}</p>
                            </div>
                            <div class="row">
                                <div class="col-10">
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Description:</b> ${project.description}</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Status:</b> ${project.projectStatus}</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Program:</b> ${project.programName}</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Owner:</b> ${owner}</p>
                                </div>
                            </div>

                            <div class="row" style="padding-top:16px">
                                <p>Analytics</p>
                            </div>
                            <div class="row">
                                <div class="col-10">
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Assets (#):</b> ${project.assetCount}</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>Adjusted Project Cost ($):</b> ${formattedCostValue} (Est. Discount: ${formattedDiscountPercent})</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>B/C Ratio (10-year risk reduction):</b> ${formattedBcRisk}</p>
                                    <p style="font-size: 12px; margin-bottom: 8px;"><b>B/C Ratio (action vs. deferral):</b> ${formattedBcDeferral}</p>
                                </div>
                            </div>

                            ${extraHtml}
                      </div>`;
        return div;
    }


}
