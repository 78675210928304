// Configuration
export class Measure {
    code: MeasureType;
    disabled?: boolean = true;
    selectDefault?: boolean = false;
    metrics?: Metric[];
}

export class Metric {
    code: MetricType;
    disabled?: boolean = true;
    selectDefault?: boolean = false;
    valueType: ValueType;
}

// Other DTOs
export class MapValueFilter {
    valueType: ValueType;
    categoryList?: string[];
    range?: {
        min: number;
        max: number;
    };
}

// Enumerations
export enum ValueType {
    CATEGORY = 'CATEGORY',
    NUMERIC = 'NUMERIC',
}

export enum MeasureType {
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
    HEALTH_INDEX = 'HEALTH_INDEX',
    ASSET_FAILURES = 'ASSET_FAILURES',
    OUTAGES = 'OUTAGES',
    TCO = 'TCO',
    MONETIZED_RISK = 'MONETIZED_RISK',
    LIFE_CYCLE_ANALYSIS = 'LIFE_CYCLE_ANALYSIS',
    DATA_QUALITY = 'DATA_QUALITY',
}
export enum MetricType {
    AGE = 'AGE',
    AGE_VS_TUL = 'AGE_VS_TUL',
    LIFE_REMAINING = 'LIFE_REMAINING',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
    EFFECTIVE_AGE_VS_TUL = 'EFFECTIVE_AGE_VS_TUL',
    EFFECTIVE_LIFE_REMAINING = 'EFFECTIVE_LIFE_REMAINING',
    HEALTH_INDEX_CATEGORY = 'HEALTH_INDEX_CATEGORY',
    HEALTH_INDEX_VALUE = 'HEALTH_INDEX_VALUE',
    PERCENT_HEALTH_REMAINING = 'PERCENT_HEALTH_REMAINING',
    CURRENT_FAILURE_PROBABILITY = 'CURRENT_FAILURE_PROBABILITY',
    TEN_YEAR_FAILURE_PROBABILITY = 'TEN_YEAR_FAILURE_PROBABILITY',
    TIME_UNTIL_SR_50 = 'TIME_UNTIL_SR_50',
    TCO_UNDER_OPTIMAL_REPLACEMENT = 'TCO_UNDER_OPTIMAL_REPLACEMENT',
    TCO_RUN_TO_FAILURE = 'TCO_RUN_TO_FAILURE',
    RISK = 'RISK',
    RISK_VS_REPLACEMENT_COST = 'RISK_VS_REPLACEMENT_COST',
    RISK_CATEGORY = 'RISK_CATEGORY',
    YEARS_TO_INTERVENTION = 'YEARS_TO_INTERVENTION',
    OIT = 'OIT',
    ECONOMIC_CATEGORY = 'ECONOMIC_CATEGORY',
    DATA_QUALITY = 'DATA_QUALITY',
    NUMBER_MISSING_DATA_POINTS = 'NUMBER_MISSING_DATA_POINTS',
}

// Labels
export const MeasureTypeLabel = {
    [MeasureType.CALENDAR_AGE]: 'Calendar Age',
    [MeasureType.EFFECTIVE_AGE]: 'Effective Age',
    [MeasureType.HEALTH_INDEX]: 'Health Index',
    [MeasureType.ASSET_FAILURES]: 'Asset Failures',
    [MeasureType.OUTAGES]: 'Outages',
    [MeasureType.TCO]: 'Total Cost of Ownership',
    [MeasureType.MONETIZED_RISK]: 'Monetized Risk',
    [MeasureType.LIFE_CYCLE_ANALYSIS]: 'Life-cycle Analysis',
    [MeasureType.DATA_QUALITY]: 'Data Quality',
};
export const MetricTypeLabel = {
    [MetricType.AGE]: 'Calendar Age',
    [MetricType.AGE_VS_TUL]: 'Age vs. TUL (%)',
    [MetricType.LIFE_REMAINING]: 'Life Remaining',
    [MetricType.EFFECTIVE_AGE]: 'Effective Age',
    [MetricType.EFFECTIVE_AGE_VS_TUL]: 'Effective Age vs. TUL (%)',
    [MetricType.EFFECTIVE_LIFE_REMAINING]: 'Effective Life Remaining',
    [MetricType.HEALTH_INDEX_CATEGORY]: 'HI Category',
    [MetricType.HEALTH_INDEX_VALUE]: 'Health Index (%)',
    [MetricType.PERCENT_HEALTH_REMAINING]: 'Health Missing (%)',
    [MetricType.CURRENT_FAILURE_PROBABILITY]: 'Current FP',
    [MetricType.TEN_YEAR_FAILURE_PROBABILITY]: '10-year FP',
    [MetricType.TIME_UNTIL_SR_50]: 'Time Until SR 50%',
    [MetricType.TCO_UNDER_OPTIMAL_REPLACEMENT]: 'Optimal TCO',
    [MetricType.TCO_RUN_TO_FAILURE]: 'TCO Run-to-failure',
    [MetricType.RISK]: 'Risk',
    [MetricType.RISK_VS_REPLACEMENT_COST]: 'Risk vs. Replacement Cost',
    [MetricType.RISK_CATEGORY]: 'Risk Category',
    [MetricType.YEARS_TO_INTERVENTION]: 'Years to Intervention',
    [MetricType.OIT]: 'OIT',
    [MetricType.ECONOMIC_CATEGORY]: 'Economic Category',
    [MetricType.DATA_QUALITY]: 'Data Quality (%)',
    [MetricType.NUMBER_MISSING_DATA_POINTS]: 'Number of Missing Data Points',
};
