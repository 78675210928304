import {GraphLabel} from '@core/interfaces/common/pages';

export class GraphLabelService {
    static extractGraphLabel(graphLabels: GraphLabel[], graphId: string): string {
        const defaultLabel = 'none';
        if (graphLabels) {
            const graphLabel = graphLabels.filter((g) => g.graphId === graphId);
            const selectedLabel = graphLabel[0].options.filter((o) => o.selected)[0];
            return selectedLabel.value;
        } else {
            return defaultLabel;
        }
    }
}
