import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';

export interface PmSensitivityDto {
    id: number;
    workflowItemId: number;
    userId: string;
    userTypeAdmin: boolean;
    global: boolean;
    pmSensitivityStatus: string;
    sensitivityTableName: string;
}

export interface PmSensitivity {
    userId: string;
    workflowItemId: number;
    global: boolean; //value from the switcher
}

/*
export interface ProgramOutcomes {
    assetClassList: string[];
    investmentPriority: InvestmentPriority;
    outcomeMaintainType: OutcomeMaintainType;
    belowPercent: number;
    startYear: number;
}
export enum InvestmentPriority {
    ECONOMIC = 'ECONOMIC',
    HEALTH = 'HEALTH',
    CALENDAR_AGE = 'CALENDAR_AGE',
    EFFECTIVE_AGE = 'EFFECTIVE_AGE',
}
export enum OutcomeMaintainType {
    PAST = 'PAST',
    APPROACHING = 'APPROACHING',
}

export enum SimpleUnitType {
    CURRENCY = 'CURRENCY',
    COUNT = 'COUNT',
    PERCENT = 'PERCENT',
    TEMPERATURE = 'TEMPERATURE',
    PRESSURE = 'PRESSURE',
    POWER = 'POWER',
    LENGTH = 'LENGTH',

    CONCENTRATION = 'CONCENTRATION',

    RATE = 'RATE',

    NONE = 'NONE',
}
export interface ProgramBudget {
    sensitivityId: number;
    startYear: number;
    endYear: number;
    budgetMetric: SimpleUnitType;
    value: number;
    scope: AssetScope[];
}
*/
export abstract class PmSensitivityService {
    abstract getSensitivity(data: PmSensitivity): Observable<APIResponse<PmSensitivityDto>>;
}
