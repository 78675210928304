import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {StationInfo, StationLoadForecastService} from '@core/interfaces/engin/load-forecast/station';
import {StationLoadForecastApi} from '@core/backend/engin/api/load-forecast/station.api';

@Injectable()
export class StationLoadForecastServiceImpl extends StationLoadForecastService {
    constructor(private api: StationLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for station(s)
     */
    public getAllStationsPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getAllStationsPivot(workflowItemId, scenarioId, period, unit, sensitivityId);
    }

    public getAllStationsPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getAllStationsPivotCSV(workflowItemId, scenarioId, period, unit, sensitivityId);
    }

    public getStationInfo(workflowItemId: number, scenarioId: string, stationId: string): Observable<StationInfo[]> {
        return this.api.getStationInfo(workflowItemId, scenarioId, stationId);
    }

    public getStationViolationsByInfrastructureType(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld> {
        return this.api.getStationViolationsByInfrastructureType(
            workflowItemId,
            scenarioId,
            stationId,
            seasonTiming,
            viewBy,
            unit,
            sensitivityId,
        );
    }

    public getStationForecastByInfrastructureType(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        filterViolations: boolean,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getStationForecastByInfrastructureType(
            workflowItemId,
            scenarioId,
            stationId,
            seasonTiming,
            viewBy,
            filterViolations,
            unit,
            sensitivityId,
        );
    }

    public getStationBreakdownPivot(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getStationBreakdownPivot(workflowItemId, scenarioId, stationId, period, unit, sensitivityId);
    }

    public getStationBreakdownPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getStationBreakdownPivotCSV(workflowItemId, scenarioId, stationId, period, unit, sensitivityId);
    }
}
