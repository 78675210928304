<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">Edit parameters: {{ assetId }}</p>

        <button (click)="close()" [disabled]="saving$ | async" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body
        *ngIf="form"
        [nbSpinner]="(loading$ | async) || (saving$ | async)"
        nbSpinnerSize="medium"
        nbSpinnerStatus="primary"
    >
        <form [formGroup]="form">
            <nb-accordion multi>
                <nb-accordion-item expanded>
                    <nb-accordion-item-header>Parameters</nb-accordion-item-header>
                    <nb-accordion-item-body *ngIf="!(loading$ | async)">
                        <ng-container *ngFor="let column of assetColumns">
                            <ng-container
                                *ngIf="
                                    !hideFields.includes(column.fieldKey) && !geographicFields.includes(column.fieldKey)
                                "
                            >
                                <label [for]="column.fieldKey">{{ column.fieldName }}</label>
                                <input
                                    [type]="FilterFieldType.isNumericFieldType(column.fieldType) ? 'number' : 'text'"
                                    [formControlName]="column.fieldKey"
                                    [id]="column.fieldKey"
                                    fullWidth
                                    nbInput
                                />
                                <ngx-input-error [fieldName]="column.fieldKey" [formGroup]="form"></ngx-input-error>
                            </ng-container>
                        </ng-container>
                    </nb-accordion-item-body>
                </nb-accordion-item>
                <nb-accordion-item>
                    <nb-accordion-item-header>Geography</nb-accordion-item-header>
                    <nb-accordion-item-body *ngIf="!(loading$ | async)">
                        <ng-container *ngFor="let column of assetColumns">
                            <ng-container
                                *ngIf="
                                    !hideFields.includes(column.fieldKey) && geographicFields.includes(column.fieldKey)
                                "
                            >
                                <label [for]="column.fieldKey">{{ column.fieldName }}</label>
                                <input
                                    [formControlName]="column.fieldKey"
                                    [id]="column.fieldKey"
                                    [type]="FilterFieldType.isNumericFieldType(column.fieldType) ? 'number' : 'text'"
                                    fullWidth
                                    nbInput
                                />
                                <ngx-input-error [fieldName]="column.fieldKey" [formGroup]="form"></ngx-input-error>
                            </ng-container>
                        </ng-container>
                    </nb-accordion-item-body>
                </nb-accordion-item>
            </nb-accordion>

            <div class="btn-container full-btn">
                <button (click)="close()" nbButton status="basic">
                    <nb-icon icon="close-circle-outline"></nb-icon>
                    Cancel
                </button>

                <button
                    (click)="save()"
                    [disabled]="form.invalid && saveBtnPressed"
                    class="ml-3"
                    nbButton
                    status="primary"
                >
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                    Save
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>
