import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '@store/index';
import {Workflow} from '@core/interfaces/engin/workflow';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {map, takeUntil} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {WorkflowInfo} from '@core/interfaces/common/users';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'ngx-active-study-selector',
    template: `
        <ng-container
            *ngIf="{
                activeWorkflowIds: activeWorkflowIds$ | async,
                dataLoading: this.studiesStore.dataLoading$ | async
            } as data"
        >
            <nb-icon
                class="pointer"
                [icon]="
                    data.activeWorkflowIds.includes(currentWorkflowId)
                        ? 'checkmark-circle-2'
                        : 'checkmark-circle-2-outline'
                "
                [status]="
                    (disabled | async) || !data.activeWorkflowIds.includes(currentWorkflowId) ? 'basic' : 'warning'
                "
                [class.disabled]="disabled | async"
                (click)="setActiveCollection(rowData, data.dataLoading)"
            ></nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit, OnDestroy {
    @Input() rowData: Workflow;
    @Input() value: string | number;
    @Input() disabled: Observable<boolean>;

    readonly activeWorkflowIds$: Observable<number[]> = this.studiesStore.activeWorkflows$.pipe(
        takeUntil(this.unsubscribe$),
        map((activeWorkflows: WorkflowInfo[]) => activeWorkflows.map((w) => w.workflowId)),
    );

    currentWorkflowId: number;

    constructor(public studiesStore: StudiesStore, private toastrService: NbToastrService) {
        super();
    }

    ngOnInit(): void {
        this.currentWorkflowId = this.rowData.id;
    }

    setActiveCollection(rowData, dataLoading) {
        if (dataLoading) {
            this.toastrService.info(
                'Active study is already changing. Please wait for this to finish.',
                'Please wait',
                {duration: 10000},
            );
        } else {
            if (rowData.status !== 'SUCCESS' && rowData.status !== 'SUCCESS_WITH_WARNING') {
                this.toastrService.danger(
                    'Study collection is incomplete and cannot be set to active collection. Please ' +
                        'select a study collection which has been successfully completed.',
                    'Active study collection not updated!',
                    {duration: 10000},
                );
            } else {
                this.studiesStore.updateActiveWorkflowByType(rowData.workflowType, rowData.id);
            }
        }
    }
}
