<div class="row mini-chart-container justify-content-between">
    <div class="col-4 p-0">
        <div class="mini-chart-title">{{ title }}</div>
        <span>
            <span class="percentage">{{ getFormattedValue() }}</span>
            <ng-container [ngSwitch]="trend">
                <nb-icon
                    class="trend-icon trend-icon-up"
                    *ngSwitchCase="'up'"
                    icon="diagonal-arrow-right-up-outline"
                ></nb-icon>
                <nb-icon
                    class="trend-icon trend-icon-down"
                    *ngSwitchCase="'down'"
                    icon="diagonal-arrow-right-down-outline"
                ></nb-icon>
                <nb-icon
                    class="trend-icon trend-icon-flat"
                    *ngSwitchCase="'flat'"
                    icon="arrow-forward-outline"
                ></nb-icon>
            </ng-container>
        </span>
    </div>

    <span class="col-8 p-0">
        <div echarts class="mini-chart-body" [options]="chartOptions"></div>
    </span>
</div>
<!--{{chartOptions|json}}-->
