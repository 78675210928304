import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class OutputMeterLPApi {
    private readonly prefix = 'load-profile-analysis/output/meter';

    constructor(private api: HttpService) {}

    public getMeterDataList(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list-meters`);
    }
}
