<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">Draw Signature</p>
        <button (click)="cancel()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div>
            <signature-pad
                #signaturePad
                [options]="signaturePadOptions"
                (onBeginEvent)="drawStart()"
                (onEndEvent)="drawComplete()"
            ></signature-pad>
        </div>
    </nb-card-body>

    <nb-card-footer class="btn-container full-btn">
        <button (click)="clear()" nbButton status="basic">
            <nb-icon icon="close-circle-outline"></nb-icon>
            Clear
        </button>

        <!-- TODO: disable until user has drawn a signature -->
        <button (click)="save()" class="ml-3" nbButton status="primary">
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            Save
        </button>
    </nb-card-footer>
</nb-card>
