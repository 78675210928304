import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Observable} from 'rxjs';
import {APIResponse} from '../system/system-common';

export interface DownloadCSVUrl {
    url: string;
}

export abstract class WorkflowValidationService {
    abstract getValidationDetailDataSource(workflowId: number, cause: string, subcause: string): Observable<DataSource>;

    abstract getValidationOverviewDataSource(workflowId: number): Observable<DataSource>;

    abstract downloadValidationDetailCsv(
        workflowId: number,
        cause: string,
        subcause: string,
        additionalQueryParam: string,
    ): Observable<APIResponse<DownloadCSVUrl>>;

    abstract downloadValidationCsv(
        workflowId: number,
        additionalQueryParam: string,
    ): Observable<APIResponse<DownloadCSVUrl>>;
}
