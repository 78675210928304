import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    ViewChild,
} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {
    AssetInspectionResultDto,
    FormCellType,
    FormSection,
    FormViewModeType,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {BehaviorSubject, Observable} from 'rxjs';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {S3Service} from '@core/interfaces/common/s3';

@Component({
    selector: 'ngx-form-canvas',
    templateUrl: './form-canvas.component.html',
    styleUrls: ['./form-canvas.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCanvasComponent implements OnChanges {
    @Input() currentSection: FormSection;
    @Input() viewMode: FormViewModeType = FormViewModeType.EDIT_RESULTS;
    // @Input() formResults: AssetInspectionResultDto[];
    @Input() currentFormResults: AssetInspectionResultDto;
    @Input() previousFormResults: AssetInspectionResultDto;
    @Input() pageMode: Observable<FormMode> = new BehaviorSubject<FormMode>(null);
    @Input() fieldResultForm: FormGroup = this.fb.group({});
    @Output() formFieldChangeEvent = new EventEmitter();
    @Input() s3service: S3Service;
    checkValidation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    showPreviousValues: boolean;
    FormCellType = FormCellType;
    FormMode = FormMode;

    constructor(protected cd: ChangeDetectorRef, private fb: FormBuilder) {}

    ngOnChanges(): void {
        switch (this.viewMode) {
            case FormViewModeType.EDIT_RESULTS:
            case FormViewModeType.VIEW_RESULTS:
                this.showPreviousValues = true;
                break;
            case FormViewModeType.VIEW_CONFIG:
                this.showPreviousValues = false;
                break;
        }
        this.cd.markForCheck();
    }

    // Get FormCellType, when there are no previous values
    public getCellTypeNoPrevious(): FormCellType {
        return this.viewMode === FormViewModeType.VIEW_CONFIG ? FormCellType.NEW : FormCellType.CURRENT;
    }

    // Get applicable results based on FormCellType, else null
    public getFormResultsByType(type: FormCellType): AssetInspectionResultDto {
        switch (type) {
            case FormCellType.CURRENT:
                if (this.currentFormResults) {
                    return this.currentFormResults;
                }
                break;
            case FormCellType.PREVIOUS:
                if (this.previousFormResults) {
                    return this.previousFormResults;
                }
                break;
            case FormCellType.NEW:
                break;
        }
        return null;
    }

    public getViewModeForPrevious(): FormViewModeType {
        return FormViewModeType.VIEW_RESULTS;
    }

    public getViewModeForCurrent(): FormViewModeType {
        return FormViewModeType.EDIT_RESULTS;
    }

    public checkValidationEvent(value) {
        this.checkValidation.next(value);
    }
}
