import {Observable} from 'rxjs';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';

export interface ReportConfig {
    dashboards: ReportConfigDashboard[];
    filters: Filter[];
}

export interface ReportTemplate {
    reportKey: string;
    reportName: string;
    dashboards: ReportDashboard[];
    reportType: ReportType;
}

export interface ReportDashboard {
    key: ReportDashboardType;
    group: ReportGroup;
    filters?: Filter[];
}
export interface ReportConfigDashboard {
    key: ReportDashboardType;
    groups: ReportGroup[];
    filters?: Filter[];
}

export interface ReportGroup {
    fieldKey: string;
    fieldName: string;
}

// Enums / labels
export enum ReportDashboardType {
    EOL_METRICS_CALENDAR_AGE_PIE = 'EOL_METRICS_CALENDAR_AGE_PIE',
    EOL_METRICS_EFFECTIVE_AGE_PIE = 'EOL_METRICS_EFFECTIVE_AGE_PIE',
    EOL_METRICS_HEALTH_PIE = 'EOL_METRICS_HEALTH_PIE',
    EOL_METRICS_ECONOMIC_PIE = 'EOL_METRICS_ECONOMIC_PIE',
    EOL_METRICS_CALENDAR_AGE_BAR = 'EOL_METRICS_CALENDAR_AGE_BAR',
    EOL_METRICS_EFFECTIVE_AGE_BAR = 'EOL_METRICS_EFFECTIVE_AGE_BAR',
    EOL_METRICS_HEALTH_BAR = 'EOL_METRICS_HEALTH_BAR',
    EOL_METRICS_ECONOMIC_BAR = 'EOL_METRICS_ECONOMIC_BAR',

    DEMOGRAPHICS_CALENDAR_AGE = 'DEMOGRAPHICS_CALENDAR_AGE',
    DEMOGRAPHICS_EFFECTIVE_AGE = 'DEMOGRAPHICS_EFFECTIVE_AGE',
    DEMOGRAPHICS_HEALTH = 'DEMOGRAPHICS_HEALTH',
    DEMOGRAPHICS_ECONOMIC = 'DEMOGRAPHICS_ECONOMIC',

    ACA_CURRENT_DATA_QUALITY = 'ACA_CURRENT_DATA_QUALITY',
}
export const ReportDashboardTypeLabel = {
    [ReportDashboardType.EOL_METRICS_CALENDAR_AGE_PIE]: 'EOL Metrics > Calendar Age > Donut',
    [ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_PIE]: 'EOL Metrics > Effective Age > Donut',
    [ReportDashboardType.EOL_METRICS_HEALTH_PIE]: 'EOL Metrics > Health > Donut',
    [ReportDashboardType.EOL_METRICS_ECONOMIC_PIE]: 'EOL Metrics > Economic > Donut',
    [ReportDashboardType.EOL_METRICS_CALENDAR_AGE_BAR]: 'EOL Metrics > Calendar Age > Bar',
    [ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_BAR]: 'EOL Metrics > Effective Age > Bar',
    [ReportDashboardType.EOL_METRICS_HEALTH_BAR]: 'EOL Metrics > Health > Bar',
    [ReportDashboardType.EOL_METRICS_ECONOMIC_BAR]: 'EOL Metrics > Economic > Bar',
    [ReportDashboardType.DEMOGRAPHICS_CALENDAR_AGE]: 'Demographics > Calendar Age',
    [ReportDashboardType.DEMOGRAPHICS_EFFECTIVE_AGE]: 'Demographics > Effective Age',
    [ReportDashboardType.DEMOGRAPHICS_HEALTH]: 'Demographics > Health',
    [ReportDashboardType.DEMOGRAPHICS_ECONOMIC]: 'Demographics > Economic',
    [ReportDashboardType.ACA_CURRENT_DATA_QUALITY]: 'ACA > Current Data Quality',
};
export enum ReportType {
    INPUT = 'INPUT',
    OUTPUT = 'OUTPUT',
}
export const ReportTypeLabel = {
    [ReportType.INPUT]: 'Input Data',
    [ReportType.OUTPUT]: 'Calculated Results',
};

export abstract class ReportConfigService {
    abstract getReportConfigOptions(): Observable<APIResponse<ReportConfig>>;

    abstract listReportTemplates(): Observable<APIResponse<ReportTemplate[]>>;

    abstract getOneReportTemplate(templateCode: string): Observable<APIResponse<ReportTemplate>>;
}
