import {Injectable} from '@angular/core';
import {LoadForecastApi} from '@core/backend/engin/api/load-forecast/load-forecast.api';
import {LoadForecastService, LoadForecastSettings} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {SensitivityConfiguration} from '@store/load-forecast/sensitivity-analysis.store';

@Injectable()
export class LoadForecastServiceImpl extends LoadForecastService {
    constructor(private api: LoadForecastApi) {
        super();
    }

    public initSettingsByWorkflowItem(workflowItemId: number): Observable<LoadForecastSettings> {
        return this.api.initSettingsByWorkflowItem(workflowItemId);
    }

    public executeSensitivityAnalysis(workflowItemId: number, req: SensitivityConfiguration): Observable<any> {
        return this.api.executeSensitivityAnalysis(workflowItemId, req);
    }
}
