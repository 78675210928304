import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IconDetails} from '@core/interfaces/common/pages';
import {ValidatedFile} from '@core/interfaces/engin/data-preparation';

@Component({
    selector: 'ngx-file-action-column',
    template: `
        <div class="icon-container">
            <button
                nbButton
                type="submit"
                status="basic"
                size="small"
                (click)="changeValue('delete', file)"
                class="delete-icon mr-2"
            >
                <nb-icon size="large" icon="trash-2-outline"></nb-icon>
            </button>
            <button
                *ngIf="file.validationStatus === 'FAILED'"
                nbButton
                type="submit"
                status="basic"
                size="small"
                (click)="changeValue('log', file)"
                class="log-icon"
            >
                <nb-icon size="large" icon="info-outline"></nb-icon>
            </button>
        </div>
    `,
    styleUrls: ['action-column.scss'],
})
export class ActionColumnComponent implements OnInit {
    @Input() file: ValidatedFile;
    @Output() action: EventEmitter<any> = new EventEmitter<any>();
    icon: IconDetails;

    changeValue(action, file) {
        this.action.emit({action, file});
    }
    ngOnInit(): void {
        this.initProcess();
    }
    initProcess() {}
}
