import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ChartRequest} from '@core/interfaces/engin/status-change';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    SimpleSeries,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {AssetColumn} from '@core/interfaces/common/asset';
import {DataExplorerLineChart} from '../../../../pages/survival-analysis/api/pojo/survival-study.pojo';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class StatusChangeApi {
    private readonly prefix = 'survivalAnalysis/status-change';

    constructor(private api: HttpService) {}

    getStatusChangeList(filterParams: Filter[]): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/list`, {filterParams});
    }
    getStatusChangeListCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.post(`${this.prefix}/list/csv`, {filterParams});
    }
    getChartByDate(chartRequest: ChartRequest): Observable<APIResponse<DataExplorerLineChart>> {
        return this.api.post(`${this.prefix}/chart/date`, chartRequest);
    }
    getChartByCategory(chartRequest: ChartRequest): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.post(`${this.prefix}/chart/category`, chartRequest);
    }
    getTableColumns(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.get(`${this.prefix}/options/columns`);
    }
    getGroupOptions(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.get(`${this.prefix}/options/groups`);
    }
    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.get(`${this.prefix}/options/filters`);
    }
    getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.post(`${this.prefix}/options/filters/field`, req);
    }
}
