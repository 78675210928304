<ng-container>
    <div class="summary-container">
        <div class="value-box-container">
            <label class="value-box-title">empty string</label>
            <label class="value-box-value">empty number</label>
        </div>
    </div>
    <ng-container>
        <div class="chart-card-container">
            <nb-card class="chart-card">
                <nb-card-body class="chart-card-body">
                    <ngx-icon-box
                        class="chart-settings"
                        *ngIf="chartSettings"
                        icon="more-vertical-outline"
                    ></ngx-icon-box>
                    <div class="chart-container">
                        <ngx-chart [options]="options"></ngx-chart>
                    </div>
                </nb-card-body>

                <nb-card-footer class="chart-card-footer">
                    <div class="chart-footer-item" *ngFor="let number of [0, 1, 2]">
                        <label class="footer-label-value">empty string</label>
                    </div>
                </nb-card-footer>
            </nb-card>
        </div>
    </ng-container>
</ng-container>
