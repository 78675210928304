<div class="btn-container" (click)="toggleContents()">
    <nb-icon class="message-header-icon" icon="alert-triangle"></nb-icon>
    <span class="message-header-text">CEII</span>
</div>
<div *ngIf="toggleAction">
    <div class="contents-box">
        <ngx-sensitive-info-contents
            (buttonAction)="toggleContents()"
            [iconInHeader]="false"
        ></ngx-sensitive-info-contents>
    </div>
</div>
