import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleMultiSeries, SimpleUnit, ThresholdStatic} from '@core/interfaces/system/system-common';
import {LatestModelStatus, RowDataReadingsDashboardData} from '@core/interfaces/live/live';

export interface Co2LoadUnits {
    co2Unit: SimpleUnit;
    loadUnit: SimpleUnit;
}

export interface Co2LoadFaultIndicationDashboardData {
    data: SimpleMultiSeries<Date>;
    thresholds: ThresholdStatic[];
}

export abstract class Co2LoadService {
    abstract getCo2LoadUnits(): Observable<Co2LoadUnits>;

    abstract getCo2LoadDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getCo2LoadLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus>;

    abstract getCo2LoadRowDataReadingsDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<RowDataReadingsDashboardData>;

    abstract getCo2LoadFaultIndicationDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<Co2LoadFaultIndicationDashboardData>;
}
