import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus} from '@core/interfaces/live/live';
import {
    ThicknessRateDashboardData,
    ThicknessRateService,
    ThicknessRateUnits,
} from '@core/interfaces/live/thickness-rate';
import {ThicknessRateApi} from '@core/backend/engin/api/live/thickness-rate.api';

@Injectable()
export class ThicknessRateServiceImpl extends ThicknessRateService {
    constructor(private api: ThicknessRateApi) {
        super();
    }

    public getThicknessRateUnits(): Observable<ThicknessRateUnits> {
        return this.api.getThicknessRateUnits();
    }

    public getThicknessRateDatasource(id: string): Observable<DataSource> {
        return this.api.getThicknessRateDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getThicknessRateLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api.getThicknessRateLatestModelStatus(id, uploadDate);
    }

    public getThicknessRateDashboardData(
        chart: string,
        id: string,
        uploadDate?: string,
    ): Observable<ThicknessRateDashboardData> {
        return this.api.getThicknessRateDashboardData(chart, id, uploadDate);
    }
}
