import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    PmSensitivity,
    PmSensitivityDto,
    PmSensitivityService,
} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {PmSensitivityApi} from '../../api/program-management/pm-sensitivity.api';

@Injectable()
export class PmSensitivityServiceImpl extends PmSensitivityService {
    constructor(private api: PmSensitivityApi) {
        super();
    }

    getSensitivity(data: PmSensitivity): Observable<APIResponse<PmSensitivityDto>> {
        return this.api.getSensitivity(data);
    }
}
