import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {GuideInfo} from '@theme/components';

// Models for exchanging data with API
export interface DataPrepTool {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
    dataPrepType: DataPrepType;
    description?: string;
    status: DataPrepStatus;
    activeStep: number;
    steps: ProcessStep[];
}
export interface ProcessStep {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
    stepOrder: number;
    status: DataPrepStatus;
    stepType: ProcessStepType;
    label: string;
    shortDescription: string;
    description?: GuideInfo[];
    stepData: ProcessStepData[];
    dataPrepToolId: number;
    currentProgress: number;
    maxProgress: number;
}
export interface ProcessStepData {
    id: number;
    createdOn: Date;
    updatedOn?: Date;
    stepDataType: ProcessStepDataType;
    dataKey: string;
    dataValue: string;
    dataType: DataType;
    dataOperator: string; // todo: type FilterOperator
    processStepId: number;
}

export interface MergeStepData {
    dataKey: string;
    dataValue: string;
}

// Enums
export enum DataPrepType {
    ENMAX_NFT = 'ENMAX_NFT',
    FILE_COMBINER = 'FILE_COMBINER',
    SENSOR_TO_METER_DATA = 'SENSOR_TO_METER_DATA',
    INSP_DATA_PREP = 'INSP_DATA_PREP',
    FORM_UPLOAD = 'FORM_UPLOAD',
}
export const DataPrepLabel = {
    [DataPrepType.ENMAX_NFT]: 'Neighbourhood-Feeder Translation',
    [DataPrepType.FILE_COMBINER]: 'File Combiner',
    [DataPrepType.SENSOR_TO_METER_DATA]: 'Sensor Data Translation',
    [DataPrepType.INSP_DATA_PREP]: 'Inspection Data Translation',
    [DataPrepType.FORM_UPLOAD]: 'Form Upload',
};
export enum DataPrepStatus {
    ACTIVE = 'ACTIVE',
    CREATED = 'CREATED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
    IN_PROGRESS = 'IN_PROGRESS',
}
export enum ProcessStepType {
    SELECT_DATASET = 'SELECT_DATASET',
    UPLOAD_FILE = 'UPLOAD_FILE',
    FORM_FIELDS = 'FORM_FIELDS',
    VALIDATE_FILE = 'VALIDATE_FILE',
    TRANSFORM_ONE_TO_ONE = 'TRANSFORM_ONE_TO_ONE',
    TRANSFORM_MANY_TO_ONE = 'TRANSFORM_MANY_TO_ONE',
    DISPLAY_FOR_REVIEW = 'DISPLAY_FOR_REVIEW',
}
export enum ProcessStepDataType {
    INBOUND = 'INBOUND',
    OUTBOUND = 'OUTBOUND',
}
export enum DataType {
    INTEGER = 'INTEGER',
    LONG = 'LONG',
    DOUBLE = 'DOUBLE',
    STRING = 'STRING',
    BOOLEAN = 'BOOLEAN',
    JSON = 'JSON',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
}

// Supporting DTOs
export interface DataFile {
    id?: number;
    fileName: string;
    fileKey: string;
    fileType: string;
    fileTypeLabel: string;
    fileFormat: string;
    fileSize: number;
}
export interface UploadFile extends DataFile {
    uploadStatus: FileStatus;
}
export interface ValidatedFile extends DataFile {
    validationStatus: FileStatus;
}
export interface OutputFile extends DataFile {
    url: string;
    inputFileList: DataFile[];
    transformStatus: FileStatus;
    errors?: ProcessStepError[];
}

export interface ProcessStepError {
    stepId: number;
    trackingId?: string;
    stepErrorMessage: string;
    tableName: string;
    columnName: string;
    errorCount: number;
}

export interface FormFieldSettings {
    fields: FormField[];
}
export interface FormField {
    key: string;
    label: string;
    value: string | boolean;
    type: FormFieldType;
    required?: boolean;
    // String
    minLength?: number;
    // Number
    pattern?: string;
    min?: number;
    max?: number;
    // Table
    tableLabelColumn?: boolean;
    children?: FormField[];
    // Select
    options?: any[];
}
export enum FormFieldType {
    STRING = 'STRING',
    NUMBER = 'NUMBER',
    BOOLEAN = 'BOOLEAN',
    TABLE = 'TABLE',
    SELECT = 'SELECT',
}

export enum FileStatus {
    STARTED = 'STARTED',
    UPLOADED = 'UPLOADED',
    CREATED = 'CREATED',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED',
}

export abstract class DataPreparationService {
    abstract listActiveTools(): Observable<DataSource>;

    abstract getDataPrepTool(toolId: number): Observable<APIResponse<DataPrepTool>>;

    abstract setupDataPrepTool(toolType: DataPrepType): Observable<APIResponse<DataPrepTool>>;

    abstract validateNextStep(toolId: number, data: any): Observable<APIResponse<DataPrepTool>>;

    abstract getGuideInfo(toolType: DataPrepType): Observable<APIResponse<GuideInfo[]>>;

    abstract getStepIssueLog(stepId: number, fileKey: string): Observable<APIResponse<ProcessStepError[]>>;

    abstract getValidation(toolId: number, stepId: number): Observable<any>;

    abstract deleteTool(toolId: number): Observable<any>;

    abstract deleteStepDataFile(toolId: number, stepId: number, fileKey: string): Observable<APIResponse<DataPrepTool>>;
}
