import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, forkJoin, Observable, Subscription} from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    filter,
    finalize,
    map,
    mergeMap,
    shareReplay,
    startWith,
    takeUntil,
    tap,
} from 'rxjs/operators';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {AnalyzerPopoutFilter, GeospatialFilterSettings} from '@core/interfaces/common/popout';
import {GeospatialViewerControlsService} from './geospatial-viewer-controls.service';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {WorkflowInfo, WorkflowItemInfo} from '@core/interfaces/common/users';
import {StudiesStore} from '@store/common/studies.store';
import {APIResponse, Filter, FilterFieldOption, FilterFieldType} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialFilterStore extends Unsubscribable {
    public selectedOption = new BehaviorSubject(null);
    public selectedLegend = new BehaviorSubject<any>(null);
    public initLegend = new BehaviorSubject<any>(null);
    public resetFlag = new BehaviorSubject<boolean>(false);

    private changeSubscription: Subscription;
    private searchSubscription: Subscription;

    public formGroup = this.fb.group({});
    private formGroupValue = new BehaviorSubject(null);

    // todo: purpose?
    readonly generalFilter$: Observable<GeospatialFilterSettings> = this.formGroupValue.asObservable().pipe(
        filter((value) => {
            return value !== null;
        }),
    );

    private filterSettings = new BehaviorSubject<GeospatialFilterSettings>(null);
    readonly filterSettings$: Observable<GeospatialFilterSettings> = this.filterSettings.asObservable().pipe(
        filter((settings) => settings != null),
        tap((settings) => {
            this.changeSubscription && this.changeSubscription.unsubscribe();
            this.searchSubscription && this.searchSubscription.unsubscribe();
            this.initGeospatialFilters(settings);
        }),
        shareReplay(1),
    );

    readonly currentFilters$: Observable<Filter[]> = this.formGroupValue.asObservable().pipe(
        filter((value) => value !== null),
        map((value) => this.mapFormGroupValue(value)),
    );

    // todo: delete?
    readonly geospatialMeasures$: Observable<any> = combineLatest<
        Observable<any>,
        Observable<any>,
        Observable<any>,
        Observable<any>
    >([
        this.filterSettings$,
        this.selectedOption.asObservable(),
        this.selectedLegend.asObservable(),
        this.initLegend.asObservable(),
    ]).pipe(
        map(([popoutSetting, selectedOption, selectedLegend, initLegend]) => {
            // reset the legend values as a true when user back to EOL page.
            if (this.resetFlag.value) {
                Object.keys(selectedLegend).map((key) => {
                    selectedLegend[key] = true;
                });
            }
            if (selectedOption && popoutSetting) {
                return {
                    popoutSetting,
                    selectedOption,
                    selectedLegend,
                };
            }
        }),
    );

    constructor(
        private controlsService: GeospatialViewerControlsService,
        private fb: FormBuilder,
        protected studiesStore: StudiesStore,
    ) {
        super();

        this.studiesStore.activeWorkflowRisk$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((activeWorkflowInfo: WorkflowInfo) => {
                    if (!activeWorkflowInfo) this.destroy();

                    return activeWorkflowInfo?.workflowId && activeWorkflowInfo?.workflowId !== -1;
                }),
                distinctUntilChanged(),
                mergeMap((activeWorkflowInfo: WorkflowInfo) =>
                    this.controlsService.getSpatialFilterSettings().pipe(
                        tap((settings: GeospatialFilterSettings) => {
                            let requests: Observable<APIResponse<FilterFieldOption[]>>[] = [];

                            // Find default study within active study workflow
                            const activeStudy: WorkflowItemInfo = activeWorkflowInfo.workflowItemList.find(
                                (i) => i.defaultItem,
                            );

                            settings.filters?.forEach((filter: any) => {
                                if (filter.fieldType === FilterFieldType.STRING)
                                    requests.push(this.getFilterOptions(activeStudy, filter));
                            });

                            forkJoin(requests).subscribe(() => this.updateFilterSettings(settings));
                        }),
                    ),
                ),
            )
            .subscribe();
    }

    get filtersFormGroupControls(): any {
        return this.filtersFormGroup?.controls;
    }

    get filtersFormGroup(): FormGroup {
        return this.formGroup.get('filters') as FormGroup;
    }

    public loadPopoutSettings() {
        this.controlsService.getSpatialFilterSettings().subscribe((filters) => {
            this.updateFilterSettings(filters);
        });
    }
    public resetGeospatialFilters() {
        if (this.filterSettings && this.filterSettings.value) {
            const currentFilters = this.getActiveFilterSettings();
            const resetFilters = {
                ...currentFilters,
                filters: currentFilters.filters?.map((group) => {
                    if (group.fieldType === FilterFieldType.STRING) {
                        return {
                            ...group,
                            options: group.options?.map((option) => {
                                return {
                                    ...option,
                                    selected: false,
                                };
                            }),
                        };
                    } else if (FilterFieldType.isNumericFieldType(group.fieldType)) {
                        return {...group};
                    }
                }),
            };

            this.destroy();
            this.initGeospatialFilters(resetFilters);
        }
    }

    get searchStringFormControl(): FormControl {
        return this.formGroup.get('searchString') as FormControl;
    }

    public destroy() {
        this.changeSubscription && this.changeSubscription.unsubscribe();
        this.searchSubscription && this.searchSubscription.unsubscribe();
        this.filtersFormGroup?.reset();
        this.searchStringFormControl?.reset();
    }

    public getActiveFilterSettings() {
        return this.filterSettings.value;
    }

    public updateFilterSettings(settings: GeospatialFilterSettings, settingName?: string) {
        let resultSettings: GeospatialFilterSettings = settings;
        if (settingName) {
            resultSettings = {
                ...this.filterSettings.value,
                [settingName]: settings[settingName].map((item) => {
                    return {
                        ...item,
                    };
                }),
            };
        }
        this.filterSettings.next(resultSettings);
    }

    private isEquivalent(val1: any, val2: any): boolean {
        if (val1 instanceof Object && val2 instanceof Object) {
            const val1props = Object.keys(val1);
            const val2props = Object.keys(val2);

            if (val1props.length !== val2props.length) return false;

            for (let i = 0; i < val1props.length; i++) {
                if (val1props[i] === 'enabled') continue;
                const val2Prop = val2props.find((item) => item === val1props[i]);
                if (!val2Prop) return false;

                if (!this.isEquivalent(val1[val2Prop], val2[val2Prop])) return false;
            }
            return true;
        }
        return val1 === val2;
    }

    private setFiltersFormGroup(group: FormGroup) {
        if (this.filtersFormGroup) {
            this.filtersFormGroup.reset(group.value);
            Object.keys(group.value).forEach((prop) => {
                this.filtersFormGroupControls[prop].enable();
            });
        } else {
            this.formGroup.setControl('filters', group);
        }
    }

    private setSearchStringFormControl(search: FormControl) {
        if (this.searchStringFormControl) {
            this.searchStringFormControl.reset(search.value);
        } else {
            this.formGroup.setControl('searchString', search);
        }
    }

    private updateTotals() {
        this.getActiveFilterSettings().filters.forEach((filter) => {
            if (this.filtersFormGroupControls[filter.fieldKey].value && filter?.fieldType === FilterFieldType.STRING) {
                const control = this.filtersFormGroupControls[`${filter.fieldKey}_selected`];
                const selected = this.filtersFormGroupControls[filter.fieldKey].value.filter(
                    (item) => item.selected,
                ).length;
                const all = this.filtersFormGroupControls[filter.fieldKey].value.length;
                control.setValue(selected === all ? 'All' : selected, {emitEvent: false});
            }
        });
    }

    private search(searchString: string) {
        const filters = {};
        this.getActiveFilterSettings().filters.forEach((filter) => {
            if (this.filtersFormGroupControls[filter.fieldKey].value && filter.fieldType === FilterFieldType.STRING) {
                filters[filter.fieldKey] = this.filtersFormGroupControls[filter.fieldKey].value.map((item) => {
                    return {
                        ...item,
                        enabled: `${item.key}${item.name}`.toLowerCase().includes(searchString.toLowerCase()),
                    };
                });
            }
        });

        this.filtersFormGroup.patchValue(filters, {
            emitEvent: false,
            onlySelf: true,
        });
    }

    private subscribeOnChanges() {
        this.changeSubscription = this.filtersFormGroup.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                startWith(this.filtersFormGroup.value),
                tap((value) => {
                    this.updateTotals();
                }),
                tap((value) => {
                    if (this.formGroupValue.value === null) {
                        this.formGroupValue.next(value);
                    }
                }),
                filter((value) => {
                    return !this.isEquivalent(this.formGroupValue.value, value);
                }),
                debounceTime(500),
            )
            .subscribe((value) => {
                this.updateFilterSettingsValue(value);
                this.formGroupValue.next(value);
            });

        this.searchSubscription = this.searchStringFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((searchString) => this.search(searchString));
    }

    private updateFilterSettingsValue(next: any) {
        // Update filters
        this.filterSettings.value.filters.forEach((item: AnalyzerPopoutFilter) => {
            if (next[item.fieldKey] !== null && next[item.fieldKey] !== undefined) {
                item.options = next[item.fieldKey];
            }
        });
    }

    private mapFormGroupValue(popoutValue: any): Filter[] {
        // Filters
        const filterList: Filter[] = [];

        this.filterSettings.value.filters.forEach((item: AnalyzerPopoutFilter) => {
            let options = [];

            if (item.fieldType === FilterFieldType.STRING) {
                popoutValue[item.fieldKey]?.forEach((option) => {
                    if (option.selected) options.push(option.key);
                });

                // Do not add filters if there are no selected values
                if (options.length > 0) {
                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (item.fieldType === FilterFieldType.DATE_RANGE) {
                let _value = popoutValue.filters[item.fieldKey],
                    min = _value?.start,
                    max = _value?.end;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                let _value = popoutValue[item.fieldKey],
                    min = _value?.min,
                    max = _value?.max;

                if (min || max) {
                    options = [min ?? item.min, max ?? item.max];

                    filterList.push({
                        fieldKey: item.fieldKey,
                        fieldType: item.fieldType,
                        operator: item.operator,
                        values: options,
                    });
                }
            }
        });

        return filterList;
    }

    private getFilterOptions(
        workflowItem: WorkflowItemInfo,
        filter: AnalyzerPopoutFilter,
    ): Observable<APIResponse<FilterFieldOption[]>> {
        filter.loading = true;
        return this.controlsService.getFilterFieldOptions(workflowItem.workflowItemId, filter.fieldKey).pipe(
            tap((res: APIResponse<FilterFieldOption[]>) => (filter.options = res.response)),
            finalize(() => (filter.loading = false)),
        );
    }

    private initGeospatialFilters(settings: GeospatialFilterSettings): void {
        this.initFilters(settings.filters);
        this.setSearchStringFormControl(this.fb.control(''));
        this.subscribeOnChanges();
    }

    private initFilters(initialFilters: AnalyzerPopoutFilter[]): void {
        const filters = this.fb.group({});
        initialFilters.forEach((item) => {
            if (item.fieldType === FilterFieldType.STRING) {
                filters.setControl(
                    `${item.fieldKey}_selected`,
                    this.fb.control({
                        value: 0,
                        disabled: true,
                    }),
                );
                filters.setControl(item.fieldKey, this.fb.control(item.options));
            } else if (FilterFieldType.isNumericFieldType(item.fieldType)) {
                filters.setControl(item.fieldKey, this.fb.control({}));
            }
        });
        this.setFiltersFormGroup(filters);
    }
}
