import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ReplaySubject} from 'rxjs';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {FilterFieldOption} from '@core/interfaces/system/system-common';

@Component({
    selector: 'ngx-multiselect-autocomplete',
    templateUrl: './multiselect-autocomplete.component.html',
    styleUrls: ['./multiselect-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => MultiselectAutocompleteComponent),
            multi: true,
        },
    ],
})
export class MultiselectAutocompleteComponent
    extends Unsubscribable
    implements OnInit, OnChanges, ControlValueAccessor
{
    @Input() options: FilterFieldOption[] = []; //list of the options for the select
    @Input() placeholder: string = '';
    @Input() selectLabel?: string; //provide custom value for nb-select-label
    @Input() optionsPanelClass: string = ''; //provide optionsPanelClass property for nb-select
    @Input() withAmount: boolean = true; //if true shows the amount of selected items in circle; if false - the circle isn't shown

    //for the purposes of using this component inside other overlays for not closing the parent overlay use: true
    @Input() withStopPropagation?: boolean = false;

    //if options come from api and search is on the backend side
    @Input() dynamicSearch?: boolean = false;
    @Input() currentSearching?: string = null;

    @Output() selectedChange = new EventEmitter();
    @Output() onSearch = new EventEmitter<string>();

    @ViewChild('filterInput') filterInput: ElementRef;

    selectControl: FormControl = new FormControl([]);
    filterControl: FormControl = new FormControl();
    filteredOptions$: ReplaySubject<FilterFieldOption[]> = new ReplaySubject(1);

    isAllSelected: boolean = false;

    onChange = (value) => {};
    onTouched = () => {};
    touched = false;
    disabled = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        if (this.currentSearching) {
            this.filterControl.setValue(this.currentSearching, {emitEvent: false});
        }

        this.selectControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((value) => {
                    //when focusing on search input checkbox selects
                    //this removes search-input's value from this.selectControl
                    // and stops the process of subscription to this event
                    let isSearch = value?.includes('');
                    if (isSearch) {
                        let _value = value.filter((item) => item !== '');
                        this.selectControl.setValue(_value, {emitEvent: false});
                    }

                    return !isSearch;
                }),
                map((value: any[]) => {
                    if (!this.dynamicSearch) {
                        if (value && value.length === this.options?.length && !value.includes(0)) {
                            let _value = [...value, 0];
                            this.isAllSelected = true;
                            this.selectControl.setValue(_value, {emitEvent: false});

                            return _value;
                        } else if (value && value.length !== this.options?.length + 1) {
                            let _value = value.filter((item) => item !== 0);
                            this.isAllSelected = false;
                            this.selectControl.setValue(_value, {emitEvent: false});

                            return _value;
                        } else if (value && value.length === this.options.length + 1) {
                            let _value = value.filter((item) => item !== 0);
                            this.isAllSelected = true;

                            return _value;
                        } else return value;
                    }
                    return value;
                }),
                debounceTime(500),
            )
            .subscribe((value: FilterFieldOption[]) => {
                this.onChange(value || []);
                let _value: string[] = value.map((item: FilterFieldOption) => item.key);

                this.selectedChange.emit(_value);
            });

        this.filterControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged(), debounceTime(500))
            .subscribe((value: string) => {
                if (!this.dynamicSearch) {
                    value && this.selectControl.reset([]);
                    this.filterOptions();
                } else this.onSearch.emit(value);

                this.filterInput.nativeElement.focus();
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options?.currentValue?.length) {
            this.combineOptionsForDynamicSearch();
        }
    }

    combineOptionsForDynamicSearch() {
        //this is used with dynamic search to prevent unselecting items from selectControl
        //if they are not included in the current set of options what can happen after search
        //this logic relates to nbSelect behavior behind the scene
        const controlKeys: string[] = this.selectControl.value.map((_item: FilterFieldOption) => _item.key);

        const _options: FilterFieldOption[] =
            this.options?.filter((_item: FilterFieldOption) => !controlKeys.includes(_item.key)) || this.options || [];

        this.filteredOptions$.next(Array.from([...this.selectControl.value, ..._options]));
    }

    toggleSelectAll() {
        if (this.selectControl.value.length >= this.options.length) {
            this.selectControl.reset([]);
            this.isAllSelected = false;
        } else {
            let _options = this.options.reduce((acc: string[], curr: FilterFieldOption) => {
                return [...acc, curr.key];
            }, []);
            this.selectControl.setValue([..._options, 0]);
            this.isAllSelected = true;
        }
    }

    getLabels(values: FilterFieldOption[]): string {
        let _labels: string = values?.reduce((acc: string, curr: FilterFieldOption) => {
            if (acc !== '') acc += ', ';
            return (acc += this.options.find((option: FilterFieldOption) => option.key === curr.key)?.name);
        }, '');
        return _labels || '';
    }

    protected filterOptions() {
        if (!this.options) {
            return;
        }

        let search = this.filterControl.value;
        if (!search) {
            this.filteredOptions$.next(this.options);
            return;
        } else {
            search = search.toLowerCase();
        }

        this.filteredOptions$.next(
            this.options.filter((opt: FilterFieldOption) => opt.key.toLowerCase().indexOf(search) > -1),
        );
    }

    writeValue(value: any[]): void {
        if (!this.dynamicSearch) this.isAllSelected = value.length === this.options?.length;

        this.setSelectControl(value);

        if (this.dynamicSearch) {
            this.combineOptionsForDynamicSearch();
        }
    }

    setSelectControl(value: any[]) {
        let _value: FilterFieldOption[] = value.map((item: any) => {
            if (typeof item === 'object') return item;

            let _item: FilterFieldOption = this.options?.find((o: FilterFieldOption) => o.key === item);

            //ToDo think how to avoid this
            if (!_item) _item = {key: item, name: item};

            return _item;
        });

        this.selectControl.setValue(this.isAllSelected ? [..._value, 0] : _value, {emitEvent: false});
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
