import {ChangeDetectionStrategy, Component, Input, OnInit, Optional} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {UsageAnalyticsService} from '@core/utils';

export interface TagNote {
    id: number;
    version?: number;
    createdOn?: Date;
    tag: string;
    note: string;
}
@Component({
    selector: 'ngx-tag-note-edit-dialog',
    templateUrl: './tag-note-edit-dialog.component.html',
    styleUrls: ['./tag-note-edit-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagNoteEditDialogComponent extends Unsubscribable implements OnInit {
    @Input() usageAnalyticsTitle: string;
    @Input() title: string = '';
    @Input() firstInfoBlock?: {label: string; value: string};
    @Input() tagNote: TagNote;

    tagNoteUpdateForm: FormGroup = this.fb.group({
        id: [null],
        tag: [null, [Validators.minLength(1), Validators.maxLength(30)]],
        note: [null, [Validators.maxLength(255)]],
    });
    eventType: string;
    constructor(
        @Optional() private dialogRef: NbDialogRef<TagNoteEditDialogComponent>,
        private fb: FormBuilder,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.tagNoteUpdateForm.patchValue(this.tagNote);

        this.eventType = !this.tagNote.id ? 'Create' : 'Edit';
    }

    save() {
        if (this.tagNoteUpdateForm.valid) {
            this.dialogRef.close(this.tagNoteUpdateForm.value);
            this.usageAnalyticsService.logEvent(this.eventType, this.usageAnalyticsTitle);
        }
    }

    close() {
        this.dialogRef.close();
    }
}
