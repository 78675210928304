import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MessageDisplayLevelType, User, UserNotification} from '@core/interfaces/common/users';
import {TimeLocalizePipe} from '@theme/pipes';
import {takeUntil} from 'rxjs/operators';
import {UsersStore} from '@store/common/users.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-user-notification',
    templateUrl: './user-notification.component.html',
    styleUrls: ['./user-notification.component.scss'],
})
export class UserNotificationComponent extends Unsubscribable implements OnInit {
    @Input() firstTime;
    @Input() messageList: UserNotification[];
    @Input() clickEvent: boolean = true;
    @Input() height: string;
    private currentUser: User;
    constructor(private router: Router, private timeLocalizePipe: TimeLocalizePipe, private usersStore: UsersStore) {
        super();
    }

    ngOnInit(): void {
        this.messageList.map((m) => {
            m.openByDefault = true;
        });
        this.usersStore.currentUser$.pipe(takeUntil(this.unsubscribe$)).subscribe((user: User) => {
            this.currentUser = user;
        });
    }

    formatMessage(item: UserNotification): string {
        if (item) {
            if (item.date) {
                return `${item.message} ${this.timeLocalizePipe.transform(item.date)}`;
            }
            return item.message;
        }
    }

    messageAction(item) {
        switch (item.type) {
            case MessageDisplayLevelType.WARNING:
                this.router.navigate(['/user-management/user/view'], {
                    queryParams: {username: this.currentUser.username},
                });
                item.open = false;
                break;
            case MessageDisplayLevelType.INFO:
                item.open = false;
                break;
        }
    }

    getIcon(type) {
        let result;
        switch (type) {
            case MessageDisplayLevelType.WARNING:
                result = 'alert-circle';
                break;
            case MessageDisplayLevelType.INFO:
                result = 'alert-triangle';
                break;
        }
        return result;
    }
}
