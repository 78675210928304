import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {
    FormCellType,
    FormField,
    FormFieldOption,
    FormViewModeType,
    SelectFieldOptionResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';

interface Option extends SelectFieldOptionResultDto {
    label: string;
    order: number;
    selected: boolean;
}

@Component({
    selector: 'ngx-form-field-single-select',
    templateUrl: './single-select.component.html',
    styleUrls: ['./single-select.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleSelectComponent extends FormFieldBaseComponent<SelectFieldOptionResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;
    options: Option[] = [];
    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    ngOnInit(): void {
        // Set options
        this.options = this.field.options.map((option: FormFieldOption) => {
            return {
                fieldId: this.field.id,
                optionType: this.field.options[0].optionType,
                optionId: option.id,
                label: option.optionLabel,
                order: option.order,
                selected: this.result?.optionId == option.id,
            };
        });

        this.genericInit();
        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    if (!this.fieldForm?.value && this.required) {
                        this.fieldForm?.markAsTouched();
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    public selectedOption(pressedOption: Option): void {
        // If option is currently selected, unselect (all options now unselected).
        // Else, select the newly pressed option.
        this.options.map((o) => {
            return {
                ...o,
                selected: false,
            };
        });

        if (!pressedOption.selected) {
            pressedOption.selected = true;
        }

        this.options.map((item) => {
            if (item.optionId !== pressedOption.optionId) {
                item.selected = false;
            }
        });
        // This component does not trigger fieldForm.valueChanges so perform this manually
        const newValue: SelectFieldOptionResultDto = this.applyValueChange(this.options.find((o) => o.selected));
        if (this.validate(newValue)) {
            this.fieldForm.setErrors(null);
            this.fieldForm.setValue(newValue);
            this.emitEvent(newValue);
        } else {
            this.fieldForm.setErrors({required: true});
        }
    }

    public getValueOrDefault(defaultValue: string): string | number {
        return this.options?.find((o) => o.selected)?.label || defaultValue;
    }

    /*
     * Implement abstract methods
     */
    validate(value: SelectFieldOptionResultDto): boolean {
        if (this.required && value == null) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormControl;
    }

    applyValueChange(item: Option): SelectFieldOptionResultDto {
        if (item == null) return null;
        return item as SelectFieldOptionResultDto;
    }

    getFormValue(): any {
        return this.options?.find((o) => o.selected) || null;
    }
}
