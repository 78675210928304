import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';

export class ProjectRendererService {
    public customLayerRenderer(): Renderer {
        return new UniqueValueRenderer({
            field: 'owner',
            uniqueValueInfos: [].concat([
                {
                    value: 'me',
                    symbol: {
                        type: 'simple-fill',
                        ...ProjectRendererService.getRenderDetailsMyProject(),
                    },
                },
                {
                    value: 'other',
                    symbol: {
                        type: 'simple-fill',
                        ...ProjectRendererService.getRenderDetailsOtherProject(),
                    },
                },
            ]),
        });
    }

    private static getRenderDetailsMyProject() {
        return {
            color: [12, 138, 199, 0.1],
            outline: {
                color: '#0C8AC7',
                width: 1,
            },
        };
    }
    private static getRenderDetailsOtherProject() {
        return {
            color: [42, 52, 79, 0.08],
            outline: {
                color: '#abaebd',
                width: 1,
            },
        };
    }
    private static getRenderDetailsMyProjectFocus() {
        return {
            color: [12, 138, 199, 0.16],
            outline: {
                color: '#066fa2',
                width: 2,
            },
        };
    }
    private static getRenderDetailsOtherProjectFocus() {
        return {
            color: [42, 52, 79, 0.16],
            outline: {
                color: '#424d69',
                width: 2,
            },
        };
    }
    private static getRenderDetailsMyProjectEdit() {
        return {
            color: [12, 138, 199, 0.16],
            outline: new SimpleLineSymbol({
                color: '#066fa2',
                width: 2,
                style: 'dash',
            }),
        };
    }
    private static getRenderDetailsOtherProjectEdit() {
        return {
            color: [42, 52, 79, 0.16],
            outline: new SimpleLineSymbol({
                color: '#424d69',
                width: 2,
                style: 'dash',
            }),
        };
    }
    public static getSimpleFillSymbolFocus(currentUser: boolean) {
        if (currentUser) {
            return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsMyProjectFocus());
        }
        return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsOtherProjectFocus());
    }
    public static getSimpleFillSymbolEdit(currentUser: boolean) {
        if (currentUser) {
            return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsMyProjectEdit());
        }
        return new SimpleFillSymbol(ProjectRendererService.getRenderDetailsOtherProjectEdit());
    }
}
