import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    SimpleSeries,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {ChartRequest, StatusChangeService} from '@core/interfaces/engin/status-change';
import {StatusChangeApi} from '../api/status-change.api';
import {AssetColumn} from '@core/interfaces/common/asset';
import {DataExplorerLineChart} from '../../../../pages/survival-analysis/api/pojo/survival-study.pojo';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class StatusChangeServiceImpl extends StatusChangeService {
    constructor(private api: StatusChangeApi) {
        super();
    }

    getStatusChangeList(filterParams: Filter[]): Observable<DataSource> {
        return this.api.getStatusChangeList(filterParams);
    }
    getStatusChangeListCsv(filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.getStatusChangeListCsv(filterParams);
    }
    getChartByDate(chartRequest: ChartRequest): Observable<APIResponse<DataExplorerLineChart>> {
        return this.api.getChartByDate(chartRequest);
    }
    getChartByCategory(chartRequest: ChartRequest): Observable<APIResponse<SimpleSeries<string, number>>> {
        return this.api.getChartByCategory(chartRequest);
    }
    getTableColumns(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.getTableColumns();
    }
    getGroupOptions(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.getGroupOptions();
    }
    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.getFilterOptions();
    }
    getFilterFieldOptions(req: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.getFilterFieldOptions(req);
    }
}
