import {Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {Document, DocumentCreate, DocumentService, FileFormatType} from '@core/interfaces/common/document';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';

export interface LocalDocument {
    file: File;
    fileName: string;
    progress: number;
    status: 'STARTED' | 'SUCCESS' | 'FAILED' | 'UPLOADED'; // Upload status, not same as Document.status
    fileFormat: FileFormatType;
    documentType: string;
    size: number;
}
@Injectable()
export class DocumentsStore {
    constructor(private documentService: DocumentService, private toastrService: NbToastrService) {}

    public uploadAndCreateImage(newFile: any) {
        const file: LocalDocument = newFile.file;

        // Upload image to ENGIN data store
        return this.documentService.getPresignedUrl(newFile.fileName, file.fileFormat).pipe(
            mergeMap((url) => {
                return this.documentService.uploadToPresignedUrl(url.response.url, file.file).pipe(
                    map((event: HttpEvent<any>) => {
                        switch (event.type) {
                            case HttpEventType.Sent:
                                break;
                            case HttpEventType.UploadProgress:
                                file.progress = Math.round((event.loaded * 100) / event.total);
                                break;
                            case HttpEventType.Response:
                                file.status = 'UPLOADED';
                                return {
                                    fileName: newFile.fileName,
                                    fileKey: url.response.fileKey,
                                    fileSize: file.size,
                                    bucketName: url.response.bucketName,
                                    documentType: file.documentType.toUpperCase(),
                                    fileFormat: file.fileFormat,
                                    relatedEntity: newFile.assetId,
                                    status: 'UPLOADED',
                                    tag: newFile.tag,
                                    description: newFile.description,
                                };
                        }
                    }),
                    filter((event) => !!event),
                    mergeMap((event: DocumentCreate) => this.createDocument(event)),
                    catchError((error: HttpErrorResponse) => {
                        return of(error);
                    }),
                );
            }),
            catchError((error: HttpErrorResponse) => {
                return of(error);
            }),
        );
    }

    private createDocument(model: DocumentCreate) {
        return this.documentService.create(model).pipe(
            tap((response: APIResponse<Document>) => {
                const obj: Document = response.response;
                const message = `Image "${obj.fileName}" uploaded successfully.`;
                this.toastrService.success(message, 'Image upload success', {duration: 10000});
            }),
        );
    }
}
