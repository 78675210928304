import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, InfrastructureForecastBySeasons, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface VaultInfo extends InfoBlock {}

export abstract class VaultLoadForecastService {
    /*
     * Infrastructure: results for vault(s)
     */
    abstract getVaultPivot(
        workflowItemId: number,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getVaultInfo(workflowItemId: number, scenarioId: string, vaultId: string): Observable<VaultInfo[]>;

    abstract getVaultForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons>;

    abstract getVaultForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getVaultPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        vaultId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
