import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Note} from '@core/interfaces/common/note';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'ngx-edit-note-dialog',
    templateUrl: './edit-note-dialog.component.html',
    styleUrls: ['./edit-note-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditNoteDialogComponent implements OnInit {
    @Input() noteData?: Note;
    form: FormGroup = this.fb.group({
        note: [null, [Validators.required, Validators.maxLength(255)]],
    });

    saveBtnPressed = false;

    constructor(protected ref: NbDialogRef<EditNoteDialogComponent>, private fb: FormBuilder) {}

    ngOnInit() {
        this.form.patchValue(this.noteData);
    }

    save() {
        if (this.form.invalid) {
            this.saveBtnPressed = true;
            this.form.markAllAsTouched();
            return;
        }

        this.ref.close(this.form.value);
    }

    close() {
        this.ref.close();
    }
}
