import {Pipe, PipeTransform} from '@angular/core';
import {Moment, utc} from 'moment';
import {FormatsService} from '@core/utils';

@Pipe({
    name: 'datetimeFormat',
    pure: false,
})
export class DatetimeFormatPipe implements PipeTransform {
    /**
     * Convert date into date format
     * @param inDateRaw input string timestamp (read as UTC) or date (read timezone)
     * @param outFormat optional else DEFAULT_DATE_FORMAT = 'yyyy-MM-DD hh:mm A'
     */
    transform(inDateRaw: any, outFormat: any = FormatsService.DEFAULT_DATETIME_FORMAT): any {
        // moment.utc() interprets ambiguous input as UTC
        const utcMoment: Moment =
            typeof inDateRaw === 'string'
                ? utc(inDateRaw)
                : typeof inDateRaw === 'number'
                ? utc(inDateRaw)
                : utc(inDateRaw.toUTCString());
        return utcMoment.format(outFormat);
    }
    convertDateToISO(dateString) {
        const date = new Date(dateString.replace(/-/g, '/'));
        return date.toISOString();
    }
}
