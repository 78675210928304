import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {filter, finalize, map, mergeMap, takeUntil, tap} from 'rxjs/operators';
import {
    FileFormat,
    FormCellType,
    FormField,
    FormViewModeType,
    MediaFieldResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {HelpersService} from '@core/utils/helpers.service';
import {FormFieldBaseComponent} from '@theme/components/form/cells/base/form-field-base.component';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiType, S3Service} from '@core/interfaces/common/s3';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {SignatureDialogComponent} from '@theme/components/dialogs/signature-dialog/signature-dialog.component';
import {MediaCarouselDialogComponent} from '@theme/components/image-carousel/image-carousel-dialog/media-carousel-dialog.component';
import {HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
    selector: 'ngx-form-field-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss', '../base/form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignatureComponent extends FormFieldBaseComponent<MediaFieldResultDto> implements OnInit {
    @Input() field: FormField;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Input() s3service: S3Service;
    @Input() checkValidation: Observable<boolean> = new BehaviorSubject<boolean>(false);
    @Input() pageMode: FormMode;
    public FormMode = FormMode;

    signatureForm: FormGroup = this.fb.group({
        fieldId: [null],
        fileKey: [null],
        fileName: [null],
        fileFormat: [null],
        fileSize: [null],
        size: [-1],
        name: [null],
        url: [null],
    });
    mediaLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    ApiType = ApiType;
    constructor(
        //@Inject(MAT_DIALOG_DATA) public data: any, // nebulizer can't do it this way :(
        private cd: ChangeDetectorRef,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: NbDialogService,
        private helpersService: HelpersService,
    ) {
        super();
    }

    get signature() {
        return this.signatureForm as FormGroup;
    }

    ngOnInit(): void {
        this.mediaLoaded.next(false);
        this.createSignatureMedia(this.result);

        this.checkValidation
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((value) => !!value),
                map((_) => {
                    const validSignatureImage = this.signature.value;
                    if (validSignatureImage == null && this.required) {
                        this.fieldForm?.setErrors({required: true});
                    }
                    this.cd.detectChanges();
                }),
            )
            .subscribe();
    }

    private createSignatureMedia(result: MediaFieldResultDto): void {
        if (result == null) return;

        // Signature is saved as an image
        this.signature.reset(this.createItem(result));
        this.mediaLoaded.next(true);
    }

    private createItem(result: MediaFieldResultDto): MediaFieldResultDto {
        const media: MediaFieldResultDto = {
            fieldId: this.field.id,
            fileKey: result.fileKey,
            fileName: result.fileName,
            fileFormat: result.fileFormat,
            fileSize: result.fileSize,
            formattedSize: this.helpersService.formatFileSize(result.fileSize),
            url: result.url,
        };
        return media;
    }

    // Support for viewing signature in pop-up carousel
    public openCarousel(file): void {
        this.dialogService
            .open(MediaCarouselDialogComponent, {
                closeOnBackdropClick: false,
                context: {
                    mediaList: [this.signature.value],
                    mainMediaName: file.fileName,
                },
            })
            .onClose.pipe(
                filter((res) => !!res),
                map((res) => {}),
            )
            .subscribe(() => {});
    }

    public remove(): void {
        this.signature.reset();

        this.triggerChange();
    }

    public addSignature() {
        this.dialogService
            .open(SignatureDialogComponent, {
                closeOnBackdropClick: false,
                context: {},
            })
            .onClose.pipe(
                filter((res) => !!res),
                map((res) => {
                    // Create image from res.contents [base64 string]. File type is PNG from our signature pad.
                    const byteArray = new Uint8Array(
                        atob(res.contents.replace('data:image/png;base64,', ''))
                            .split('')
                            .map((char) => char.charCodeAt(0)),
                    );
                    const blob = new Blob([byteArray], {type: 'image/png'});
                    const file = new File([blob], `sign_field_id_${this.field.id}.png`, {type: 'image/png'});

                    // Upload image to S3
                    this.s3service
                        .getPresignedUrl(file.name, FileFormat.PNG, ApiType.MAINTENANCE_PLANNING)
                        .pipe(
                            mergeMap((url) => {
                                return this.s3service.uploadToPresignedUrl(url.response.url, file).pipe(
                                    tap((event: HttpEvent<any>) => {
                                        switch (event.type) {
                                            case HttpEventType.Response:
                                                const media: MediaFieldResultDto = {
                                                    fieldId: this.field.id,
                                                    fileKey: `inspection-content/${file.name}`,
                                                    fileName: file.name,
                                                    fileFormat: FileFormat.PNG,
                                                    fileSize: file.size,
                                                    formattedSize: this.helpersService.formatFileSize(file.size),
                                                    url: res.contents, // base 64 string can be used to render thumbnail
                                                };
                                                this.signature.reset(media);
                                                break;
                                        }
                                    }),
                                    finalize(() => this.triggerChange()),
                                );
                            }),
                        )
                        .subscribe();
                }),
            )
            .subscribe();
    }

    // This component does not trigger fieldForm.valueChanges so perform this manually
    private triggerChange(): void {
        const validSignatureImage = this.signature.value;
        const newSignature: MediaFieldResultDto = this.applyValueChange(validSignatureImage);
        this.fieldForm?.markAsTouched();
        if (this.required && newSignature == null) this.fieldForm?.setErrors({required: true});
        if (this.validate(newSignature)) {
            this.fieldForm.setErrors(null);
        } else {
            this.fieldForm.setErrors({required: true});
            this.cd.detectChanges();
        }
        this.emitEvent(newSignature);
    }

    /*
     * Implement abstract methods
     */
    validate(value: MediaFieldResultDto): boolean {
        if (this.required && value == null) {
            return false;
        }

        return true;
    }

    get fieldForm() {
        return this.fieldResultForm?.get(this.field.id + '') as FormArray;
    }

    applyValueChange(signature: MediaFieldResultDto): MediaFieldResultDto {
        if (signature == null) return null;
        return signature as MediaFieldResultDto;
    }

    getFormValue(): any {
        return this.signatureForm.value;
    }

    handleImageError(file) {
        console.log(file);
    }
}
