import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {Filter, FilterFieldOption, FilterFieldType} from '@core/interfaces/system/system-common';
import {FormGroup} from '@angular/forms';

export interface AccordionFilter extends Filter {
    options?: FilterFieldOption[];
    min?: number;
    max?: number;
}

@Component({
    selector: 'ngx-filters-accordion',
    templateUrl: './filters-accordion.component.html',
    styleUrls: ['./filters-accordion.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersAccordionComponent {
    @Input() form: FormGroup;
    @Input() filtersList: AccordionFilter[];

    FilterFieldType = FilterFieldType;

    constructor() {}

    getFilterSelectedNumber(filterItem: AccordionFilter) {
        if (filterItem.fieldType === FilterFieldType.STRING) {
            let _selected = this.form.controls[filterItem.fieldKey].value?.length;
            if (!_selected) return 'All';
            return _selected === filterItem.options.length ? 'All' : _selected;
        } else if (FilterFieldType.isNumericFieldType(filterItem.fieldType)) {
            let _value = this.form.controls[filterItem.fieldKey].value;
            if (!_value?.min && !_value?.max) return 'None';
            return `${_value?.min ?? filterItem.min}-${_value.max ?? filterItem.max}`;
        }
    }

    isNumeric(fieldType: FilterFieldType): boolean {
        return FilterFieldType.isNumericFieldType(fieldType);
    }
}
