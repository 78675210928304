import {GeospatialService} from '../../helper/geospatial-service';
import {SpatialEntityData} from '../../../../../../pages/geospatial-viewer/model/api';
import {AssetRendererService} from '../../renderers/asset-renderer.service';
import {AssetFieldsService} from '../../fields/asset-fields.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';

export class HeatmapSource {
    category: string;
    source: Graphic[];
}

@Injectable()
export class HeatmapLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private rendererService: AssetRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    /**
     * Create heatmap layers (one per category) for asset population.
     * Linear assets are consolidated to a point with average [X,Y].
     * @return esri.FeatureLayer
     */
    public prepareHeatmapLayers(data: SpatialEntityData[], id: string, theme: string, zoom: number): FeatureLayer[] {
        // Prepared heatmap data sources: list of items with value (for rendering colour) and source data points
        const heatmapSource: HeatmapSource[] = this.createHeatmapSource(data);

        const heatmapLayers: FeatureLayer[] = [];
        for (const layerSource of heatmapSource) {
            const layer = new FeatureLayer({
                id: (id ? id : 'asset_heatmap_layer') + layerSource.category,
                objectIdField: 'ObjectID',
                source: layerSource.source,
                fields: this.fieldsService.getMapFields(),
                renderer: this.rendererService.heatmapRenderer(layerSource.category, theme, zoom),
            });
            heatmapLayers.push(layer);
        }

        return heatmapLayers;
    }

    /*
     * Create array of Graphics, type = point. Return Graphic[]
     */
    private createHeatmapSource(data: SpatialEntityData[]): HeatmapSource[] {
        // Heatmap is constructed of unique categories, and list of data per category
        const uniqueCategories = data
            .map((e) => e.displayCategory)
            .filter((value, index, self) => self.indexOf(value) === index);

        return uniqueCategories.map((category, i) => {
            const categoryData = data
                .filter((e) => e.displayCategory === category)
                .map((asset, j) => {
                    return new Graphic({
                        geometry: new Point({
                            latitude: asset.geoJsonGeometry.coordinates[1],
                            longitude: asset.geoJsonGeometry.coordinates[0],
                        }),
                        attributes: {
                            // Unique identifiers
                            id: asset.id,
                            classCode: asset.classCode,
                            // Data
                            displayCategory: asset.displayCategory,
                            data: asset.data,
                            count: asset.count,
                        },
                    });
                });

            return {
                category: category,
                source: categoryData,
            };
        });
    }
}
