import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject} from '@angular/core';
import {
    NbRequestPasswordComponent,
    NB_AUTH_OPTIONS,
    NbAuthService,
    NbAuthResult,
    getDeepFromObject,
} from '@nebular/auth';
import {Router} from '@angular/router';

/**
 * Forgot Password
 * - Pre-auth (no login required)
 * - User enters this page to request a password reset email
 */
@Component({
    selector: 'ngx-auth-forgot-password',
    templateUrl: './forgot-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordComponent extends NbRequestPasswordComponent {
    showMessages: any = {
        success: true,
        error: true,
    };

    constructor(
        protected service: NbAuthService,
        @Inject(NB_AUTH_OPTIONS) protected options = {},
        protected cd: ChangeDetectorRef,
        protected router: Router,
    ) {
        super(service, options, cd, router);
        this.strategy = 'generalAPI';
    }

    requestPass(): void {
        this.errors = this.messages = [];
        this.submitted = true;

        this.service.requestPassword(this.strategy, this.user).subscribe((result: NbAuthResult) => {
            const resp = result.getResponse().body;
            const apiSuccess = resp.success as boolean;
            const apiErrors = resp.errors as string[];
            const apiMessages = resp.messages as string[];

            this.submitted = false;
            if (apiSuccess) {
                this.messages = apiMessages;
            } else {
                this.errors = apiErrors;
            }

            this.cd.detectChanges();
        });
    }

    getConfigValue(key: string): any {
        return getDeepFromObject(this.options, key, null);
    }
}
