import {Observable} from 'rxjs';
import {SurvivalStudyRequest} from '../../../pages/survival-analysis/api/pojo/survival-study.pojo';
import {DownloadLink} from '../common/legacyUploads';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, SimpleSeries, TableFilterConfig} from '@core/interfaces/system/system-common';
import {CurveCalibrationDetails} from 'src/app/pages/survival-analysis/api/pojo/curve-calibration-outputs.pojo';
import {AssetColumn} from '../common/asset';

export interface FilterOperatorOption {
    operatorLabel: string;
    filterOperator: FilterOperator;
    filterType: WebFilterType;
    fieldValue: any;
    fieldValues: any[];
}
//todo: need to confirm
export interface FilterOperator {}
//todo: need to confirm
export enum WebFilterType {
    None,
    Numeric,
    Date,
    Freetext,
    SingleSelect,
    MultiSelect,
}

export abstract class SurvivalAnalysisService {
    abstract getCurveCalibrationList(workflowItemId: number): Observable<DataSource>;

    abstract getAnalysisHistoryData(): Observable<DataSource>;

    abstract acceptCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>>;

    abstract rejectCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>>;

    abstract getCurveCalibrationData(
        workflowItemId: number,
        curveId: number,
    ): Observable<APIResponse<CurveCalibrationDetails>>;

    abstract getWorkflowDtoFull(workflowItemId: number): Observable<APIResponse<any>>;
}
