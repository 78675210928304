import {Injectable} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, Observable} from 'rxjs';
import {
    ConfigurationService,
    DatasetItem,
    GraphUnitConfig,
    TenantUnits,
    TenantUnitsConfig,
    WorkflowConfig,
    AlertStatusItem,
    LiveConfig,
    EnginDatatableDetails,
} from '@core/interfaces/common/configuration';
import {filter, map, shareReplay, takeUntil} from 'rxjs/operators';
import {APIResponse, SimpleUnit} from '@core/interfaces/system/system-common';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {UsersStore} from '@store/common/users.store';
import {AlertStatusEnum, LiveService} from '@core/interfaces/live/live';

@Injectable()
export class ConfigurationStore extends Unsubscribable {
    // System defaults to CAD and metric (m)
    private defaultUnits: TenantUnitsConfig = {
        currency: {
            prefix: '$',
            suffix: '',
            symbol: 'CAD',
        },
        count: {
            prefix: '',
            suffix: '',
            symbol: '#',
        },
        length: {
            prefix: '',
            suffix: 'm',
            symbol: 'km',
        },
        power: [
            {
                prefix: '',
                suffix: 'MVA',
                symbol: 'MVA',
            },
            {
                prefix: '',
                suffix: 'kVA',
                symbol: 'kVA',
            },
            {
                prefix: '',
                suffix: 'A',
                symbol: 'Amp',
            },
        ],
    };

    private units: BehaviorSubject<TenantUnits> = new BehaviorSubject<TenantUnits>(null);
    readonly units$: Observable<TenantUnits> = this.units.asObservable();

    private liveConfig: BehaviorSubject<LiveConfig> = new BehaviorSubject<LiveConfig>(null);
    readonly liveConfig$: Observable<LiveConfig> = this.liveConfig.asObservable().pipe(filter((d) => d && !!d));

    constructor(
        private configurationService: ConfigurationService,
        private usersStore: UsersStore,
        private liveService: LiveService,
    ) {
        super();

        this.usersStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                switchMap((_) => this.configurationService.getTenantUnits()),
            )
            .subscribe((res: APIResponse<TenantUnits>) => {
                let config: TenantUnitsConfig = this.defaultUnits;
                if (res.status === 200) {
                    config = res.response;
                }

                const graphUnits: GraphUnitConfig[] = [];

                // Create consistent list of graph unit options from tenant units configuration
                graphUnits.push({value: 'count-length-phase', label: `# (${config.length.symbol})`});
                graphUnits.push({value: 'currency', label: config.currency.symbol});
                graphUnits.push({value: 'count', label: config.count.symbol});
                config.power.forEach((powerUnit) => {
                    graphUnits.push({value: powerUnit.symbol.toLowerCase(), label: powerUnit.symbol});
                });

                this.units.next({
                    ...config,
                    graphUnits: graphUnits,
                });
            });

        this.liveService
            .getLiveConfiguration()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((res: APIResponse<LiveConfig>) => {
                this.liveConfig.next(res.response);
            });
    }

    readonly datasetList$: Observable<DatasetItem[]> = this.configurationService.getDatasets().pipe(
        map((res) => res.response),
        shareReplay(1),
    );
    readonly persistedDatasetList$: Observable<DatasetItem[]> = this.configurationService
        .getPersistedDatasetList()
        .pipe(map((res) => res.response));
    readonly persistedTableDetails$: Observable<EnginDatatableDetails[]> = this.configurationService
        .getPersistedDatasetTableAllList()
        .pipe(map((res) => res.response));

    // Helper functions for LiveConfig
    public statusToLabel(status: AlertStatusEnum): string {
        if (this.liveConfig.value) {
            const mapping: AlertStatusItem[] = this.liveConfig.value.statuses;
            return mapping.filter((m) => m.code === status)[0]
                ? mapping.filter((m) => m.code === status)[0].label
                : status;
        }
        return status.toString();
    }

    public getLiveConfig(): LiveConfig {
        if (this.liveConfig.value) {
            return this.liveConfig.value;
        }
        return null;
    }

    public getLiveStatuses(): AlertStatusItem[] {
        if (this.liveConfig.value) {
            return this.liveConfig.value.statuses;
        }
        return Object.keys(AlertStatusEnum).map((k: string) => {
            return {
                code: AlertStatusEnum[k],
                label: k,
            };
        });
    }

    public getGraphUnit(code: string) {
        return this.units.value?.graphUnits.find((u) => u.value === code);
    }

    public getSimpleUnit(code: string): SimpleUnit {
        if (this.liveConfig.value) {
            return this.liveConfig.value.units.filter((u) => u.code === code)[0]?.unit;
        }
    }

    readonly getAvailableWorkflows$: Observable<WorkflowConfig[]> = this.configurationService
        .getWorkFlows()
        .pipe(map((res) => res.response));
}
