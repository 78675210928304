<div class="component-container">
    <div class="logo-container">
        <div class="header-logo-container">
            <div class="header-logo"></div>
        </div>
    </div>
    <div class="message-box-container">
        <ngx-sensitive-info-contents (buttonAction)="goToDashboard()"></ngx-sensitive-info-contents>
    </div>
</div>
