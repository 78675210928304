export class SystemViewRequest {
    constructor(budgetInMillion: Number, priorityRiskMitigation: Number, prioritySAIFI: Number, prioritySAIDI: Number) {
        this.budgetInMillion = budgetInMillion;
        this.priorityRiskMitigation = priorityRiskMitigation;
        this.prioritySAIFI = prioritySAIFI;
        this.prioritySAIDI = prioritySAIDI;
    }

    budgetInMillion: Number;
    priorityRiskMitigation: Number;
    prioritySAIFI: Number;
    prioritySAIDI: Number;
}
