import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NbDialogService} from '@nebular/theme';
import {DeleteDialogComponent} from './delete-dialog/delete-dialog.component';
import {map, share} from 'rxjs/operators';
import {
    DataPreparationService,
    ProcessStep,
    ProcessStepError,
    ValidatedFile,
} from '@core/interfaces/engin/data-preparation';
import {APIResponse} from '@core/interfaces/system/system-common';
import {LogDialogComponent} from '@theme/components/dialogs/log-dialog/log-dialog.component';
export enum ActionButton {
    LOG = 'log',
    DELETE = 'delete',
}

@Component({
    selector: 'ngx-dp-file-validate',
    templateUrl: './file-validate.component.html',
    styleUrls: ['./file-validate.component.scss'],
})
export class FileValidateComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() processStep: ProcessStep;
    @Output() outputEvent = new EventEmitter();
    @Output() deleteFileEvent = new EventEmitter();

    fileUploadForm: FormGroup = this.fb.group({
        fileList: this.fb.array([], Validators.required),
    });

    title = 'Data File Validation';
    subTitle = 'All file must pass validation to proceed';
    tableColumns: any[] = [
        {
            code: 'fileName',
            title: 'File Name',
            width: '42%',
        },
        {
            code: 'fileType',
            title: 'File Type',
            width: '25%',
        },
        {
            code: 'validationStatus',
            title: 'Validation Status',
            width: '25%',
        },
        {
            code: 'action',
            title: '',
            width: '8%',
        },
    ];

    constructor(
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private dialogService: NbDialogService,
        private dataPreparationService: DataPreparationService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.updateFileData();

        this.fileUploadForm.valueChanges.subscribe(() => {
            this.triggerOutputEvent();
        });
    }

    ngOnChanges(_: SimpleChanges): void {
        this.updateFileData();
    }

    onActionClick(data, index) {
        switch (data.action) {
            case ActionButton.DELETE:
                this.dialogService
                    .open(DeleteDialogComponent, {context: data.file})
                    .onClose.pipe(
                        map((flag: boolean) => {
                            if (flag) {
                                this.removeFile(data.file, index);
                                this.changeDetectorRef.markForCheck();
                            }
                        }),
                    )
                    .subscribe();
                break;
            case ActionButton.LOG:
                this.dialogService.open(LogDialogComponent, {
                    context: {
                        fileNameToDownload: 'Step Error Log',
                        fileName: data.file?.fileName,
                        errorList$: this.dataPreparationService
                            .getStepIssueLog(this.processStep.id, data.file?.fileKey || null)
                            .pipe(
                                map((res: APIResponse<ProcessStepError[]>) => {
                                    return res.response.map((err: ProcessStepError) => ({
                                        errorMessage: err.stepErrorMessage,
                                        tableName: err.tableName,
                                        columnName: err.columnName,
                                        errorCount: err.errorCount,
                                    }));
                                }),
                                share(),
                            ),
                    },
                });
                break;
            default:
                break;
        }
    }

    removeFile(file, index) {
        // Delete file event will automatically refresh step data
        if (file) {
            this.deleteFileEvent.emit({stepId: this.processStep.id, fileKey: file.fileKey});
        }
        /*this.fileList.removeAt(index);
        // TODO: send event to delete file*/
    }

    get fileList() {
        return this.fileUploadForm.get('fileList') as FormArray;
    }

    triggerOutputEvent() {
        // "Next" call starts the data validation, so no need to check file status
        if (this.fileList.value.length > 0) {
            const failedFileStatus = this.fileList.value.filter(
                (file: ValidatedFile) => file.validationStatus === 'FAILED',
            );
            if (failedFileStatus.length === 0) {
                this.outputEvent.emit({validation: true, data: this.processStep.stepData});
            } else {
                this.outputEvent.emit({validation: false, data: this.processStep.stepData});
            }
        }
    }

    private updateFileData() {
        const fileData = this.processStep.stepData.filter((d) => d.dataKey === 'OUTBOUND_FILES');

        if (fileData.length) {
            let validatedFiles = JSON.parse(fileData[0].dataValue) as ValidatedFile[];
            this.fileList.clear();
            validatedFiles.forEach((file) => {
                this.fileList.push(this.fb.group({...file}));
                this.triggerOutputEvent();
            });
        }
    }
}
