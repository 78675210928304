import {Injectable} from '@angular/core';
import {AuthSettings, TenantSettingsService} from '@core/interfaces/common/tenantSettings';
import {Observable} from 'rxjs';
import {TenantSettingsApi} from '@core/backend/common/api/tenantSettings.api';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class TenantSettingsServiceImpl extends TenantSettingsService {
    constructor(private api: TenantSettingsApi) {
        super();
    }

    getTenantSettings(): Observable<APIResponse<AuthSettings>> {
        return this.api.getTenantSettings();
    }
    updateTenantSettings(): Observable<any> {
        return this.api.updateTenantSettings();
    }
}
