import {Pipe, PipeTransform} from '@angular/core';
import {User} from '@core/interfaces/common/users';
import {FormatsService} from '@core/utils';
import {filter, takeUntil} from 'rxjs/operators';
import {DateTime} from 'luxon';
import {UsersStore} from '@store/common/users.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Pipe({
    name: 'unlocalizeTime',
    pure: false,
})
export class TimeUnlocalizePipe extends Unsubscribable implements PipeTransform {
    private localTimezone: string = 'Etc/UTC'; // default utc
    constructor(private usersStore: UsersStore) {
        super();

        this.usersStore.currentUser$
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => !!d),
            )
            .subscribe((user: User) => {
                this.localTimezone = user.timezone;
            });
    }

    /**
     * Convert localized time back to universal (UTC) time; ignore any timezone inDateRaw in favour of user's local time
     * saved in ENGIN.
     * @param inDateRaw input date as type date or string
     * @param outFormat optional else DEFAULT_DATE_FORMAT = 'yyyy-MM-DD HH:mm A'
     */
    transform(inDateRaw: any, outFormat: any = FormatsService.DEFAULT_DATE_FORMAT_LUXON): any {
        const inLocalData =
            typeof inDateRaw === 'string'
                ? DateTime.fromISO(inDateRaw, {zone: this.localTimezone})
                : DateTime.fromJSDate(inDateRaw);

        return inLocalData.setZone('UTC').toFormat(outFormat);
    }
}
