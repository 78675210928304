import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {map} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PmProgramService} from '@core/interfaces/engin/program-management/pm-program';
import {PmSegmentService} from '@core/interfaces/engin/program-management/pm-segment';

@Injectable()
export class ProgramManagementStore {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    readonly resultsLoading$ = this.resultsLoading.asObservable();

    public programKpiOptions$ = this.programService
        .getKpiOptions()
        .pipe(map((res: APIResponse<string[]>) => res.response));

    public segmentKpiOptions$ = this.segementService
        .getSegmentKpiOptions()
        .pipe(map((res: APIResponse<string[]>) => res.response));

    constructor(private programService: PmProgramService, private segementService: PmSegmentService) {}
}
