<ngx-icon-box
    class="table-settings"
    icon="more-vertical-outline"
    [nbPopover]="settings"
    nbPopoverTrigger="hover"
    nbPopoverPlacement="left"
></ngx-icon-box>

<ng-template #settings>
    <ngx-table-settings-popup
        [unitOptions]="fullUnitOptions"
        [unitItem]="unitItem"
        [graphId]="graphId"
        [disableDataDownload]="disableDataDownload"
        (itemChanged)="changed($event)"
        (downloadFile)="downLoadTableFile()"
    ></ngx-table-settings-popup>
</ng-template>
