import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '@core/backend/common/api/http.service';
import {StudyOverviewPageData, TitlePageData} from '../../../../../pages/reporting-tool/model/common-page-data';

@Injectable()
export class ReportRenderApi {
    private prefix = 'reporting/render';

    constructor(private api: HttpService) {}

    getTitlePageContent(reportCode: string): Observable<TitlePageData> {
        return this.api.get(`${this.prefix}/title/${reportCode}`);
    }

    getStudyOverviewPageContent(reportCode: string, workflowItemId: number): Observable<StudyOverviewPageData> {
        return this.api.get(`${this.prefix}/study-overview/${reportCode}/${workflowItemId}`);
    }
}
