import * as moment from 'moment';
import {SimpleUnit} from '../interfaces/system/system-common';
import {Page, ValuePrepareModeEnum, ValuePrepareSetting} from '@core/interfaces/common/pages';
import {NumberWithCommasPipe} from '@theme/pipes';

export enum DateType {
    DATE,
    STRING,
    EPOCHE,
}

export class FormatsService {
    static EMAIL_PATTERN = "^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@([a-zA-Z0-9]{1,61})+\\.([a-zA-Z0-9]{1,61})+$";
    static ONE_NUMBER_PATTERN = '.*[0-9].*';
    static ONE_UPPERCASE_LETTER_PATTERN = '.*[A-Z].*';
    static ONE_LOWERCASE_LETTER_PATTERN = '.*[a-z].*';
    static PASS_LENGTH_PATTERN = '^\\w{8,50}$';
    static DEFAULT_DATE_FORMAT = 'yyyy-MM-DD hh:mm A';
    static SHORT_DATE_FORMAT = 'yyyy-MM-DD';
    static DEFAULT_DATE_FORMAT_LUXON = 'yyyy-MM-dd HH:mm:ss';
    static SHORT_DATE_FORMAT_LUXON = 'yyyy-MM-dd';
    static DEFAULT_DATETIME_FORMAT = 'yyyy-MM-DD hh:mm A';
    static USERNAME_PATTERN = '[\\.@\\w]+';

    // TODO: change all instance of getFormmatedValue to prepareValue?
    public static getFormattedValue(value: number, full: boolean = false): string[] {
        // Changed B, M, K from "0" and "1" to "1" and "2"
        if (value >= 10000000000) {
            return [parseFloat((value / 1000000000).toFixed(1)).toString(), `${full ? 'Billion' : 'B'}`];
        } else if (value >= 1000000000) {
            return [parseFloat((value / 1000000000).toFixed(2)).toString(), `${full ? 'Billion' : 'B'}`];
        } else if (value >= 10000000) {
            return [parseFloat((value / 1000000).toFixed(1)).toString(), `${full ? 'Million' : 'M'}`];
        } else if (value >= 1000000) {
            return [parseFloat((value / 1000000).toFixed(2)).toString(), `${full ? 'Million' : 'M'}`];
        } else if (value >= 10000) {
            return [parseFloat((value / 1000).toFixed(1)).toString(), `${full ? 'Thousand' : 'K'}`];
        } else if (value >= 1000) {
            return [parseFloat((value / 1000).toFixed(2)).toString(), `${full ? 'Thousand' : 'K'}`];
        } else if (value >= 100) {
            return [parseFloat(value.toFixed(0)).toString(), ''];
        } else if (value >= 10) {
            return [parseFloat(value.toFixed(1)).toString(), ''];
        } else if (value >= 1) {
            return [parseFloat(value.toFixed(2)).toString(), ''];
        } else {
            return [parseFloat(value.toFixed(3)).toString(), ''];
        }
    }

    /*
     * Updated, try to use for all formatting going forward
     */
    public static prepareValueFromUnit(value, unit: SimpleUnit, full: boolean = false, tolerance: number = 1): string {
        const finalUnit = unit ? unit : new SimpleUnit();
        return FormatsService.prepareValue(value, finalUnit.prefix, finalUnit.suffix, full, tolerance);
    }

    // TODO: remove, use prepareValueFromUnit
    public static prepareValue(
        value: number,
        prefix: string,
        suffix: string,
        full: boolean = false,
        tolerance: number = 1,
    ): string {
        const renderedValue = (count, qual) => `${prefix}${count}${qual}${suffix}`;
        const renderedValueLength = (val, qual) =>
            `${prefix}${new NumberWithCommasPipe().transform(val)}${qual}${suffix}`;

        // TODO: this needs to be made more expandable, but works for now
        // If the unit is length-based in meters (m), round everything to thousands and use lower-case 'k'
        if (suffix != null && suffix === 'm') {
            if (value >= 10000000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 1000000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 10000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 1000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 10000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(1), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 1000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(2), `${full ? 'Thousand' : 'k'}`);
            } else if (value >= 100) {
                return renderedValueLength(value.toFixed(0), '');
            } else if (value >= 10) {
                return renderedValueLength(value.toFixed(1), '');
            } else if (value >= 1) {
                return renderedValueLength(value.toFixed(2), '');
            } else {
                return renderedValueLength(value.toFixed(3), '');
            }
        } else if (suffix != null && suffix === 'mi') {
            // If the unit is length-based in miles (mi), allow more digits before adding qualifier
            if (value >= 10000000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 1000000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 10000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 1000000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(0), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 10000 * tolerance) {
                return renderedValueLength((value / 1000).toFixed(1), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 1000 * tolerance) {
                return renderedValueLength(value.toFixed(0), '');
            } else if (value >= 100) {
                return renderedValueLength(value.toFixed(0), '');
            } else if (value >= 10) {
                return renderedValueLength(value.toFixed(1), '');
            } else if (value >= 1) {
                return renderedValueLength(value.toFixed(2), '');
            } else {
                return renderedValueLength(value.toFixed(3), '');
            }
        } else {
            if (value >= 10000000000) {
                return renderedValue((value / 1000000000).toFixed(0), `${full ? 'Billion' : 'B'}`);
            } else if (value >= 1000000000) {
                return renderedValue((value / 1000000000).toFixed(1), `${full ? 'Billion' : 'B'}`);
            } else if (value >= 10000000) {
                return renderedValue((value / 1000000).toFixed(0), `${full ? 'Million' : 'M'}`);
            } else if (value >= 1000000) {
                return renderedValue((value / 1000000).toFixed(1), `${full ? 'Million' : 'M'}`);
            } else if (value >= 10000) {
                return renderedValue((value / 1000).toFixed(1), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 1000) {
                return renderedValue((value / 1000).toFixed(2), `${full ? 'Thousand' : 'K'}`);
            } else if (value >= 100) {
                return renderedValue(value.toFixed(0), '');
            } else if (value >= 10) {
                return renderedValue(value.toFixed(1), '');
            } else if (value >= 1) {
                return renderedValue(value.toFixed(2), '');
            } else {
                return renderedValue(value.toFixed(3), '');
            }
        }
    }

    // Proper function
    public static prepareCostValueFromUnit(value, unit: SimpleUnit) {
        const finalUnit = unit ? unit : new SimpleUnit();
        return FormatsService.prepareCostValue(value, finalUnit.prefix, finalUnit.suffix);
    }

    // TODO: remove, use prepareCostValueFromUnit
    public static prepareCostValue(value, prefix, suffix) {
        const renderedValue = (count, qual) => `${prefix}${count}${qual}${suffix}`;

        if (value >= 10000000000) {
            return renderedValue((value / 1000000000).toFixed(1), 'B');
        } else if (value >= 1000000000) {
            return renderedValue((value / 1000000000).toFixed(2), 'B');
        } else if (value >= 1000000) {
            return renderedValue((value / 1000000).toFixed(1), 'M');
        } else if (value >= 1000000) {
            return renderedValue((value / 1000000).toFixed(2), 'M');
        } else if (value >= 10000) {
            return renderedValue((value / 1000).toFixed(1), 'K');
        } else if (value >= 1000) {
            return renderedValue((value / 1000).toFixed(2), 'K');
        } else {
            return renderedValue(value.toFixed(2), '');
        }
    }

    // Proper function
    public static prepareChartLabelFromUnit(value, unit: SimpleUnit) {
        const finalUnit = unit ? unit : new SimpleUnit();
        return FormatsService.prepareChartLabel(value, finalUnit.prefix, finalUnit.suffix);
    }

    // TODO: remove, use prepareChartLabelFromUnit
    public static prepareChartLabel(value, prefix, suffix) {
        if (value >= 0 && value < 1000) {
            return `${prefix}${value}`;
        } else if (value >= 1000 && value < 1000000) {
            return `${prefix}${value / 1000}K`;
        } else {
            return `${prefix}${value / 1000000}M`;
        }
    }

    public static prepareIntegerNumber(value) {
        if (value >= 0 && value < 1000) {
            return `${value}`;
        } else if (value >= 1000 && value < 10000) {
            return `${(value / 1000).toFixed(2)}K`;
        } else if (value >= 10000 && value < 100000) {
            return `${(value / 1000).toFixed(1)}K`;
        } else {
            return `${value / 1000000}M`;
        }
    }
    public static numberFromDecimal(val: number, decimals: number = 0, type: string) {
        switch (type) {
            case '%':
                return FormatsService.preparePercentageLabel(val, decimals);
            default:
                return Number(val.toFixed(decimals));
        }
    }

    public static preparePercentageLabelFromDecimal(val: number, decimals: number) {
        const pVal = val * 100.0;
        return FormatsService.preparePercentageLabel(pVal, decimals);
    }

    public static preparePercentageLabel(val: number, decimals: number) {
        if (val < 10) {
            return val.toFixed(decimals) + '%';
        }
        if (Number.isNaN(val)) {
            return 'N/A';
        } else {
            return val.toFixed(decimals == 0 ? 0 : decimals - 1) + '%';
        }
    }
    /*
     * Map a codified string to a title format
     * e.g. data_load --> Data Load
     */
    public static mapCodeToTitle(code: string) {
        return code
            .split('_')
            .map((str) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase())
            .join(' ');
    }

    /*
     * Dates
     */
    /* @Deprecated - see localtime.pipe.ts */
    public static prepareDateString(val: any, inType: DateType, outFormat: string) {
        switch (inType) {
            case DateType.DATE:
                return moment(val).format(outFormat);
            case DateType.STRING:
                const epocheMs = Date.parse(val);
                return FormatsService.prepareDateString(epocheMs, DateType.EPOCHE, outFormat);
            case DateType.EPOCHE:
                const date = new Date(val);
                return moment(date).format(outFormat);
        }
    }

    public static parseDateToEpoche(val: any) {
        return moment(val).valueOf();
    }

    // Helper functions for formatting
    public static getPrepareMethodFormGroup(page: Page, graphId: string, formGroup: any): ValuePrepareModeEnum {
        let prepareMethod: ValuePrepareModeEnum = ValuePrepareModeEnum.STANDARD;
        try {
            const unit = formGroup[graphId].unit;
            return FormatsService.getPrepareMethodUnit(page, graphId, unit);
        } catch (err) {}
        return prepareMethod;
    }

    public static getPrepareMethodUnit(page: Page, graphId: string, unit: string): ValuePrepareModeEnum {
        let prepareMethod: ValuePrepareModeEnum = ValuePrepareModeEnum.STANDARD;
        try {
            const valuePrepares: ValuePrepareSetting[] = page.graphList.find(
                (g) => g.graphId === graphId,
            )?.valuePrepare;
            prepareMethod = valuePrepares.find((p) => p.unit === unit)?.prepareMode;
        } catch (err) {}
        return prepareMethod;
    }
    public static getPrepareMethodTolerance(page: Page, graphId: string, unit: string): number {
        let prepareMethod: ValuePrepareModeEnum = ValuePrepareModeEnum.STANDARD;
        try {
            const valuePrepares: ValuePrepareSetting[] = page.graphList.find(
                (g) => g.graphId === graphId,
            )?.valuePrepare;
            prepareMethod = valuePrepares.find((p) => p.unit === unit)?.prepareMode;
            if (prepareMethod == ValuePrepareModeEnum.TOLERANCE) {
                return valuePrepares.find((p) => p.unit === unit)?.tolerance;
            }
        } catch (err) {}
        // When prepare method is not TOLERANCE, or not found
        return 1;
    }

    public static formattedValueToNumber(formattedValue: string): number {
        const match = formattedValue.match(/\d+.*/);

        formattedValue = match ? match[0].toLowerCase() : formattedValue.toLowerCase();

        if (formattedValue.endsWith('k')) {
            return parseFloat(formattedValue) * 1000;
        } else if (formattedValue.endsWith('m')) {
            return parseFloat(formattedValue) * 1000000;
        } else if (formattedValue.endsWith('b')) {
            return parseFloat(formattedValue) * 10000000000;
        } else {
            // If no suffix, assume it's already in numeric format
            return parseFloat(formattedValue);
        }
    }

    public static stringConvert(str: string) {
        str = str.toLowerCase();
        str = str.replace(new RegExp(/_/, 'g'), ' ');
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
}
