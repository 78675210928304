import {Component, Input} from '@angular/core';
import {NbThemeService} from '@nebular/theme';

@Component({
    selector: 'ngx-tooltip',
    template: `
        <ng-container>
            <p
                [nbPopover]="tooltip"
                class="w-100 main-label"
                nbPopoverTrigger="hover"
                [ngStyle]="{
                    fontSize: labelSize ? labelSize : '',
                    fontWeight: labelWeight ? labelWeight : '',
                    color: labelColor ? labelColor : '',
                    cursor: disableClick ? 'default' : 'pointer'
                }"
                [nbPopoverPlacement]="tooltipPosition"
            >
                {{ label }}
            </p>
            <ng-template #tooltip>
                <p class="tooltip-message">{{ tooltipMessage }}</p>
                <div class="arrow" [ngStyle]="{background: tooltipArrowColor}"></div>
            </ng-template>
        </ng-container>
    `,
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent {
    @Input() tooltipMessage;
    @Input() tooltipPosition;
    @Input() label;
    @Input() labelSize;
    @Input() labelColor;
    @Input() labelWeight;
    @Input() disableClick;

    currValue: string;
    tooltipArrowColor;

    constructor(private nbThemeService: NbThemeService) {
        this.currValue = this.nbThemeService.currentTheme;
        switch (this.currValue) {
            case 'dark':
                this.tooltipArrowColor = '#171d33';
                break;
            case 'default':
                this.tooltipArrowColor = 'white';
                break;
        }
    }
}
