import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {User, UserNotification, UsersService} from '@core/interfaces/common/users';
import {shareReplay, switchMap, tap} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class UsersStore extends Unsubscribable {
    public multipleTenants = new BehaviorSubject<boolean>(false);
    public currentUser = new BehaviorSubject<User>(null);
    readonly currentUser$: Observable<User> = this.currentUser.asObservable().pipe(shareReplay(1));

    private requestLoading = new BehaviorSubject<boolean>(false);
    readonly requestLoading$ = this.requestLoading.asObservable().pipe(shareReplay(1));

    constructor(private userService: UsersService, private router: Router, private toastrService: NbToastrService) {
        super();
    }

    /**
     * Get current user.
     */
    public getCurrentUser() {
        return this.currentUser.getValue();
    }

    /**
     * Refresh current user. This will trigger currentUser$ observable.
     */
    refreshUser() {
        return this.userService.getCurrentUser().pipe(tap((user: User) => this.setUser(user)));
    }

    setUser(user: User) {
        // If user timezone is undefined (never set), one-time set based on timezone from browser
        if (!user.timezone) {
            const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
            const newUserInfo = {
                ...user,
                timezone: localTimezone,
            };
            this.updateUser(newUserInfo);
        } else {
            this.currentUser.next(user);
        }
    }

    clearCurrentUser() {
        this.currentUser.next(null);
    }

    logout() {
        // Clear all local data, navigate to login page; send session expired message
        localStorage.clear();
        this.clearCurrentUser();

        this.router.navigate(['/auth/login'], {state: {sessionExpire: true}});
        setTimeout(() => {
            this.toastrService.warning(
                'Session has expired and you have been automatically logged out. ' + 'Please log back in.',
                'Session is expired',
                {duration: 0},
            );
        }, 1500);
    }

    updateUser(newUserInfo) {
        this.userService.update(newUserInfo).subscribe(() => {
            this.currentUser.next(newUserInfo);
        });
    }

    public fromLogin = new BehaviorSubject<boolean>(false);

    readonly getUserNotifications$: Observable<UserNotification[]> = this.fromLogin.asObservable().pipe(
        switchMap((flag) => {
            if (flag) {
                return this.userService.getUsersNotification();
            } else {
                return of(null);
            }
        }),
    );

    public changeRequestLoading(value) {
        this.requestLoading.next(value);
    }
}
