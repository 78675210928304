<form [formGroup]="form" class="password-form" aria-labelledby="title" *ngIf="validation$ | async as validation">
    <div class="form-control-group">
        <!-- New password -->
        <div class="col-12">
            <label class="section-label" for="input-new-password">New Password</label>
            <ngx-input-password
                controlName="newPassword"
                id="input-new-password"
                fieldSize="medium"
                [fc]="newPassword"
                (onInputChanges)="onPasswordChanged($event)"
                name="newPassword"
                [placeholder]="'New Password'"
                [passValidation]="validation?.validation"
            ></ngx-input-password>
            <ngx-input-error [formGroup]="form" fieldName="newPassword"></ngx-input-error>
        </div>

        <!-- Validation requirements -->
        <div class="col-12 requirements-container">
            <div class="row">
                <span class="section-label">Password requirements:</span>
            </div>
            <div class="row" *ngFor="let message of validation?.messages">
                <ng-container [ngSwitch]="message.validation">
                    <div *ngSwitchCase="true" class="col-1 passed">
                        <nb-icon icon="checkmark-circle-2"></nb-icon>
                    </div>
                    <div *ngSwitchCase="false" class="col-1 failed"><nb-icon icon="close-circle"></nb-icon></div>
                </ng-container>
                <div class="col-11 password-requirement">
                    <label>{{ message.description }}</label>
                </div>
            </div>
        </div>

        <!-- Confirm new password -->
        <div class="col-12">
            <label class="section-label" for="input-confirm-password">Confirm Password</label>
            <ngx-input-password
                controlName="confirmPassword"
                [fc]="confirmPassword"
                name="confirmPassword"
                id="input-confirm-password"
                fieldSize="medium"
                [placeholder]="'Confirm Password'"
                [passValidation]="validation?.validation"
            ></ngx-input-password>
            <ngx-input-error [formGroup]="form" fieldName="confirmPassword"></ngx-input-error>
        </div>
    </div>
</form>
