import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {ScenarioInfo, ScenarioLoadForecastService} from '@core/interfaces/engin/load-forecast/scenario';
import {ScenarioLoadForecastApi} from '../../api/load-forecast/scenario.api';

@Injectable()
export class ScenarioLoadForecastServiceImpl extends ScenarioLoadForecastService {
    constructor(private api: ScenarioLoadForecastApi) {
        super();
    }

    /*
     * Scenario comparison
     */
    public getScenarioPivot(
        workflowItemId: number,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getScenarioPivot(workflowItemId, seasonTiming, unit, sensitivityId);
    }

    public getScenarioForecast(
        workflowItemId: number,
        filter: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.getScenarioForecast(workflowItemId, filter, unit, sensitivityId);
    }

    public getScenarioInfo(workflowItemId: number): Observable<ScenarioInfo[]> {
        return this.api.getScenarioInfo(workflowItemId);
    }

    public getScenarioPivotCSV(
        workflowItemId: number,
        seasonTiming: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getScenarioPivotCSV(workflowItemId, seasonTiming, unit, sensitivityId);
    }

    /*
     * Other
     */
    public getCustomPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        factor: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getCustomPivot(workflowItemId, scenarioId, period, factor, pivotBy, unit, sensitivityId);
    }

    public getCustomPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        factor: string[],
        pivotBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getCustomPivotCSV(workflowItemId, scenarioId, period, factor, pivotBy, unit, sensitivityId);
    }
}
