import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ValueType} from 'src/app/pages/geospatial-viewer/model/metric';

@Component({
    selector: 'ngx-legend-bar',
    templateUrl: './legend-bar.component.html',
    styleUrls: ['./legend-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LegendBarComponent {
    @Input() legendData: {color: string; state: string};
    @Input() valueType;
    ValueType = ValueType;

    constructor() {}
    getZoneNumber(index) {
        return `Zone ${index + 1}`;
    }
}
