<p class="number">{{ value }}</p>

<p
    class="text"
    *ngIf="body?.label as label"
    #text
    [ngxTruncateText]="label"
    [nbTooltip]="label"
    [nbTooltipTrigger]="text.innerText !== label ? NbTrigger.HOVER : NbTrigger.NOOP"
>
    {{ label }}
</p>
