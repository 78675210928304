import {FormatsService} from '@core/utils/formats.service';
import {Filter, SimpleUnit} from '@core/interfaces/system/system-common';

export class PivotTableSource {
    data: AnnualLoad[];
}

export class AnnualLoad {
    year: number;
    load: number;
}

export class TableService {
    // Prepare and return dynamic header for table
    public static prepareTableSettings(
        settings: any,
        startYear: string,
        endYear: string,
        currentYear: string,
        columnWidth?: string,
        unit?: SimpleUnit,
    ) {
        var years = [];
        for (let i = parseInt(startYear); i <= parseInt(endYear); i++) {
            years.push(i);
        }
        const columns = TableService.prepareDynamicHeader(
            years,
            {
                currentYear: currentYear,
                fixedCellTotalWidth: TableService.getTotalFixedWidth(settings),
            },
            unit,
        );
        Object.assign(settings.columns, columns);
        return settings;
    }

    // Prepare dynamic header column for pivot table
    public static prepareDynamicHeader(yearsData: any[], options?: any, unit?: SimpleUnit) {
        const width = options?.width
            ? options.width
            : this.calculateWidth(yearsData.length, options.fixedCellTotalWidth ? options.fixedCellTotalWidth : 0);
        const currentYear = options?.currentYear ? options.currentYear : new Date().getFullYear();

        const loadHeaderSuffix = unit ? ` (${unit.symbol})` : '';
        let dynamicCol = {};
        yearsData.map((yearData) => {
            let val = {
                [yearData]: {
                    title: yearData + loadHeaderSuffix,
                    filter: false,
                    sort: false,
                    type: 'html',
                    width: width,
                    class: yearData <= currentYear ? 'actual' : 'forecast',
                    valuePrepareFunction: (value, row) => {
                        if (value == '' || isNaN(value)) {
                            return '-';
                        } else {
                            const suffix = row.suffix ? (row.suffix.value ? row.suffix.value : null) : null;
                            if (suffix != null && suffix == '%') {
                                return FormatsService.preparePercentageLabelFromDecimal(value, 2);
                            }
                            return FormatsService.prepareValueFromUnit(value, unit, false, 10);
                        }
                    },
                },
            };
            dynamicCol = {
                ...dynamicCol,
                ...val,
            };
        });
        return dynamicCol;
    }

    public static calculateWidth(numOfHeader: number, fixedCellTotalWidth): string {
        const tableCardWidth = window.innerWidth - 64 - 9 - 48;

        const totalDynamicCellWidth = tableCardWidth - fixedCellTotalWidth;
        const averageWidth = fixedCellTotalWidth ? totalDynamicCellWidth : 1250;
        let width;
        if (numOfHeader < 10) {
            let _width = averageWidth / numOfHeader;
            width = _width >= 150 ? _width : 150;
        } else {
            width = 150;
        }
        return width + 'px';
    }

    public static getTotalFixedWidth(setting) {
        const widthList = [];
        Object.keys(setting.columns).map((key) => {
            if (setting.columns[key].groupBy) {
                widthList.push(parseInt(setting.columns[key].width.replace('px', '')));
            }
        });
        return widthList.reduce((partialSum, a) => partialSum + a, 0);
    }

    public static createFiltersParamsString(filters: Filter[]) {
        return filters.reduce((acc, curr) => {
            if (acc !== '') acc += '&';
            acc += `filterBy${curr.fieldKey}_${curr.operator}=${curr.value || curr.values?.join('|') || ''}`;
            return acc;
        }, '');
    }
}
