import {GeospatialService} from '../../helper/geospatial-service';
import {SpatialEntityData} from '../../../../../../pages/geospatial-viewer/model/api';
import {AssetPopupService} from '../../popups/asset-popup.service';
import {AssetRendererService} from '../../renderers/asset-renderer.service';
import {AssetFieldsService} from '../../fields/asset-fields.service';

import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Point from '@arcgis/core/geometry/Point';
import Graphic from '@arcgis/core/Graphic';
import {SpatialCardType} from '@theme/components/spatial-card/model/spatialCardType';

@Injectable()
export class PointLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private assetPopupService: AssetPopupService,
        private rendererService: AssetRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    /**
     * Create two point layers for asset population:
     * 1. Point "main" layer showing individual points
     * 2. Point "outline" layer to emphasize overlapping points
     * @return [FeatureLayer, FeatureLayer]
     */
    _preparePointSettings = {
        popupTemplate: true,
        labelingInfo: {
            expression: `if ($feature.count == 2)
                            {return Text('•')}`,
            labelPlacement: 'center-center',
            symbol: {
                type: 'text',
                color: 'black',
                font: {
                    weight: 'bold',
                    size: '12px',
                },
            },
        },
        mapType: null,
    };

    public preparePointLayers(
        pointData: SpatialEntityData[],
        id: string,
        theme: string,
        settings = this._preparePointSettings,
    ): FeatureLayer[] {
        // Zoomed in: render point asset layer (+ outline as separate layer), and linear asset layer distinctly
        const pointSource = this.createPointGraphics(pointData);
        let pointPopupTemplate = null;
        if (settings?.popupTemplate) {
            pointPopupTemplate = {
                outFields: ['*'],
                content: this.assetPopupService.getAssetPopupFunction.bind(this.assetPopupService),
            };
        }

        if (settings?.mapType === SpatialCardType.APP_REGISTRY) {
            pointPopupTemplate = {
                outFields: ['*'],
                title: '{id}',
                content: this.assetPopupService.defaultRenderAssetPopup.bind(this.assetPopupService),
            };
        }
        // ArcGIS does not support data-driven symbol / symbol.border, nor multi-variate rendering of symbols; use two layers.
        const pointAssetLayer = new FeatureLayer({
            title: 'asset_point_layer',
            id: id ? id : 'asset_point_layer',
            source: pointSource,
            renderer: this.rendererService.layerRenderer('simple-marker', theme, true),
            fields: this.fieldsService.getMapFields(),
            // @ts-ignore
            labelingInfo: settings.labelingInfo
                ? [
                      {
                          labelExpressionInfo: {
                              expression: settings.labelingInfo.expression,
                          },
                          // @ts-ignore
                          symbol: settings.labelingInfo.symbol,
                          // @ts-ignore
                          labelPlacement: settings.labelingInfo.labelPlacement,
                      },
                  ]
                : {},
            popupTemplate: pointPopupTemplate,
        });

        const pointAssetOutlineLayer = new FeatureLayer({
            id: (id ? id : 'asset_point_layer') + 'outline',
            source: pointSource,
            renderer: this.rendererService.pointLayerOverlapRenderer(),
            fields: this.fieldsService.getMapFields(),
            popupTemplate: pointPopupTemplate,
        });

        return [pointAssetLayer, pointAssetOutlineLayer];
    }

    /**
     * Create single point layer with "bubble" style reduction
     * @return FeatureLayer
     */
    public preparePointBubbleLayer(data: SpatialEntityData[], theme: string): FeatureLayer {
        const bubbleLayer = new FeatureLayer({
            id: 'asset_bubble_layer',
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createPointGraphics(data),
            renderer: this.rendererService.bubbleLayerRenderer(theme),
            fields: this.fieldsService.getMapFields(),
            labelingInfo: [
                {
                    labelExpressionInfo: {
                        expression: `Text($feature.count)`,
                    },
                    symbol: {
                        type: 'text',
                        color: '#444',
                        font: {
                            weight: 'normal',
                            size: '12px',
                        },
                    },
                    labelPlacement: 'center-center',
                },
            ],
        });
        return bubbleLayer;
    }

    /*
     * Create array of Graphics, type = point. Return Graphic[]
     */
    private createPointGraphics(pointData: SpatialEntityData[]): Graphic[] {
        return pointData.map((asset: SpatialEntityData) => {
            const point = new Point({
                latitude: asset.geoJsonGeometry.coordinates[1],
                longitude: asset.geoJsonGeometry.coordinates[0],
            });

            return new Graphic({
                geometry: point,
                attributes: {
                    // Unique identifiers
                    id: asset.id,
                    classCode: asset.classCode,
                    // Data
                    displayCategory: asset.displayCategory,
                    data: asset.data,
                    count: asset.count,
                },
            });
        });
    }
}
