import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {
    AnalyzerPopoutSettings,
    ForecasterPopoutSettings,
    OptimizerPopoutSettings,
    DataAuditPopoutSettings,
} from '@core/interfaces/common/popout';
import {APIResponse, FilterFieldOption} from '@core/interfaces/system/system-common';

@Injectable()
export class PopoutApi {
    private readonly apiController: string = 'popoutSettings';

    constructor(private api: HttpService) {}

    getAnalyzerPopoutSettings(): Observable<AnalyzerPopoutSettings> {
        return this.api.get(`${this.apiController}/get/analyzer`);
    }

    getFilterOptions(workflowItemId, physicalField, search = '', filters = []): Observable<FilterFieldOption[]> {
        return this.api.post(`analyzer/${workflowItemId}/filter-options`, {
            physicalField,
            search,
            filters,
        });
    }

    getOptimizerPopoutSettings(studyId: number, panelConfig: number): Observable<APIResponse<OptimizerPopoutSettings>> {
        return this.api.get(`${this.apiController}/get/optimizer/${studyId}/${panelConfig}`);
    }

    getForecasterPopoutSettings(): Observable<ForecasterPopoutSettings> {
        return this.api.get(`${this.apiController}/get/forecaster`);
    }

    getDataAuditToolPopoutSettings(): Observable<DataAuditPopoutSettings> {
        return this.api.get(`${this.apiController}/dataAudit`);
    }
}
