import {MapLayerDetails} from '../../../../../pages/geospatial-viewer/model/layers';
import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

export class RoadMoratoriumRendererService {
    public customLayerRenderer(details: MapLayerDetails[]): Renderer {
        return new UniqueValueRenderer({
            field: 'status',
            uniqueValueInfos: details.map((val) => {
                return {
                    value: val.code,
                    symbol:
                        val.symbol === 'line'
                            ? {
                                  type: 'simple-line',
                                  color: val.color,
                                  width: 3,
                              }
                            : {
                                  type: 'simple-marker',
                                  style: val.symbol,
                                  color: val.color,
                                  width: 3,
                              },
                };
            }),
        });
    }
}
