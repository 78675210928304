import {Injectable} from '@angular/core';
import {RequestTemplate} from './request-template';
import {AnalyzerRequest, AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {BehaviorSubject, Observable} from 'rxjs';
import {ReportDataService} from '@core/interfaces/engin/reporting/report-data';
import {ReportDashboard, ReportDashboardType} from '@core/interfaces/engin/reporting/report-config';

@Injectable()
export class ReportRequestComposer {
    constructor(private dataService: ReportDataService) {}

    public studySelected = new BehaviorSubject<boolean>(false);

    public pdfReportPageDisplayed = new BehaviorSubject<number>(null);
    public pdfReportPageDisplayed$ = this.pdfReportPageDisplayed.asObservable();

    retrieveDataResponse(workflowItemId: number, dashboard: ReportDashboard): Observable<AnalyzerResponse[]> {
        let req: AnalyzerRequest = RequestTemplate.configureReportRequest(
            dashboard.key,
            this.renderGroups(dashboard.group),
            dashboard.filters,
        );

        let res: Observable<AnalyzerResponse[]>;
        switch (dashboard.key) {
            case ReportDashboardType.EOL_METRICS_CALENDAR_AGE_PIE:
            case ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_PIE:
            case ReportDashboardType.EOL_METRICS_HEALTH_PIE:
            case ReportDashboardType.EOL_METRICS_ECONOMIC_PIE:
            case ReportDashboardType.EOL_METRICS_CALENDAR_AGE_BAR:
            case ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_BAR:
            case ReportDashboardType.EOL_METRICS_HEALTH_BAR:
            case ReportDashboardType.EOL_METRICS_ECONOMIC_BAR:
            case ReportDashboardType.DEMOGRAPHICS_CALENDAR_AGE:
            case ReportDashboardType.DEMOGRAPHICS_EFFECTIVE_AGE:
            case ReportDashboardType.DEMOGRAPHICS_HEALTH:
            case ReportDashboardType.DEMOGRAPHICS_ECONOMIC:
                res = this.dataService.getAnalyzerAbstractGraphData(req, workflowItemId);
                break;
            case ReportDashboardType.ACA_CURRENT_DATA_QUALITY:
                res = this.dataService.getAnalyzerCurrentDataQuality(req, workflowItemId);
                break;
            default:
                break;
        }

        return res;
    }

    private renderGroups(group: any) {
        return [
            {
                ...group,
                fieldKey: group.fieldKey,
                selected: true,
            },
        ];
    }
}
