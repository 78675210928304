import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {IconDetails} from '@core/interfaces/common/pages';
import {FileFormatTypeLabels} from '@core/interfaces/common/document';

@Component({
    selector: 'ngx-display-file',
    template: `
        <div class="display-file-container" *ngIf="showFile">
            <nb-icon
                [class.active-icon]="activeState"
                size="large"
                [pack]="icon.pack"
                [icon]="icon.icon"
                status="primary"
            ></nb-icon>
            <div class="file-detail">
                <ng-container *ngIf="showFileFormat; else hideFileFormat">
                    <p
                        class="file-name"
                        [nbTooltip]="name + '.' + FileFormatTypeLabels[file.fileFormat]"
                        nbTooltipStatus="control"
                    >
                        {{ name }}{{ '.' + FileFormatTypeLabels[file.fileFormat] }}
                    </p>
                </ng-container>
                <ng-template #hideFileFormat>
                    <p class="file-name" [nbTooltip]="name" nbTooltipStatus="control">{{ name }}</p>
                </ng-template>
                <p class="file-size" *ngIf="showFileSize">{{ getFileSize(size) }}</p>
            </div>
        </div>
    `,
    styleUrls: ['./display-file.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DisplayFileComponent implements OnInit {
    @Input() file: any;
    @Input() showFileSize: boolean = true;
    @Input() showFileFormat: boolean = true;
    @Input() activeState: boolean = true;
    name: string;
    size: number;
    icon: IconDetails;
    showFile: boolean;

    FileFormatTypeLabels = FileFormatTypeLabels;

    getIcon(format: string, active: boolean): IconDetails {
        format = format.toUpperCase();

        switch (format) {
            case '.CSV':
            case 'CSV':
                return {
                    pack: 'engin',
                    icon: active ? 'file-csv-active' : 'file-csv',
                };
            case '.XLSX':
            case 'XLSX':
            case 'MS_EXCEL':
                return {
                    pack: 'engin',
                    icon: active ? 'file-xlsx-active' : 'file-xlsx',
                };
            case '.PDF':
            case 'PDF':
                return {
                    pack: 'engin',
                    icon: 'file-pdf-active',
                };
            case '.PNG':
            case 'PNG':
                return {
                    pack: 'engin',
                    icon: 'file-png-active',
                };
            case '.JPG':
            case 'JPG':
                return {
                    pack: 'engin',
                    icon: 'file-jpg-active',
                };
            case '.JPEG':
            case 'JPEG':
                return {
                    pack: 'engin',
                    icon: 'file-jpeg-active',
                };
            default:
                return {
                    pack: 'eva',
                    icon: 'file-outline',
                };
        }
    }

    // copied from https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string/10420404 to save time
    getFileSize = (bytes, si = true, dp = 1) => {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10 ** dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

        return bytes.toFixed(dp) + ' ' + units[u];
    };

    ngOnInit(): void {
        this.name = this.file.name || this.file.fileName;
        this.size = this.file.fileSize || this.file.size;
        this.showFile = this.file.fileName !== null || this.file.name !== null;
        this.icon = this.getIcon(this.file.fileFormat, this.activeState);

        if (this.name.lastIndexOf('.') != -1) {
            this.name = this.name.substring(0, this.name.lastIndexOf('.'));
        }
    }
}
