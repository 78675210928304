<nb-card class="edit-dialog" *ngIf="tagNote">
    <nb-card-header>
        <p class="primary-header">{{ eventType === "Create" ? "Create" : "Edit" }} {{ title }}</p>

        <button ghost status="basic" nbButton (click)="close()">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <div class="row">
            <div class="col-4">
                <span class="col-form-label">{{ firstInfoBlock?.label || "Version -" }}</span>
                <span class="col-form-data">{{ firstInfoBlock?.value || tagNote.version }}</span>
            </div>
            <div class="col-8" *ngIf="tagNote.createdOn">
                <span class="col-form-label">Created On -</span>
                <span class="col-form-data">{{ tagNote.createdOn | localizeTime }}</span>
            </div>
        </div>

        <form [formGroup]="tagNoteUpdateForm">
            <label for="tag">Tag</label>
            <input id="tag" formControlName="tag" fullWidth placeholder="Enter Tag" nbInput />
            <ngx-input-error [formGroup]="tagNoteUpdateForm" fieldName="tag"></ngx-input-error>

            <label for="note">Note</label>
            <textarea formControlName="note" fullWidth id="note" nbInput placeholder="Text area"></textarea>
            <ngx-input-error [formGroup]="tagNoteUpdateForm" fieldName="note"></ngx-input-error>

            <div class="btn-container">
                <button nbButton status="basic" (click)="close()">
                    <nb-icon icon="close-circle-outline"></nb-icon>
                    Cancel
                </button>

                <button nbButton status="primary" (click)="save()">
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                    Save
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>
