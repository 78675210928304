import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HttpCancelService {
    private cancelPendingRequests = new Subject<void>();
    private forceCancelPendingRequests = new Subject<void>();

    cancelRequests() {
        this.cancelPendingRequests.next();
    }

    forceCancelRequests() {
        this.forceCancelPendingRequests.next();
    }

    public onCancelPendingRequests() {
        return this.cancelPendingRequests.asObservable();
    }

    public onForceCancelPendingRequests() {
        return this.forceCancelPendingRequests.asObservable();
    }
}
