import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

export enum FileFormatType {
    MS_EXCEL = 'MS_EXCEL',
    CSV = 'CSV',
    JPG = 'JPG',
    JPEG = 'JPEG',
    PNG = 'PNG',
    PDF = 'PDF',
    INVALID = 'INVALID',
}

export const FileFormatTypeLabels = {
    [FileFormatType.MS_EXCEL]: 'xlsx',
    [FileFormatType.CSV]: 'csv',
    [FileFormatType.JPG]: 'jpg',
    [FileFormatType.JPEG]: 'jpeg',
    [FileFormatType.PNG]: 'png',
    [FileFormatType.PDF]: 'pdf',
    [FileFormatType.INVALID]: '',
};

export interface Document {
    id: number;
    createdOn: Date;
    updatedOn: Date;
    fileFormat: FileFormatType;
    fileName: string;
    fileKey: string;
    fileSize: number;
    bucketName: string;
    datasetType: string;
    relatedEntity: string;
    userName: string;
    userId: string;
    tag: string;
}

export interface DocumentCreate {
    fileFormat: FileFormatType;
    fileName: string;
    fileKey: string;
    fileSize: number;
    bucketName: string;
    documentType: string;
    relatedEntity: string;
    userName: string;
    userId: string;
    tag: string;
    description: string;
}

export interface DocumentAssetImage {
    id: number;
    assetId: number;
    fileName: string;
    description: string;
    tag: string;
    url: string;
    fileFormat?: FileFormatType;
}

export abstract class DocumentService {
    abstract listFiles(assetId: string): Observable<DataSource>;
    /*
     * Operational
     */
    abstract create(document: DocumentCreate): Observable<APIResponse<Document>>;

    abstract update(documentId: number, document: any): Observable<APIResponse<Document>>;

    abstract delete(documentId: number): Observable<APIResponse<boolean>>;

    abstract getPresignedUrl(fileName: string, fileFormat: string): Observable<APIResponse<PresignedURL>>;

    abstract uploadToPresignedUrl(url: string, data: File);

    /*
     * Retrieval
     */
    abstract getAssetImageDocuments(assetId: string): Observable<APIResponse<DocumentAssetImage[]>>;

    abstract findOne(documentId: string): Observable<APIResponse<Document>>;

    abstract downloadDocumentsByIds(documentIds: number[]): Observable<APIResponse<string[]>>;

    abstract downloadDocumentsById(documentId: number): Observable<string>;

    abstract getSearchTag(searchString: string): Observable<string[]>;
    abstract getAllTags(assetId: string): Observable<APIResponse<string[]>>;
}
