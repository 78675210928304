import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs';
import {UserSettingPageMode} from '../../../../pages/users/user/profile-settings/general/general.component';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';

/**
 * Force password component
 * - Requires auth (must be logged in)
 * - User enters new password to force reset password for a different (target) account. e.g. admin password reset.
 */
@Component({
    selector: 'ngx-password-force',
    templateUrl: './password-force.component.html',
    styleUrls: ['./password-force.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordForceComponent {
    @Input() form: FormGroup;
    @Input() pageMode: UserSettingPageMode;
    @Input() validation$: Observable<PasswordRequirements>;
    @Input() layoutCompressed: boolean = false;
    @Input() indicateRequired: boolean = false;
    @Output() onInputChanges = new EventEmitter();

    get password(): FormControl {
        return this.form.get('password') as FormControl;
    }

    onPasswordChanged($event) {
        this.onInputChanges.emit({password: $event.target.value});
    }
}
