import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    AssetInspectionResultDto,
    FieldType,
    FormField,
    FormSection,
    FormViewModeType,
    InspectionResultFullDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {FormService} from '@theme/components/form/form.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormCanvasComponent} from '@theme/components/form/form-canvas/form-canvas.component';
import {FormMode} from '@core/interfaces/engin/maintenance-planning/maintenance-planning';
import {S3Service} from '@core/interfaces/common/s3';
import {distinctUntilChanged} from 'rxjs/operators';

export enum ResultFieldTypes {
    NUMERIC = 'numberFields',
    TEXT = 'textFields',
    SINGLE_SELECT = 'selectFields',
    MULTI_SELECT = 'selectFields',
    CHECKBOX = 'checkboxFields',
    IMAGE = 'mediaFields',
    VIDEO = 'mediaFields',
    IMAGE_VIDEO = 'mediaFields',
    SIGNATURE = 'mediaFields',
    ISSUES = 'issueFields',
}
export enum ButtonActionType {
    Next = 'next',
    Prev = 'prev',
}
@Component({
    selector: 'ngx-form-parent',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [FormService],
})
export class FormComponent extends Unsubscribable implements OnInit, AfterViewInit {
    @ViewChild(FormCanvasComponent) formCanvasComponent: FormCanvasComponent;
    private multipleSelectionType = [FieldType.MULTI_SELECT, FieldType.IMAGE_VIDEO, FieldType.IMAGE, FieldType.VIDEO];
    private supportTypes = [
        FieldType.NUMERIC,
        FieldType.TEXT,
        FieldType.CHECKBOX,
        FieldType.SINGLE_SELECT,
        FieldType.MULTI_SELECT,
        FieldType.IMAGE,
        FieldType.TEXT,
        FieldType.NUMERIC,
        FieldType.SINGLE_SELECT,
        FieldType.VIDEO,
        FieldType.SIGNATURE,
    ];
    @Input() dataSource: InspectionResultFullDto;
    @Input() viewMode: FormViewModeType = FormViewModeType.EDIT_RESULTS;
    @Input() pageMode: Observable<FormMode> = new BehaviorSubject<FormMode>(null);
    @Input() s3service: S3Service;
    @Output() formValueChangeEvent = new EventEmitter();
    @Output() completedRequiredFields = new EventEmitter();
    @Output() scrollToTop = new EventEmitter();
    completedRequiredFieldsProgress = new BehaviorSubject<number>(0);
    continueEvent: number = 0;
    FormViewModeType = FormViewModeType;
    showPreviousValues: boolean;
    sectionRequiredStatus: FormGroup = this.fb.group({
        total: 0,
        completed: 0,
    });
    totalRequiredStatus: FormGroup = this.fb.group({
        total: 0,
        completed: 0,
    });
    activeSection: FormSection;
    formSections: FormSection[] = [];
    currentFormResults: AssetInspectionResultDto;
    previousFormResults: AssetInspectionResultDto;
    currentDisplayFields: AbstractControl[] = [];
    fieldResultForm: FormGroup = this.fb.group({});
    formIsReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    lastSubSection: boolean = false;
    firstSection: boolean = false;
    nextBtnDisabled: boolean = false;
    previousBtnDisabled: boolean = false;
    totalRequiredValue: FormField[] = [];
    passedRequiredFieldsList: BehaviorSubject<FormField[]> = new BehaviorSubject<FormField[]>([]);

    buttonAction: ButtonActionType = ButtonActionType.Next;

    constructor(protected cd: ChangeDetectorRef, private fb: FormBuilder, private formService: FormService) {
        super();
    }

    ngOnInit(): void {
        this.initProcess();
        this.totalRequiredStatus.valueChanges.pipe(distinctUntilChanged()).subscribe((value) => {
            const progressBarValue = (value.completed / value.total) * 100;
            this.completedRequiredFieldsProgress.next(Math.round(progressBarValue));
            this.totalRequiredStatusEmitEvent();
        });
    }

    private initProcess(): void {
        // Adjust based on view mode
        switch (this.viewMode) {
            case FormViewModeType.VIEW_CONFIG:
                this.showPreviousValues = false;
                break;
            case FormViewModeType.VIEW_RESULTS:
                this.showPreviousValues = false;
                break;
            case FormViewModeType.EDIT_RESULTS:
                this.showPreviousValues = true;
                break;
        }
        this.showPreviousValues = true;
        this.currentFormResults = this.dataSource?.currentAssetInspectionResult;
        this.previousFormResults = this.dataSource?.previousAssetInspectionResult;
        this.dataSource?.formResponse.sections.forEach((section: FormSection) => {
            this.formSections.push(section);
            if (this.viewMode == FormViewModeType.EDIT_RESULTS) {
                this.initSection(section);
            }
            this.formIsReady$.next(true);
        });
    }

    // Initialize form control for all form fields, for a particular section (recursive)
    private initSection(s: FormSection): void {
        if (s.sections?.length > 0) {
            s.sections.forEach((sub) => this.initSection(sub));
        }
        if (s.fields?.length > 0) {
            this.initFields(s.fields);
        }
    }

    // Initialize form control for all form fields
    private initFields(fields: FormField[]): void {
        fields.forEach((f) => {
            if (this.supportTypes.includes(f.fieldType)) {
                if (this.formService.requiredCheck(f, this.currentFormResults)) {
                    this.totalRequiredValue.push(f);
                }
                this.fieldResultForm.addControl(
                    f.id + '',
                    this.fb.control(
                        {value: this.formService.getResultsByField(f, this.currentFormResults)}, // set the form control getting it from the form service?
                        this.formService.requiredCheck(f, this.currentFormResults) ? Validators.required : null,
                    ),
                );
            }
        });
    }

    public selectionChanged(activeSection): void {
        const selectedSection = this.dataSource?.formResponse.sections.find(
            (section: FormSection) => section.id === activeSection.sectionId,
        );

        // Set subsection as active if applicable, otherwise section
        if (activeSection.subsectionId && activeSection.subsectionId != -1) {
            this.activeSection = selectedSection.sections.find(
                (subsection: FormSection) => subsection.id === activeSection.subsectionId,
            );
        } else {
            this.activeSection = selectedSection;
        }
        this.checkFormPosition();
        this.getCurrentDisplayFields();
        this.updateSectionRequiredValues();
        this.nextBtnDisabled = this.lastSubSection;
        this.previousBtnDisabled = this.firstSection;
        this.cd.detectChanges();
    }

    private checkFormPosition(): void {
        const section = this.dataSource?.formResponse.sections;
        const firstObject = section[0];
        const firstSubSection = firstObject.sections[0];
        const lastObject = section[section.length - 1];
        const lastSubSection = lastObject.sections[lastObject.sections.length - 1];
        this.lastSubSection =
            JSON.stringify(this.activeSection) === JSON.stringify(lastObject) ||
            JSON.stringify(this.activeSection) === JSON.stringify(lastSubSection);
        this.firstSection =
            JSON.stringify(this.activeSection) === JSON.stringify(firstObject) ||
            JSON.stringify(this.activeSection) === JSON.stringify(firstSubSection);
    }

    private getCurrentDisplayFields(): void {
        const fieldIdArray = [];
        this.currentDisplayFields = [];
        if (this.activeSection?.fields?.length > 0) {
            this.activeSection.fields.forEach((field) => {
                if (this.formService.requiredCheck(field, this.currentFormResults)) {
                    fieldIdArray.push(field.id.toString());
                }
            });
        }
        if (this.activeSection?.sections?.length > 0) {
            this.activeSection.sections.forEach((section) => {
                section.fields.map((field) => {
                    if (this.formService.requiredCheck(field, this.currentFormResults)) {
                        fieldIdArray.push(field.id.toString());
                    }
                });
            });
        }
        Object.keys(this.fieldResultForm.controls).forEach((key) => {
            const control = this.fieldResultForm.get(key);
            if (fieldIdArray.includes(key) && control.validator) {
                this.currentDisplayFields.push(control);
            }
        });
    }

    public formValueChange(updateValue): void {
        this.currentFormResults = this.updateOrAddField(updateValue);
        this.updateSectionRequiredValues();
        const validation = this.currentDisplayFields.every((control) => control.status === 'VALID');
        this.resultEmitEvent();
        if (validation && this.lastSubSection) {
            this.formCanvasComponent.checkValidationEvent(false);
        }
        this.updateTotalRequiredValues();
        this.cd.detectChanges();
    }

    private updateOrAddField(newFields): AssetInspectionResultDto {
        const selectedField = ResultFieldTypes[newFields.fieldType];
        const updateField = {
            ...newFields,
            value: this.ensureArray(newFields.value),
        };
        const index = this.currentFormResults[selectedField].findIndex((item) => item.fieldId === newFields.fieldId);
        const multipleSelector = this.multipleSelectionType.includes(newFields.fieldType);
        if (index !== -1) {
            if (multipleSelector) {
                // Remove all values with matched fieldId in the original array and add new values
                this.currentFormResults[selectedField] = this.currentFormResults[selectedField].filter(
                    (item) => item?.fieldId !== newFields.fieldId,
                );
                updateField.value.forEach((item) => {
                    this.currentFormResults[selectedField].push(this.resultDto(item, updateField));
                });
            } else {
                if (this.checkEmptyValue(updateField)) {
                    // remove the field if value is empty
                    this.currentFormResults[selectedField] = this.currentFormResults[selectedField].filter(
                        (item) => item.fieldId !== updateField.fieldId,
                    );
                } else {
                    // Update existing field
                    updateField.value.forEach((item) => {
                        this.currentFormResults[selectedField][index] = this.resultDto(item, updateField);
                    });
                }
            }
        } else {
            // Add new field
            updateField.value.forEach((item) => {
                this.currentFormResults[selectedField].push(this.resultDto(item, updateField));
            });
        }
        const originData = this.currentFormResults;
        const updatedData = {
            formId: originData.formId,
            assetId: originData.assetId,
            completedOn: originData.completedOn,
            completedBy: originData.completedBy,
            completedByUserId: originData.completedByUserId,
            ...this.currentFormResults,
        };
        return updatedData;
    }
    private checkEmptyValue(updateField) {
        switch (updateField.fieldType) {
            case FieldType.TEXT:
            case FieldType.NUMERIC:
                return updateField.value[0]?.value === '';
            default:
                return false;
        }
    }
    private resultDto(item, newFields) {
        switch (newFields.fieldType) {
            case FieldType.TEXT:
            case FieldType.CHECKBOX:
                return {
                    fieldId: newFields.fieldId,
                    value: item.value,
                };
            case FieldType.NUMERIC:
                let valueType: string;
                if (Number.isInteger(item.value)) {
                    valueType = 'INTEGER';
                } else {
                    valueType = 'DOUBLE';
                }
                return {
                    fieldId: newFields.fieldId,
                    value: item.value,
                    valueType,
                };
            case FieldType.IMAGE:
            case FieldType.VIDEO:
            case FieldType.IMAGE_VIDEO:
            case FieldType.SIGNATURE:
                return {
                    fieldId: newFields.fieldId,
                    fileKey: item.fileKey,
                    fileName: item.fileName,
                    fileFormat: item.fileFormat,
                    fileSize: item.fileSize,
                    url: item.url,
                    assetFromId: this.dataSource.assetFormId,
                };
            case FieldType.SINGLE_SELECT:
            case FieldType.MULTI_SELECT:
                return {
                    fieldId: newFields.fieldId,
                    optionId: item.optionId,
                    optionType: item.optionType,
                    assetFromId: this.dataSource.assetFormId,
                };
        }
    }
    public previous(): void {
        this.pageMode.subscribe((pageMode) => {
            this.buttonAction = ButtonActionType.Prev;
            this.continueEvent = this.continueEvent - 1;
            this.cd.detectChanges();
        });
        this.scrollToTop.emit();
    }
    public next(): void {
        this.pageMode.subscribe((pageMode) => {
            switch (pageMode) {
                case FormMode.EDIT:
                    this.formCanvasComponent.checkValidationEvent(true);
                    this.resultEmitEvent();
                    this.buttonAction = ButtonActionType.Next;
                    this.continueEvent = this.continueEvent + 1;
                    this.formCanvasComponent.checkValidationEvent(false);
                    this.cd.detectChanges();
                    break;
                default:
                    this.buttonAction = ButtonActionType.Next;
                    this.continueEvent = this.continueEvent + 1;
                    this.cd.detectChanges();
                    break;
            }
        });
        this.scrollToTop.emit();
    }

    private ensureArray(value) {
        return Array.isArray(value) ? value : [value];
    }

    private updateSectionRequiredValues() {
        const completedValue = this.currentDisplayFields.filter((control) => control.status === 'VALID');
        this.sectionRequiredStatus.setValue({
            total: this.currentDisplayFields.length,
            completed: completedValue.length,
        });
        this.cd.detectChanges();
    }

    private updateTotalRequiredValues() {
        if (this.currentFormResults) {
            const totalRequiredValues = [];
            Object.keys(this.fieldResultForm.controls).forEach((key) => {
                const control = this.fieldResultForm.get(key);
                if (control.validator) {
                    const validatorFn = control.validator({} as AbstractControl);
                    if (validatorFn && validatorFn.required) {
                        totalRequiredValues.push({control, id: key});
                    }
                }
            });

            const valueArr = [];
            Object.keys(this.currentFormResults).forEach((key) => {
                if (typeof this.currentFormResults[key] === 'object') {
                    this.currentFormResults[key].forEach((item) => {
                        valueArr.push(item.fieldId);
                    });
                }
            });
            const uniqueValueArr = [...new Set(valueArr)];
            this.passedRequiredFieldsList.next(uniqueValueArr);
            this.totalRequiredStatus.setValue({
                total: totalRequiredValues.length,
                completed: this.totalRequiredValue.filter((item) => uniqueValueArr.includes(item.id)).length,
            });
            this.cd.detectChanges();
        }
    }

    ngAfterViewInit(): void {
        this.updateTotalRequiredValues();
        this.updateSectionRequiredValues();
    }
    resultEmitEvent() {
        this.formValueChangeEvent.emit(this.currentFormResults);
        this.totalRequiredStatusEmitEvent();
    }
    totalRequiredStatusEmitEvent() {
        this.completedRequiredFields.emit(this.totalRequiredStatus.value);
    }
}
