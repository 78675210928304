import {Component, Input} from '@angular/core';
import {SimpleUnit} from '@core/interfaces/system/system-common';
import {FormatsService} from '@core/utils';

export enum KpiOptions {
    Increasing = 'increasing',
    Decreasing = 'decreasing',
}
export interface KpiData {
    title: String;
    subtitle?: String;
    kpiDirection: KpiOptions;
    deltaPercent: number;
    deltaValue: number | string;
    unit: SimpleUnit;
    baseValue?: number;
    residualValue?: number;
}

@Component({
    selector: 'ngx-chart-kpi',
    templateUrl: './chart-kpi.component.html',
    styleUrls: ['./chart-kpi.component.scss'],
})
export class ChartKpiComponent {
    @Input() wide = false;
    @Input() get data(): KpiData {
        return this._data;
    }
    set data(value: KpiData) {
        this._data = {
            ...value,
            deltaPercent: Math.round(value.deltaPercent),
            baseValue: value.baseValue
                ? FormatsService.prepareValue(value.baseValue, value.unit.prefix, value.unit.suffix)
                : value.baseValue,
            residualValue: value.residualValue
                ? FormatsService.prepareValue(value.residualValue, value.unit.prefix, value.unit.suffix)
                : value.residualValue,
        };

        this.setDeltaColor(this._data.deltaPercent);
    }

    public readonly KpiOptions = KpiOptions;

    public deltaColor = '';
    private _data;

    setDeltaColor(deltaPercent) {
        switch (true) {
            case deltaPercent > 66:
                this.deltaColor = 'color-gradient-category-5';
                break;
            case deltaPercent > 33:
                this.deltaColor = 'color-gradient-category-4';
                break;
            case deltaPercent <= 33:
                this.deltaColor = 'color-gradient-category-3';
                break;
        }
    }
}
