import {AdditionalMapLayer, MapLayerDetails} from '../../../../../../pages/geospatial-viewer/model/layers';
import {CustomerDemographicData} from '../../../../../../pages/geospatial-viewer/model/api';
import {CustomerDemographicFieldsService} from '../../fields/customer-demographic-fields.service';
import {CustomerDemographicRendererService} from '../../renderers/customer-demographic-renderer.service';
import {CustomerDemographicPopupService} from '../../popups/customer-demographic-popup.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polygon from '@arcgis/core/geometry/Polygon';

@Injectable()
export class CustomerDemographicLayerService {
    constructor(
        private fieldsService: CustomerDemographicFieldsService,
        private popupService: CustomerDemographicPopupService,
        private rendererService: CustomerDemographicRendererService,
    ) {}

    public prepareCustomerDemographicLayer(
        layer: AdditionalMapLayer,
        data: CustomerDemographicData[],
        details: MapLayerDetails[],
    ): FeatureLayer {
        const customerDemographicLayer: FeatureLayer = new FeatureLayer({
            id: layer.code,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data),
            renderer: this.rendererService.customLayerRenderer(),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                outFields: ['*'],
                content: this.popupService.getCustomPopoutFunction,
            },
        });

        return customerDemographicLayer;
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createGraphics(data: CustomerDemographicData[]): Graphic[] {
        return data.map((demo: CustomerDemographicData) => {
            return new Graphic({
                geometry: new Polygon({
                    rings: demo.geoJsonGeometry.coordinates,
                }),
                attributes: {
                    // Unique identifiers
                    ObjectID: demo.id,
                    // Data
                    type: 'empty',
                },
            });
        });
    }
}
