import {GeospatialService} from '../../helper/geospatial-service';
import {SpatialEntityData} from '../../../../../../pages/geospatial-viewer/model/api';
import {AssetPopupService} from '../../popups/asset-popup.service';
import {AssetRendererService} from '../../renderers/asset-renderer.service';
import {AssetFieldsService} from '../../fields/asset-fields.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polyline from '@arcgis/core/geometry/Polyline';
import {AssetGroupPopupService} from '../../popups/asset-group-popup.service';

@Injectable()
export class PolylineLayerService {
    constructor(
        private geospatialService: GeospatialService,
        private assetPopupService: AssetPopupService,
        private assetGroupPopupService: AssetGroupPopupService,
        private rendererService: AssetRendererService,
        private fieldsService: AssetFieldsService,
    ) {}

    /**
     * Prepare polyline layer (geometry type = polyline).
     * Works for point assets (lines) or regions (multi-lines).
     * @return FeatureLayer
     */
    public preparePolylineLayer(
        assetType: boolean,
        linearData: SpatialEntityData[],
        id: string,
        theme: string,
    ): FeatureLayer {
        let popupTemplate;
        if (assetType) {
            popupTemplate = {
                outFields: ['*'],
                content: this.assetPopupService.getAssetPopupFunction.bind(this.assetPopupService),
            };
        } else {
            popupTemplate = {
                outFields: ['*'],
                content: this.assetGroupPopupService.getAssetGroupPopupFunction.bind(this.assetGroupPopupService),
            };
        }
        // Load Esri dependencies from AMD
        const source = this.createPolylineGraphics(linearData);
        const polylineLayer = new FeatureLayer({
            id: id ? id : 'asset_line_layer',
            objectIdField: 'ObjectID',
            source: source,
            renderer: this.rendererService.layerRenderer('simple-line', theme),
            fields: this.fieldsService.getMapFields(),
            popupTemplate: popupTemplate,
        });

        return polylineLayer;
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createPolylineGraphics(lineData: SpatialEntityData[]): Graphic[] {
        return lineData.map((area, i) => {
            const geometry = new Polyline({
                paths: area.geoJsonGeometry.coordinates,
            });
            return new Graphic({
                geometry: geometry,
                attributes: {
                    // Unique identifiers
                    id: area.id,
                    classCode: area.classCode,
                    // Data
                    displayCategory: area.displayCategory,
                    data: area.data,
                    count: area.count,
                },
            });
        });
    }
}
