import {Injectable} from '@angular/core';
import {HttpService} from './http.service';
import {Observable} from 'rxjs';

@Injectable()
export class UsageAnalyticsApi {
    private readonly apiController: string = 'usage-analytics';

    constructor(private api: HttpService) {}

    trackEvent(event): Observable<any> {
        return this.api.post(`${this.apiController}/track-event`, event);
    }
}
