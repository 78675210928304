import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {debounceTime, shareReplay, switchMap, tap, takeUntil, map} from 'rxjs/operators';
import {OptimizerService, OptimizerAnalysisRequest} from '@core/interfaces/engin/optimizer';
import {StudiesStore} from '../common/studies.store';
import {OptimizerPopoutStore} from './optimizer-popout.store';
import {User} from '@core/interfaces/common/users';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {PagesStore} from '../config/pages.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbToastrService} from '@nebular/theme';
import {UsersStore} from '@store/common/users.store';
import {ProbabilityTypeEnum} from '@core/interfaces/engin/analyzer';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class OptimizerStore extends Unsubscribable {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    public pdfHrToggle$: BehaviorSubject<ProbabilityTypeEnum> = new BehaviorSubject<ProbabilityTypeEnum>(
        ProbabilityTypeEnum.PDF,
    );

    private combineAnalysisRequestInfo$ = combineLatest<
        Observable<OptimizerAnalysisRequest>,
        Observable<number>,
        Observable<User>
    >([
        this.optimizerPopoutStore.serverPopout$,
        this.studiesStore.activeStudyIdRisk$,
        this.usersStore.currentUser$,
    ]).pipe(shareReplay(1));

    // Check if calculations have been run before fetching any results
    readonly checkResultsReady$: Observable<boolean> = this.combineAnalysisRequestInfo$.pipe(
        tap(() => this.resultsLoading.next(true)),
        debounceTime(2000), // await cascading changes since serverPopout$ depends on activeStudyId$
        switchMap(([popoutValue, studyId, user]) => {
            const userId = user.id;
            const stateChanged = this.optimizerPopoutStore.stateChanged.value;
            this.optimizerPopoutStore.stateChanged.next({value: false, events: false});
            const req = this.prepareOptimizerCalculationRequest(popoutValue, stateChanged.value, studyId, userId);
            return this.optimizerService.checkOptimizerResultsReady(req);
        }),
        takeUntil(this.unsubscribe$),
        map((resp: APIResponse<boolean>) => resp.response),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        shareReplay(1),
    );

    // TODO: get current status, filter for true; get data load, filter for false
    readonly combineChartRequestInfo$ = combineLatest<
        Observable<number>,
        Observable<User>,
        Observable<DisplaySettings>,
        Observable<boolean>,
        Observable<ProbabilityTypeEnum>
    >([
        this.studiesStore.activeStudyIdRisk$,
        this.usersStore.currentUser$,
        this.pagesStore.currentDisplay$,
        this.checkResultsReady$,
        this.pdfHrToggle$,
    ]).pipe(shareReplay(1));

    private prepareOptimizerCalculationRequest(
        popoutValue: OptimizerAnalysisRequest,
        stateChanged: boolean,
        studyId: number,
        userId?: string,
    ): OptimizerAnalysisRequest {
        const req = popoutValue;
        req.sensitivityStudy = {
            studyId: studyId,
            userId: userId,
            sensitivityParams: null,
            currentYear: new Date().getFullYear(),
            evaluationPeriod: 10,
        };
        req.stateChanged = stateChanged;

        return req;
    }

    constructor(
        private optimizerService: OptimizerService,
        private studiesStore: StudiesStore,
        private optimizerPopoutStore: OptimizerPopoutStore,
        protected toastrService: NbToastrService,
        private usersStore: UsersStore,
        private pagesStore: PagesStore,
    ) {
        super();
    }
}
