<!-- Icon in sidebar -->
<ng-container>
    <div class="help-support-menu">
        <div class="menu-items">
            <!-- Collapsed -->
            <div
                *ngIf="!expanded; else expended"
                [nbPopover]="labelBox"
                [nbPopoverContext]="'Help Desk'"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
            >
                <div class="menu-item-container">
                    <ngx-icon-box
                        class="menu-item-link"
                        (click)="openSupportDialog()"
                        [icon]="'question-mark-circle'"
                    ></ngx-icon-box>
                </div>
            </div>

            <!-- Expanded -->
            <ng-template #expended>
                <div class="menu-item-container">
                    <ngx-icon-box
                        class="menu-item-link"
                        (click)="openSupportDialog()"
                        *ngIf="expanded"
                        [title]="'Help'"
                        [icon]="'question-mark-circle'"
                    ></ngx-icon-box>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>

<ng-template #labelBox let-text>
    <ngx-help-support-side-bar
        [expanded]="expanded"
        [text]="text"
        (openSupportDialog)="openSupportDialog()"
    ></ngx-help-support-side-bar>
</ng-template>
