<nb-card>
    <nb-card-header class="spb">
        <div>
            <p class="primary-header">Upload issue log</p>
            <p class="sub-header" *ngIf="fileName">{{ fileName }}</p>
        </div>

        <button ghost status="basic" nbButton (click)="close()">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body
        *ngIf="errorList$"
        [nbSpinner]="!(errorList$ | async)"
        nbSpinnerSize="medium"
        nbSpinnerStatus="primary"
    >
        <ngx-smart-table [settings]="tableSettings" [source]="errorList$ | async"></ngx-smart-table>
    </nb-card-body>

    <nb-card-footer *ngIf="errorList$ | async as errorList">
        <button nbButton status="info" (click)="downloadFile(errorList)">Download</button>
    </nb-card-footer>
</nb-card>
