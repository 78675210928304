<div class="chart-settings-container">
    <div class="chart-settings-items">
        <!-- Units selector -->
        <ng-container *ngIf="unitsEnabled() && unitOptions as settings">
            <p class="section-title">Units</p>
            <nb-radio-group name="group1" [value]="unitItem.unit" (valueChange)="unitChanges($event)">
                <nb-radio class="unit-selector-item" *ngFor="let unit of settings" [value]="unit.value">
                    {{ unit.label }}
                </nb-radio>
            </nb-radio-group>

            <div
                *ngIf="(!disableDataDownload && chartOptions && chartType) || preparedDownloadData"
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Label selections -->
        <ng-container *ngIf="showLabelSelection && graphLabels.options as labelSettings">
            <p class="section-title">Labels</p>
            <nb-radio-group name="group2" [value]="labelSelected" (valueChange)="labelChanged($event)">
                <nb-radio class="unit-selector-item" *ngFor="let label of labelSettings" [value]="label.value">
                    {{ label.label }}
                </nb-radio>
            </nb-radio-group>
            <div
                *ngIf="(!disableDataDownload && chartOptions && chartType) || preparedDownloadData"
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Custom selection -->
        <ng-container *ngIf="customSettings">
            <p class="section-title">{{ customSettingsLabel }}</p>
            <nb-radio-group
                name="group3"
                [value]="customSelected"
                (valueChange)="customSettingsValueChanged.emit($event)"
            >
                <nb-radio class="unit-selector-item" *ngFor="let unit of customSettings" [value]="unit.value">
                    {{ unit.label }}
                </nb-radio>
            </nb-radio-group>

            <div
                *ngIf="(!disableDataDownload && chartOptions && chartType) || preparedDownloadData"
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Axis selections -->
        <ng-container *ngIf="showAxisSelection && axisOptions as axisSettings">
            <p class="section-title">Axis Settings</p>
            <nb-radio-group name="group3" [formControl]="axisSelected" (valueChange)="axisChanged($event)">
                <nb-radio class="unit-selector-item" *ngFor="let axis of axisSettings" [value]="axis.value">
                    {{ axis.label }}
                </nb-radio>
            </nb-radio-group>
            <div
                *ngIf="(!disableDataDownload && chartOptions && chartType) || preparedDownloadData"
                class="section-divider"
            ></div>
        </ng-container>

        <!-- Data download -->
        <ng-container *ngIf="(!disableDataDownload && chartOptions && chartType) || preparedDownloadData">
            <p class="section-title">Export</p>
            <div class="download-menu-item" (click)="dataDownloadCSV()">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">CSV</span>
            </div>
            <div class="download-menu-item" (click)="dataDownloadImage('jpg')">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">JPG</span>
            </div>
            <div class="download-menu-item" (click)="dataDownloadImage('png')">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">PNG</span>
            </div>
        </ng-container>
    </div>

    <div class="selector-arrow"></div>
</div>
