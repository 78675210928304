import {Component} from '@angular/core';

/**
 Empty password component i.e. for SSO users
 */
@Component({
    selector: 'ngx-password-empty-sso',
    templateUrl: './password-empty-sso.component.html',
    styleUrls: ['./password-empty-sso.component.scss'],
})
export class PasswordEmptySsoComponent {
    constructor() {}
}
