import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {LoadForecastSettings} from '@core/interfaces/engin/load-forecast/load-forecast';
import {SensitivityConfiguration} from '@store/load-forecast/sensitivity-analysis.store';

@Injectable()
export class LoadForecastApi {
    private readonly PREFIX_SETTINGS = 'load-forecast/settings';
    private readonly PREFIX_SENSITIVITY = 'load-forecast/sensitivity';

    constructor(private api: HttpService) {}

    public initSettingsByWorkflowItem(workflowItemId: number): Observable<LoadForecastSettings> {
        return this.api.get(`${this.PREFIX_SETTINGS}/${workflowItemId}`);
    }

    public executeSensitivityAnalysis(workflowItemId: number, req: SensitivityConfiguration): Observable<any> {
        return this.api.post(`${this.PREFIX_SENSITIVITY}/${workflowItemId}`, req);
    }
}
