import {MapColoursService} from './map-colours.service';
import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import {NbThemeService} from '@nebular/theme';
import {Injectable} from '@angular/core';

@Injectable()
export class DistrictSummarizeRendererService {
    constructor(private nbThemeService: NbThemeService) {}

    public customLayerRenderer(): Renderer {
        const theme = this.nbThemeService.currentTheme;
        return new UniqueValueRenderer({
            field: 'displayCategory',
            uniqueValueInfos: MapColoursService.list(theme).map((item) => {
                return {
                    value: item.value,
                    symbol: {
                        type: 'simple-fill',
                        color: `rgba(${item.colorList[0]}, ${item.colorList[1]}, ${item.colorList[2]}, 0.2)`,
                        width: 3,
                        outline: {
                            color: item.color,
                        },
                    },
                };
            }),
        });
    }
}
