import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {Workflow, WorkflowItem, WorkflowType} from '@core/interfaces/engin/workflow';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {SnapshotDto} from '@core/interfaces/common/snapshot';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {WorkflowInfo} from '@core/interfaces/common/users';

@Injectable()
export class WorkflowApi {
    private readonly prefix = 'workflow';

    constructor(private api: HttpService) {}

    getWorkflowOverviewDataSource(activeWorkflowIds: number[]): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list/overview?activeWorkflowIds=${activeWorkflowIds}`);
    }

    getWorkflowDataSource(type: WorkflowType): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list?filterByworkflowType_EQUAL=${type.toLowerCase()}`);
    }

    getWorkflowById(workflowId: number): Observable<APIResponse<Workflow>> {
        return this.api.get(`${this.prefix}/find/${workflowId}`);
    }

    getWorkflowItemById(workflowItemId: number): Observable<APIResponse<WorkflowItem>> {
        return this.api.get(`${this.prefix}/find/workflowItem/${workflowItemId}`);
    }

    executeNewWorkflowFromSnapshot(
        snapshotId: number,
        workflowType: string,
        automated: boolean,
        date: string,
    ): Observable<any> {
        const description = workflowType + (automated ? ' Data refresh ' : ' Run on ') + date.slice(0, 10);
        const name = (automated ? 'Automated multi-item ' : 'Manual multi-item ') + workflowType + ' workflow';
        const body = {
            snapshotId: snapshotId,
            workflowType: workflowType,
            automatedExecution: automated,
            name: name,
            description: description,
        };
        return this.api.post(`${this.prefix}/execute`, body);
    }

    executeWorkflowWithoutSnapshot(param: any): Observable<APIResponse<Workflow>> {
        return this.api.post(`${this.prefix}/execute/withoutSnapshot`, param);
    }

    // executeNewWorkflowFromSnapshot(snapshotId: number, automated: boolean, date: string): Observable<any> {
    //     const description = (automated ? 'Data refresh ' : 'Run on ') + date.slice(0, 10);
    //     const name = automated ? 'Automated multi-study run' : 'Manual multi-study run';
    //     const body = {
    //         snapshotId: snapshotId,
    //         automatedExecution: automated,
    //         name: name,
    //         description: description,
    //     };
    //     return this.api.post(`${this.prefix}/execute`, body);
    // }

    updateWorkflowNoteTag(workflowId: number, workflow: Workflow): Observable<APIResponse<Workflow>> {
        return this.api.put(`${this.prefix}/update/${workflowId}`, workflow);
    }

    findBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow>> {
        return this.api.get(`${this.prefix}/find/snapshot/${snapshotId}`);
    }

    delete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${workflowId}`);
    }

    reversePendingDelete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.put(`${this.prefix}/reverse-pending-delete/${workflowId}`, {});
    }

    public clearActiveWorkflowsByUser(userId: string): Observable<APIResponse<boolean>> {
        const req = {
            userId: userId,
        };
        return this.api.post(`${this.prefix}/tracking/clear`, req, {
            headers: {'X-Exclude-Cancel': 'true'},
        });
    }

    public activateWorkflowsByUser(userId: string, workflowIds: number[]): Observable<APIResponse<WorkflowInfo[]>> {
        const req = {
            workflowIds: workflowIds,
            userId: userId,
        };
        return this.api.post(`${this.prefix}/tracking/activate`, req, {
            //headers: {'X-Exclude-Cancel': 'true'},
        });
    }

    findBySnapshotDtoByWorkflowId(workflowId: number): Observable<APIResponse<SnapshotDto>> {
        return this.api.get(`${this.prefix}/find/snapshot-dto/${workflowId}`);
    }

    findAllBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow[]>> {
        return this.api.get(`${this.prefix}/find/snapshot-all/${snapshotId}`);
    }

    listLatestWorkflows(pageSize: number, workflowType: string): Observable<PagingResponse<Workflow>> {
        return this.api.get(
            `${this.prefix}/list?pageNumber=1&pageSize=${pageSize}&filterByworkflowType_EQUAL=${workflowType}&sortBy=id&orderBy=desc`,
        );
    }
}
