import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {FeederInfo} from '@core/interfaces/engin/load-forecast/feeder';

@Injectable()
export class FeederLoadForecastApi {
    private readonly PREFIX_FEEDER = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for feeder(s)
     */
    public getFeederPivot(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/pivot?${params}`,
        );
    }

    public getFeederPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('filterByseasonId_IN', seasonIds.join('|'))
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getFeederInfo(workflowItemId: number, scenarioId: string, feederId: string): Observable<FeederInfo[]> {
        return this.api.get(`${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/info`);
    }

    public getFeederForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/seasons`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getFeederForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/factors`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getFeederInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
    ): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/infrastructure/list`,
        );
    }

    public getFeederInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        feederId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/feeders/${feederId}/infrastructure/list/csv`,
        );
    }
}
