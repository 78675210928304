import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {filter, finalize, map, mergeMap, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {NbDialogService, NbMenuService, NbToastrService, NbTrigger} from '@nebular/theme';
import {DocumentAssetImage, DocumentService, FileFormatTypeLabels} from '@core/interfaces/common/document';
import {ConfirmDialogComponent, TagOptionsCallback, UploadFileDialogComponent} from '@theme/components';
import {BehaviorSubject, Observable} from 'rxjs';
import {IconDetails} from '@core/interfaces/common/pages';
import {HelpersService} from '@core/utils/helpers.service';

@Component({
    selector: 'ngx-document-preview',
    templateUrl: './document-preview.component.html',
    styleUrls: ['./document-preview.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentPreviewComponent extends Unsubscribable implements OnInit, OnChanges {
    @Input() document: DocumentAssetImage;
    @Input() getTagOptionsCallback: TagOptionsCallback;
    @Input() acceptedFileTypes: string[];
    @Output() changeFile = new EventEmitter();
    @Output() deleteFile = new EventEmitter();

    public readonly NbTrigger = NbTrigger;
    loadingRefresh: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loadingRefresh$: Observable<boolean> = this.loadingRefresh.asObservable();

    loadingDelete: BehaviorSubject<boolean> = new BehaviorSubject(false);
    loadingDelete$: Observable<boolean> = this.loadingDelete.asObservable();

    actions = [
        {title: 'Download', name: 'DOWNLOAD', icon: 'download-outline'},
        {title: 'Edit', name: 'EDIT', icon: 'edit-outline'},
        {title: 'Delete', name: 'DELETE', icon: 'trash-2-outline'},
    ];

    documentUrl: string;

    icon: IconDetails;

    FileFormatTypeLabels = FileFormatTypeLabels;

    constructor(
        private nbMenuService: NbMenuService,
        private documentService: DocumentService,
        private dialogService: NbDialogService,
        private toastrService: NbToastrService,
        private helpersService: HelpersService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.nbMenuService
            .onItemClick()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter(({tag}) => ['context-menu-upload-document-action'].includes(tag)),
                map(({item}) => item),
            )
            .subscribe((item) => this.menuSelectedActionClick(item));
    }

    menuSelectedActionClick = (data: any) => {
        switch (data.name) {
            case 'EDIT':
                this.editFile();
                return;
            case 'DELETE':
                this.delete();
                return;
            case 'DOWNLOAD':
                this.downloadFile();
                return;
            default:
                throw new Error('Option not implemented yet ' + data.name);
        }
    };

    ngOnChanges(changes: SimpleChanges) {
        if (changes.document) {
            this.getDocument().subscribe((data: string) => {
                this.documentUrl = data;

                if (['JPEG', 'JPG', 'PNG'].includes(this.document.fileFormat)) {
                    this.icon = null;
                } else this.icon = this.getIcon(this.document.fileFormat);
            });

            if (this.document.fileName.lastIndexOf('.') != -1) {
                this.document.fileName = this.document.fileName.substring(0, this.document.fileName.lastIndexOf('.'));
            }
        }
    }

    downloadFile() {
        this.helpersService.openPopup(this.documentUrl);
    }

    editFile() {
        this.dialogService
            .open(UploadFileDialogComponent, {
                context: {
                    acceptedFileTypes: this.acceptedFileTypes,
                    getTagOptionsCallback: this.getTagOptionsCallback,
                    file: {...this.document, url: this.documentUrl},
                },
                closeOnBackdropClick: false,
            })
            .onClose.pipe(
                filter((res) => !!res),
                mergeMap((res) =>
                    this.documentService.update(this.document.id, {
                        fileName: res.fileName,
                        tag: res.tag,
                        description: res.description,
                    }),
                ),
            )
            .subscribe((res) => {
                this.changeFile.emit();

                this.document = {...this.document, ...res.response};

                this.getDocument().subscribe((data: string) => {
                    this.documentUrl = data;
                });
            });
    }

    getDocument(): Observable<string> {
        this.loadingRefresh.next(true);

        return this.documentService
            .downloadDocumentsById(this.document.id)
            .pipe(finalize(() => this.loadingRefresh.next(false)));
    }

    getIcon(format: string): IconDetails {
        format = format.toUpperCase();

        switch (format) {
            case '.CSV':
            case 'CSV':
                return {
                    pack: 'engin',
                    icon: 'file-csv-active',
                };
            case '.XLSX':
            case 'XLSX':
            case 'MS_EXCEL':
                return {
                    pack: 'engin',
                    icon: 'file-xlsx-active',
                };
            case '.PDF':
            case 'PDF':
                return {
                    pack: 'engin',
                    icon: 'file-pdf-active',
                };
            case '.PNG':
            case 'PNG':
                return {
                    pack: 'engin',
                    icon: 'file-png-active',
                };
            case '.JPG':
            case 'JPG':
                return {
                    pack: 'engin',
                    icon: 'file-jpg-active',
                };
            case '.JPEG':
            case 'JPEG':
                return {
                    pack: 'engin',
                    icon: 'file-jpeg-active',
                };
            default:
                return {
                    pack: 'eva',
                    icon: 'file-outline',
                };
        }
    }

    delete() {
        let confirmParam: any = {
            title: `Delete file`,
            message: `Are you sure you want to delete file ${this.document.fileName}?`,
        };
        return this.dialogService
            .open(ConfirmDialogComponent, {
                context: confirmParam,
                closeOnBackdropClick: false,
            })
            .onClose.pipe(
                filter((res) => !!res.confirm),
                mergeMap(() => {
                    this.loadingDelete.next(true);
                    return this.documentService.delete(this.document.id);
                }),
                finalize(() => this.loadingDelete.next(false)),
            )
            .subscribe((res) => {
                this.toastrService.success(res.message, 'Success delete', {duration: 10000});
                this.deleteFile.emit();
            });
    }

    fileName(doc) {
        if (FileFormatTypeLabels[doc.fileFormat]) {
            return `${doc.fileName}.${FileFormatTypeLabels[doc.fileFormat]}`;
        } else {
            return `${doc.fileName}`;
        }
    }
}
