import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadURL, InfoBlock, InfrastructureForecastBySeasons, PeriodFilter, SimpleSeriesOld} from './load-forecast';

export interface SectionInfo extends InfoBlock {}

export abstract class SectionLoadForecastService {
    /*
     * Infrastructure: results for line section(s)
     */
    abstract getSectionPivot(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource>;

    abstract getSectionInfo(workflowItemId: number, scenarioId: string, sectionId: string): Observable<SectionInfo[]>;

    abstract getSectionForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons>;

    abstract getSectionForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]>;

    abstract getSectionPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>>;

    abstract getSectionInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<DataSource>;

    abstract getSectionInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<APIResponse<DownloadURL>>;
}
