import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, FilterFieldOption} from '@core/interfaces/system/system-common';
import {Form} from '@core/interfaces/common/forms';
import {FormResponseDto} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';

@Injectable()
export class FormsApi {
    private readonly apiController: string = 'mobile/forms';

    constructor(private api: HttpService) {}

    // Form content: basic content
    public createForm(name: string, description: string): Observable<APIResponse<Form>> {
        return this.api.post(`${this.apiController}/`, {
            name,
            description,
        });
    }

    public toggleFormPublish(formId: number, isPublished: boolean): Observable<APIResponse<Form>> {
        return this.api.put(`${this.apiController}/toggle-publish/${formId}`, {
            published: isPublished,
        });
    }

    public updateForm(formId: number, name: string, description: string): Observable<APIResponse<Form>> {
        return this.api.put(`${this.apiController}/${formId}`, {
            name,
            description,
        });
    }

    public getInspectionFormDto(formId: number): Observable<APIResponse<FormResponseDto>> {
        return this.api.get(`${this.apiController}/${formId}`);
    }

    // Form content: scope
    public getFormScope(formId: number): Observable<APIResponse<Filter[]>> {
        return this.api.get(`${this.apiController}/scope/${formId}`);
    }

    public setFormScope(formId: number, filters: Filter[]): Observable<APIResponse<any>> {
        return this.api.post(`${this.apiController}/scope/${formId}`, {
            filters,
        });
    }

    public getFilterOptions(fieldKey: string, searchString: string): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.post(`${this.apiController}/filter-options`, {
            fieldKey,
            searchString,
        });
    }

    // Form content: config
    public commitFormConfig(
        formId: number,
        isConfirmed: boolean,
        dataPrepToolId: number,
    ): Observable<APIResponse<Form>> {
        return this.api.post(`${this.apiController}/config/commit/${formId}`, {
            dataPrepToolId: dataPrepToolId,
            isConfirmed: isConfirmed,
        });
    }

    public downloadFormConfig(formId: number): Observable<APIResponse<DownloadCSVUrl[]>> {
        return this.api.get(`${this.apiController}/dto/download-form/${formId}`);
    }

    // Other
    public getInspectionFormsDataSource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.apiController}/dto/list`);
    }

    public duplicateForm(formId: number): Observable<APIResponse<any>> {
        return this.api.post(`${this.apiController}/duplicate/${formId}`, {});
    }

    public deleteForm(formId: number): Observable<APIResponse<any>> {
        return this.api.delete(`${this.apiController}/${formId}`);
    }

    public downloadInspectionForms(): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.apiController}/dto/list/csv`);
    }
}
