import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';
import {PmSegmentService, Segment, SegmentOption} from '@core/interfaces/engin/program-management/pm-segment';
import {PmSegmentApi} from '../../api/program-management/pm-segment.api';

@Injectable()
export class PmSegmentServiceImpl extends PmSegmentService {
    constructor(private api: PmSegmentApi) {
        super();
    }

    // Segment
    getSegmentList(params: Filter[]): Observable<Segment[]> {
        return this.api.getSegmentList(params);
    }

    getSegmentDtoList(): Observable<DataSource> {
        return this.api.getSegmentDtoList();
    }

    findSegmentKpis(
        segmentId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>> {
        return this.api.findSegmentKpis(segmentId, workflowItemId, sensitivityId);
    }

    getSegmentById(segmentId: number): Observable<APIResponse<Segment>> {
        return this.api.getSegmentById(segmentId);
    }

    createSegment(segment): Observable<APIResponse<any>> {
        return this.api.createSegment(segment);
    }

    editSegment(segment: Segment): Observable<APIResponse<Segment>> {
        return this.api.editSegment(segment);
    }

    deleteSegment(segmentId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteSegment(segmentId);
    }

    searchSegmentName(segmentName: string): Observable<APIResponse<Segment[]>> {
        return this.api.searchSegmentName(segmentName);
    }

    getSegmentOptions(): Observable<APIResponse<SegmentOption[]>> {
        return this.api.getSegmentOptions();
    }

    getSegmentKpiOptions(): Observable<APIResponse<string[]>> {
        return this.api.getSegmentKpiOptions();
    }
    validateSegmentName(programName: string): Observable<boolean> {
        return this.api.validateSegmentName(programName);
    }
    findAllBySegment(segmentId: number): Observable<APIResponse<Segment[]>> {
        return this.api.findAllBySegment(segmentId);
    }
    downloadAssetsListDtoCsvSegment(
        segmentId: number,
        workflowItemId: number,
    ): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadAssetsListDtoCsvSegment(segmentId, workflowItemId);
    }
    downloadTableCsvFile(): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadTableCsvFile();
    }
}
