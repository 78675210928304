import {Component, Input, OnInit} from '@angular/core';
import {IconDetails} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-file-name-with-icon',
    template: `
        <div class="file-container">
            <div class="icon-container">
                <nb-icon
                    size="large"
                    [pack]="icon.pack"
                    [icon]="icon.icon"
                    [ngClass]="{'active-icon': activeState}"
                ></nb-icon>
            </div>
            <div class="info-container">
                <div class="items">
                    <div class="file-name">
                        {{ fileName }}
                    </div>
                    <div class="file-size">
                        {{ fileSize }}
                    </div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['file-name-with-icon.scss'],
})
export class FileNameWithIconsComponent implements OnInit {
    @Input() file;
    @Input() activeState: boolean = false;
    fileName;
    fileSize;
    icon: IconDetails;

    ngOnInit(): void {
        this.initProcess();
        this.icon = this.getIcon(this.file.fileFormat, this.activeState);
    }

    initProcess() {
        this.fileName = this.file.fileName;
        this.fileSize = this.file.fileSize;
    }

    getIcon(format: string, active: boolean): IconDetails {
        switch (format) {
            case 'CSV':
                return {
                    pack: 'engin',
                    icon: active ? 'file-csv-active' : 'file-csv',
                };
            case 'MS_EXCEL':
                return {
                    pack: 'engin',
                    icon: active ? 'file-xlsx-active' : 'file-xlsx',
                };
            default:
                return {
                    pack: 'eva',
                    icon: 'file-outline',
                };
        }
    }
}
