import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FiltersDynamicOptionsStore,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {AssetRegistryService} from '@core/interfaces/asset-registry';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {isEqual} from 'lodash';

@Injectable()
export class MobileAdminStore extends Unsubscribable {
    public selectedFilters = new BehaviorSubject<Filter[]>([]);
    public selectedFilters$ = this.selectedFilters.asObservable();

    public genericFilters$ = this.assetRegistryService.getFilterOptions().pipe(
        map((res: APIResponse<TableFilterConfig[]>) => res.response),
        shareReplay(1),
    );

    public filtersDynamicOptions: BehaviorSubject<FiltersDynamicOptionsStore> = new BehaviorSubject<any>([]);
    public filtersDynamicOptions$: Observable<FiltersDynamicOptionsStore> = this.filtersDynamicOptions
        .asObservable()
        .pipe(shareReplay(1));

    // APIs are used from AssetRegistryService since they are shared.
    // However, data should be stored in separate stores for each module to ensure proper isolation.
    constructor(private assetRegistryService: AssetRegistryService) {
        super();
    }

    setSelectedFilters(filters: Filter[], requiredChange = false) {
        if (!requiredChange && isEqual(filters, this.selectedFilters.value)) return;
        this.selectedFilters.next(filters);
    }

    resetSelectedFilters() {
        this.setSelectedFilters([]);
    }

    setFiltersDynamicOptions(value: FiltersDynamicOptionsStore) {
        return this.filtersDynamicOptions.next(value);
    }

    getOptionsDynamically(data: FilterFieldOptionRequest) {
        this.setFiltersDynamicOptions({
            ...this.filtersDynamicOptions.value,
            [data.fieldKey]: {
                scope: data.filterParams,
                options: this.assetRegistryService.getFilterFieldOptions(data).pipe(
                    map((res) => res.response?.data),
                    shareReplay(1),
                ),
            },
        });
    }
}
