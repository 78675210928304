import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../system/system-common';
import {PasswordRequirements} from './tenantSettings';
import {WorkflowType} from '@core/interfaces/engin/workflow';

// User
export interface User {
    id: string;
    username: string;
    firstName: string;
    lastName: string;
    email: string;
    emailRegistered: boolean;
    timezone: string;

    activeTenantId: string;
    tenants?: string[];
    multipleTenants: boolean;

    role: string;
    status: string;
    lockoutUntil: Date;
    lockoutActive: boolean;
    userType: UserType;

    auditInformation?: {
        createdOn: Date;
        updatedOn: Date;
    };
    lastLoginDetails: {
        date: any;
        sameIp: boolean;
    };
    passwordExpired: boolean;
}
export enum UserType {
    ENGIN = 'ENGIN',
    SSO = 'SSO',
}

// User notification
export interface UserNotification {
    message: string;
    date?: Date;
    displayLevel: MessageDisplayLevelType;
    openByDefault: boolean;
}
export enum MessageDisplayLevelType {
    INFO = 'info',
    WARNING = 'warning',
}

// Password (request)
export interface PasswordData {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

// Default study info
export interface DefaultStudyInfo {
    latestAsDefault: boolean;
    defaultWorkflowList: WorkflowInfo[];
}
export interface WorkflowInfo {
    workflowType: WorkflowType;
    workflowId: number;
    workflowItemList: WorkflowItemInfo[];
}
export interface WorkflowItemInfo {
    workflowItemId: number;
    sensitivityCode: string;
    itemName: string;
    defaultItem: boolean;
    currentYear: number;
}

export abstract class UsersService {
    abstract get(id: string): Observable<User>;

    abstract getCurrentUser(): Observable<User>;

    abstract delete(id: string): Observable<boolean>;

    abstract update(user: User): Observable<any>;

    abstract updateRole(user: User): Observable<any>;

    abstract create(dto: any): Observable<any>;

    abstract userNameAvailable(userName: string): Observable<APIResponse<Boolean>>;

    abstract getUserByUserName(userName: string): Observable<User>;

    abstract userEmailAvailable(email: string, id: string): Observable<APIResponse<Boolean>>;

    abstract updatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<any>;

    abstract forceUpdatePassword(userId: string, newPassword: string): Observable<any>;

    abstract requestEmailVerification(userId: string): Observable<any>;

    abstract getUsersDataSource(): Observable<DataSource>;

    abstract getUsersListData(): Observable<User[]>;

    abstract getUsersNotification(): Observable<UserNotification[]>;

    abstract userUnlock(id): Observable<any>;

    abstract passwordValidation(
        password: string,
        checkPasswordHistory: boolean,
    ): Observable<APIResponse<PasswordRequirements>>;

    abstract passwordValidationOtherUser(
        userId: string,
        password: string,
        checkPasswordHistory: boolean,
    ): Observable<APIResponse<PasswordRequirements>>;

    abstract passwordValidationNewUser(password: string): Observable<APIResponse<PasswordRequirements>>;

    abstract inviteUserToOrganization(tenantId: string, username: string): Observable<any>;

    // abstract updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any>;
    abstract getDefaultStudyInfo(): Observable<APIResponse<DefaultStudyInfo>>;

    abstract updateDefaultStudyStrategy(latestAsDefault: boolean): Observable<APIResponse<DefaultStudyInfo>>;

    abstract updateDefaultStudyByType(
        type: WorkflowType,
        workflowId: number,
    ): Observable<APIResponse<DefaultStudyInfo>>;
}
