import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {SidebarStore} from '../../../@store';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {HelpersService} from '@core/utils/helpers.service';

@Component({
    selector: 'ngx-report-navbar',
    templateUrl: './report-navbar.component.html',
    styleUrls: ['./report-navbar.component.scss'],
})
/* tslint:disable */
export class ReportNavbarComponent {
    @Input() reportContainer;

    constructor(
        private sidebarStore: SidebarStore,
        private cd: ChangeDetectorRef,
        private usageAnalyticsService: UsageAnalyticsService,
        private helpersService: HelpersService,
    ) {
        sidebarStore.setMainPage(false);
    }

    printPage() {
        window.print();
    }

    savePDF(htmlElement: HTMLElement) {
        this.helpersService.downloadPDF(htmlElement, 'ENGIN_PDF_Report.pdf');
        this.usageAnalyticsService.logDownload('Unknown', 'PDF Report > Navbar');
    }
}
