import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    LatestModelStatus,
    FaultIndicationDashboardData,
    RowDataReadingsDashboardData,
} from '@core/interfaces/live/live';
import {
    OilTemperatureMoistureService,
    OilTemperatureVsMoistureUnits,
} from '@core/interfaces/live/oil-temperature-moisture';
import {OilTemperatureMoistureApi} from '@core/backend/engin/api/live/oil-temperature-moisture.api';

@Injectable()
export class OilTemperatureMoistureServiceImpl extends OilTemperatureMoistureService {
    constructor(private api: OilTemperatureMoistureApi) {
        super();
    }

    public getOilTemperatureVsMoistureUnits(): Observable<OilTemperatureVsMoistureUnits> {
        return this.api.getOilTemperatureVsMoistureUnits();
    }

    public getOilTemperatureVsMoistureDatasource(id: string): Observable<DataSource> {
        return this.api.getOilTemperatureVsMoistureDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getOilTemperatureVsMoistureLatestModelStatus(
        id: string,
        uploadDate?: string,
    ): Observable<LatestModelStatus> {
        return this.api.getOilTemperatureVsMoistureLatestModelStatus(id, uploadDate);
    }

    public getOilRowDataReadingsDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<RowDataReadingsDashboardData> {
        return this.api.getOilRowDataReadingsDashboardData(id, uploadDate);
    }

    public getOilFaultIndicationDashboardData(
        id: string,
        uploadDate?: string,
    ): Observable<FaultIndicationDashboardData> {
        return this.api.getOilFaultIndicationDashboardData(id, uploadDate);
    }
}
