import {ChangeDetectorRef, Component, Input, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {BehaviorSubject} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';

export enum ExpandRowTypes {
    Icon,
    Button,
}

@Component({
    template: `
        <button *ngIf="type === ExpandRowTypes.Button && isBtnShown(rowData)" nbButton size="small" status="basic">
            <nb-icon *ngIf="getButtonIcon(rowData) as icon" [icon]="icon"></nb-icon>
            <span *ngIf="getButtonTitle(rowData) as title">{{ title }}</span>
        </button>

        <button
            *ngIf="type === ExpandRowTypes.Icon && isBtnShown(rowData)"
            nbButton
            status="control"
            size="tiny"
            (click)="toggleFeederExpanded()"
        >
            <nb-icon
                [icon]="rowData.expanded ? 'chevron-up-outline' : 'chevron-down-outline'"
                [status]="rowData.expanded ? '' : 'basic'"
            ></nb-icon>
        </button>
    `,
})
export class ExpandRowComponent extends Unsubscribable implements ViewCell, OnInit {
    @Input() value: any;
    @Input() rowData: any;
    @Input() checkState?: BehaviorSubject<any> = new BehaviorSubject(null);
    @Input() columnKey?: string = 'id';

    public onExpand: BehaviorSubject<any> = new BehaviorSubject(null);

    ExpandRowTypes = ExpandRowTypes;

    type: ExpandRowTypes = ExpandRowTypes.Button;

    getButtonTitle = (data: any) => 'DETAILS';
    getButtonIcon = (data: any) => '';

    isBtnShown = (data: any) => true;

    constructor(private cd: ChangeDetectorRef) {
        super();
    }

    public ngOnInit() {
        this.checkState &&
            this.checkState
                .asObservable()
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((selected: any) => {
                    if (this.rowData?.[this.columnKey] !== selected?.[this.columnKey]) {
                        this.rowData.expanded = false;
                        this.cd.detectChanges();
                    }
                });
    }

    toggleFeederExpanded(): void {
        this.rowData.expanded = !this.rowData.expanded;
        this.rowData.expanded && this.onExpand.next(this.rowData);
    }
}
