import {ChangeDetectionStrategy, Component, Input, OnInit, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-report-filter-dialog',
    templateUrl: './report-filter-dialog.component.html',
    styleUrls: ['./report-filter-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportFilterDialogComponent extends Unsubscribable implements OnInit {
    @Input() options$: Observable<{value: string; label: string}[]>;

    options: {value: string; label: string}[];

    selectControl: FormControl = new FormControl([]);
    isAllSelected: boolean = false;

    constructor(@Optional() private dialogRef: NbDialogRef<ReportFilterDialogComponent>) {
        super();
    }

    ngOnInit() {
        this.options$.pipe(takeUntil(this.unsubscribe$)).subscribe((_options) => (this.options = _options));

        this.selectControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                map((value: any[]) => {
                    if (value && value.length === this.options.length && !value.includes(0)) {
                        let _value = [...value, 0];
                        this.isAllSelected = true;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    } else if (value && value.length !== this.options.length + 1) {
                        let _value = value.filter((item) => item !== 0);
                        this.isAllSelected = false;
                        this.selectControl.setValue(_value, {emitEvent: false});
                        return _value;
                    }
                }),
                debounceTime(100),
            )
            .subscribe();
    }

    toggleSelectAll() {
        if (this.selectControl.value.length >= this.options.length) {
            this.selectControl.reset([]);
            this.isAllSelected = false;
        } else {
            let _options = this.options.reduce((acc, curr) => {
                return [...acc, curr.value];
            }, []);
            this.selectControl.setValue([..._options, 0]);
            this.isAllSelected = true;
        }
    }

    getLabels(values: string[]) {
        let _labels = values?.reduce((acc, curr) => {
            if (acc !== '') acc += ', ';
            return (acc += this.options.find((option) => option.value === curr)?.label);
        }, '');
        return _labels || '';
    }

    confirm() {
        this.dialogRef.close({action: 'confirm', data: this.isAllSelected ? [] : [...this.selectControl.value]});
    }

    cancel() {
        this.dialogRef.close({action: 'cancel'});
    }
}
