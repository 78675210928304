<nb-card>
    <nb-card-header class="justify-content-end">
        <button (click)="close()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>
        <div class="carousel-container">
            <div class="image-row">
                <!-- Main media -->
                <div class="main-img-container" *ngIf="mainMedia">
                    <div class="arrows">
                        <button ghost status="basic" nbButton (click)="back()">
                            <nb-icon icon="arrow-back-outline" status="basic"></nb-icon>
                        </button>
                    </div>
                    <!-- Main media is an image -->
                    <img
                        *ngIf="enableImage && mediaIsImage(mainMedia)"
                        [src]="mainMedia.url"
                        [alt]="mainMedia.fileName"
                        class="photo main-image"
                    />
                    <!-- Main media is an video -->
                    <video
                        *ngIf="enableVideo && mediaIsVideo(mainMedia)"
                        src="{{ mainMedia.url }}"
                        controls
                        class="photo main-image"
                    ></video>
                    <div class="arrows">
                        <button ghost status="basic" nbButton (click)="forward()">
                            <nb-icon icon="arrow-forward-outline" status="basic"></nb-icon>
                        </button>
                    </div>
                </div>

                <!-- Thumbnails -->
                <div #thumbnailContainer class="thumbnail-container">
                    <div
                        *ngFor="let thumbnail of thumbnailList"
                        (click)="selectMedia(thumbnail)"
                        class="thumbnail-row"
                        [ngClass]="thumbnail.url ? '' : 'empty-box'"
                    >
                        <!-- Thumbnail found -->
                        <ng-container *ngIf="thumbnail.url; else emptyDiv">
                            <!-- Media is an image -->
                            <img
                                *ngIf="enableImage && mediaIsImage(thumbnail)"
                                [src]="thumbnail.url"
                                [alt]="thumbnail.fileName"
                                class="photo thumbnail-image"
                                [ngClass]="mainMedia.id === thumbnail.id ? 'thumbnail selected' : 'thumbnail'"
                            />
                            <!-- Media is a video -->
                            <video
                                *ngIf="enableVideo && mediaIsVideo(thumbnail)"
                                src="{{ thumbnail.url }}"
                                class="photo thumbnail-image"
                                [ngClass]="mainMedia.id === thumbnail.id ? 'thumbnail selected' : 'thumbnail'"
                            ></video>
                        </ng-container>

                        <!-- Thumbnail not found -->
                        <ng-template #emptyDiv>
                            <div class="photo thumbnail-image"></div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>
