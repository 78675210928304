import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {ActivatedRoute} from '@angular/router';
import {debounceTime, distinctUntilChanged, takeUntil} from 'rxjs/operators';

interface defaultInterface {
    name: string;
    id: number;
    description: string;
}

@Component({
    selector: 'ngx-custom-autocomplete',
    templateUrl: './custom-autocomplete.component.html',
    styleUrls: ['./custom-autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CustomAutocompleteComponent),
            multi: true,
        },
    ],
})
export class CustomAutocompleteComponent<T = defaultInterface>
    extends Unsubscribable
    implements OnInit, ControlValueAccessor
{
    @Output() routeChange: EventEmitter<number> = new EventEmitter();
    @Output() selectedChange: EventEmitter<T> = new EventEmitter();
    @Output() onSearch: EventEmitter<T | string> = new EventEmitter();
    @Input() options: FormControl = new FormControl([]);
    @Input() customTemplate?: any;
    inputFormControl: FormControl = new FormControl('');

    touched = false;
    disabled = false;

    onChange = (value) => {};

    onTouched = () => {};

    constructor(private route: ActivatedRoute) {
        super();
    }

    @Input() set selectedName(value: T) {
        if (!this.inputFormControl.value) this.inputFormControl.setValue(value, {emitEvent: false});
    }

    @Input() viewHandle = (value: T | defaultInterface) => {
        return (typeof value === 'object' && 'name' in value && value?.name) || value;
    };
    optionsList = new BehaviorSubject(null);
    ngOnInit(): void {
        let _id = +this.route.snapshot.params.id ?? null;
        this.routeChange.emit(!isNaN(_id) ? +_id : null);
        this.inputFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), debounceTime(500), distinctUntilChanged())
            .subscribe((value) => {
                this.onSearch.emit(value);
            });

        this.options.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((options) => this.optionsList.next(options));
    }

    onSelectedChange(value: T) {
        this.selectedChange.emit(value);
        this.onChange(value);
    }

    writeValue(value: T): void {
        if (value) this.inputFormControl.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
