import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-radio-box-cell',
    template: `
        <div class="action-cell">
            <nb-radio
                [checked]="isChecked"
                [ngClass]="(loadedDataTableFlag.asObservable() | async) ? '' : 'pointer-none'"
                (click)="onClick()"
            ></nb-radio>
        </div>
    `,
    styleUrls: ['./radio-box-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioBoxCellComponent<T> extends Unsubscribable implements ViewCell, OnInit {
    @Input() value: string | number;
    @Input() rowData: T;
    @Input() selectedRow$: Observable<T>;
    @Input() keyColumnName: string = 'id';

    @Input() multipleSelect: false;
    @Output() onSelectChange: EventEmitter<T> = new EventEmitter();
    loadedDataTableFlag: BehaviorSubject<boolean> = new BehaviorSubject(false);

    isChecked: boolean = false;

    constructor(private cd: ChangeDetectorRef) {
        super();

        // If user select the checkbox during the reloading datatable time, it will be causing an error.
        setTimeout(() => {
            this.loadedDataTableFlag.next(true);
        }, 500);
    }

    public ngOnInit() {
        this.selectedRow$.pipe(takeUntil(this.unsubscribe$)).subscribe((row: T) => {
            this.checkSelectedValues(this.rowData[this.keyColumnName], row);
        });
    }

    checkSelectedValues(value, item) {
        this.isChecked = item?.[this.keyColumnName] === value;
        this.cd.markForCheck();
    }

    onClick() {
        this.onSelectChange.emit(this.rowData);
    }
}
