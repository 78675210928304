import {Component, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {filter, takeWhile} from 'rxjs/operators';
import {NbAuthService} from '@nebular/auth';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'custom-nb-auth',
    styleUrls: ['./custom-nb-auth.component.scss'],
    template: `
        <nb-layout>
            <nb-layout-column>
                <nb-card>
                    <nb-card-header *ngIf="headerShow">
                        <nav class="navigation">
                            <a href="#" (click)="back()" class="link" aria-label="Back">
                                <nb-icon icon="arrow-back-outline" class="icon"></nb-icon>
                            </a>
                        </nav>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-auth-block>
                            <router-outlet></router-outlet>
                        </nb-auth-block>
                    </nb-card-body>
                </nb-card>
            </nb-layout-column>
        </nb-layout>
    `,
})
export class CustomNbAuthComponent implements OnDestroy {
    public headerShow: boolean = false;
    private alive = true;

    subscriptions: any[] = [];

    authenticated: boolean = false;
    token: string = '';

    constructor(protected auth: NbAuthService, protected location: Location, private router: Router) {
        this.subscriptions.push(
            auth
                .onAuthenticationChange()
                .pipe(takeWhile(() => this.alive))
                .subscribe((authenticated: boolean) => {
                    this.authenticated = authenticated;
                }),
        );

        this.subscriptions.push(
            this.router.events
                .pipe(filter((event) => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.headerShow = !(event.url.includes('select-organization') || event.url.includes('login'));
                }),
        );
    }

    back() {
        this.location.back();
        return false;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }
}
