import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {PmSensitivity, PmSensitivityDto} from '@core/interfaces/engin/program-management/pm-sensitivity';
import {APIResponse} from '@core/interfaces/system/system-common';
import {Observable} from 'rxjs';

@Injectable()
export class PmSensitivityApi {
    private readonly prefix = 'sensitivity';

    constructor(private api: HttpService) {}

    getSensitivity(data: PmSensitivity): Observable<APIResponse<PmSensitivityDto>> {
        return this.api.post(`${this.prefix}/find`, data);
    }
}
