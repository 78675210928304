import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UsersService} from '../../interfaces/common/users';
import {UsersServiceImpl} from './services/users.service';
import {UsersApi} from './api/users.api';
import {HttpService} from './api/http.service';
import {PagesService} from '../../interfaces/common/pages';
import {PagesApi} from './api/pages.api';
import {PopoutService} from '../../interfaces/common/popout';
import {PopoutApi} from './api/popout.api';
import {RolesApi} from './api/roles.api';
import {LegacyUploadsApi} from './api/legacyUploads.api';
import {RolesService} from '../../interfaces/common/roles';
import {RolesServiceImpl} from './services/roles.service';
import {LegacyUploadsService} from '../../interfaces/common/legacyUploads';
import {LegacyUploadsServiceImpl} from './services/legacyUploads.service';
import {FlaskService} from './api/flask.service';
import {SupportServiceImpl} from './services/support.service';
import {SupportApi} from './api/support.api';
import {SupportService} from '../../interfaces/common/support';
import {PagesServiceImpl} from './services/pages.service';
import {PopoutServiceImpl} from './services/popout.service';
import {SnapshotServiceImpl} from './services/snapshot.service';
import {DataFileServiceImpl} from './services/data-file.service';
import {ConfigurationServiceImpl} from './services/configuration.service';
import {DatasetService} from '../../interfaces/common/dataset';
import {DatasetServiceImpl} from './services/dataset.service';
import {SnapshotApi} from './api/snapshot.api';
import {DataFileApi} from './api/data-file-api.service';
import {ConfigurationApi} from './api/configuration.api';
import {DatasetApi} from './api/dataset.api';
import {SnapshotService} from '../../interfaces/common/snapshot';
import {DataFileService} from '../../interfaces/common/data-file';
import {ConfigurationService} from '../../interfaces/common/configuration';
import {SurvivalAnalysisService} from '../../interfaces/engin/survival-analysis';
import {SurvivalAnalysisServiceImpl} from '../../../pages/survival-analysis/api/survival-analysis.service';
import {SurvivalAnalysisApi} from '../../../pages/survival-analysis/api/survival-analysis.api';
import {UsageAnalyticsApi} from '@core/backend/common/api/usage-analytics-api.service';
import {AuthApi} from '@core/backend/common/api/auth.api';
import {AuthService} from '@core/interfaces/common/auth';
import {AuthServiceImpl} from '@core/backend/common/services/auth.service';
import {TenantSettingsApi} from '@core/backend/common/api/tenantSettings.api';
import {TenantSettingsService} from '@core/interfaces/common/tenantSettings';
import {TenantSettingsServiceImpl} from '@core/backend/common/services/tenantSettings.service';
import {HttpCancelService} from '@core/backend/common/services/http-cancel.service';
import {DocumentApi} from '@core/backend/common/api/document.api';
import {NoteServiceImpl} from './services/note.service';
import {DocumentService} from '@core/interfaces/common/document';
import {NoteService} from '@core/interfaces/common/note';
import {DocumentServiceImpl} from '@core/backend/common/services/document.service';
import {NoteApi} from '@core/backend/common/api/note.api';
import {PersistedDataService} from '@core/interfaces/common/persisted-data';
import {PersistedDataServiceImpl} from './services/persisted-data.service';
import {PersistedDataApi} from './api/persisted-data.api.service';
import {FormsServiceImpl} from '@core/backend/common/services/forms.service';
import {FormsService} from '../../interfaces/common/forms';
import {FormsApi} from '@core/backend/common/api/forms.api';

const API = [
    HttpService,
    FlaskService,
    AuthApi,
    UsersApi,
    FormsApi,
    RolesApi,
    PagesApi,
    PopoutApi,
    LegacyUploadsApi,
    SupportApi,
    SnapshotApi,
    PersistedDataApi,
    DataFileApi,
    ConfigurationApi,
    DatasetApi,
    SurvivalAnalysisApi,
    UsageAnalyticsApi,
    TenantSettingsApi,
    DocumentApi,
    NoteApi,
];

const SERVICES = [
    HttpService,
    FlaskService,
    {provide: AuthService, useClass: AuthServiceImpl},
    {provide: UsersService, useClass: UsersServiceImpl},
    {provide: FormsService, useClass: FormsServiceImpl},
    {provide: RolesService, useClass: RolesServiceImpl},
    {provide: PagesService, useClass: PagesServiceImpl},
    {provide: PopoutService, useClass: PopoutServiceImpl},
    {provide: LegacyUploadsService, useClass: LegacyUploadsServiceImpl},
    {provide: SupportService, useClass: SupportServiceImpl},
    {provide: ConfigurationService, useClass: ConfigurationServiceImpl},
    {provide: DataFileService, useClass: DataFileServiceImpl},
    {provide: SnapshotService, useClass: SnapshotServiceImpl},
    {provide: DatasetService, useClass: DatasetServiceImpl},
    {provide: PersistedDataService, useClass: PersistedDataServiceImpl},
    {provide: SurvivalAnalysisService, useClass: SurvivalAnalysisServiceImpl},
    {provide: TenantSettingsService, useClass: TenantSettingsServiceImpl},
    {provide: DocumentService, useClass: DocumentServiceImpl},
    {provide: NoteService, useClass: NoteServiceImpl},
];

@NgModule({
    imports: [CommonModule],
})
export class CommonBackendModule {
    static forRoot(): ModuleWithProviders<CommonBackendModule> {
        return {
            ngModule: CommonBackendModule,
            providers: [HttpCancelService, ...API, ...SERVICES],
        };
    }
}
