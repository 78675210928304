import {Injectable} from '@angular/core';
import {
    PopoutService,
    ForecasterDropdownSettings,
    ForecasterPopoutSettings,
    ForecasterSliderSettings,
    SliderSettingsBase,
    PopoutSettingsBase,
    DropdownSettingsBase,
} from '../../@core/interfaces/common/popout';
import {FormArray, FormBuilder, FormControl} from '@angular/forms';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {ForecasterRequest} from '../../@core/interfaces/engin/forecaster';
import {debounceTime, filter, map, shareReplay, startWith, tap} from 'rxjs/operators';
import {OptimizerPopoutStore} from '../optimizer/optimizer-popout.store';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';

@Injectable()
export class ForecasterPopoutStore {
    public popoutFormGroup = this.fb.group({
        customPeriodType: this.fb.control(false),
        // constrained: this.fb.control(false),
        selectedYear: this.fb.control(0),
        selectedDropdownIndex: this.fb.control(1),
        temporaryDropdownIndex: this.fb.control(1),
    });

    private controlPanelCollapsed = new BehaviorSubject<boolean>(true);
    readonly controlPanelCollapsed$: Observable<boolean> = this.controlPanelCollapsed.asObservable();

    readonly currentYear = new Date().getFullYear();
    readonly customPeriodType$: Observable<boolean> = this.customPeriodTypeFormControl.valueChanges;
    readonly selectedYear$: Observable<number> = this.selectedYearFormControl.valueChanges;
    readonly yearsArray: number[] = new Array(10).fill(0).map((yearCell, index) => this.currentYear + index);

    private selectedDropdownIndexSubscription: Subscription;
    private selectedDropdownSlidersSubscription: Subscription;
    private selectedYearSubscription: Subscription;
    private customPeriodTypeSubscription: Subscription;
    private constraindSubscription: Subscription;

    private stateChanged = false;
    private dropdownsList = new BehaviorSubject<any[]>([]);
    public selectedDropdownFormGroup = this.fb.group({});

    // Current investment plan from Optimizer is carried over into Forecaster
    readonly constrained: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly constrained$: Observable<boolean> = this.constrained.asObservable();

    protected serverPopout = new BehaviorSubject<any[]>(null);
    readonly serverPopout$: Observable<ForecasterRequest> = combineLatest<Observable<boolean>, Observable<any[]>>([
        this.constrained$,
        this.serverPopout.asObservable(),
    ]).pipe(
        filter(([constrained, serverFilter]: [boolean, any[]]) => serverFilter !== null),
        map(([constrained, serverFilter]: [boolean, any[]]) => {
            const stateChanged = this.stateChanged; // TODO: link to filter itself
            const slidersConfig = serverFilter.map((dropdown) => {
                return {
                    id: dropdown.id,
                    sliders: dropdown.sliders.map((slider) => {
                        return {
                            id: slider.id,
                            values: slider.values,
                            effectiveness: slider.effectiveness,
                        };
                    }),
                };
            });

            return new ForecasterRequest(slidersConfig, stateChanged, constrained);
        }),
    );

    protected getPopoutSettings(): Observable<ForecasterPopoutSettings> {
        return this.popoutData.getForecasterPopoutSettings();
    }

    protected initSelectedDropdownFormGroup(index: number) {
        const selectedDropdownValue = this.dropdownsList.value[index];
        this.selectedDropdownFormGroup = this.fb.group({
            id: this.fb.control(selectedDropdownValue.id),
            description: this.fb.control(selectedDropdownValue.description),
            dirtyYears: this.fb.array([]),
            sliders: this.fb.array(
                selectedDropdownValue.sliders.map((slider) => {
                    return this.fb.group({
                        id: this.fb.control(slider.id),
                        description: this.fb.control(slider.description),
                        prefix: this.fb.control(slider.prefix),
                        suffix: this.fb.control(slider.suffix),
                        max: this.fb.control(slider.max),
                        min: this.fb.control(slider.min),
                        value: this.fb.control(slider.value),
                        values: this.fb.array(slider.values),
                        effectiveness: this.fb.control(slider.effectiveness),
                    });
                }),
            ),
        });
    }

    protected initDropdownsList() {
        const initialDropdownsList = this.popoutSettings.value.dropdowns.map((dropdown: ForecasterDropdownSettings) => {
            return {
                id: dropdown.id,
                description: dropdown.description,
                dirtyYears: [],
                sliders: dropdown.sliders.map((sliderItem: ForecasterSliderSettings) => {
                    return {
                        id: sliderItem.id,
                        description: sliderItem.description,
                        prefix: sliderItem.prefix,
                        suffix: sliderItem.suffix,
                        max: sliderItem.max,
                        min: sliderItem.min,
                        value: sliderItem.value,
                        values: this.popoutSettings.value.years.map(() => sliderItem.value),
                        effectiveness: sliderItem.effectiveness,
                    };
                }),
            };
        });
        this.dropdownsList.next(initialDropdownsList);
        this.serverPopout.next(initialDropdownsList);
    }

    protected popoutSettings = new BehaviorSubject<PopoutSettingsBase>(null);
    readonly popoutSettings$: Observable<PopoutSettingsBase> = this.getPopoutSettings().pipe(
        map((settings) => {
            return {
                ...settings,
                years: this.yearsArray,
            };
        }),
        tap((settings) => {
            this.popoutSettings.next(settings);
        }),
        tap(() => this.initDropdownsList()),
        tap(() => this.subscribeDropdownOnChange()),
        shareReplay(1),
    );

    private selectedDropdownSliders = new BehaviorSubject<DropdownSettingsBase>(null);
    readonly selectedDropdownSliders$ = this.selectedDropdownSliders.asObservable().pipe(
        filter((sliders) => {
            return sliders !== null;
        }),
    );

    constructor(
        protected popoutData: PopoutService,
        protected fb: FormBuilder,
        protected optimizerPopoutStore: OptimizerPopoutStore,
        private usageAnalyticsService: UsageAnalyticsService,
    ) {}

    get selectedDropdownDirtyYearsFormArrayValue(): number[] {
        const dirtyYears = this.selectedDropdownFormGroup.get('dirtyYears') as FormArray;
        return dirtyYears ? dirtyYears.value : [];
    }

    private get selectedDropdownIndexFormControl(): FormControl {
        return this.popoutFormGroup.get('selectedDropdownIndex') as FormControl;
    }

    private get customPeriodTypeFormControl(): FormControl {
        return this.popoutFormGroup.get('customPeriodType') as FormControl;
    }

    private get selectedYearFormControl(): FormControl {
        return this.popoutFormGroup.get('selectedYear') as FormControl;
    }

    private get temporaryDropdownIndexFormControl(): FormControl {
        return this.popoutFormGroup.get('temporaryDropdownIndex') as FormControl;
    }

    private get selectedDropdownDirtyYearsFormArray(): FormArray {
        return this.selectedDropdownFormGroup.get('dirtyYears') as FormArray;
    }

    private get selectedDropdownSlidersFormArray(): FormArray {
        return this.selectedDropdownFormGroup.get('sliders') as FormArray;
    }

    public updateServerPopout() {
        this.stateChanged = true;
        this.serverPopout.next(this.dropdownsList.value);
        this.usageAnalyticsService.logView('Forecaster Control Panel');
    }

    public destroy() {
        this.selectedDropdownIndexSubscription && this.selectedDropdownIndexSubscription.unsubscribe();
        this.selectedDropdownSlidersSubscription && this.selectedDropdownSlidersSubscription.unsubscribe();
        this.selectedYearSubscription && this.selectedYearSubscription.unsubscribe();
        this.customPeriodTypeSubscription && this.customPeriodTypeSubscription.unsubscribe();
        this.constraindSubscription && this.constraindSubscription.unsubscribe();
    }

    public resetAllYears() {
        this.customPeriodTypeFormControl.setValue(false);
    }

    get slidersArray() {
        return this.selectedDropdownFormGroup.get('sliders') as FormArray;
    }

    protected subscribeDropdownOnChange() {
        this.selectedDropdownIndexSubscription = this.selectedDropdownIndexFormControl.valueChanges
            .pipe(startWith(1))
            .subscribe((index) => {
                if (Object.keys(this.selectedDropdownFormGroup.value).length > 0) {
                    const newDropdownSelected = this.dropdownsList.value[index - 1];
                    this.selectedDropdownDirtyYearsFormArray.clear();
                    newDropdownSelected.dirtyYears.forEach((dirtyYear) => {
                        this.selectedDropdownDirtyYearsFormArray.push(this.fb.control(dirtyYear));
                    });
                    // Manually reset form array controls to allow for change in number of sliders per dropdown
                    const newLength = newDropdownSelected.sliders.length;
                    this.slidersArray.clear();

                    for (let i = 0; i < newLength; i++) {
                        this.slidersArray.push(
                            this.fb.group({
                                id: this.fb.control(newDropdownSelected.sliders[i].id),
                                description: this.fb.control(newDropdownSelected.sliders[i].description),
                                prefix: this.fb.control(newDropdownSelected.sliders[i].prefix),
                                suffix: this.fb.control(newDropdownSelected.sliders[i].suffix),
                                max: this.fb.control(newDropdownSelected.sliders[i].max),
                                min: this.fb.control(newDropdownSelected.sliders[i].min),
                                value: this.fb.control(newDropdownSelected.sliders[i].value),
                                values: this.fb.array(newDropdownSelected.sliders[i].values),
                                effectiveness: this.fb.control(newDropdownSelected.sliders[i].effectiveness),
                            }),
                        );
                    }
                } else {
                    this.initSelectedDropdownFormGroup(0);
                }
                this.temporaryDropdownIndexFormControl.setValue(index);
                this.selectedDropdownSliders.next(this.selectedDropdownSlidersFormArray.value);
            });

        this.selectedDropdownSlidersSubscription = this.selectedDropdownSlidersFormArray.valueChanges
            .pipe(
                filter((dropdownSliders) => dropdownSliders.length > 0),
                debounceTime(100), // for reduce changes of popout when moving sliders
            )
            .subscribe((dropdownSliders: SliderSettingsBase[]) => {
                const selectedYear = this.selectedYearFormControl.value;
                if (this.customPeriodTypeFormControl.value && selectedYear !== 0) {
                    dropdownSliders = dropdownSliders.map((slider) => {
                        return {
                            ...slider,
                            values: slider.values.map((value, index) => {
                                return this.yearsArray.indexOf(selectedYear) === index ? slider.value : value;
                            }),
                        };
                    });
                    if (this.selectedDropdownDirtyYearsFormArray.value.indexOf(selectedYear) < 0) {
                        this.selectedDropdownDirtyYearsFormArray.push(this.fb.control(selectedYear));
                    }
                } else {
                    dropdownSliders = dropdownSliders.map((slider) => {
                        return {
                            ...slider,
                            values: slider.values.map(() => slider.value),
                        };
                    });
                }
                dropdownSliders.forEach((slider, index) => {
                    this.selectedDropdownSlidersFormArray
                        .at(index)
                        .patchValue({values: slider.values}, {emitEvent: false, onlySelf: true});
                });
                this.updateDropdownsList(this.temporaryDropdownIndexFormControl.value - 1, dropdownSliders);
            });

        this.selectedYearSubscription = this.selectedYear$
            .pipe(
                filter((year) => year > 0),
                map((year) => this.yearsArray.indexOf(year)),
            )
            .subscribe((yearIndex) => {
                this.selectedDropdownSlidersFormArray.value.forEach((slider, index) => {
                    this.selectedDropdownSlidersFormArray
                        .at(index)
                        .patchValue({value: slider.values[yearIndex]}, {emitEvent: false, onlySelf: true});
                });
            });

        this.customPeriodTypeSubscription = this.customPeriodType$
            .pipe(filter((customPeriod) => !customPeriod))
            .subscribe(() => {
                this.resetPopout();
            });

        this.constraindSubscription = this.optimizerPopoutStore.constrained$.subscribe((constrained) => {
            this.constrained.next(constrained);
        });
    }

    private updateDropdownsList(index: number, value: SliderSettingsBase[]) {
        const dropdownsList = this.dropdownsList.value;
        dropdownsList[index].sliders = value;
        dropdownsList[index].dirtyYears = this.selectedDropdownDirtyYearsFormArray.value;
        this.dropdownsList.next(dropdownsList);
    }

    private resetPopout() {
        this.initDropdownsList();
        this.selectedYearFormControl.setValue(0);
        this.selectedDropdownIndexFormControl.setValue(1);
        this.customPeriodTypeFormControl.setValue(false, {emitEvent: false});
    }

    public toggleControlPanelCollapsed(collapsed: boolean) {
        if (collapsed != null) {
            this.controlPanelCollapsed.next(collapsed);
        }
    }
}
