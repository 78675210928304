import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../@core/backend/common/api/http.service';
import {SurvivalStudyRequest} from './pojo/survival-study.pojo';
import {DownloadLink} from '../../../@core/interfaces/common/legacyUploads';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter, SimpleSeries, TableFilterConfig} from '@core/interfaces/system/system-common';
import {CurveCalibrationDetails} from './pojo/curve-calibration-outputs.pojo';
import {AssetColumn} from '@core/interfaces/common/asset';

@Injectable()
export class SurvivalAnalysisApi {
    private readonly prefix = 'survivalAnalysis';

    constructor(private api: HttpService) {}

    getCurveCalibrationList(workflowItemId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/results/list/${workflowItemId}`);
    }

    getAnalysisHistoryData(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list`);
    }

    getWorkflowDtoFull(workflowItemId: number): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/config/${workflowItemId}`);
    }

    acceptCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>> {
        return this.api.put(`${this.prefix}/${workflowItemId}/curve/${curveId}/accept`, {comment: comment});
    }
    rejectCurve(workflowItemId: number, curveId: number, comment: string): Observable<APIResponse<any>> {
        return this.api.put(`${this.prefix}/${workflowItemId}/curve/${curveId}/reject`, {comment: comment});
    }

    getCurveCalibrationData(workflowItemId: number, curveId: number): Observable<APIResponse<CurveCalibrationDetails>> {
        return this.api.get(`${this.prefix}/results/${workflowItemId}/${curveId}`);
    }
}
