import {ChangeDetectionStrategy, Component, Input, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Observable} from 'rxjs';
import {AssetHealthIndex} from '@core/interfaces/engin/assets';

@Component({
    selector: 'ngx-preprocess-dialog',
    templateUrl: './preprocess-dialog.component.html',
    styleUrls: ['./preprocess-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreprocessDialogComponent {
    @Input() assetHealthIndexData$: Observable<AssetHealthIndex>;

    constructor(@Optional() private dialogRef: NbDialogRef<PreprocessDialogComponent>) {}

    close() {
        this.dialogRef.close();
    }

    formatValue(value: string): string {
        return value.includes(',') ? value.split(',').join(',<br>') : value;
    }
}
