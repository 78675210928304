import {Component, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {map} from 'rxjs/operators';
import {AboutSystemInfo} from '@core/interfaces/common/support';
import {SupportStore} from '@store/common/support.store';

@Component({
    selector: 'ngx-system-about',
    templateUrl: './system-info-dialog.component.html',
    styleUrls: ['./system-info-dialog.component.scss'],
})
export class SystemInfoDialogComponent extends Unsubscribable {
    // Static fields for about dialog
    dialogTitle: String = 'About';

    constructor(@Optional() private dialogRef: NbDialogRef<any>, private supportStore: SupportStore) {
        super();
    }

    readonly aboutInfo$ = this.supportStore.aboutSystemInfo$.pipe(
        map((aboutSystemInfo: AboutSystemInfo) => {
            return [
                {title: 'ENGIN Version', value: aboutSystemInfo.getEnginVersion()},
                {title: 'Tenant Identifier', value: aboutSystemInfo.getTenantId()},
                {
                    title: 'User Account',
                    value: `${aboutSystemInfo.getUserName()} \n ${aboutSystemInfo.getUserEmail()}`,
                },
                {title: 'Browser', value: aboutSystemInfo.getBrowserInfo()},
            ];
        }),
    );

    onClose() {
        this.dialogRef.close();
    }
}
