import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {LatestModelStatus, FaultIndicationDashboardData} from '@core/interfaces/live/live';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {InsulationMoistureUnits} from '@core/interfaces/live/insulation-moisture';

@Injectable()
export class InsulationMoistureApi {
    private readonly PREFIX = 'live/output/insulation-moisture';

    constructor(private api: HttpService) {}

    public getInsulationMoistureUnits(): Observable<InsulationMoistureUnits> {
        return this.api.get(`${this.PREFIX}/units`);
    }

    public getInsulationMoistureDatasource(id: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX}/list?filterByassetId=${id}`);
    }

    public getCountResults(assetId: string, uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX}/count`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    public getInsulationMoistureLatestModelStatus(assetId: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api
            .get(`${this.PREFIX}/latest`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<LatestModelStatus>) => res.response));
    }

    public getInsulationMoistureDashboardData(
        assetId: string,
        uploadDate?: string,
    ): Observable<FaultIndicationDashboardData> {
        return this.api
            .get(`${this.PREFIX}/data`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<FaultIndicationDashboardData>) => res.response));
    }
}
