import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {NbDialogService} from '@nebular/theme';
import {DataPrepStatus, ProcessStep} from '@core/interfaces/engin/data-preparation';
import {GuideDialogComponent} from '@theme/components';

export enum ComponentStates {
    Active = 'active',
    Default = 'default',
    Disabled = 'disabled',
    Success = 'success',
    Failed = 'failed',
    InProgress = 'in-progress',
}

@Component({
    selector: 'ngx-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnChanges {
    @Input() activeStep: number;
    @Input() steps: ProcessStep[];
    @Input() formValidation: boolean = false;
    @Output() changeStep: EventEmitter<number> = new EventEmitter<number>();
    @Output() refreshEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    ComponentStates = ComponentStates;
    DataPrepStatuses = DataPrepStatus;

    constructor(private dialogService: NbDialogService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.steps?.currentValue !== changes?.steps?.previousValue) {
            let currentStep = this.steps.find((step) => step.stepOrder === this.activeStep);
            if (currentStep?.status === this.DataPrepStatuses.IN_PROGRESS) {
                this.refreshEvent.emit(true);
            }
        }
    }

    getState(step: ProcessStep) {
        // Known states
        if (step.status === DataPrepStatus.ACTIVE) return ComponentStates.Active;
        if (step.status === DataPrepStatus.SUCCESS) return ComponentStates.Success;
        if (step.status === DataPrepStatus.FAILED) return ComponentStates.Failed;

        // Assumed states from conditions
        if (step.status === DataPrepStatus.IN_PROGRESS || step.stepOrder === this.activeStep)
            return ComponentStates.InProgress;
        if (step.status === DataPrepStatus.CREATED && this.formValidation && this.activeStep + 1 === step.stepOrder)
            return ComponentStates.Default;
        if (
            (step.status === DataPrepStatus.CREATED && step.stepOrder !== this.activeStep && !this.formValidation) ||
            step.status === DataPrepStatus.CREATED
        )
            return ComponentStates.Disabled;

        return ComponentStates.Disabled;
    }

    openDescription(e: Event, step: ProcessStep) {
        e.stopPropagation();
        this.dialogService.open(GuideDialogComponent, {
            context: {title: step.label, guideData: step.description},
            closeOnBackdropClick: false,
        });
    }

    changeCurrentStep(order, state) {
        if (state === ComponentStates.Default) {
            this.changeStep.emit(order);
        }
    }

    public getIconFromState(state): string {
        if (state === ComponentStates.Success) return 'checkmark-circle-2-outline';
        if (state === ComponentStates.Failed) return 'close-circle-outline';
        return 'info-outline';
    }
}
