<div class="page-container">
    <nb-card>
        <nb-card-header>
            <div class="user-general-header">
                <h5>User Settings</h5>
                <ng-container [ngSwitch]="pageMode">
                    <button nbButton status="basic" (click)="goToEditMode()" *ngSwitchCase="UserSettingPageMode.VIEW">
                        <nb-icon icon="edit-outline"></nb-icon>
                        Edit
                    </button>
                    <div *ngSwitchCase="UserSettingPageMode.EDIT" class="viewMode">
                        <button nbButton status="basic" (click)="goToViewMode()">
                            <nb-icon icon="close-circle-outline"></nb-icon>
                            Cancel
                        </button>
                        <button nbButton status="info" (click)="update()" [disabled]="disableSaveBtn()">
                            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                            Save Changes
                        </button>
                    </div>
                    <div *ngSwitchCase="UserSettingPageMode.CREATE" class="viewMode">
                        <button nbButton status="info" (click)="save()" [disabled]="disableSaveBtn()">
                            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                            Save Changes
                        </button>
                    </div>
                </ng-container>
            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="user-general-container">
                <!-- User details form -->
                <div class="user-info-container">
                    <ngx-user-settings
                        class="w-100"
                        [newUserCreate]="pageMode === UserSettingPageMode.CREATE"
                        [pageMode]="pageMode"
                        [userForm]="userForm"
                        [user]="user"
                    ></ngx-user-settings>
                </div>

                <div class="user-password-container">
                    <ng-container *ngIf="pageMode !== UserSettingPageMode.CREATE">
                        <!-- SSO users have no password -->
                        <ngx-password-empty-sso *ngIf="userTypeSSO()"></ngx-password-empty-sso>

                        <!-- ENGIN users -->
                        <ngx-user-password
                            [pageMode]="pageMode"
                            *ngIf="ownProfile && !userTypeSSO()"
                            [resetPasswordForm]="resetPasswordForm"
                            [formReady]="passwordFormReady"
                        ></ngx-user-password>
                        <ngx-user-force-password
                            *ngIf="!ownProfile && !userTypeSSO()"
                            [forcePasswordForm]="forcePasswordForm"
                            [formReady]="passwordFormReady"
                            [pageMode]="pageMode"
                            [userId]="user.id"
                        ></ngx-user-force-password>
                    </ng-container>
                    <ng-container *ngIf="pageMode === UserSettingPageMode.CREATE">
                        <ngx-new-user-password
                            [pageMode]="pageMode"
                            [newUserPassword]="newUserPassword"
                            [user]="user"
                            [formReady]="passwordFormReady"
                        ></ngx-new-user-password>
                    </ng-container>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
    <ngx-user-info *ngIf="pageMode !== UserSettingPageMode.CREATE" [user]="user"></ngx-user-info>
</div>
