import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    FeederAnalysisLPService,
    FeederMetricRequest,
    InfrastructurePeakLoads,
} from '@core/interfaces/engin/load-profile-analysis/feeder-analysis';
import {FeederAnalysisLPApi} from '@core/backend/engin/api/load-profile-analysis/feeder-analysis.api';
import {SimpleMultiSeries} from '@core/interfaces/system/system-common';

@Injectable()
export class FeederAnalysisLPServiceImpl extends FeederAnalysisLPService {
    constructor(private api: FeederAnalysisLPApi) {
        super();
    }

    public getPeekData(batchNumber: number, feederId: string): Observable<InfrastructurePeakLoads> {
        return this.api.getPeekData(batchNumber, feederId);
    }

    public getFeederMetricsByBatch(
        batchNumber: number,
        data: FeederMetricRequest,
    ): Observable<SimpleMultiSeries<string>> {
        return this.api.getFeederMetricsByBatch(batchNumber, data);
    }
}
