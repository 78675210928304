<nb-card>
    <nb-card-body>
        <div class="container-fluid">
            <!-- Information -->
            <div class="col-12">
                <span class="section-label">SSO users have no password in ENGIN.</span>
            </div>
        </div>
    </nb-card-body>
</nb-card>
