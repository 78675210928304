export class DistrictSummarizePopupService {
    /*
     * Render popup for road closure feature. Works for point or line features equivalently.
     */
    getCustomPopoutFunction = (feature) => {
        const div = document.createElement('div');

        const name = feature.graphic.attributes.name;
        const description = feature.graphic.attributes.description;

        div.innerHTML = `<div class='container'>
                         <div class="row">
                            <div class="col">
                            <p>${name}</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                            <p style="font-size: 12px">Description: ${description}</p>
                            </div>
                        </div>
                      </div>`;
        return div;
    };
}
