import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UsersApi} from '../api/users.api';
import {DefaultStudyInfo, User, UserNotification, UsersService} from '@core/interfaces/common/users';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';
import {APIResponse} from '@core/interfaces/system/system-common';
import {WorkflowType} from '@core/interfaces/engin/workflow';

@Injectable()
export class UsersServiceImpl extends UsersService {
    constructor(private api: UsersApi) {
        super();
    }

    getUsersDataSource(): Observable<DataSource> {
        return this.api.getUsersDataSource();
    }

    get(id: string): Observable<User> {
        return this.api.get(id);
    }

    getCurrentUser(): Observable<User> {
        return this.api.getCurrent();
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(id);
    }

    create(user: any): Observable<any> {
        return this.api.create(user);
    }

    update(user: any): Observable<any> {
        return this.api.update(user);
    }

    updateRole(user: any): Observable<any> {
        return this.api.updateRole(user);
    }

    updatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
        return this.api.updatePassword(currentPassword, newPassword, confirmPassword);
    }

    forceUpdatePassword(userId: string, newPassword: string): Observable<any> {
        return this.api.forceUpdatePassword(userId, newPassword);
    }

    requestEmailVerification(userId: string): Observable<any> {
        return this.api.requestEmailVerifyEmail(userId);
    }
    getUsersListData(): Observable<User[]> {
        return this.api.getUsersListData();
    }
    userUnlock(id): Observable<any> {
        return this.api.userUnlock(id);
    }

    getUsersNotification(): Observable<UserNotification[]> {
        return this.api.getUsersNotification();
    }

    passwordValidation(password: string, checkPasswordHistory: boolean): Observable<APIResponse<PasswordRequirements>> {
        return this.api.passwordValidation(password, checkPasswordHistory);
    }

    public passwordValidationOtherUser(
        userId: string,
        password: string,
        checkPasswordHistory: boolean,
    ): Observable<APIResponse<PasswordRequirements>> {
        return this.api.passwordValidationOtherUser(userId, password, checkPasswordHistory);
    }

    public passwordValidationNewUser(password: string): Observable<APIResponse<PasswordRequirements>> {
        return this.api.passwordValidationNewUser(password);
    }

    userNameAvailable(userName: string): Observable<APIResponse<Boolean>> {
        return this.api.userNameAvailable(userName);
    }
    getUserByUserName(userName: string): Observable<User> {
        return this.api.getUserByUserName(userName);
    }

    userEmailAvailable(email: string, id: string): Observable<APIResponse<Boolean>> {
        return this.api.userEmailAvailable(email, id);
    }

    public inviteUserToOrganization(tenantId: string, username: string): Observable<any> {
        return this.api.inviteUserToOrganization(tenantId, username);
    }

    /*    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any> {
        return this.api.updateDefaultStudyStrategy(latestAsDefault, defaultCollectionId);
    }*/
    public getDefaultStudyInfo(): Observable<APIResponse<DefaultStudyInfo>> {
        return this.api.getDefaultStudyInfo();
    }

    public updateDefaultStudyStrategy(latestAsDefault: boolean): Observable<APIResponse<DefaultStudyInfo>> {
        return this.api.updateDefaultStudyStrategy(latestAsDefault);
    }

    public updateDefaultStudyByType(type: WorkflowType, workflowId: number): Observable<APIResponse<DefaultStudyInfo>> {
        return this.api.updateDefaultStudyByType(type, workflowId);
    }
}
