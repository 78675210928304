import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {ColorsService} from '@core/utils';
import {take} from 'rxjs/operators';
import {ConfigurationStore} from '@store/config/configuration.store';

@Component({
    selector: 'ngx-alert-status-cell',
    template: `
        <div class="status-cell" *ngIf="icon && iconLabel">
            <nb-icon pack="eva" [icon]="icon" [style.color]="iconColor"></nb-icon>

            <span class="status-label">{{ iconLabel }}</span>
        </div>
    `,
    styleUrls: ['./alert-status-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertStatusCellComponent implements ViewCell, OnInit {
    icon: string;
    iconLabel: string;
    iconColor: string;

    @Input() value: any;
    @Input() rowData: any;

    constructor(private colorsService: ColorsService, private configurationStore: ConfigurationStore) {}

    ngOnInit(): void {
        this.colorsService.colorsMapShared$.pipe(take(1)).subscribe((colors) => {
            switch (this.value) {
                case 'REACTIVE_PRIORITY1':
                case 'REACTIVE_PRIORITY2':
                case 'REACTIVE_PRIORITY3':
                case 'REACTIVE_PRIORITY4':
                case 'REACTIVE_PRIORITY5':
                case 'PREDICT_1MONTH':
                case 'PREDICT_3MONTH':
                case 'PREDICT_6MONTH':
                case 'PREDICT_1YEAR':
                case 'PREDICT_3YEAR':
                case 'PREDICT_5YEAR':
                    this.icon = 'alert-triangle';
                    break;
                case 'INFORMATION':
                    this.icon = 'alert-circle';
                    break;
                case 'NONE':
                    this.icon = 'checkmark-circle-2';
                    break;
                default:
                    this.icon = null;
                    this.iconLabel = null;
                    this.iconColor = null;
            }

            this.iconLabel = this.configurationStore.statusToLabel(this.value);
            this.iconColor = this.iconLabel ? colors['Alert Statuses'][this.value] : null;
        });
    }
}
