import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PagesStore, SidebarStore} from '../../@store';
import {filter} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {Observable} from 'rxjs';
import {User} from '@core/interfaces/common/users';
import {DatePipe} from '@angular/common';
import {UsersStore} from '@store/common/users.store';
import {ColorsService} from '@core/utils';
import {DashboardBodyType, DashboardCardBody} from '@core/interfaces/common/pages';

@Component({
    selector: 'ngx-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardComponent {
    readonly currentUser$: Observable<User> = this.usersStore.currentUser$.pipe(filter((u) => u && !!u));
    readonly userNotifications$ = this.usersStore.getUserNotifications$;

    readonly pageGroups$ = this.pagesStore.pageGroups$;

    readonly colors = this.colorsService.colorsMapShared$;

    constructor(
        private sidebarStore: SidebarStore,
        public pagesStore: PagesStore,
        private usersStore: UsersStore,
        private toastrService: NbToastrService,
        private datePipe: DatePipe,
        private colorsService: ColorsService,
    ) {
        this.checkFromLoginPage();
        //change sidebar expanded state to false
        sidebarStore.toggleSidebarState(false);
    }

    private checkFromLoginPage() {
        const fromLoginPage = JSON.parse(localStorage.getItem('fromLogin'));
        if (fromLoginPage) {
            this.usersStore.fromLogin.next(fromLoginPage);
            localStorage.removeItem('fromLogin');
        } else {
            this.usersStore.fromLogin.next(false);
        }
    }

    public getDashboardBody(id: string, body: DashboardCardBody): DashboardCardBody {
        return (
            body || {
                id: id,
                type: DashboardBodyType.Empty,
            }
        );
    }
}
