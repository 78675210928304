import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {Observable} from 'rxjs';
import {UserSettingPageMode} from '../../../../pages/users/user/profile-settings/general/general.component';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';

/**
 * Change password component
 * - Requires auth (must be logged in)
 * - User enters current, new, confirm password to reset password for their own account.
 * - Optional expanded (default; e.g. during login process) or collapsed (e.g. user profile) format.
 */
@Component({
    selector: 'ngx-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChangePasswordComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() pageMode: UserSettingPageMode;
    @Input() passwordHistory: boolean;
    @Input() validation$: Observable<PasswordRequirements>;
    @Input() layoutCompressed: boolean = false;
    @Output() onInputChanges = new EventEmitter();

    constructor() {}

    ngOnInit(): void {
        this.form.addValidators(this.mustMatch('newPassword', 'confirmPassword'));
        this.form.addValidators(this.mustNotMatch('newPassword', 'currentPassword'));
    }

    get currentPassword(): FormControl {
        return this.form.get('currentPassword') as FormControl;
    }

    get newPassword(): FormControl {
        return this.form.get('newPassword') as FormControl;
    }

    get confirmPassword(): FormControl {
        return this.form.get('confirmPassword') as FormControl;
    }

    onPasswordChanged($event) {
        this.onInputChanges.emit({password: $event.target.value});
    }

    private mustMatch(controlName: string, matchingControlName: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors => {
            const matchingControl = formGroup.controls[matchingControlName];
            const currentControl = formGroup.controls[controlName];
            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                return;
            }
            if (currentControl.value !== matchingControl.value) {
                matchingControl.setErrors({mustMatch: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    private mustNotMatch(controlName: string, secondControlName: string): ValidatorFn {
        return (formGroup: FormGroup): ValidationErrors => {
            const currentControl = formGroup.controls[controlName];
            const secondControl = formGroup.controls[secondControlName];
            if (currentControl.errors && !currentControl.errors.mustNotMatch) {
                return;
            }
            if (currentControl.value === secondControl.value) {
                currentControl.setErrors({mustNotMatch: true});
            } else {
                currentControl.setErrors(null);
            }
        };
    }
}
