<nb-accordion multi="true" class="accordion-container">
    <nb-accordion-item
        class="accordion-item"
        *ngFor="let section of accordionData; index as i"
        [expanded]="expandedSection === section.code || (expandedSection === 'analytic' && section.depth === 2)"
    >
        <!-- Header -->
        <nb-accordion-item-header class="accordion-item-header">
            {{ mapCodeToTitle(section.code) }}
        </nb-accordion-item-header>

        <!-- Body -->
        <nb-accordion-item-body class="accordion-item-body">
            <ng-container [ngSwitch]="section.depth">
                <!-- Sections with one level of depth add level with selected items -->
                <ng-container *ngSwitchCase="1">
                    <span class="summary-font">
                        {{ getSelectedNumber(section.data) }} {{ section.summaryUnit }} Selected
                    </span>
                    <nb-list class="list">
                        <nb-list-item *ngFor="let item of section.data" class="list-item">
                            <div class="d-flex">
                                <span class="list-item-icon-container">
                                    <nb-icon class="icon-border-light" icon="checkmark-circle-2-outline"></nb-icon>
                                </span>
                                <span class="list-item-text">{{ item.name }}</span>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </ng-container>

                <!-- Sections with two levels of depth expect a total at the first item -->
                <ng-container *ngSwitchCase="2">
                    <nb-list class="list">
                        <!-- Total element -->
                        <nb-list-item class="list-item" *ngIf="section.dataTotal as totalSection">
                            <div class="d-flex">
                                <span class="list-item-icon-container">
                                    <nb-icon class="icon-border-dark" icon="cube"></nb-icon>
                                </span>

                                <span class="col-11 list-item-text">
                                    <span class="d-flex justify-content-between">
                                        <span>{{ totalSection.name }}</span>
                                        <span>{{ totalSection.value }}</span>
                                    </span>
                                </span>
                            </div>
                        </nb-list-item>

                        <!-- General list items, could have children -->
                        <nb-list-item
                            class="list-item"
                            *ngFor="let item of section.data"
                            [ngClass]="item.children === null ? 'list-item' : 'list-item-multi'"
                        >
                            <div class="d-flex">
                                <!-- Items with children show expand/collapse menu -->
                                <ng-container *ngIf="item.children !== null">
                                    <span
                                        class="list-item-icon-container"
                                        (click)="item.isCollapsed = !item.isCollapsed"
                                    >
                                        <!--<nb-icon class="icon-filled" icon="plus-square" *ngIf="item.isCollapsed"></nb-icon>-->
                                        <nb-icon
                                            class="icon-border-highlight"
                                            icon="plus-square-outline"
                                            *ngIf="item.isCollapsed"
                                        ></nb-icon>
                                        <nb-icon
                                            class="icon-border-light"
                                            icon="minus-square-outline"
                                            *ngIf="!item.isCollapsed"
                                        ></nb-icon>
                                    </span>

                                    <span class="col-11 list-item-text" (click)="item.isCollapsed = !item.isCollapsed">
                                        <span class="d-flex justify-content-between">
                                            <span>{{ item.name }}</span>
                                            <span>{{ item.value }}</span>
                                        </span>
                                        <div [(ngbCollapse)]="item.isCollapsed">
                                            <nb-list class="list">
                                                <nb-list-item
                                                    class="list-item"
                                                    style="border-top: none"
                                                    *ngFor="let subitem of item?.children"
                                                >
                                                    <span
                                                        style="padding-left: 15px"
                                                        class="d-flex justify-content-between pl-3"
                                                    >
                                                        <span>{{ subitem.name }}</span>
                                                        <span>{{ subitem.value }}</span>
                                                    </span>
                                                </nb-list-item>
                                            </nb-list>
                                        </div>
                                    </span>
                                </ng-container>

                                <!-- Items without children -->
                                <ng-container *ngIf="item.children === null">
                                    <span class="list-item-icon-empty"></span>

                                    <span class="col-11 list-item-text">
                                        <span class="d-flex justify-content-between">
                                            <span>{{ item.name }}</span>
                                            <span>{{ item.value }}</span>
                                        </span>
                                    </span>
                                </ng-container>
                            </div>
                        </nb-list-item>
                    </nb-list>
                </ng-container>
            </ng-container>
        </nb-accordion-item-body>
    </nb-accordion-item>
</nb-accordion>
