import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LatestModelStatus} from '@core/interfaces/live/live';
import {
    HydrogenGasTrendingDashboardData,
    HydrogenTrendingService,
    HydrogenUnits,
} from '@core/interfaces/live/hydrogen-tending';
import {HydrogenTrendingApi} from '@core/backend/engin/api/live/hydrogen-trending.api';

@Injectable()
export class HydrogenTrendingServiceImpl extends HydrogenTrendingService {
    constructor(private api: HydrogenTrendingApi) {
        super();
    }

    public getHydrogenGasTrendingUnits(): Observable<HydrogenUnits> {
        return this.api.getHydrogenGasTrendingUnits();
    }

    public getHydrogenGasTrendingDatasource(id: string): Observable<DataSource> {
        return this.api.getHydrogenGasTrendingDatasource(id);
    }

    public getCountResults(id: string, uploadDate?: string): Observable<number> {
        return this.api.getCountResults(id, uploadDate);
    }

    public getHydrogenGasTrendingLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus> {
        return this.api.getHydrogenGasTrendingLatestModelStatus(id, uploadDate);
    }

    public getHydrogenGasTrendingDashboardData(
        chart: string,
        id: string,
        uploadDate?: string,
    ): Observable<HydrogenGasTrendingDashboardData> {
        return this.api.getHydrogenGasTrendingDashboardData(chart, id, uploadDate);
    }
}
