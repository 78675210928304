import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {debounceTime, distinctUntilChanged, filter, map, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {NbOptionComponent} from '@nebular/theme';

interface MultiselectOption<T> {
    option: T;
    label: string;
}

@Component({
    selector: 'ngx-button-multiselect',
    templateUrl: './button-multiselect.component.html',
    styleUrls: ['./button-multiselect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ButtonMultiselectComponent),
            multi: true,
        },
    ],
})
export class ButtonMultiselectComponent<
        T extends {
            fieldGroup: string;
            disabled: boolean;
        },
    >
    extends Unsubscribable
    implements OnInit
{
    @Input() options$: Observable<MultiselectOption<T>[]>;
    @Input() defaultGroupTitle: string;
    @Input() iconDetails: {icon: string; pack?: string} = {icon: 'plus-outline'};
    @Input() btnTitle: string = '';
    @Input() status: string = 'basic';
    @Input() size: string = 'medium';
    @Output() optionChange: EventEmitter<{option: T; selected: boolean}> = new EventEmitter();

    @ViewChild('filterInput') filterInput: ElementRef;

    options: MultiselectOption<T>[] = [];
    fieldGroups;

    _selectControl: FormControl = new FormControl([]);

    filterControl: FormControl = new FormControl();

    isButtonHover = false;
    disabled = false;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.options$.pipe(takeUntil(this.unsubscribe$)).subscribe((_options: MultiselectOption<T>[]) => {
            this.options = _options;
            this.fieldGroups = Array.from(
                new Set(this.options.map((item: MultiselectOption<T>) => item.option.fieldGroup)),
            );
        });

        this._selectControl.valueChanges
            .pipe(
                takeUntil(this.unsubscribe$),
                distinctUntilChanged(),
                filter((value) => {
                    //when focusing on search input checkbox selects
                    //this removes search-input's value from this.selectControl
                    // and stops the process of subscription to this event
                    let isSearch = value?.includes('');
                    if (isSearch) {
                        let _value = value.filter((item) => item !== '');
                        this._selectControl.setValue(_value, {emitEvent: false});
                    }

                    return !isSearch;
                }),
                map((value: any[]) => {
                    let _value = value;
                    if (value && value.length === this.options.length && !value.includes(0)) {
                        _value = [...value, 0];
                        this._selectControl.setValue(_value, {emitEvent: false});
                    } else if (value && value.length !== this.options.length + 1) {
                        _value = value.filter((item) => item !== 0);
                        this._selectControl.setValue(_value, {emitEvent: false});
                    }
                    return _value;
                }),
                debounceTime(500),
            )
            .subscribe((value: any[]) => {
                this.emitValue(value);
            });
    }

    writeValue(value: any): void {
        if (value.length === this.options.length) value.push(0);
        this._selectControl.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
    }

    toggleSelectAll() {
        if (this._selectControl.value.length >= this.options.length) {
            let _disabled: T[] = [];
            this.options.forEach((_option: MultiselectOption<T>) => {
                if (_option.option.disabled) _disabled.push(_option.option);
            });
            this._selectControl.reset(_disabled);
        } else {
            let _options: T[] = this.options.reduce((acc: T[], curr: MultiselectOption<T>) => {
                return [...acc, curr.option];
            }, []);
            this._selectControl.setValue([..._options, 0]);
        }
    }

    private onChange = (value: any) => {};

    private onTouched = () => {};

    private emitValue(value: any) {
        this.onChange(value.filter((item) => !!item));
        this.onTouched();
    }

    onSelectChange(option: NbOptionComponent) {
        this.optionChange.emit({option: option.value, selected: option.selected});
    }

    getShouldShowOption(option: MultiselectOption<T>) {
        let search = this.filterControl.value;
        if (!search) return true;
        search = search.toLowerCase();
        this.filterInput.nativeElement.focus();
        return option.label.toLowerCase().indexOf(search) > -1;
    }
}
