import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, SimpleSeries} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';
import {LpInfrastructureRequest} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';

@Injectable()
export class PowerMeterReadingApi {
    private readonly prefix = 'load-profile-analysis/readings/corrected';

    constructor(private api: HttpService) {}

    public getInfrastructureNetLoad(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]> {
        return this.api
            .post(`${this.prefix}/${batchNumber}/chart/net-load`, data)
            .pipe(map((res: APIResponse<SimpleSeries<Date, number>[]>) => res.response));
    }
}
