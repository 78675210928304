<nb-card *ngIf="workflows$ | async as workflowList" [class.pointer-none]="!dataReady">
    <nb-card-header class="pb-1">{{ title }}</nb-card-header>

    <nb-card-body class="chart-card-body degradation-card">
        <!-- Study collection dropdown -->
        <nb-select fullWidth class="w-100" placeholder="Select Study Collection" [formControl]="formControl">
            <nb-option *ngFor="let workflow of workflowList" [value]="workflow.id">
                {{ getWorkflowName(workflow) }}
            </nb-option>
        </nb-select>

        <div class="pl-3">
            <nb-radio-group
                (valueChange)="onWorkflowItemSelected($event)"
                *ngIf="activeWorkflow"
                [(ngModel)]="workflowItemId"
            >
                <nb-radio *ngFor="let workflowItem of activeWorkflow.studies" [value]="workflowItem.id">
                    {{ workflowItem.itemName }}
                </nb-radio>
            </nb-radio-group>
        </div>
    </nb-card-body>
</nb-card>
