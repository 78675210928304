import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {SystemViewRequest} from '../@core/interfaces/engin/planner/system-view-request';
import {filter} from 'rxjs/operators';

@Injectable()
export class PlannerFilterStore {
    // ======== show filter based on Page
    public currentPage$ = new Subject<String>();

    public updateUrl(latestUrl) {
        this.currentPage$.next(latestUrl);
    }

    private currentUrl = new BehaviorSubject<string>('');
    readonly currentUrl$ = this.currentUrl.asObservable().pipe(filter((url) => !!url));

    // ======== filter value
    public serverFilter$ = new BehaviorSubject(new SystemViewRequest(40, 2, 2, 2));

    public updateServerFilter(latestValue) {
        this.serverFilter$.next(latestValue);
    }

    // ======== project and alternative value
    public currentProjAlt$ = new BehaviorSubject<number[]>([1, 61]);

    public updateCurrentProjAlt(latestValue) {
        this.currentProjAlt$.next(latestValue);
    }
}

// .pipe(
//     debounceTime(1000),
// )

// .pipe(tap(request => {
//     if (request.priorityRiskMitigation !== 2) {
//         request.prioritySAID
//         I = 2;
//         request.prioritySAIFI = 2;
//     }
//     if (request.prioritySAIDI !== 2) {
//         request.priorityRiskMitigation = 2;
//         request.prioritySAIFI = 2;
//     }
//     if (request.prioritySAIFI !== 2) {
//         request.priorityRiskMitigation = 2;
//         request.prioritySAIDI = 2;
//     }
// }))
