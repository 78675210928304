import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {StationInfo} from '@core/interfaces/engin/load-forecast/station';

@Injectable()
export class StationLoadForecastApi {
    private readonly PREFIX_STATION = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for station(s)
     */
    public getAllStationsPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/pivot?${params}`,
        );
    }

    public getAllStationsPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getStationInfo(workflowItemId: number, scenarioId: string, stationId: string): Observable<StationInfo[]> {
        return this.api.get(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/${stationId}/info`,
        );
    }

    public getStationViolationsByInfrastructureType(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld> {
        return this.api.get(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/${stationId}/violations`,
            {
                params: new HttpParams()
                    .set('infrastructureType', viewBy)
                    .set('seasonTiming', seasonTiming)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getStationForecastByInfrastructureType(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        seasonTiming: string,
        viewBy: string,
        filterViolations: boolean,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/${stationId}/forecast`,
            {
                params: new HttpParams()
                    .set('infrastructureType', viewBy)
                    .set('seasonTiming', seasonTiming)
                    .set('filterViolations', filterViolations)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getStationBreakdownPivot(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/${stationId}/pivot?${params}`,
        );
    }

    public getStationBreakdownPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        stationId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_STATION}/${workflowItemId}/scenarios/${scenarioId}/stations/${stationId}/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }
}
