import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {
    AnalyzerRequest,
    AnalyzerResponse,
    AnalyzerService,
    ConsequenceCategory,
    ConsequenceConfig,
    ConsequenceTypeEnum,
    ConsequenceTypeLabel,
} from '@core/interfaces/engin/analyzer';
import {catchError, filter, share, switchMap, takeUntil, tap} from 'rxjs/operators';
import {StudiesStore} from '../common/studies.store';
import {PagesStore} from '../config/pages.store';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {EnginErrorHandlerService} from '@core/error-handling/engin-error-handler.service';
import {of} from 'rxjs/internal/observable/of';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {APIException} from '@core/error-handling/exception.dto';
import {AssetsService} from '@core/interfaces/engin/assets';
import {WorkflowInfo, WorkflowItemInfo} from '@core/interfaces/common/users';
import {AnalyzerControlStore} from '@store/analyzer/analyzer-control.store';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Injectable()
export class AnalyzerStore extends Unsubscribable {
    public resultsLoading = new BehaviorSubject<boolean>(false);
    public resultsLoading$ = this.resultsLoading.asObservable();

    private consequenceConfig: BehaviorSubject<ConsequenceConfig> = new BehaviorSubject<ConsequenceConfig>(null);
    readonly consequenceConfig$: Observable<ConsequenceConfig> = this.consequenceConfig
        .asObservable()
        .pipe(filter((d) => d && !!d));

    constructor(
        private analyzerService: AnalyzerService,
        private assetsService: AssetsService,
        private studiesStore: StudiesStore,
        private popoutStore: AnalyzerControlStore,
        private pagesStore: PagesStore,
        private errorService: EnginErrorHandlerService,
    ) {
        super();

        this.studiesStore.activeStudyIdRisk$
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((workflowItemId: number) => {
                this.analyzerService
                    .getConsequenceConfiguration(workflowItemId)
                    .subscribe((res: APIResponse<ConsequenceConfig>) => {
                        this.consequenceConfig.next(res.response);
                    });
            });
    }

    // Helper functions for LiveConfig
    public consequenceLabel(category: ConsequenceTypeEnum): string {
        if (this.consequenceConfig.value) {
            const mapping: ConsequenceCategory[] = this.consequenceConfig.value.configList;
            return mapping.find((c) => c.categoryCode === category)?.category || ConsequenceTypeLabel[category];
        }
        return ConsequenceTypeLabel[category];
    }

    readonly combineAnalyzerRequestInformation$ = combineLatest<
        Observable<AnalyzerRequest>,
        Observable<WorkflowInfo>,
        Observable<DisplaySettings>
    >([
        this.popoutStore.currentPopout$,
        this.studiesStore.activeWorkflowRisk$.pipe(filter((d) => !!d)),
        this.pagesStore.currentDisplay$,
    ]);

    // EOL metrics, Demographics
    readonly abstractChartData$: Observable<AnalyzerResponse[]> = this.combineAnalyzerRequestInformation$.pipe(
        tap(() => this.resultsLoading.next(true)),
        switchMap(([popoutValue, activeWorkflowInfo, displaySettings]) => {
            const req = this.analyzerService.prepareAnalyzerRequest(popoutValue, displaySettings, activeWorkflowInfo);
            const activeStudyId: number = this.analyzerService.getActiveStudyId(popoutValue, activeWorkflowInfo);
            return this.analyzerService.getAnalyzerDataAbstract(req, activeStudyId);
        }),
        switchMap((res: APIResponse<AnalyzerResponse[] | APIException>) => {
            const resp = res.response as AnalyzerResponse[];
            return of(resp);
        }),
        tap(() => {
            this.resultsLoading.next(false);
        }),
        share(),
        catchError((error) => {
            const exception = error.response as APIException;
            this.errorService.handleCustomException(exception);
            return of([]);
        }),
    );

    // Study assets
    readonly studyAssetsDataSource$: Observable<DataSource> = this.combineAnalyzerRequestInformation$.pipe(
        tap(() => this.resultsLoading.next(true)),
        switchMap(([popoutValue, activeWorkflowInfo, displaySettings]) => {
            // This call joins active study collection to popout settings
            const req = this.analyzerService.prepareAnalyzerRequest(popoutValue, displaySettings, activeWorkflowInfo);
            let filters: Filter[] = [];
            if (
                !displaySettings?.controlPanel?.disableEntirePanel &&
                !displaySettings?.controlPanel?.disableSectionFilter
            ) {
                filters = req.filterList;
            }
            const activeStudyId = this.analyzerService.getActiveStudyId(popoutValue, activeWorkflowInfo);
            return this.assetsService.getStudyAssetsDataSource(activeStudyId, filters);
        }),
        tap(() => this.resultsLoading.next(false)),
    );
}
