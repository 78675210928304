import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {FormControl} from '@angular/forms';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {distinctUntilChanged, takeUntil} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'ngx-toggle-cell',
    template: `
        <div class="action-cell">
            <nb-toggle [formControl]="toggleFormControl" [checked]="value"></nb-toggle>
        </div>
    `,
})
export class ToggleCellComponent<T> extends Unsubscribable implements OnInit, ViewCell {
    @Input() value: any;
    @Input() rowData: T;
    @Input() toggledArray$: Observable<T[]>;
    @Input() simpleToggle = false;
    @Input() disabled = (rowData: T) => false;
    @Output() onSelectChange: EventEmitter<{toggle: boolean; rowData: T}> = new EventEmitter();

    toggleFormControl: FormControl = new FormControl();

    constructor() {
        super();
    }

    ngOnInit() {
        if (!this.simpleToggle) {
            this.toggledArray$
                .pipe(takeUntil(this.unsubscribe$), distinctUntilChanged())
                .subscribe((array: T[]) =>
                    this.toggleFormControl.setValue(array.includes(this.rowData), {emitEvent: false}),
                );
            this.disabled(this.rowData) && this.toggleFormControl.disable();
        } else {
            this.toggleFormControl.setValue(this.value, {emitEvent: false});
            this.disabled(this.rowData) && this.toggleFormControl.disable();
        }

        this.toggleFormControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: boolean) => this.onSelectChange.emit({toggle: value, rowData: this.rowData}));
    }
}
