import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SimpleSeries, SimpleUnit, ThresholdStatic} from '@core/interfaces/system/system-common';
import {LatestModelStatus} from '@core/interfaces/live/live';

export interface ThicknessRateUnits {
    lengthUnit: SimpleUnit;
    rateUnit: SimpleUnit;
}

export interface ThicknessRateDashboardData {
    data: SimpleSeries<Date, number>;
    thresholds: ThresholdStatic[];
}

export abstract class ThicknessRateService {
    abstract getThicknessRateUnits(): Observable<ThicknessRateUnits>;

    abstract getThicknessRateDatasource(id: string): Observable<DataSource>;

    abstract getCountResults(id: string, uploadDate?: string): Observable<number>;

    abstract getThicknessRateLatestModelStatus(id: string, uploadDate?: string): Observable<LatestModelStatus>;

    abstract getThicknessRateDashboardData(
        chart: string,
        id: string,
        uploadDate?: string,
    ): Observable<ThicknessRateDashboardData>;
}
