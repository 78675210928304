<div [ngSwitch]="tableType">
    <ng-container *ngSwitchCase="TableValueType.StringValues">
        <div class="dashboard-row string-values" *ngFor="let row of rows">
            <p
                class="label"
                *ngIf="row.label"
                #label
                [nbTooltip]="row.label"
                [nbTooltipTrigger]="label.innerText !== row.label ? NbTrigger.HOVER : NbTrigger.NOOP"
            >
                {{ row.label }}
            </p>
            <p
                class="text"
                *ngIf="row.value"
                #text
                [nbTooltip]="row.value"
                [nbTooltipTrigger]="text.innerText !== row.value ? NbTrigger.HOVER : NbTrigger.NOOP"
            >
                {{ row.value }}
            </p>
        </div>
    </ng-container>

    <ng-container *ngSwitchCase="TableValueType.NumberValues">
        <div class="dashboard-row numbers-values" *ngFor="let row of rows">
            <p
                class="label"
                *ngIf="row.label"
                #label
                [nbTooltip]="row.label"
                [nbTooltipTrigger]="label.innerText !== row.label ? NbTrigger.HOVER : NbTrigger.NOOP"
            >
                {{ row.label }}
            </p>
            <p class="number">{{ row.value }}</p>
        </div>
    </ng-container>
</div>
