import {CHART_SORT_TYPE, ChartSortOption} from './chart-data-sort.interface';
import {SimpleSeries} from '../../../@core/interfaces/engin/data-audit/asset-data-audit';

export class ChartDataSortService {
    public static sortChartData(originalSeries: SimpleSeries[], sortOption: ChartSortOption) {
        // Aggregate data together, assuming all labels are the same
        const aggData = [];
        for (let i = 0; i < originalSeries[0].labels.length; i++) {
            const elem = {};
            elem['label'] = originalSeries[0].labels[i];
            if (originalSeries != null) {
                originalSeries.forEach((series) => {
                    elem[series.code] = series.data[i];
                });
                aggData.push(elem);
            }
        }

        // Sort aggregated data based on sort order
        const sortOrder = sortOption.order.code;
        const sortParam = sortOption.series.code;
        const sortedAggData = aggData.sort((a, b) => {
            switch (sortOrder) {
                case CHART_SORT_TYPE.ASC:
                    return a[sortParam] < b[sortParam] ? 1 : -1;
                case CHART_SORT_TYPE.DESC:
                    return a[sortParam] > b[sortParam] ? 1 : -1;
            }
        });

        // Disaggregate the sorted series
        const sortedLabels = sortedAggData.map((elem) => elem['label']);
        const sortedSeries = originalSeries.map((series) => {
            const propertyCode = series.code;
            const sortedData = sortedAggData.map((elem) => elem[propertyCode]);
            return {
                ...series,
                data: sortedData,
                labels: sortedLabels,
            };
        });

        return sortedSeries;
    }
}
