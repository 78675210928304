import {Component, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DefaultFilter} from '@mominsamir/ngx-smart-table';
import {FormControl} from '@angular/forms';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {Subscription} from 'rxjs';

@Component({
    template: `
        <input
            class="form-control"
            [maxlength]="maxQueryLength"
            placeholder="{{ column.title }}"
            [formControl]="inputControl"
        />
    `,
})
export class InputFilterComponent extends DefaultFilter implements OnInit, OnChanges, OnDestroy {
    maxQueryLength = 200;
    inputControl: FormControl = new FormControl();

    inputControlSubscription: Subscription;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.inputControlSubscription = this.inputControl.valueChanges
            .pipe(distinctUntilChanged(), debounceTime(this.delay))
            .subscribe((value: string) => {
                this.query = value || '';
                this.setFilter();
            });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();

        if (this.inputControlSubscription) {
            this.inputControlSubscription.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.query) {
            this.query = changes.query.currentValue;
            this.inputControl.setValue(this.inputControl.value);
        }
    }
}
