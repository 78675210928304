import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Workflow, WorkflowItem, WorkflowService} from '@core/interfaces/engin/workflow';
import {TimeLocalizePipe} from '../../pipes';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter, map, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {switchMap} from 'rxjs/internal/operators/switchMap';
import {APIResponse, PagingResponse} from '@core/interfaces/system/system-common';
import {FormControl} from '@angular/forms';
import {ConfirmDialogComponent} from '@theme/components';
import {NbDialogService} from '@nebular/theme';

@Component({
    selector: 'ngx-workflow-selector',
    templateUrl: './workflow-selector.component.html',
    styleUrls: ['./workflow-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkflowSelectorComponent extends Unsubscribable implements OnInit {
    // Component inputs
    @Input() title: string;
    @Input() dataReady: boolean;
    @Input() isDefaultTemplateChanged: boolean;

    // Component outputs/events
    @Output() workflowItemChange: EventEmitter<number> = new EventEmitter<number>();

    // Component internal data
    activeWorkflow: Workflow;
    private activeWorkflowId: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    workflowItemId: number;
    multipleStudyList: boolean;

    formControl = new FormControl();

    constructor(
        private timeLocalizePipe: TimeLocalizePipe,
        private workflowService: WorkflowService,
        private dialogService: NbDialogService,
    ) {
        super();
    }

    ngOnInit() {
        this.activeWorkflowId
            .asObservable()
            .pipe(
                takeUntil(this.unsubscribe$),
                filter((d) => d && !!d),
                switchMap((workflowId: number) => {
                    return this.workflowService.getWorkflowById(workflowId);
                }),
            )
            .subscribe((workflow: APIResponse<Workflow>) => {
                this.activeWorkflow = workflow.response as Workflow;
                this.workflowItemId =
                    this.activeWorkflow.studies.find((s: WorkflowItem) => s.defaultItem).id ||
                    this.activeWorkflow.studies[0].id;
                this.workflowItemChange.emit(this.workflowItemId);
                this.multipleStudyList = this.activeWorkflow.studies.length > 1;
            });

        this.formControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((workflowId: number) => {
            if (this.isDefaultTemplateChanged) {
                let context: any = {
                    title: `Reset configuration`,
                    message: `Changing Study collection will reset Advanced configuration to default. Are you sure you want to proceed?`,
                };
                this.dialogService
                    .open(ConfirmDialogComponent, {
                        context,
                        closeOnBackdropClick: false,
                    })
                    .onClose.subscribe((res) => {
                        if (!!res?.confirm) {
                            this.activeWorkflowId.next(workflowId);
                        } else this.formControl.setValue(this.activeWorkflowId.value, {emitEvent: false});
                    });
            } else this.activeWorkflowId.next(workflowId);
        });
    }

    readonly workflows$: Observable<Workflow[]> = this.workflowService.listLatestWorkflows(50, 'study').pipe(
        map((resp: PagingResponse<Workflow>) => {
            return resp.items.filter((w: Workflow) => w.status === 'SUCCESS' || w.status === 'SUCCESS_WITH_WARNING');
        }),
    );

    onWorkflowItemSelected(newWorkflowItemId: number) {
        if (this.multipleStudyList) {
            this.workflowItemChange.emit(newWorkflowItemId);
        }
    }

    public getWorkflowName(w: Workflow): string {
        return `Snapshot V${w.snapshotVersion}: ${this.timeLocalizePipe.transform(w.createdOn)}`;
    }
}
