import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {Component, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
    selector: 'ngx-delete-dialog',
    template: `
        <nb-card>
            <nb-card-header>
                <div class="dialog-header">
                    <div class="header-text">File Delite</div>
                    <div class="header-icon">
                        <button nbButton ghost status="info" (click)="closeMe()">
                            <nb-icon icon="close-outline"></nb-icon>
                        </button>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body>Are you sure you want to delete this file?</nb-card-body>
            <nb-card-footer>
                <div class="dialog-footer">
                    <button class="close-btn" nbButton (click)="closeMe()">
                        <nb-icon icon="close-outline"></nb-icon>
                        Cancel
                    </button>
                    <button class="save-btn" nbButton status="info" (click)="save()">
                        <nb-icon icon="trash-2-outline"></nb-icon>
                        Yes
                    </button>
                </div>
            </nb-card-footer>
        </nb-card>
    `,
    styleUrls: ['delete-dialog.component.scss'],
    // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeleteDialogComponent extends Unsubscribable {
    constructor(@Optional() private dialogRef: NbDialogRef<Boolean>) {
        super();
    }

    save() {
        this.dialogRef.close(true);
    }

    closeMe() {
        this.dialogRef.close();
    }
}
