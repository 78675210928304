import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {APIResponse} from '../../../interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {Snapshot, SnapshotService} from '../../../interfaces/common/snapshot';
import {SnapshotApi} from '../api/snapshot.api';

@Injectable()
export class SnapshotServiceImpl extends SnapshotService {
    constructor(private api: SnapshotApi) {
        super();
    }

    create = (data: Snapshot): Observable<APIResponse<Snapshot>> => this.api.create(data);

    getDataSource = (): Observable<DataSource> => this.api.getDataSource();

    findOne = (id: string): Observable<APIResponse<Snapshot>> => this.api.findOne(id);

    update = (id: string, data: Snapshot): Observable<APIResponse<Snapshot>> => this.api.update(id, data);

    delete = (snapshotId: string): Observable<APIResponse<boolean>> => this.api.delete(snapshotId);

    reversePendingDelete(snapshotId: string): Observable<APIResponse<boolean>> {
        return this.api.reversePendingDelete(snapshotId);
    }

    findByDataSetId = (id: string): Observable<APIResponse<any>> => this.api.findByDataSetId(id);
}
