import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import {Observable} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {HttpCancelService} from '@core/backend/common/services/http-cancel.service';

@Injectable()
export class RequestCancelInterceptor implements HttpInterceptor {
    constructor(private httpCancelService: HttpCancelService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Check if the request should be excluded from cancellation
        if (req.headers.get('X-Exclude-Cancel')) {
            return next.handle(req).pipe(takeUntil(this.httpCancelService.onForceCancelPendingRequests()));
        }

        const requestWithCancellation = req.clone({
            headers: req.headers.set('X-Cancel-Request', 'true'),
        });

        return next.handle(requestWithCancellation).pipe(takeUntil(this.httpCancelService.onCancelPendingRequests()));
    }
}
