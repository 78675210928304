import {Observable} from 'rxjs';
import {UploadFileCreate} from '@core/interfaces/common/data-file';

export enum UploadFailureDataType {
    file = 'file',
    record = 'record',
}

export interface DownloadLink {
    link: string;
}

export abstract class LegacyUploadsService {
    abstract processFCFile(fileData: UploadFileCreate, comment: string, purge: boolean): Observable<any>;
}
