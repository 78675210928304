import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-popover-message-box',
    templateUrl: './popover-message-box.component.html',
    styleUrls: ['./popover-message-box.component.scss'],
})
export class PopoverMessageBoxComponent implements OnInit {
    @Input() messageType: string;
    message;
    constructor() {}

    ngOnInit(): void {
        switch (this.messageType) {
            case 'coming_soon':
                this.message = 'Nothing here yet. Check back later.';
                break;
            default:
                break;
        }
    }
}
