import {Directive, ElementRef, HostListener, Inject, Input, OnChanges, PLATFORM_ID, SimpleChanges} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';

@Directive({
    selector: '[ngxTruncateText]',
})
export class TruncateTextDirective implements OnChanges {
    @Input() ngxTruncateText: string;

    /** The native HTMLElement. */
    private get el(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    /** The original innerText; */
    private innerText: string;

    constructor(private readonly elementRef: ElementRef, @Inject(PLATFORM_ID) private readonly platformId) {}

    @HostListener('window:resize')
    private onWindowResize() {
        this.truncate();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ngxTruncateText) {
            setTimeout(() => this.truncate());
        }
    }

    private truncate(): void {
        // verify execution context is the browser platform
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }

        // store the original innerText
        if (!this.innerText) {
            this.innerText = this.ngxTruncateText;
        }

        // reset the innerText
        this.el.innerText = this.innerText;

        // truncate the text and append the ellipsis
        const text = this.innerText.split(' ');
        if (text.length > 0 && this.el.scrollHeight > this.el.clientHeight) {
            while (text.length > 0 && this.el.scrollHeight > this.el.clientHeight) {
                text.pop();
                this.el.innerText = `${text.join(' ')}…`;
            }
        }
    }
}
