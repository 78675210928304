import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

export class DistrictOverlayRendererService {
    public customLayerRenderer(): Renderer {
        return new UniqueValueRenderer({
            field: 'type',
            uniqueValueInfos: [].concat([
                {
                    value: 'district',
                    symbol: {
                        type: 'simple-fill',
                        color: 'rgba(150, 150, 150, 0.2)',
                        width: 5,
                        outline: {
                            color: 'grey',
                        },
                    },
                },
            ]),
        });
    }
}
