import {MapColoursService} from './map-colours.service';
import Renderer from '@arcgis/core/renderers/Renderer';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';

export class AssetPolygonRendererService {
    public layerRenderer(theme: string): Renderer {
        return new UniqueValueRenderer({
            field: 'displayCategory',
            uniqueValueInfos: MapColoursService.list(theme).map((item) => {
                return {
                    value: item.value,
                    symbol: {
                        type: 'simple-fill',
                        color: `rgba(${item.colorList[0]}, ${item.colorList[1]}, ${item.colorList[2]}, 0.5)`,
                        width: 5,
                        outline: '',
                    },
                };
            }),
        });
    }
}
