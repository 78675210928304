import {Injectable, OnDestroy} from '@angular/core';
import {StudiesStore} from './studies.store';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {exhaustMap, filter, finalize, switchMap, take, takeUntil} from 'rxjs/operators';
import {Report} from '@core/interfaces/common/pages';
import {User, WorkflowInfo} from '@core/interfaces/common/users';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {AnalyzerService} from '@core/interfaces/engin/analyzer';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {OutputReportSource, ReportingService, ReportRequest} from '@core/interfaces/engin/reporting';
import {UsersStore} from '@store/common/users.store';
import {NbToastrService} from '@nebular/theme';
import {AnalyzerPopoutSensitivity} from '@core/interfaces/common/popout';
import {HelpersService} from '@core/utils/helpers.service';

@Injectable()
export class ReportsStore extends Unsubscribable implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private analyzerService: AnalyzerService,
        private reportingService: ReportingService,
        private studiesStore: StudiesStore,
        private usersStore: UsersStore,
        private usageAnalyticsService: UsageAnalyticsService,
        private toastrService: NbToastrService,
        private helpersService: HelpersService,
    ) {
        super();
    }

    readonly assetClassList$: Observable<string[]> = this.studiesStore.activeStudyIdRisk$.pipe(
        takeUntil(this.unsubscribe$),
        filter((activeStudyId) => !!activeStudyId && activeStudyId > -1),
        switchMap((activeStudyId) => {
            return this.analyzerService.getAssetClassList(activeStudyId);
        }),
    );

    downloadOutputReportGeneric(report: Report, sensitivityItem: AnalyzerPopoutSensitivity) {
        this.subscription = combineLatest<Observable<number>, Observable<WorkflowInfo>, Observable<User>>([
            this.studiesStore.activeStudyIdRisk$,
            this.studiesStore.activeWorkflowRisk$.pipe(filter((d) => !!d)),
            this.usersStore.currentUser$,
        ])
            .pipe(
                exhaustMap(([studyId, activeWorkflowInfo, user]: [number, WorkflowInfo, User]) => {
                    const req = this.prepareReportRequest(report, studyId, user, activeWorkflowInfo, sensitivityItem);
                    return this.reportingService.getOutputReportGeneric(req);
                }),
                take(1),
            )
            .subscribe((out: OutputReportSource) => {
                out.links.forEach((item) => {
                    if (item && item !== 'none') {
                        this.helpersService.openPopup(item);
                    }
                });
            });
        this.usageAnalyticsService.logDownload('Unknown', 'Generic Report - ' + report.reportId);
    }

    downloadOutputReportAnalyzer(report: Report, sensitivityItem: AnalyzerPopoutSensitivity, filters: string[]) {
        let activeToastr = this.toastrService.info('Retrieving Output Report. Please wait...', 'Processing.', {
            duration: 0,
        });

        combineLatest<Observable<number>, Observable<WorkflowInfo>>([
            this.studiesStore.activeStudyIdRisk$,
            this.studiesStore.activeWorkflowRisk$.pipe(filter((d) => !!d)),
        ])
            .pipe(
                switchMap(([studyId, activeCollection]: [number, WorkflowInfo]) => {
                    const finalStudyId: number = sensitivityItem
                        ? this.getActiveStudyId(sensitivityItem, activeCollection)
                        : studyId;
                    return this.reportingService.getOutputReportAnalyzer(finalStudyId, filters);
                }),
                take(1),
                finalize(() => activeToastr.close()),
            )
            .subscribe((out: OutputReportSource) => {
                out.links.forEach((item) => {
                    if (item && item !== 'none') {
                        this.helpersService.openPopup(item);
                    }
                });
            });
        this.usageAnalyticsService.logDownload('Unknown', 'Analyzer Report');
    }

    private prepareReportRequest(
        report: Report,
        studyId: number,
        user: User,
        activeWorkflowInfo: WorkflowInfo,
        sensitivityItem: AnalyzerPopoutSensitivity,
    ): ReportRequest {
        // In the Analyzer module the user may toggle between active studies using the popout panel
        let finalStudyId = studyId;
        if (report.reportId === 'analyzer') {
            finalStudyId = sensitivityItem ? this.getActiveStudyId(sensitivityItem, activeWorkflowInfo) : studyId;
        }

        // The Optimizer and Forecaster modules perform sensitivity analysis on top of the active studyId
        let sensitivityFlag = false;
        if (report.reportId === 'optimizer' || report.reportId === 'forecaster') {
            sensitivityFlag = true;
        }

        return new ReportRequest(report, finalStudyId, user.id, sensitivityFlag);
    }

    private getActiveStudyId(sensitivityItem: AnalyzerPopoutSensitivity, activeWorkflowInfo: WorkflowInfo): number {
        const relatedStudy = activeWorkflowInfo.workflowItemList.find(
            (study) => study.sensitivityCode === sensitivityItem.alias,
        );

        return relatedStudy.workflowItemId || null;
    }

    ngOnDestroy(): void {
        this.subscription && this.subscription.unsubscribe();
    }
}
