import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {IntegrationFile, IntegrationFileRenameDto} from '@core/interfaces/landing-zone';
import {GuideInfo} from '@theme/components';

@Injectable()
export class LandingZoneApi {
    private readonly prefix = 'integration-file';
    constructor(private api: HttpService) {}

    getIntegrationFileById(integrationFileId: number): Observable<APIResponse<IntegrationFile>> {
        return this.api.get(`${this.prefix}/${integrationFileId}`);
    }

    getListCurrent(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list-current`);
    }

    getListHistory(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list-history`);
    }

    getGuideInfoCurrent(): Observable<APIResponse<GuideInfo[]>> {
        return this.api.get(`${this.prefix}/guide/current`);
    }

    getGuideInfoHistory(): Observable<APIResponse<GuideInfo[]>> {
        return this.api.get(`${this.prefix}/guide/history`);
    }

    downloadIntegrationFile(integrationFileId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/${integrationFileId}/download`);
    }

    renameIntegrationFile(integrationFileId: number, name: string): Observable<APIResponse<any>> {
        return this.api.put(`${this.prefix}/${integrationFileId}/rename`, {name: name});
    }

    retryIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/${integrationFileId}/retry`);
    }

    deleteIntegrationFileProcess(integrationFileId: number): Observable<APIResponse<any>> {
        return this.api.delete(`${this.prefix}/${integrationFileId}`);
    }
}
