import {ChangeDetectionStrategy, Component, Input, OnChanges} from '@angular/core';
import {NbThemeService} from '@nebular/theme';
import {ReportChartViewModel} from '../view-models/report-chart.vm';

@Component({
    selector: 'ngx-chart-bar-demographics',
    templateUrl: './report-chart.component.html',
    styleUrls: ['./report-chart.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportChartComponent implements OnChanges {
    @Input() currentTheme: string = null;
    @Input() vm: ReportChartViewModel;
    constructor(private nbThemeService: NbThemeService) {}
    ngOnChanges(changes: any) {
        if (changes.currentTheme?.currentValue) {
            this.nbThemeService.changeTheme(this.currentTheme);
        }
    }
}
