import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DefaultStudyInfo, User, UserNotification} from '@core/interfaces/common/users';
import {map} from 'rxjs/operators';
import {HttpParams} from '@angular/common/http';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';
import {APIResponse} from '@core/interfaces/system/system-common';
import {WorkflowType} from '@core/interfaces/engin/workflow';

@Injectable()
export class UsersApi {
    private readonly apiController: string = 'users';

    constructor(private api: HttpService) {}

    getUsersDataSource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.apiController}/list`);
    }

    get(id: string): Observable<any> {
        return this.api.get(`${this.apiController}/${id}`);
    }

    getCurrent(): Observable<any> {
        return this.api.get(`${this.apiController}/current`);
    }

    delete(id: string): Observable<boolean> {
        return this.api.delete(`${this.apiController}/${id}`);
    }

    create(item: any): Observable<any> {
        return this.api.post(`${this.apiController}/`, item);
    }

    update(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/${item.id}`, item);
    }

    updateRole(item: any): Observable<any> {
        return this.api.put(`${this.apiController}/role/${item.id}`, item);
    }

    updatePassword(currentPassword: string, newPassword: string, confirmPassword: string): Observable<any> {
        // UserId is retrieved from the JWT for current user
        return this.api.put(`${this.apiController}/update-password`, {currentPassword, newPassword, confirmPassword});
    }

    forceUpdatePassword(userId: string, newPassword: string): Observable<any> {
        return this.api.put(`${this.apiController}/force-update-password`, {userId, newPassword});
    }

    requestEmailVerifyEmail(userId: string): Observable<any> {
        return this.api.post(`${this.apiController}/request-email-verify`, {userId});
    }

    getUsersListData(): Observable<User[]> {
        // Get max 100 users
        return this.api.get(`${this.apiController}/list?pageNumber=1&pageSize=100`).pipe(
            map((data) => {
                return data['items'];
            }),
        );
    }

    userUnlock(userId): Observable<any> {
        return this.api.post(`${this.apiController}/unlock`, {id: userId});
    }

    getUsersNotification(): Observable<UserNotification[]> {
        return this.api.get(`${this.apiController}/login-notification`);
    }

    passwordValidation(password: string, passwordHistory: boolean): Observable<APIResponse<PasswordRequirements>> {
        return this.api.post(`${this.apiController}/password/validate`, {
            password: password,
            passwordHistory: passwordHistory,
        });
    }

    public passwordValidationOtherUser(
        userId: string,
        password: string,
        checkPasswordHistory: boolean,
    ): Observable<APIResponse<PasswordRequirements>> {
        return this.api.post(`${this.apiController}/password/validate/other`, {
            password: password,
            userId: userId,
            passwordHistory: checkPasswordHistory,
        });
    }

    public passwordValidationNewUser(password: string): Observable<APIResponse<PasswordRequirements>> {
        return this.api.post(`${this.apiController}/password/validate/new`, {
            password: password,
        });
    }

    userNameAvailable(userName: string): Observable<APIResponse<Boolean>> {
        return this.api.get(`${this.apiController}/valid/username/${userName}`);
    }

    getUserByUserName(userName: string): Observable<any> {
        return this.api.get(`${this.apiController}/username/${userName}`);
    }

    userEmailAvailable(email: string, id: string): Observable<APIResponse<Boolean>> {
        return this.api.get(`${this.apiController}/valid/email/${email}?currentId=${id}`);
    }

    public inviteUserToOrganization(tenantId: string, username: string): Observable<any> {
        return this.api.post(`${this.apiController}/invite`, {
            tenantId: tenantId,
            username: username,
        });
    }

    /*    updateDefaultStudyStrategy(latestAsDefault: boolean, defaultCollectionId: string): Observable<any> {
        const params = new HttpParams()
            .set('latestAsDefault', latestAsDefault.toString())
            .set('defaultCollectionId', defaultCollectionId);
        return this.api.post(`${this.apiController}/update-default-study-strategy`, null, {params: params});
    }*/
    public getDefaultStudyInfo(): Observable<APIResponse<DefaultStudyInfo>> {
        return this.api.get(`${this.apiController}/study/default`);
    }

    public updateDefaultStudyStrategy(latestAsDefault: boolean): Observable<APIResponse<DefaultStudyInfo>> {
        const body = {
            latestAsDefault: latestAsDefault,
        };
        return this.api.put(`${this.apiController}/study/strategy`, body);
    }

    public updateDefaultStudyByType(type: WorkflowType, workflowId: number): Observable<APIResponse<DefaultStudyInfo>> {
        const body = {
            workflowType: type,
            workflowId: workflowId,
        };
        return this.api.put(`${this.apiController}/study/type`, body);
    }
}
