import {Injectable} from '@angular/core';
import {AssetsApi} from '../api/assets.api';
import {AssetHealthIndex, AssetsService, StudyAsset} from '../../../interfaces/engin/assets';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {AssetDto} from '@core/interfaces/common/asset';

@Injectable()
export class AssetsServiceImpl extends AssetsService {
    constructor(private api: AssetsApi) {
        super();
    }

    // Study Assets
    getStudyAssetsDataSource(studyId: number, filters: Filter[]): Observable<DataSource> {
        return this.api.getStudyAssetsDataSource(studyId, filters);
    }

    searchStudyAssets(studyId: number, assetId: string, filters: Filter[]): Observable<string[]> {
        return this.api.searchStudyAssets(studyId, assetId, filters);
    }

    getStudyAsset(studyId: number, assetId: string): Observable<StudyAsset> {
        return this.api.getStudyAsset(studyId, assetId);
    }

    // Asset Details
    getAssetDetails(studyId: number, assetId: string): Observable<any> {
        return this.api.getAssetDetails(studyId, assetId);
    }

    getAssetDetailsHealthIndex(studyId: number, assetId: string): Observable<AssetHealthIndex> {
        return this.api.getAssetDetailsHealthIndex(studyId, assetId);
    }

    getOneAssetForStudy(workflowItemId: number, assetId: string): Observable<APIResponse<AssetDto>> {
        return this.api.getOneAssetForStudy(workflowItemId, assetId);
    }
}
