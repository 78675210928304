<div class="table-settings-container tooltip-container">
    <div class="chart-settings-items tooltip-item">
        <!-- Units selector -->
        <ng-container *ngIf="unitItem !== null && unitOptions as settings">
            <ng-container *ngIf="settings.length">
                <p class="section-title">Units</p>
                <nb-radio-group name="group1" [value]="unitItem?.unit" (valueChange)="itemChanged.emit($event)">
                    <nb-radio class="unit-selector-item" *ngFor="let unit of settings" [value]="unit.value">
                        {{ unit.label }}
                    </nb-radio>
                </nb-radio-group>

                <div class="section-divider"></div>
            </ng-container>
        </ng-container>

        <!-- Data download -->
        <ng-container>
            <p class="section-title">Export</p>
            <div class="download-menu-item" (click)="dataDownloadCSV()">
                <nb-icon class="download-menu-icon" icon="download"></nb-icon>
                <span class="download-menu-text">CSV</span>
            </div>
        </ng-container>
    </div>

    <div class="selector-arrow"></div>
</div>
