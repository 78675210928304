import {Injectable} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';
import {debounceTime, filter, map} from 'rxjs/operators';
import {UsageAnalyticsApi} from '@core/backend/common/api/usage-analytics-api.service';

@Injectable({
    providedIn: 'root',
})
export class UsageAnalyticsService {
    constructor(private usageAnalyticsApi: UsageAnalyticsApi, private router: Router) {
        this.router.events
            .pipe(
                debounceTime(2000),
                filter((event: RouterEvent): event is NavigationEnd => event instanceof NavigationEnd),
                map((event) => {
                    const result = event as NavigationEnd;
                    // Do not send for auth APIs which are pre-login and will fail to authenticate
                    if (!result.url.includes('/auth/')) {
                        this.sendTrackingEvent('View', {$action_type: 'Page Changes'});
                    }
                }),
            )
            .subscribe();
    }

    // Public APIs to receive and prepare tracking data from different application events
    public logEvent(action: string, type: string, extras?: any) {
        const properties = {
            ...extras,
            $action_type: type,
        };
        this.sendTrackingEvent(action, properties);
    }

    public logCreate(type: string, extras?: any) {
        const properties = {
            ...extras,
            $action_type: type,
        };
        this.sendTrackingEvent('Create', properties);
    }

    public logEdit(type: string, extras?: any) {
        const properties = {
            ...extras,
            $action_type: type,
        };
        this.sendTrackingEvent('Edit', properties);
    }

    public logView(type: string, extras?: any) {
        const properties = {
            ...extras,
            $action_type: type,
        };
        this.sendTrackingEvent('View', properties);
    }

    public logDownload(fileName: string, downloadName: string, extras?: any) {
        const properties = {
            ...extras,
            $file_name: fileName,
            $download_name: downloadName,
        };
        this.sendTrackingEvent('Download', properties);
    }

    // Main API to send tracking events to server
    private sendTrackingEvent(action: string, properties?: any) {
        let trackingInfo = {
            ...properties,
        };
        if (action === 'View' || action === 'Create' || action === 'Edit') {
            trackingInfo = {
                ...trackingInfo,
                $module: this.getCurrentModule(this.router.url),
            };
        }
        const trackingRequest = {
            action: action,
            trackingInfo,
        };
        this.usageAnalyticsApi.trackEvent(trackingRequest).subscribe();
    }

    // Helper
    private getCurrentModule(url) {
        const moduleUrl = url.slice(1);
        return moduleUrl
            .split('/')[0]
            .split('-')
            .map((s) => s.charAt(0).toUpperCase() + s.slice(1))
            .join(' ');
    }
}
