import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    DownloadURL,
    InfrastructureForecastBySeasons,
    PeriodFilter,
    SimpleSeriesOld,
} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {SectionInfo} from '@core/interfaces/engin/load-forecast/line-section';

@Injectable()
export class SectionLoadForecastApi {
    private readonly PREFIX_FEEDER = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for line section(s)
     */
    public getSectionPivot(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/pivot?${params}`,
        );
    }

    public getSectionPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('filterByseasonId_IN', seasonIds.join('|'))
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getSectionInfo(workflowItemId: number, scenarioId: string, sectionId: string): Observable<SectionInfo[]> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/info`,
        );
    }

    public getSectionForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<InfrastructureForecastBySeasons> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/seasons`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getSectionForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/factors`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getSectionInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/infrastructure/list`,
        );
    }

    public getSectionInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        sectionId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_FEEDER}/${workflowItemId}/scenarios/${scenarioId}/sections/${sectionId}/infrastructure/list/csv`,
        );
    }
}
