<div class="row breadcrumb-container">
    <!-- First n-1 breadcrumbs with navigation actions -->
    <ng-container *ngFor="let e of breadcrumbs | slice: 0:breadcrumbs.length - 1">
        <p class="breadcrumb-active m-0" (click)="onBreadcrumbClick(e)">{{ e.label }}</p>
        <nb-icon icon="chevron-right" class="separator"></nb-icon>
    </ng-container>

    <!-- Last breadcrumb without navigation -->
    <p *ngIf="breadcrumbs[breadcrumbs.length - 1] as lastBreadcrumb" class="breadcrumb-inactive m-0">
        {{ lastBreadcrumb.label }}
    </p>
</div>
