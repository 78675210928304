<div class="popover-container">
    <div class="selector-arrow"></div>
    <div class="popover-items">
        <ng-container *ngFor="let page of pages">
            <ngx-icon-box
                *ngIf="page.enabled"
                class="popover-item"
                (click)="topMenuClicked()"
                [routerLink]="page.link"
                routerLinkActive="active"
                [title]="page.title"
                direction="column"
                [iconDetails]="page.icon"
            ></ngx-icon-box>

            <ngx-icon-box
                *ngIf="!page.enabled"
                class="popover-item disabled-link"
                (click)="topMenuClicked()"
                [title]="page.title"
                direction="column"
                [iconDetails]="page.icon"
            ></ngx-icon-box>
        </ng-container>
    </div>
</div>
