import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {PasswordData, User, UsersService} from '@core/interfaces/common/users';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
import {PasswordRequirements} from '@core/interfaces/common/tenantSettings';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UsersStore} from '@store/common/users.store';

/**
 * Update password component
 * - Requires auth (must be logged in)
 * - User enters current, new, confirm password to reset password before entering main system. e.g. password expired.
 */
@Component({
    selector: 'ngx-auth-update-password',
    templateUrl: './update-password.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpdatePasswordComponent extends Unsubscribable implements OnInit {
    // Info messages
    // todo: may be admin increased password requirements, can add different message in the future.
    showingMessage: any[] = [{message: 'Your password has expired, please, change it.', type: 'info'}];

    submitted: boolean = false;
    user: User;

    // Success / fail messages from API
    showMessages: any = {
        success: true,
        error: true,
    };
    errors: string[] = [];
    messages: string[] = [];

    // Current user
    user$ = this.usersStore.currentUser$;
    // Password data
    private passwordValidation = new BehaviorSubject<PasswordRequirements>(null);
    readonly passwordValidation$: Observable<PasswordRequirements> = this.passwordValidation.asObservable();

    // Password reset form
    resetPasswordForm: FormGroup = this.fb.group({
        currentPassword: ['', [Validators.required]],
        newPassword: ['', [Validators.required]],
        confirmPassword: ['', [Validators.required]],
    });
    formReady$: Observable<boolean> = combineLatest<Observable<PasswordRequirements>, Observable<any>>([
        this.passwordValidation$,
        this.resetPasswordForm.statusChanges,
    ]).pipe(
        takeUntil(this.unsubscribe$),
        startWith(false),
        map(([requirements, formValid]: [PasswordRequirements, any]) => {
            return requirements?.validation && formValid === 'VALID';
        }),
    );

    constructor(
        private usersService: UsersService,
        private usersStore: UsersStore,
        private fb: FormBuilder,
        protected cd: ChangeDetectorRef,
    ) {
        super();
    }

    ngOnInit() {
        this.checkValidation('');
    }

    public onInputChanges(event: any) {
        this.checkValidation(event.password);
    }

    private checkValidation(newValue) {
        this.usersService.passwordValidation(newValue, true).subscribe((res: APIResponse<PasswordRequirements>) => {
            if (res) this.passwordValidation.next(res.response);
        });
    }

    public submit() {
        this.errors = this.messages = [];
        this.submitted = true;

        let data = this.resetPasswordForm.value as PasswordData;

        this.usersService
            .updatePassword(data.currentPassword, data.newPassword, data.confirmPassword)
            .subscribe((resp) => {
                if (resp && resp.success) {
                    this.messages = resp.messages;
                } else {
                    this.errors = resp.errors;
                    this.submitted = false; // allow re-submit
                }
                this.cd.detectChanges();
            });
    }
}
