import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {DefaultStudyInfo, User, UsersService, WorkflowInfo, WorkflowItemInfo} from '@core/interfaces/common/users';
import {debounceTime, filter, finalize, map, shareReplay, takeUntil} from 'rxjs/operators';
import {APIResponse} from '@core/interfaces/system/system-common';
import {WorkflowService, WorkflowType} from '@core/interfaces/engin/workflow';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {UsersStore} from '@store/common/users.store';
import {isEqual} from 'lodash';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class StudiesStore extends Unsubscribable {
    private defaultStudyInfo: BehaviorSubject<DefaultStudyInfo> = new BehaviorSubject<DefaultStudyInfo>(null);
    readonly defaultStudyInfo$: Observable<DefaultStudyInfo> = this.defaultStudyInfo
        .asObservable()
        .pipe(shareReplay(1));

    private activeWorkflows: BehaviorSubject<WorkflowInfo[]> = new BehaviorSubject<WorkflowInfo[]>([]);
    readonly activeWorkflows$: Observable<WorkflowInfo[]> = this.activeWorkflows.asObservable().pipe(shareReplay(1));

    constructor(
        private userService: UsersService,
        private workflowService: WorkflowService,
        private userStore: UsersStore,
        private toastrService: NbToastrService,
    ) {
        super();

        this.userStore.currentUser$.subscribe((user: User) => {
            // Refresh related info on user change
            if (!!user) {
                this.userService.getDefaultStudyInfo().subscribe((resp: APIResponse<DefaultStudyInfo>) => {
                    if (resp?.response) {
                        this.setDefaultStudyInfo(resp.response);
                        this.setActiveWorkflows(resp.response.defaultWorkflowList);
                    }
                });
            }
        });

        // Show toastr for loading study archive for all pages
        this.studiesPending$.pipe(debounceTime(100), takeUntil(this.unsubscribe$)).subscribe((dataLoading: boolean) => {
            if (dataLoading) {
                this.lastLoadStatus = false;
                this.activeToastr = this.toastrService.info(
                    'Retrieving Study data from archives, this should only take a moment. Please do not leave this page...',
                    'Recalling Study Archives.',
                    {duration: 0},
                );
            } else {
                if (!this.lastLoadStatus) {
                    this.activeToastr.close(); // remove previous toastr
                    this.activeToastr = this.toastrService.success(
                        'Study data has been retrieved from archives and is ready for browsing.',
                        'Study is ready.',
                        {duration: 10000},
                    );
                }
                this.lastLoadStatus = true;
            }
        });
    }

    // Helper methods for default study info
    /**
     * Force fetch default study info. This will trigger an update via defaultStudyInfo$ observable.
     * @return void
     */
    public refreshDefaultStudyInfo(): void {
        this.userService.getDefaultStudyInfo().subscribe((resp: APIResponse<DefaultStudyInfo>) => {
            this.setDefaultStudyInfo(resp.response as DefaultStudyInfo);
        });
    }
    /**
     * Get default study info.
     * @return DefaultStudyInfo
     */
    public getDefaultStudyInfo(): DefaultStudyInfo {
        if (this.defaultStudyInfo.getValue() == null) this.refreshDefaultStudyInfo();
        return this.defaultStudyInfo.getValue();
    }
    /**
     * (Re)set the default study info based on provided input.
     * @param defaultStudyInfo
     * @private
     */
    public setDefaultStudyInfo(defaultStudyInfo: DefaultStudyInfo): void {
        this.defaultStudyInfo.next(defaultStudyInfo);
    }

    // Centralized logic for frequent access to active study and active study ID, by type
    readonly activeWorkflowRisk$: Observable<WorkflowInfo> = this.activeWorkflows$.pipe(
        map(
            (activeWorkflows: WorkflowInfo[]) =>
                activeWorkflows?.find((w) => w.workflowType === WorkflowType.STUDY) || null,
        ),
        shareReplay(1),
    );
    readonly activeStudyRisk$: Observable<WorkflowItemInfo> = this.activeWorkflowRisk$.pipe(
        map(
            (activeWorkflowRisk: WorkflowInfo) =>
                activeWorkflowRisk?.workflowItemList.find((i) => i.defaultItem) || null,
        ),
        shareReplay(1),
    );
    readonly activeStudyIdRisk$: Observable<number> = this.activeStudyRisk$.pipe(
        map((i: WorkflowItemInfo) => i?.workflowItemId),
        shareReplay(1),
        filter((d) => !!d && d != -1),
    );
    readonly activeWorkflowLf$: Observable<WorkflowInfo> = this.activeWorkflows$.pipe(
        map(
            (activeWorkflows: WorkflowInfo[]) =>
                activeWorkflows?.find((w) => w.workflowType === WorkflowType.LOAD_FORECAST) || null,
        ),
        shareReplay(1),
    );
    readonly activeStudyLf$: Observable<WorkflowItemInfo> = this.activeWorkflowLf$.pipe(
        map((activeWorkflowLf: WorkflowInfo) => activeWorkflowLf?.workflowItemList.find((i) => i.defaultItem)) || null,
        shareReplay(1),
    );
    readonly activeStudyIdLf$: Observable<number> = this.activeStudyLf$.pipe(
        map((i: WorkflowItemInfo) => i?.workflowItemId),
        shareReplay(1),
        filter((d) => !!d && d != -1),
    );

    // Helper methods for active study info
    /**
     * Force refresh active (study-typed) workflows. This will reset active (study-typed) workflows back to the
     * current default studies. This will trigger an update via activeWorkflows$ observable and related observables.
     * @return void
     */
    public refreshActiveWorkflows(): void {
        const defaultStudyInfo: DefaultStudyInfo = this.defaultStudyInfo.getValue();
        if (defaultStudyInfo) {
            this.setActiveWorkflows(defaultStudyInfo.defaultWorkflowList);
        }
    }
    /**
     * Get active studies.
     * @return WorkflowInfo[]
     */
    public getActiveWorkflows(): WorkflowInfo[] {
        return this.activeWorkflows.getValue();
    }

    /**
     * Update active (study-typed) workflow for provided workflow type. This will de-activate the existing workflow for
     * that type and activate the new workflow. This will trigger an update via activeStudies$ observable and related
     * observables.
     * @param type workflowType for (study-typed) workflow
     * @param workflowId workflowId for new (study-typed) workflow
     */
    public updateActiveWorkflowByType(type: WorkflowType, workflowId: number): void {
        const activeStudies: WorkflowInfo[] = this.activeWorkflows.getValue();
        const currentActiveWorkflowId: number = activeStudies.find((w) => w.workflowType === type)?.workflowId || -1;

        let foundAndReset: boolean = false;
        const newActiveStudies: WorkflowInfo[] = activeStudies.map((w: WorkflowInfo) => {
            if (w.workflowType === type) foundAndReset = true;
            return {
                ...w,
                workflowId: w.workflowId === currentActiveWorkflowId ? workflowId : w.workflowId,
            };
        });
        // If activeStudies is currently empty and first study is being activated, need to push manually
        if (!foundAndReset) {
            newActiveStudies.push({
                workflowId: workflowId,
                workflowType: type,
                workflowItemList: [], // this.setActiveStudies will call API and find the necessary data
            });
        }

        this.setActiveWorkflows(newActiveStudies);
    }
    /**
     * Get active (study-typed) workflow for a single workflow type. If none is found, return WorkflowInfo
     * will have id = -1.
     * @param type WorkflowType
     * @return WorkflowInfo active (study-typed) workflow
     */
    public getActiveWorkflowByType(type: WorkflowType): WorkflowInfo {
        if (!this.activeWorkflows.getValue() || this.activeWorkflows.getValue().length === 0)
            this.refreshActiveWorkflows();
        return this.activeWorkflows.getValue().find((w) => w.workflowType === type);
    }
    /**
     * Get active (study-typed) workflow ID for a single workflow type. If none is found, return -1.
     * @param type WorkflowType
     * @return number, active (study-typed) workflow
     */
    public getActiveWorkflowIdByType(type: WorkflowType): number {
        const workFlow = this.getActiveWorkflowByType(type);
        if (workFlow === undefined) {
            return -1;
        }
        return workFlow.workflowId;
    }
    /**
     * Get active "study" i.e., study-typed workflowItem for a single workflow type. If none is found,
     * return WorkflowItemInfo will have id = -1.
     * @param type WorkflowType
     * @return WorkflowItemInfo active (study-typed) workflow
     */
    public getActiveStudyByType(type: WorkflowType): WorkflowItemInfo {
        if (!this.activeWorkflows.getValue() || this.activeWorkflows.getValue().length === 0)
            this.refreshActiveWorkflows();
        return this.activeWorkflows
            .getValue()
            ?.find((w: WorkflowInfo) => w.workflowType === type)
            ?.workflowItemList.find((i: WorkflowItemInfo) => i.defaultItem);
    }
    /**
     * Get active "studyId" i.e., study-typed workflowItemId for a single workflow type. If none is found, return -1.
     * @param type WorkflowType
     * @return number, active (study-typed) workflow item
     */
    public getActiveStudyIdByType(type: WorkflowType): number {
        const workflowItem: WorkflowItemInfo = this.getActiveStudyByType(type);
        if (workflowItem === undefined) {
            return -1;
        }
        return workflowItem.workflowItemId;
    }
    /**
     * (Re)set the active (study-typed) workflow info based on provided input.
     * @param activeWorkflows
     * @private
     */
    private setActiveWorkflows(activeWorkflows: WorkflowInfo[]): void {
        if (activeWorkflows && !isEqual(activeWorkflows, this.activeWorkflows.getValue())) {
            this.setStudiesLoading(true);
            this.setStudyPending(true);

            const userId = this.userStore.getCurrentUser()?.id;
            // API call is kept in store rather than component or study history to preserve lifecycle of request
            userId &&
                this.workflowService
                    .activateWorkflowsByUser(
                        userId,
                        activeWorkflows.map((w) => w.workflowId),
                    )
                    .pipe(
                        filter((resp: APIResponse<WorkflowInfo[]>) => resp?.response?.length > 0),
                        finalize(() => {
                            this.setStudiesLoading(false);
                            this.setStudyPending(false);
                        }),
                    )
                    .subscribe((resp: APIResponse<WorkflowInfo[]>) => {
                        if (resp.response) {
                            this.activeWorkflows.next(resp.response);
                        }
                    });
        }
    }

    /**
     * Clear default study info and active studies e.g., during logout.
     */
    public clearStudyInfo(): void {
        this.setRequestsPending(true);

        const userId = this.userStore.getCurrentUser()?.id;
        userId &&
            this.workflowService
                .clearActiveWorkflowsByUser(userId)
                .pipe(
                    takeUntil(this.unsubscribe$),
                    finalize(() => this.setRequestsPending(false)),
                )
                .subscribe(() => {
                    this.activeWorkflows.next([]);
                    this.defaultStudyInfo.next(null);
                });
    }

    private studiesPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly studiesPending$: Observable<boolean> = this.studiesPending.asObservable();

    private studyPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly studyPending$: Observable<boolean> = this.studyPending.asObservable();

    private dataLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly dataLoading$: Observable<boolean> = this.dataLoading.asObservable();

    private requestsPending: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly requestsPending$: Observable<boolean> = this.requestsPending.asObservable().pipe(shareReplay(1));

    private lastLoadStatus: boolean = true;
    private activeToastr;

    setStudiesLoading(value: boolean) {
        this.studiesPending.next(value);
    }
    setStudyPending(value: boolean) {
        this.studyPending.next(value);
    }
    setDataLoading(value: boolean) {
        this.dataLoading.next(value);
    }

    setRequestsPending(value: boolean) {
        this.requestsPending.next(value);
    }
}
