import {ChangeDetectionStrategy, Component, forwardRef, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators} from '@angular/forms';
import {NumberInputComponent} from '@theme/components';

@Component({
    selector: 'ngx-number-input-box',
    templateUrl: './number-input-box.component.html',
    styleUrls: ['./number-input-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumberInputComponent),
            multi: true,
        },
    ],
})
export class NumberInputBoxComponent implements ControlValueAccessor {
    @Input() minYear: number;
    @Input() inputItemFormControl = new FormControl();

    plus() {
        this.inputItemFormControl.setValue(this.inputItemFormControl.value + 1);
        this.onChange(this.inputItemFormControl.value);
    }

    minus() {
        this.inputItemFormControl.setValue(this.inputItemFormControl.value - 1);
        this.onChange(this.inputItemFormControl.value);
    }

    // ControlValueAccessor methods
    writeValue(value: any): void {
        this.inputItemFormControl.setValue(value);
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.inputItemFormControl.disable();
        } else {
            this.inputItemFormControl.enable();
        }
    }

    private onChange: any = () => {};
    private onTouched: any = () => {};

    constructor() {}
}
