<div class="form-renderer-container" *ngIf="formIsReady$ | async">
    <div class="accordion-list-container">
        <ngx-form-section
            [sections]="formSections"
            (selectionChanged)="selectionChanged($event)"
            [continueEvent]="continueEvent"
            [buttonAction]="buttonAction"
            [passedRequiredFieldsList]="passedRequiredFieldsList"
            [totalRequiredValue]="totalRequiredValue"
        ></ngx-form-section>
    </div>
    <div class="accordion-cells-container">
        <ngx-form-canvas
            [currentSection]="activeSection"
            [viewMode]="viewMode"
            [currentFormResults]="currentFormResults"
            [previousFormResults]="previousFormResults"
            [fieldResultForm]="fieldResultForm"
            [pageMode]="pageMode"
            [s3service]="s3service"
            (formFieldChangeEvent)="formValueChange($event)"
        ></ngx-form-canvas>
        <div class="btn-container mb-2">
            <button
                nbButton
                fullWidth
                class="mr-2"
                status="primary"
                (click)="previous()"
                [disabled]="previousBtnDisabled"
            >
                <nb-icon icon="arrow-back-outline"></nb-icon>
                Previous
            </button>
            <button nbButton fullWidth status="primary" (click)="next()" [disabled]="nextBtnDisabled">
                Next
                <nb-icon icon="arrow-forward-outline"></nb-icon>
            </button>
        </div>
        <ng-container *ngIf="completedRequiredFieldsProgress.asObservable() | async as progressValue">
            <nb-progress-bar status="primary" size="tiny" [value]="progressValue"></nb-progress-bar>
        </ng-container>
        <p
            *ngIf="{total: totalRequiredStatus.value, section: sectionRequiredStatus.value} as status"
            class="section-state"
        >
            {{ status.section.completed }}/{{ status.section.total }} Required in the Section -
            {{ status.total.completed }}/{{ status.total.total }} Total Required
        </p>
    </div>
</div>
