import {ErrorHandler, Injectable} from '@angular/core';
import {NbToastrService} from '@nebular/theme';
import {HttpErrorResponse} from '@angular/common/http';
import {isAPIException} from '@core/error-handling/exception.dto';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    private errorToasterShown: boolean = false;

    constructor(private toastrService: NbToastrService) {}

    handleError(error: any): void {
        if (error instanceof HttpErrorResponse || isAPIException(error)) {
            // If it's an HTTP error, re-throw it to propagate to HttpErrorInterceptor
            throw error;
        } else if (
            !this.errorToasterShown &&
            error instanceof Error &&
            error.message.includes('Loading chunk node_modules_echarts_index_js failed')
        ) {
            this.toastrService.danger('Error loading ECharts library:', 'Please reload the page.', {
                duration: 10000,
            });
            this.errorToasterShown = true;

            // Exit the error handler function without throwing or rethrowing the error
            return;
        } else console.error(error);
    }
}
