import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    AbstractLiveModel,
    ChartAssetAlertHistoryResponse,
    ChartModelAlertHistoryResponse,
    ChartModelHistoryResponse,
    FleetLatestStatusResponse,
    LiveAssetRequest,
    LiveFleetRequest,
    LiveModelRequest,
} from '@core/interfaces/live/live';
import {HttpParams} from '@angular/common/http';
import {GuideInfo} from '@theme/components';
import {map} from 'rxjs/operators';
import {LiveConfig} from '@core/interfaces/common/configuration';

@Injectable()
export class LiveApi {
    private readonly PREFIX_METER_DATA = 'live';
    private readonly PREFIX_OUTPUT = 'live/output';

    constructor(private api: HttpService) {}

    /*
     * Data explorer
     */
    getMeterDataDatasource(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX_METER_DATA}/list`);
    }

    getLiveConfiguration(): Observable<APIResponse<LiveConfig>> {
        return this.api.get(`${this.PREFIX_METER_DATA}/config`);
    }

    /*
     * Generic
     */
    /**
     * Get list of Asset IDs matching search criteria
     *
     * @param assetId Partial search text for assetId
     * @param model Partial search text for model
     * #1023
     */
    getSearchAssetIds(assetId: string, model: string): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/search/asset`, {
            params: new HttpParams().set('assetId', assetId || '').set('model', model || ''),
        });
    }

    /**
     * Get list of models matching search criteria
     *
     * @param assetId Partial search text for assetId
     * @param model Partial search text for model
     * #1023
     */
    getSearchModels(assetId: string, model: string): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/search/model`, {
            params: new HttpParams().set('assetId', assetId || '').set('model', model || ''),
        });
    }

    /*
     * Model, generic
     */
    public getCountResultsByModel(request: LiveModelRequest): Observable<number> {
        return this.api
            .get(`${this.PREFIX_OUTPUT}/model/count`, {
                params: new HttpParams()
                    .set('assetId', request.assetId || '')
                    .set('model', request.model || '')
                    .set('uploadDate', request.uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    getModelOutputDatasource(assetId: string, uploadDate: string): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX_OUTPUT}/list?assetId=${assetId}&uploadDate=${uploadDate}`);
    }

    getChartModelHistory(request: LiveModelRequest): Observable<APIResponse<ChartModelHistoryResponse>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/model/history`, {
            params: new HttpParams()
                .set('assetId', request.assetId || '')
                .set('model', request.model || '')
                .set('uploadDate', request.uploadDate || ''),
        });
    }

    getChartModelAlertHistory(
        request: LiveModelRequest,
        groupBy: string,
    ): Observable<APIResponse<ChartModelAlertHistoryResponse>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/model/history-aggregate`, {
            params: new HttpParams()
                .set('assetId', request.assetId || '')
                .set('model', request.model || '')
                .set('uploadDate', request.uploadDate || '')
                .set('groupBy', groupBy || ''),
        });
    }

    /*
     * Asset
     */
    public getCountResultsByAsset(assetId: string, uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX_OUTPUT}/asset/count`, {
                params: new HttpParams().set('assetId', assetId || '').set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    getChartAssetAlertHistory(request: LiveAssetRequest): Observable<APIResponse<ChartAssetAlertHistoryResponse[]>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/asset/history`, {
            params: new HttpParams().set('assetId', request.assetId || '').set('uploadDate', request.uploadDate || ''),
        });
    }

    getAssetLatestModelStatus(id: string, uploadDate: string): Observable<AbstractLiveModel[]> {
        return this.api.get(`${this.PREFIX_OUTPUT}/asset/latest?assetId=${id}&uploadDate=${uploadDate}`);
    }

    /*
     * Fleet
     */
    public getCountResultsByFleet(uploadDate?: string): Observable<number> {
        return this.api
            .get(`${this.PREFIX_OUTPUT}/fleet/count`, {
                params: new HttpParams().set('uploadDate', uploadDate || ''),
            })
            .pipe(map((res: APIResponse<number>) => res.response));
    }

    getFleetLatestStatusDetails(request: LiveFleetRequest): Observable<APIResponse<FleetLatestStatusResponse>> {
        return this.api.get(`${this.PREFIX_OUTPUT}/fleet/summary`, {
            params: new HttpParams().set('uploadDate', request.uploadDate || ''),
        });
    }

    getCurrentAssetAlerts(request: LiveFleetRequest): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.PREFIX_OUTPUT}/list-latest?uploadDate=${request.uploadDate}`);
    }

    //TODO check api
    public getGuideInfo(modelId: string): Observable<GuideInfo[]> {
        return this.api
            .get(`${this.PREFIX_OUTPUT}/guide/${modelId}`)
            .pipe(map((resp: APIResponse<GuideInfo[]>) => resp.response));
    }
}
