import {Observable} from 'rxjs';
import {SimpleSeries} from '@core/interfaces/system/system-common';
import {LpInfrastructureRequest} from '@core/interfaces/engin/load-profile-analysis/lp-common.model';

export abstract class PowerMeterReadingService {
    abstract getInfrastructureNetLoad(
        batchNumber: number,
        data: LpInfrastructureRequest,
    ): Observable<SimpleSeries<Date, number>[]>;
}
