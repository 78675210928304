import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {NavigationExtras, Router} from '@angular/router';

@Component({
    selector: 'ngx-action-cell',
    templateUrl: './action-cell.component.html',
    styleUrls: ['./action-cell.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent implements ViewCell {
    @Input() value: string | number;
    @Input() rowData: any;

    @Output() onNavigationClicked: EventEmitter<any> = new EventEmitter();
    @Output() onInfoClicked: EventEmitter<any> = new EventEmitter();
    @Output() onEditClicked: EventEmitter<any> = new EventEmitter();
    @Output() onDeleteClicked: EventEmitter<any> = new EventEmitter();

    infoActionVisible: boolean = false;
    showInfoItems = (data: any) => this.statusNotDelete && this.infoActionVisible;
    getInfoItems = (data: any) => {
        return [{title: 'Show Details', name: 'INFO', icon: '', param: data}];
    };

    navigateActionVisible: boolean = false;
    showNavAction = (data: any) => this.statusNotDelete && this.navigateActionVisible;
    navLink = (data: any) => './';
    extras = (data: any): NavigationExtras => ({});
    navLinkDisabled = (data: any) => false;
    navIcon = 'arrow-forward-outline';
    getNavItems = (data: any) => [];

    showActionItems = (data: any, value: any) => true;
    getActionItems = (data: any) => {
        return [];
    };

    showEditAction = (data: any) => false;
    showEditDotIconAction = (data: any) => false;
    showDeleteAction = (data: any) => false;

    get statusNotDelete() {
        return !['PENDING_DELETE', 'DELETED'].includes(this.rowData.status);
    }

    constructor(private router: Router) {}

    goTo() {
        const link = this.navLink(this.rowData);
        const extras = this.extras(this.rowData);

        this.router.navigate([link], extras);
    }
}
