import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {
    FieldType,
    FormCellType,
    FormField,
    FormViewModeType,
    NumericFieldResultDto,
} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-form-field-base',
    template: '',
    styleUrls: ['./form-field-base.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class FormFieldBaseComponent<T> extends Unsubscribable {
    @Input() field: FormField;
    @Input() result: T;
    @Input() required: boolean;
    @Input() viewMode: FormViewModeType;
    @Input() cellType: FormCellType;
    @Input() fieldResultForm: FormGroup;
    @Output() formFieldChangeEvent = new EventEmitter<{fieldType: FieldType; fieldId: number; value: T}>();

    FormViewModeEnum = FormViewModeType;
    FormCellType = FormCellType;

    public currentValue: T;

    protected constructor() {
        super();
    }

    protected abstract validate(value: T): boolean;

    protected abstract get fieldForm(): FormControl | FormArray;

    protected abstract applyValueChange(item: any): T;

    protected abstract getFormValue(): any;

    public fieldRequired(): boolean {
        return this.required && this.cellType !== FormCellType.PREVIOUS;
    }

    public emitEvent(value: T): void {
        this.formFieldChangeEvent.emit({fieldType: this.field.fieldType, fieldId: this.field.id, value: value});
    }

    public genericInit(): void {
        // Set value based on configuration
        switch (this.viewMode) {
            case FormViewModeType.VIEW_RESULTS:
            case FormViewModeType.EDIT_RESULTS:
                this.currentValue = this.result;
                if (this.cellType != FormCellType.PREVIOUS) {
                    this.fieldForm?.setValue(this.getFormValue(), {emitEvent: false});
                }
                break;
            case FormViewModeType.VIEW_CONFIG:
                break;
        }

        // Monitor form field changes if form is in edit mode
        if (this.viewMode == FormViewModeType.EDIT_RESULTS) {
            this.fieldForm?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((item) => {
                const newValue: T = this.applyValueChange(item);

                if (this.validate(newValue)) {
                    this.fieldForm.setErrors(null);
                    this.emitEvent(newValue);
                } else {
                    this.emitEvent(newValue);
                    this.fieldForm.setErrors({fieldIsValid: true});
                }
            });
        }
    }
}
