import {Observable} from 'rxjs';
import {APIResponse} from '@core/interfaces/system/system-common';
export interface AuthSettings {
    validation: boolean;
    requirements: PasswordRequirement[];
    lockout: Lockout;
    history: History;
    expiry: Expiry;
}
export interface Lockout {
    enabled: boolean;
    maxFailedAttempts: number;
    cooldownDurationMins: number;
    lockoutDurationMins: number;
}
export interface History {
    enabled: boolean;
    minHistory: number;
}
export interface Expiry {
    enabled: boolean;
    expiryTimeDays: number;
}
export interface PasswordRequirements {
    validation: boolean;
    messages: PasswordRequirement[];
}

export interface PasswordRequirement {
    label: string;
    description: string;
    validation: boolean;
}
export abstract class TenantSettingsService {
    abstract getTenantSettings(): Observable<APIResponse<AuthSettings>>;
    abstract updateTenantSettings(): Observable<any>;
}
