<ng-container>
    <div class="menu-items">
        <ng-container *ngFor="let item of items">
            <div
                *ngIf="item.icon && !item.enabled"
                class="menu-item-container"
                [nbPopover]="!expanded && submenuTemplate"
                [nbPopoverContext]="item"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
            >
                <ng-template *ngTemplateOutlet="menuItem; context: {$implicit: item}"></ng-template>
            </div>
            <div
                *ngIf="item.icon && item.enabled"
                class="menu-item-container"
                [nbPopover]="!expanded && submenuTemplate"
                [nbPopoverContext]="item"
                nbPopoverPlacement="right"
                nbPopoverTrigger="hover"
                [routerLink]="item.link"
                routerLinkActive="active"
            >
                <ng-template *ngTemplateOutlet="menuItem; context: {$implicit: item}"></ng-template>
            </div>

            <ng-container *ngIf="expanded && item.opened">
                <ngx-submenu [items]="item.children"></ngx-submenu>
            </ng-container>
        </ng-container>
    </div>
</ng-container>

<ng-template #menuItem let-item>
    <ngx-icon-box
        *ngIf="item.enabled"
        (click)="expanded && item.children && (item.opened = true); $event.stopPropagation()"
        class="menu-item-link"
        [routerLink]="item.link"
        routerLinkActive="active"
        #rla="routerLinkActive"
        [title]="expanded ? item.title : null"
        [iconDetails]="getIconDetails(rla.isActive, item.icon)"
    ></ngx-icon-box>

    <ngx-icon-box
        *ngIf="!item.enabled"
        (click)="expanded && item.children && (item.opened = !item.opened); $event.stopPropagation()"
        class="menu-item-link disabled"
        [title]="expanded ? item.title : null"
        [iconDetails]="getIconDetails(false, item.icon)"
    ></ngx-icon-box>

    <ng-container *ngIf="expanded && item.children">
        <ngx-icon-box
            (click)="expanded && (item.opened = !item.opened); $event.stopPropagation()"
            class="open-arrow"
            [icon]="item.opened ? 'chevron-down-outline' : 'chevron-right-outline'"
        ></ngx-icon-box>
    </ng-container>
</ng-template>

<ng-template #submenuTemplate let-item>
    <ngx-submenu-popover [item]="item"></ngx-submenu-popover>
</ng-template>
