// Configuration

export class QuickFilterCategory {
    code: string;
    name: string;
    options: QuickFilterOption[];
}

// Other DTOs
export class QuickFilterOption {
    code: QuickFilterType;
    selected?: boolean;
}

export interface BooleanSet {
    [code: string]: boolean;
}

// Enumerations
export enum QuickFilterType {
    LINE = 'LINE',
    POINT = 'POINT',
    OVERHEAD = 'OVERHEAD',
    UNDERGROUND = 'UNDERGROUND',
}

// Labels
export const QuickFilterTypeLabel = {
    [QuickFilterType.LINE]: 'Lines',
    [QuickFilterType.POINT]: 'Points',
    [QuickFilterType.OVERHEAD]: 'Overhead',
    [QuickFilterType.UNDERGROUND]: 'Underground',
};

// Static list of quick filters
export const QUICK_FILTER_LIST: QuickFilterCategory[] = [
    {
        code: 'shape',
        name: 'Shape',
        options: [{code: QuickFilterType.LINE}, {code: QuickFilterType.POINT}],
    },
    {
        code: 'orientation',
        name: 'Orientation',
        options: [{code: QuickFilterType.OVERHEAD}, {code: QuickFilterType.UNDERGROUND}],
    },
];
