import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, share} from 'rxjs/operators';
import {NbDialogService, NbToastrService} from '@nebular/theme';
import {EditAssetDetailsDialogComponent} from '../../../pages/asset-registry/components/edit-asset-details-dialog/edit-asset-details-dialog.component';
import {AssetColumn, AssetDto, CoordinateLatLong, geographicFields, hideFields} from '@core/interfaces/common/asset';

export type GetAssetCallback = (assetId: string) => Observable<AssetDto>;
export type GetAssetCoordinate = (assetId: string) => Observable<CoordinateLatLong>;

@Component({
    selector: 'ngx-asset-overview-details',
    templateUrl: './asset-details.component.html',
    styleUrls: ['./asset-details.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetDetailsComponent implements OnChanges, OnInit {
    @Input() assetId: string;
    @Input() columns$: Observable<AssetColumn[]>;
    @Input() getAsset: GetAssetCallback;
    @Input() getAssetCoordinate: GetAssetCoordinate;
    @Input() enableEdit: boolean = true;

    @Output() onChange = new EventEmitter();

    assetCoordinate = new BehaviorSubject<CoordinateLatLong>(null);
    coordinate$: Observable<CoordinateLatLong> = this.assetCoordinate.asObservable().pipe(share());
    coordinateValid = new BehaviorSubject<boolean>(null);
    coordinateValid$: Observable<boolean> = this.coordinateValid.asObservable().pipe(share());

    assetDetails = new BehaviorSubject<AssetDto>(null);
    assetDetails$: Observable<AssetDto> = this.assetDetails.asObservable();

    availableColumns$: Observable<AssetColumn[]>;

    hideFields = hideFields;
    geographicFields = geographicFields;

    constructor(
        private dialogService: NbDialogService,
        private toastrService: NbToastrService,
        private cd: ChangeDetectorRef,
    ) {}

    public ngOnInit() {
        this.availableColumns$ = combineLatest<Observable<AssetColumn[]>, Observable<AssetDto>>([
            this.columns$,
            this.assetDetails$,
        ]).pipe(
            map(([columns, asset]: [AssetColumn[], AssetDto]) => {
                const assetProps = Object.keys(asset);
                return columns.filter((c) => assetProps.includes(c.fieldKey));
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.assetId) {
            this.refreshDetails();
        }
    }

    editDetails() {
        this.dialogService
            .open(EditAssetDetailsDialogComponent, {
                context: {
                    assetId: this.assetId,
                    availableColumns$: this.columns$,
                },
                closeOnBackdropClick: false,
            })
            .onClose.pipe(filter((res) => !!res))
            .subscribe((res) => {
                this.toastrService.success(res, 'Success update', {duration: 10000});
                this.refreshDetails();
                this.onChange.emit();
            });
    }

    refreshDetails() {
        this.getAssetDetails(this.assetId);
    }

    public getAssetDetails(assetId: string) {
        this.getAsset(assetId).subscribe((data: AssetDto) => this.assetDetails.next(data));

        this.getAssetCoordinate(assetId).subscribe((coord: CoordinateLatLong) => {
            if (coord?.latitude != 0.0 && coord?.longitude != 0.0) {
                this.coordinateValid.next(true);
                this.assetCoordinate.next(coord);
            } else {
                this.coordinateValid.next(false);
                this.assetCoordinate.next(null);
            }
            this.cd.detectChanges();
        });
    }

    generateLink(coord: CoordinateLatLong) {
        if (coord.latitude && coord.longitude && !isNaN(coord.latitude) && !isNaN(coord.longitude)) {
            return `https://www.google.com/maps/@${coord.latitude},${coord.longitude},114m/data=!3m1!1e3`;
        }
        return '';
    }
}
