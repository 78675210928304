import {Injectable} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {
    AssetDataAuditService,
    DATA_AUDIT_VIEW_OPTIONS,
    DataAuditChartResponse,
    DataAuditContextResponse,
    DataAuditHistoryResponse,
    DataAuditRequest,
    DataAuditStudyDataQuality,
} from '@core/interfaces/engin/data-audit/asset-data-audit';
import {DataAuditPopoutStore} from './data-audit-popout.store';
import {StudiesStore} from '../common/studies.store';
import {PagesStore} from '../config/pages.store';
import {DisplaySettings} from '@core/interfaces/common/pages';
import {WorkflowInfo} from '@core/interfaces/common/users';

@Injectable()
export class AssetDataAuditStore extends Unsubscribable {
    private studyDataQualityPopout: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    readonly studyDataQualityPopout$: Observable<any> = this.studyDataQualityPopout.asObservable();
    private studyDataQualityLoading = new BehaviorSubject<boolean>(false);
    readonly studyDataQualityLoading$ = this.studyDataQualityLoading.asObservable();

    public activeView = new BehaviorSubject<DATA_AUDIT_VIEW_OPTIONS>(DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD);
    public activeView$ = this.activeView.asObservable();
    public resultsLoadingContext = new BehaviorSubject<boolean>(false);
    public resultsLoadingHistory = new BehaviorSubject<boolean>(false);
    public resultsLoadingMetric = new BehaviorSubject<boolean>(false);

    // Data audit tool
    public resultsLoading$: Observable<boolean> = combineLatest<
        Observable<boolean>,
        Observable<boolean>,
        Observable<boolean>
    >([
        this.resultsLoadingContext.asObservable(),
        this.resultsLoadingHistory.asObservable(),
        this.resultsLoadingMetric.asObservable(),
    ]).pipe(
        map(([loadingContext, loadingHistory, loadingMetric]: [boolean, boolean, boolean]) => {
            return loadingContext || loadingHistory || loadingMetric;
        }),
    );

    private combineDataProfilingRequestInformation$ = combineLatest<
        Observable<DataAuditRequest>,
        Observable<DisplaySettings>,
        Observable<any>
    >([this.popoutStore.currentPopout$, this.pagesStore.currentDisplay$, this.activeView$]).pipe(shareReplay(1));

    // resultsLoading is intentionally set based on this API call alone, which is generally the longest-running
    readonly contextData$: Observable<DataAuditContextResponse> = this.combineDataProfilingRequestInformation$.pipe(
        tap(() => this.resultsLoadingContext.next(true)),
        switchMap(([popoutValue, displaySettings]) => {
            const req = this.prepareDataAuditRequest(popoutValue, displaySettings);
            return this.dataAuditService.getContextData(req);
        }),
        tap(() => this.resultsLoadingContext.next(false)),
    );

    readonly recentHistoryData$: Observable<DataAuditHistoryResponse> =
        this.combineDataProfilingRequestInformation$.pipe(
            switchMap(([popoutValue, displaySettings, activeView]) => {
                const req = this.prepareDataAuditRequest(popoutValue, displaySettings);
                return this.dataAuditService.getRecentHistory(req);
            }),
        );

    readonly metricChartData$: Observable<DataAuditChartResponse> = this.combineDataProfilingRequestInformation$.pipe(
        tap(() => this.resultsLoadingMetric.next(true)),
        switchMap(([popoutValue, displaySettings, activeView]) => {
            const req = this.prepareDataAuditRequest(popoutValue, displaySettings);
            switch (activeView) {
                case DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD:
                    return this.dataAuditService.getMetricChartByLoad(req);
                case DATA_AUDIT_VIEW_OPTIONS.ASSET_CLASS:
                    return this.dataAuditService.getMetricChartByAsset(req);
                case DATA_AUDIT_VIEW_OPTIONS.ANALYTIC:
                    return this.dataAuditService.getMetricChartByAnalytic(req);
                case DATA_AUDIT_VIEW_OPTIONS.DATA_POINT:
                    return this.dataAuditService.getMetricChartByPoint(req);
            }
        }),
        tap(() => this.resultsLoadingMetric.next(false)),
        shareReplay(1),
    );

    // Data quality widget
    readonly studyDataQuality$: Observable<DataAuditStudyDataQuality> = combineLatest<Observable<any>, Observable<any>>(
        [this.studiesStore.activeWorkflowRisk$.pipe(filter((d) => !!d)), this.studyDataQualityPopout$],
    ).pipe(
        filter(
            ([activeWorkflowInfo, popoutState]: [WorkflowInfo, any]) =>
                !!activeWorkflowInfo && activeWorkflowInfo.workflowId != -1,
        ),
        tap(() => this.studyDataQualityLoading.next(true)),
        switchMap(([activeWorkflowInfo, popoutState]: [any, any]) => {
            const filters = popoutState ? (popoutState.filterList ? popoutState.filterList : []) : [];
            const req = new DataAuditRequest(filters);
            return this.dataAuditService.getWorkflowDataQuality(activeWorkflowInfo.workflowId, req);
        }),
        tap(() => this.studyDataQualityLoading.next(false)),
    );

    // Controls
    public setStudyDataQualityPopout(val: any) {
        this.studyDataQualityPopout.next(val);
    }

    // Helpers
    private prepareDataAuditRequest(popoutValue: DataAuditRequest, displaySettings: DisplaySettings): DataAuditRequest {
        return popoutValue;
    }

    constructor(
        private studiesStore: StudiesStore,
        private dataAuditService: AssetDataAuditService,
        private popoutStore: DataAuditPopoutStore,
        private pagesStore: PagesStore,
    ) {
        super();
    }
}
