import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {AssetHealthIndex} from '../../../interfaces/engin/assets';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {AssetDto} from '@core/interfaces/common/asset';

@Injectable()
export class AssetsApi {
    private readonly prefix = 'analyzer/assets/study';

    constructor(private api: HttpService) {}

    // Study Assets
    getStudyAssetsDataSource(studyId: number, filters: Filter[]): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/${studyId}/list`, {filters: filters});
    }

    searchStudyAssets(studyId: number, assetId: string, filters: Filter[]): Observable<string[]> {
        return this.api.post(`${this.prefix}/${studyId}/search/${assetId}`, {filters: filters});
    }

    getStudyAsset(studyId: number, assetId: string) {
        return this.api.get(`${this.prefix}/${studyId}/${encodeURIComponent(assetId)}`);
    }

    // Asset Details
    getAssetDetails(studyId: number, assetId: string): Observable<any> {
        return this.api.get(`${this.prefix}/${studyId}/details/${encodeURIComponent(assetId)}`);
    }

    getAssetDetailsHealthIndex(studyId: number, assetId: string): Observable<AssetHealthIndex> {
        return this.api.get(`${this.prefix}/${studyId}/details-hi/${encodeURIComponent(assetId)}`);
    }

    getOneAssetForStudy(workflowItemId: number, assetId: string): Observable<APIResponse<AssetDto>> {
        return this.api.get(`analyzer/assets/workflowItemId/${workflowItemId}/assetId/${encodeURIComponent(assetId)}`);
    }
}
