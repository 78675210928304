import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Workflow, WorkflowItem, WorkflowService, WorkflowType} from '@core/interfaces/engin/workflow';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {WorkflowApi} from '../api/workflow.api';
import {SnapshotDto} from '@core/interfaces/common/snapshot';
import {PagingResponse} from '@core/interfaces/system/system-common';
import {WorkflowInfo} from '@core/interfaces/common/users';

@Injectable()
export class WorkflowServiceImpl extends WorkflowService {
    constructor(private api: WorkflowApi) {
        super();
    }

    getWorkflowOverviewDataSource(activeWorkflowIds: number[]): Observable<DataSource> {
        return this.api.getWorkflowOverviewDataSource(activeWorkflowIds);
    }

    getWorkflowDataSource(type: WorkflowType): Observable<DataSource> {
        return this.api.getWorkflowDataSource(type);
    }

    getWorkflowById(workflowId: number): Observable<APIResponse<Workflow>> {
        return this.api.getWorkflowById(workflowId);
    }

    getWorkflowItemById(workflowItemId: number): Observable<APIResponse<WorkflowItem>> {
        return this.api.getWorkflowItemById(workflowItemId);
    }

    executeNewWorkflowFromSnapshot(
        snapshotId: number,
        workflowType: string,
        automated: boolean,
        date?: string,
    ): Observable<any> {
        return this.api.executeNewWorkflowFromSnapshot(snapshotId, workflowType, automated, date);
    }

    executeWorkflowWithoutSnapshot(param: any): Observable<APIResponse<Workflow>> {
        return this.api.executeWorkflowWithoutSnapshot(param);
    }

    // executeNewWorkflowFromSnapshot(snapshotId: number, automated: boolean, date?: string): Observable<any> {
    //     return this.api.executeNewWorkflowFromSnapshot(snapshotId, automated, date);
    // }

    updateWorkflowNoteTag(workflowId: number, workflow: Workflow): Observable<APIResponse<Workflow>> {
        return this.api.updateWorkflowNoteTag(workflowId, workflow);
    }

    findBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow>> {
        return this.api.findBySnapshotId(snapshotId);
    }

    delete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(workflowId);
    }

    reversePendingDelete(workflowId: number): Observable<APIResponse<boolean>> {
        return this.api.reversePendingDelete(workflowId);
    }

    public clearActiveWorkflowsByUser(userId: string): Observable<APIResponse<boolean>> {
        return this.api.clearActiveWorkflowsByUser(userId);
    }

    public activateWorkflowsByUser(userId: string, workflowIds: number[]): Observable<APIResponse<WorkflowInfo[]>> {
        return this.api.activateWorkflowsByUser(userId, workflowIds);
    }

    findBySnapshotDtoByWorkflowId(workflowId: number): Observable<APIResponse<SnapshotDto>> {
        return this.api.findBySnapshotDtoByWorkflowId(workflowId);
    }

    findAllBySnapshotId(snapshotId: number): Observable<APIResponse<Workflow[]>> {
        return this.api.findAllBySnapshotId(snapshotId);
    }

    listLatestWorkflows(pageSize: number, workflowType: string): Observable<PagingResponse<Workflow>> {
        return this.api.listLatestWorkflows(pageSize, workflowType);
    }
}
