import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {SimpleTableData} from '../../../pages/connectivity-analysis/history/details/simple-table/simple-table.component';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {ProfileEntities} from '@core/backend/engin/services/connectivity.service';

export enum ConnectivityDetailsPages {
    DETAILS = 'Connectivity Details',
    REGIONS = 'Connected Regions',
    FEEDER_BREAKDOWN = 'Feeder Breakdown',
    FEEDER_PROFILE = 'Feeder Profile',
}

export abstract class ConnectivityService {
    // Main
    abstract getSummaryDetails(workflowItemId: number): Observable<APIResponse<SimpleTableData>>;

    abstract getAssetDemographicsChart(workflowItemId: number): Observable<APIResponse<SimpleMultiSeries<string>>>;

    abstract getCustomerDemographicsChart(workflowItemId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>>;

    abstract getRegionDetails(workflowItemId: number): Observable<APIResponse<SimpleTableData>>;

    abstract getRegionChart(workflowItemId: number): Observable<APIResponse<SimpleMultiSeries<string>[]>>;

    // Feeder
    abstract getFeederList(workflowItemId: number): Observable<DataSource>;

    abstract getFeederListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getFeederMatches(workflowId: number, partialFeederId: string): Observable<APIResponse<string[]>>;

    // Entity
    abstract getEntityListByFeeder(workflowItemId: number, feederId: string): Observable<DataSource>;

    abstract getEntityListByFeederCsv(
        workflowItemId: number,
        feederId: string,
    ): Observable<APIResponse<DownloadCSVUrl>>;

    abstract getProfileEntitiesByFeeder(workflowId: number, feederId: string): Observable<APIResponse<ProfileEntities>>;
}
