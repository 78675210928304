import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {
    PreSingedURL,
    DataFile,
    UploadFileCreate,
    FILE_FORMAT,
    DataManagementType,
} from '@core/interfaces/common/data-file';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DatasetType} from '@core/interfaces/common/dataset';

@Injectable()
export class DataFileApi {
    private readonly prefix = 'data/file';

    constructor(private api: HttpService) {}

    findOne(id: string): Observable<APIResponse<DataFile>> {
        return this.api.get(`${this.prefix}/${id}`);
    }

    create(data: UploadFileCreate): Observable<APIResponse<DataFile>> {
        return this.api.post(`${this.prefix}/`, data);
    }

    processFile(uploadId: string): Observable<APIResponse<DataFile>> {
        return this.api.put(`${this.prefix}/process/${uploadId}`, {});
    }

    upload(url: string, fileData: File): Observable<any> {
        return this.api.uploadS3(`${url}`, fileData, {
            reportProgress: true,
            observe: 'events',
            headers: {
                'Content-Disposition': 'attachment',
                'Content-Type': fileData.type.includes('excel') ? 'text/csv' : fileData.type,
            },
        });
    }

    getPresignedUrl(
        fileName: string,
        fileFormat: FILE_FORMAT,
        directory: string = '',
    ): Observable<APIResponse<PreSingedURL>> {
        return this.api.post(`${this.prefix}/presigned-url`, {
            fileName,
            fileFormat,
            directory,
        });
    }

    verifyUpload(fileName: string): Observable<APIResponse<any>> {
        return this.api.get(`${this.prefix}/verify-upload/${fileName}`);
    }

    listFiles(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list?filterByfileLoadType_EQUAL=SNAPSHOT`);
    }

    listPersistedFiles(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list?filterByfileLoadType_IN=LIVE|TRANSACTIONAL`);
    }

    listFilesBySchema(datasetType: DatasetType, status: string, type: DataManagementType): Observable<DataSource> {
        return this.api.getServerDataSource(
            `${this.prefix}/list?filterBydatasetType_EQUAL=${datasetType}&filterBystatus_EQUAL=${status}&filterByfileLoadType_EQUAL=${type}`,
        );
    }

    downloadFileByIds(ids: number[]): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/download-files?uploadFileIds=${ids}`);
    }
}
