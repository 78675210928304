import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    EvaluationDataAuditService,
    DatasetNote,
    Parameter,
    EvaluationAuditChartRequest,
    EvaluationAuditResponse,
    DatasetNoteRequest,
    EvaluationAuditComparisonRequest,
    EvaluationAuditComparisonResponse,
} from '@core/interfaces/engin/data-audit/evaluation-data-audit';
import {EvaluationDataAuditApi} from '../../api/data-audit/evaluation-data-audit.api';
import {APIResponse} from '@core/interfaces/system/system-common';

@Injectable()
export class EvaluationDataAuditServiceImpl extends EvaluationDataAuditService {
    constructor(private api: EvaluationDataAuditApi) {
        super();
    }

    // Retrieve core results
    getEvaluationAssetData(req: EvaluationAuditChartRequest): Observable<APIResponse<EvaluationAuditResponse>> {
        return this.api.getEvaluationAssetData(req);
    }

    getEvaluationGlobalData(req: EvaluationAuditChartRequest): Observable<APIResponse<EvaluationAuditResponse>> {
        return this.api.getEvaluationGlobalData(req);
    }

    getComparisonData(
        req: EvaluationAuditComparisonRequest,
    ): Observable<APIResponse<EvaluationAuditComparisonResponse>> {
        return this.api.getComparisonData(req);
    }

    // Support
    getDatasetNoteList(req: DatasetNoteRequest): Observable<APIResponse<DatasetNote[]>> {
        return this.api.getDatasetNoteList(req);
    }

    createDatasetNote(note: DatasetNote): Observable<APIResponse<DatasetNote>> {
        return this.api.uploadNoteData(note);
    }

    editDatasetNote(id: number, note: DatasetNote): Observable<APIResponse<DatasetNote>> {
        return this.api.editDatasetNote(id, note);
    }

    deleteDatasetNote(id: number): Observable<APIResponse<boolean>> {
        return this.api.deleteDatasetNote(id);
    }

    getAssetParameterList(): Observable<APIResponse<Parameter[]>> {
        return this.api.getAssetParameterList();
    }

    getGlobalParameterList(): Observable<APIResponse<Parameter[]>> {
        return this.api.getGlobalParameterList();
    }
}
