import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {UsersStore} from '@store/common/users.store';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'ngx-header-settings',
    templateUrl: './header-settings.component.html',
    styleUrls: ['./header-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderSettingsComponent implements OnInit {
    sensitiveInfoHeader: boolean = false;

    constructor(private usersStore: UsersStore) {}

    ngOnInit() {
        this.sensitiveInfoHeader = environment?.SENSITIVE_INFO_HEADER;
    }

    readonly currentUser$ = this.usersStore.currentUser$;
}
