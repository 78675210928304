<nb-card *ngIf="inputDataList$" class="chart-card">
    <nb-card-header>
        <span>{{ headerTitle }}</span>
        <nb-icon *ngIf="tooltipMessage" [nbTooltip]="tooltipMessage" icon="info-outline" status="basic"></nb-icon>
    </nb-card-header>

    <nb-card-body class="chart-card-body">
        <div class="subtitle-2 field-label">
            {{ radioGroupTitle }}
        </div>
        <nb-radio-group class="create-type-radio" [formControl]="selectControl" name="output">
            <nb-radio
                class="w-100"
                *ngFor="let option of inputDataList$ | async"
                [class.active]="selectControl.value === option"
                [value]="option"
            >
                {{ option[optionSetting.label] }}
            </nb-radio>
        </nb-radio-group>
        <ng-content></ng-content>
    </nb-card-body>
</nb-card>
