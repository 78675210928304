import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';
import {
    FeederMetricRequest,
    InfrastructurePeakLoads,
} from '@core/interfaces/engin/load-profile-analysis/feeder-analysis';

@Injectable()
export class FeederAnalysisLPApi {
    private readonly prefix = 'load-profile-analysis/feeder/analysis';

    constructor(private api: HttpService) {}

    public getPeekData(batchNumber: number, feederId: string): Observable<InfrastructurePeakLoads> {
        return this.api
            .get(`${this.prefix}/${batchNumber}/${feederId}/peaks-summary`)
            .pipe(map((res: APIResponse<InfrastructurePeakLoads>) => res.response));
    }

    public getFeederMetricsByBatch(
        batchNumber: number,
        data: FeederMetricRequest,
    ): Observable<SimpleMultiSeries<string>> {
        return this.api
            .post(`${this.prefix}/${batchNumber}/chart/metrics`, data)
            .pipe(map((res: APIResponse<SimpleMultiSeries<string>>) => res.response));
    }
}
