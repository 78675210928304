import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {OutputMeterLPApi} from '@core/backend/engin/api/load-profile-analysis/output-meter.api';
import {OutputMeterLPService} from '@core/interfaces/engin/load-profile-analysis/output-meter';

@Injectable()
export class OutputMeterLPServiceImpl extends OutputMeterLPService {
    constructor(private api: OutputMeterLPApi) {
        super();
    }

    public getMeterDataList(): Observable<DataSource> {
        return this.api.getMeterDataList();
    }
}
