import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'ngx-sensitive-info-contents',
    templateUrl: './sensitive-info-contents.component.html',
    styleUrls: ['./sensitive-info-contents.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitiveInfoContentsComponent {
    @Input() iconInHeader: boolean = true;
    @Input() header: string = 'Disclaimer for FERC Standards of Conduct and CEII';
    @Input() body: string =
        'Contains non-public transmission function information and ' +
        'Critical Energy Infrastructure Information. Do not duplicate or ' +
        'otherwise distribute without further authorization.';
    @Output() buttonAction = new EventEmitter<boolean>();

    constructor() {}
}
