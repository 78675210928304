<ng-container *ngIf="currentUser$ | async as user">
    <ng-container *ngIf="sensitiveInfoHeader">
        <ngx-sensitive-info-disclosure-header></ngx-sensitive-info-disclosure-header>
    </ng-container>
    <!-- Collapsed menu - user icon and username -->
    <div class="display-flex flex-column height-100">
        <div class="header-user-container" [nbPopover]="menuItems" nbPopoverTrigger="click">
            <!-- Icon -->
            <ngx-icon-box class="user-icon" icon="person-outline"></ngx-icon-box>
            <!-- Username -->
            <div class="user-info">
                <label class="secondary-label">User</label>
                <label class="main-label">
                    {{ user.username }}
                </label>
            </div>
            <!-- Expand icon -->
            <ngx-icon-box class="open-menu" icon="chevron-down-outline"></ngx-icon-box>
        </div>
    </div>
</ng-container>
<!-- Expanded menu -->
<ng-template #menuItems>
    <ngx-header-settings-dialog [user]="currentUser$ | async"></ngx-header-settings-dialog>
</ng-template>
