<nb-card class="details-card">
    <nb-card-header>
        <p class="primary-header">Asset Data</p>

        <button (click)="editDetails()" *ngIf="enableEdit && assetDetails$ | async" nbButton status="primary">
            <nb-icon icon="edit-outline"></nb-icon>
            Edit
        </button>
    </nb-card-header>

    <nb-card-body [nbSpinner]="!(assetDetails$ | async) || !(availableColumns$ | async)">
        <nb-accordion *ngIf="assetDetails$ | async as assetDetails" multi>
            <nb-accordion-item expanded>
                <nb-accordion-item-header>Parameters</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <ng-container *ngFor="let column of availableColumns$ | async">
                        <ng-container
                            *ngIf="!hideFields.includes(column.fieldKey) && !geographicFields.includes(column.fieldKey)"
                        >
                            <div class="section">
                                <span class="section-key">{{ column.fieldName }}</span>
                                <span class="section-value ellipsis">{{ assetDetails[column.fieldKey] }}</span>
                            </div>
                        </ng-container>
                    </ng-container>
                </nb-accordion-item-body>
            </nb-accordion-item>
            <nb-accordion-item>
                <nb-accordion-item-header>Geographic</nb-accordion-item-header>
                <nb-accordion-item-body>
                    <ng-container *ngFor="let column of availableColumns$ | async">
                        <ng-container
                            *ngIf="!hideFields.includes(column.fieldKey) && geographicFields.includes(column.fieldKey)"
                        >
                            <div class="section">
                                <span class="section-key">{{ column.fieldName }}</span>
                                <span class="section-value ellipsis">{{ assetDetails[column.fieldKey] }}</span>
                            </div>
                        </ng-container>
                    </ng-container>

                    <div *ngIf="coordinateValid$ | async" class="section">
                        <span class="section-key">Link</span>
                        <a
                            *ngIf="coordinate$ | async as coordinate"
                            class="section-value"
                            href="{{ generateLink(coordinate) }}"
                            target="_blank"
                        >
                            View on Google Maps
                        </a>
                    </div>
                </nb-accordion-item-body>
            </nb-accordion-item>
        </nb-accordion>
    </nb-card-body>
</nb-card>
