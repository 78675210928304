<nb-card size="large">
    <nb-card-header>
        <div>
            <p class="primary-header">Upload files</p>
            <p class="sub-header">Browse file(s) to upload</p>
        </div>
    </nb-card-header>

    <nb-card-body>
        <ngx-drop-file
            *ngIf="!fileUploaded"
            [fileFormats]="fileFormats"
            [multiple]="multiple"
            [disabled]="disabled"
            [additionalText]="additionalText"
            (onFileAdded)="onFileAdded.emit($event)"
        ></ngx-drop-file>

        <div *ngIf="fileUploaded">
            <div *ngFor="let file of fileList">
                <div class="row" *ngIf="file.status === 'STARTED'">
                    <div class="col-5">
                        <ngx-display-file [file]="file"></ngx-display-file>
                    </div>
                    <div class="col-7">
                        <nb-progress-bar size="tiny" status="info" [value]="file.progress"></nb-progress-bar>
                    </div>
                </div>
            </div>
        </div>
    </nb-card-body>
</nb-card>
