import {ChangeDetectionStrategy, Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Unsubscribable} from '@core/interfaces/unsubscribable';

@Component({
    selector: 'ngx-analyzer-numeric-range-control',
    templateUrl: './numeric-range-control.component.html',
    styleUrls: ['./numeric-range-control.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles: [
        `
            .flex {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding: 0.5rem 0.75rem;
            }

            .form-field {
                width: 42%;
            }

            label {
                font-weight: 500 !important;
                margin-bottom: 0.25rem;
            }
        `,
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => NumericRangeControlComponent),
            multi: true,
        },
    ],
})
export class NumericRangeControlComponent extends Unsubscribable implements ControlValueAccessor, OnInit, OnChanges {
    @Input() options: {min: number; max: number};

    minControl: FormControl = new FormControl();
    maxControl: FormControl = new FormControl();

    onChange(_: any) {}

    onTouched = () => {};

    constructor() {
        super();
    }

    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    registerOnTouched() {}

    touched = false;
    disabled = false;

    writeValue(value: any) {
        this.minControl.setValue(value?.min ?? null, {emitEvent: false});
        this.maxControl.setValue(value?.max ?? null);
    }

    ngOnInit() {
        const minVal = this.options.min;
        const maxVal = this.options.max;

        // Value changes trigger before user has ended their input; do not trigger min/max validation at this time
        this.minControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
            .subscribe((value: number) => {
                // Change non-integer values immediately
                if (value !== null && !this.isInteger(value)) {
                    value = Math.floor(value);
                    this.minControl.setValue(Math.floor(value), {emitEvent: false});
                }

                // Invalid data should not create further events
                if (value !== null && value < minVal) {
                    value = minVal;
                    this.minControl.setValue(value, {emitEvent: false});
                }

                if (value !== null && value > maxVal) {
                    value = maxVal;
                    this.minControl.setValue(value, {emitEvent: false});
                }

                if (value !== null && this.maxControl.value !== null && this.maxControl.value < value) {
                    this.maxControl.setValue(value, {emitEvent: false});
                }

                this.markAsTouched();
                this.onChange({min: this.minControl.value, max: this.maxControl.value});
            });

        this.maxControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$), debounceTime(500))
            .subscribe((value: number) => {
                // Change non-integer values immediately
                if (value !== null && !this.isInteger(value)) {
                    value = Math.floor(value);
                    this.maxControl.setValue(Math.floor(value), {emitEvent: false});
                }

                // Check value below defined min
                if (value !== null && value < minVal) {
                    value = minVal;
                    this.maxControl.setValue(value, {emitEvent: false});
                }

                // Check value above defined max, if a max is defined
                if (value !== null && value > maxVal) {
                    value = maxVal;
                    this.maxControl.setValue(value, {emitEvent: false});
                }

                if (value !== null && this.minControl.value !== null && this.minControl.value > value) {
                    this.minControl.setValue(value, {emitEvent: false});
                }

                this.markAsTouched();
                this.onChange({min: this.minControl.value, max: this.maxControl.value});
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.options && changes.options.currentValue) {
            setTimeout(() => {
                this.minControl.setValue(this.minControl.value, {emitEvent: false});
                this.maxControl.setValue(this.maxControl.value);
            });
        }
    }

    isInteger(num: number) {
        return (num ^ 0) === num;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    setDisabledState(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
}
