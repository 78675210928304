import {Observable} from 'rxjs';
import {AnalyzerRequest, AnalyzerResponse} from '@core/interfaces/engin/analyzer';

export abstract class ReportDataService {
    abstract getAnalyzerAbstractGraphData(req: AnalyzerRequest, workflowItemId: number): Observable<AnalyzerResponse[]>;

    abstract getAnalyzerCurrentDataQuality(
        req: AnalyzerRequest,
        workflowItemId: number,
    ): Observable<AnalyzerResponse[]>;
}
