import {Injectable} from '@angular/core';
import {HttpService} from '@core/backend/common/api/http.service';
import {Observable} from 'rxjs';
import {GeospatialViewerConfiguration} from '../model/viewer-config';
import {GeospatialFilterSettings} from '@core/interfaces/common/popout';
import {APIResponse, FilterFieldOption} from '@core/interfaces/system/system-common';

@Injectable()
export class GeospatialViewerControlsApi {
    private prefix = 'geospatial';
    private prefixFilter = 'popoutSettings';

    constructor(private api: HttpService) {}

    // Configuration
    public getConfig(): Observable<GeospatialViewerConfiguration> {
        return this.api.get(`${this.prefix}/config`);
    }

    getGeospatialFilterSettings(): Observable<GeospatialFilterSettings> {
        return this.api.get(`${this.prefixFilter}/get/analyzer`);
    }

    // Filters
    public getFilterFieldOptions(
        workflowItemId: number,
        fieldKey: string,
        search?: string,
        filters?: any[],
    ): Observable<APIResponse<FilterFieldOption[]>> {
        return this.api.post(`analyzer/${workflowItemId}/options/filters/field`, {
            fieldKey: fieldKey,
            search: search,
            filters: filters,
            maxItems: 10000,
        });
    }
}
