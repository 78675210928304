import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../@core/interfaces/common/auth';

/**
 * Verify Email
 * - Pre-auth (no login required)
 * - User lands here from email verification process. Automatically grabs token and verifies email.
 */
@Component({
    selector: 'ngx-auth-verify-email',
    templateUrl: './verify-email.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerifyEmailComponent implements OnInit {
    showMessages: any = {
        success: true,
        error: true,
    };

    errors: string[] = [];
    messages: string[] = [];

    constructor(
        private authService: AuthService,
        private router: Router,
        private route: ActivatedRoute,
        protected cd: ChangeDetectorRef,
    ) {}

    ngOnInit() {
        const token: string = this.route.snapshot.queryParamMap.get('token');
        this.authService.verifyEmailWithToken(token).subscribe((resp) => {
            if (resp && resp.success) {
                this.messages = resp.messages;
            } else {
                this.errors = resp.errors;
            }
            this.cd.detectChanges();
        });
    }
}
