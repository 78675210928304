<nb-card>
    <nb-card-header class="justify-content-end">
        <button (click)="close()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>
    <nb-card-body>
        <img [src]="img" [alt]="imgName" class="photo" />
    </nb-card-body>
</nb-card>
