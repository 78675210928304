import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

export interface CheckBoxItem {
    key: string;
    name: string;
    selected: boolean;
    enabled: boolean;
}

@Component({
    selector: 'ngx-checkbox-group',
    styleUrls: ['./checkbox-group.component.scss'],
    template: `
        <ngx-icon-box
            *ngIf="selectAllEnabled"
            (click)="changeSelection()"
            class="select-all-items"
            [title]="this.selected ? 'Unselect All' : 'Select All'"
            [icon]="this.selected ? 'square-outline' : 'checkmark-square-2-outline'"
        ></ngx-icon-box>
        <div class="checkbox-items">
            <ng-container *ngFor="let item of items">
                <nb-checkbox (click)="selectCheckbox(item)" [checked]="item.selected">
                    {{ item.name }}
                </nb-checkbox>
            </ng-container>
        </div>
    `,
    providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxGroupComponent), multi: true}],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxGroupComponent implements ControlValueAccessor, OnChanges {
    @Input() selectAllEnabled: boolean = true;
    @Input() keyName: string = 'key';

    public selected: boolean = false;

    propagateChange: any = () => {};

    constructor(private cd: ChangeDetectorRef) {}

    private _items: CheckBoxItem[] = [];

    get items(): CheckBoxItem[] {
        return this._items;
    }

    set items(val: CheckBoxItem[]) {
        this._items = val;
        this.propagateChange(val);
        this.cd.detectChanges();
    }

    selectCheckbox(checkbox: CheckBoxItem) {
        this.items = this.items.map((c: CheckBoxItem) => {
            if (c[this.keyName] === checkbox[this.keyName]) {
                c = {
                    ...c,
                    selected: !checkbox.selected,
                };
            }
            return c;
        });
    }

    writeValue(value: CheckBoxItem[]): void {
        if (value) {
            this.items = value;
        }
    }

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.propagateChange(this.items);
    }

    registerOnTouched() {}

    changeSelection() {
        this.items = this.items.map((c) => {
            return {
                ...c,
                selected: !this.selected,
            };
        });
        this.selected = !this.selected;
    }
}
