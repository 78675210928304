<ng-container>
    <div class="d-flex">
        <ngx-document-list
            (displayFile)="displayFile($event)"
            [assetId]="assetId"
            [refresh$]="refresh$"
            [getTagOptionsCallback]="searchTagOptions.bind(this)"
            [acceptedFileTypes]="acceptedFileTypes"
        ></ngx-document-list>

        <ngx-document-preview
            (changeFile)="refreshList()"
            (deleteFile)="deleteFile()"
            *ngIf="displayedFile"
            [document]="displayedFile"
            [getTagOptionsCallback]="searchTagOptions.bind(this)"
            [acceptedFileTypes]="acceptedFileTypes"
        ></ngx-document-preview>
    </div>
</ng-container>
