import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {UploadFileCreate} from '@core/interfaces/common/data-file';

/* deprecated */
@Injectable()
export class LegacyUploadsApi {
    private readonly prefix = 'uploads/legacy';

    constructor(private api: HttpService) {}

    processFCFile(fileData: UploadFileCreate, comment: string, purge: boolean): Observable<any> {
        return this.api.post(
            `${this.prefix}/survival-analysis/${fileData.schemaType}?purge=${purge}&comment=${comment}`,
            fileData,
        );
    }
}
