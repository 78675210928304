<ng-container *ngIf="statusChart$ | async as statusChart">
    <div *ngIf="!ignoreFormControl">
        <!--  <div class="height-100" *ngIf="!ignoreFormControl">-->
        <nb-card class="status-card">
            <nb-card-body class="chart-card">
                <div class="chart-card-container" *ngIf="graphsFormGroup" [formGroup]="graphsFormGroup">
                    <ngx-chart-settings
                        *ngIf="chartInstance"
                        [chartInstance]="chartInstance"
                        [unitOptions]="unitOptions"
                        [labelOptions]="labelOptions"
                        [graphId]="graphId"
                        [formControlName]="statusChart?.metadata.graphId"
                        [chartOptions]="statusChart?.options"
                        [chartType]="EC_TYPE_LOCAL"
                    ></ngx-chart-settings>
                </div>
                <ngx-chart [options]="statusChart?.options" (onChartInstanceInit)="chartInstance = $event"></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="height-100" *ngIf="ignoreFormControl">
        <nb-card class="status-card">
            <nb-card-body class="chart-card">
                <ngx-chart [options]="statusChart?.options"></ngx-chart>
            </nb-card-body>
        </nb-card>
    </div>
</ng-container>
