import {Injectable} from '@angular/core';
import {S3Service} from '@core/interfaces/common/s3';
import {Observable} from 'rxjs';
import {APIResponse, PresignedURL} from '@core/interfaces/system/system-common';
import {S3Api} from '@core/backend/common/api/s3.api';

@Injectable()
export class S3ServiceImpl extends S3Service {
    constructor(private api: S3Api) {
        super();
    }
    getPresignedUrl(fileName: string, fileFormat: string, endpoint: string): Observable<APIResponse<PresignedURL>> {
        return this.api.getPresignedUrl(fileName, fileFormat, endpoint);
    }
    uploadToPresignedUrl(url: string, fileData: File): Observable<any> {
        return this.api.upload(`${url}`, fileData);
    }
}
