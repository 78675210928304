import {Component, Input} from '@angular/core';
import {FormatsService} from '../../../@core/utils';
import {Unit} from '../../../@core/interfaces/engin/data-audit/asset-data-audit';

@Component({
    selector: 'ngx-ngx-mini-line-chart',
    templateUrl: './ngx-mini-line-chart.component.html',
    styleUrls: ['./ngx-mini-line-chart.component.scss'],
})
export class NgxMiniLineChartComponent {
    @Input() title: string;
    @Input() currentValue: number;
    @Input() unit: Unit;
    @Input() trend: any;
    @Input() chartOptions: any;

    public getFormattedValue(): string {
        if (this.currentValue) {
            return FormatsService.prepareValue(this.currentValue, this.unit.prefix[0], this.unit.suffix[0]);
        }
    }
}
