import {Injectable} from '@angular/core';
import {HttpService} from '../../../common/api/http.service';
import {Observable, of} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    PagingResponse,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    EconomicsOverTimeResponse,
    Project,
    ProjectCostAnalysis,
    ProjectCreate, ProjectDto,
    ProjectUpdate,
} from '../../../../../pages/geospatial-viewer/model/project';

import {map} from 'rxjs/operators';

@Injectable()
export class PmProjectApi {
    private readonly prefix = 'projects';

    constructor(private api: HttpService) {}

    // Project
    getProjectById(projectId: number): Observable<APIResponse<Project>> {
        return this.api.get(`${this.prefix}/${projectId}`);
    }

    createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.post(`${this.prefix}/`, req);
    }

    updateProject(req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.put(`${this.prefix}/${req.id}`, req);
    }

    deleteProject(projectId: number): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${projectId}`);
    }


    getProjectList(params: Filter[]): Observable<PagingResponse<Project>> {
        let paramQuery = params
            .map((p) => `filterBy${p.fieldKey}_${p.operator}=${p.value || p.values.join('|')}&`)
            .join('&');
        return this.api.get(`${this.prefix}/list?${paramQuery}`);
    }

    // Project Dto
    getProjectDtoById(workflowItemId: number, projectId: number): Observable<APIResponse<ProjectDto>> {
        return this.api.get(`${this.prefix}/dto/${projectId}/study/${workflowItemId}`);
    }

    getProjectDtoListDataSource(workflowItemId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/dto/list/study/${workflowItemId}`);
    }

    downloadCsvProjectDtoList(workflowItemId: number, params: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        let paramQuery = params
            .map((p) => `filterBy${p.fieldKey}_${p.operator}=${p.value || p.values.join('|')}&`)
            .join('&');
        return this.api.get(`${this.prefix}/dto/list/csv/study/${workflowItemId}?${paramQuery}`);
    }

    // Other project support
    getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.get(`${this.prefix}/assetscope/filters`);
    }

    getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.post(`${this.prefix}/${workflowItemId}/options/filters/field`, filterOption);
    }

    projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>> {
        return this.api.get(`${this.prefix}/name/${projectName}`);
    }

    getAssetListMatchingScope(workflowItemId: number, filterParams: Filter[]): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/study/${workflowItemId}/list/asset`, {filterParams});
    }

    downloadCsvAssetListMatchingScope(workflowItemId: number, dataTableParam: DataTableParam): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.post(`${this.prefix}/study/${workflowItemId}/list/asset/csv`, dataTableParam);
    }

    // Project details (and project assets)
    getProjectAssetsList(workflowItemId: number, projectId: number): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/${projectId}/study/${workflowItemId}/list/asset`);
    }

    downloadCsvProjectAssetsList(workflowItemId: number, projectId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/download/assets/${projectId || ''}/study/${workflowItemId}`);
    }

    getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>> {
        //TODO change api
        return of({
            response: {
                data: {
                    legend: 'legend',
                    labels: ['2021', '2022', '2023', '2024', '2025'],
                    data: [21, 45, 68, 38, 56],
                    unit: {
                        prefix: '',
                        suffix: '',
                        symbol: '',
                    },
                },
                info: {
                    tco_forced: 'tco_forced',
                    tco_optimal: 'tco_optimal',
                    tco_force_delta: 'tco_force_delta',
                },
            },
            status: 200,
            message: 'success',
        });
    }

    findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>> {
        //TODO change api
        return of({
            response: {
                data: {
                    legend: 'legend',
                    labels: ['Material', 'Labour', 'Vehicle & Tools', 'Other'],
                    data: [2100, 45000, 680, 3800],
                    unit: {
                        prefix: '',
                        suffix: '',
                        symbol: '',
                    },
                },
                info: {
                    capex_total: 'capex_total',
                    opex_total: 'opex_total',
                    opex_annual_average: 'opex_annual_average',
                    opex_5year_total: 'opex_5year_total',
                    opex_lifetime_total: 'opex_lifetime_total',
                },
                list: [
                    {
                        costComponent: 'Pole',
                        material: 300000,
                        labour: 1200,
                        vehicleTools: 10000,
                        other: 0,
                    },
                    {
                        costComponent: 'OH TX',
                        material: 50000,
                        labour: 172200,
                        vehicleTools: 10000,
                        other: 0,
                    },
                    {
                        costComponent: 'Pole',
                        material: 100000,
                        labour: 20000,
                        vehicleTools: 2300000,
                        other: 5000,
                    },
                ],
            },
            status: 200,
            message: 'success',
        });
    }

    downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/costanalysis/listDtoCsv?projectId=${projectId}`);
    }

    searchProjectId(search: string): Observable<APIResponse<string[]>> {
        /*return this.api.get(`${this.prefix}/search`, {
            params: new HttpParams().set('search', search || ''),
        });*/
        return of({
            response: ['1', '2', '3'],
            status: 200,
            message: 'success',
        });
    }

    // Analytics
    public removeProjectOutlierByProjectId(projectId: number, workflowItemId: number): Observable<APIResponse<Boolean>> {
        return this.api.get(`${this.prefix}/action/outliers/${projectId}/${workflowItemId}`);
    }
    public generateProjectBoundaryByProjectId(projectId: number, workflowItemId: number): Observable<APIResponse<Boolean>> {
        return this.api.get(`${this.prefix}/action/boundary/${projectId}/${workflowItemId}`);
    }
    public projectDetailsDtoListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/dto-details/list/csv/${workflowItemId}`);
    }
    public projectAssetsDtoListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.get(`${this.prefix}/dto-assets/list/csv/${workflowItemId}`);
    }
}
