import {ReportDashboardType} from '@core/interfaces/engin/reporting/report-config';
import {AnalyzerRequest} from '@core/interfaces/engin/analyzer';
import {GraphList} from '@core/interfaces/common/pages';

export const ReportDashboardTypeTemplates: {
    [key: string]: {
        pageId: string;
        tabId: string;
        graphList: GraphList[];
    };
} = {
    [ReportDashboardType.EOL_METRICS_CALENDAR_AGE_PIE]: {
        pageId: 'eolmetrics',
        tabId: 'calage',
        graphList: [
            {
                graphId: 'calage_summary',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_PIE]: {
        pageId: 'eolmetrics',
        tabId: 'effage',
        graphList: [
            {
                graphId: 'effage_summary',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_HEALTH_PIE]: {
        pageId: 'eolmetrics',
        tabId: 'health',
        graphList: [
            {
                graphId: 'health_summary',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_ECONOMIC_PIE]: {
        pageId: 'eolmetrics',
        tabId: 'economic',
        graphList: [
            {
                graphId: 'economic_summary',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_CALENDAR_AGE_BAR]: {
        pageId: 'eolmetrics',
        tabId: 'calage',
        graphList: [
            {
                graphId: 'calage_status',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_EFFECTIVE_AGE_BAR]: {
        pageId: 'eolmetrics',
        tabId: 'effage',
        graphList: [
            {
                graphId: 'effage_status',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_HEALTH_BAR]: {
        pageId: 'eolmetrics',
        tabId: 'health',
        graphList: [
            {
                graphId: 'health_status',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.EOL_METRICS_ECONOMIC_BAR]: {
        pageId: 'eolmetrics',
        tabId: 'economic',
        graphList: [
            {
                graphId: 'economic_status',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.DEMOGRAPHICS_CALENDAR_AGE]: {
        pageId: 'demographics',
        tabId: 'calage',
        graphList: [
            {
                graphId: 'calage',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.DEMOGRAPHICS_EFFECTIVE_AGE]: {
        pageId: 'demographics',
        tabId: 'effage',
        graphList: [
            {
                graphId: 'effage',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.DEMOGRAPHICS_HEALTH]: {
        pageId: 'demographics',
        tabId: 'health',
        graphList: [
            {
                graphId: 'health',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.DEMOGRAPHICS_ECONOMIC]: {
        pageId: 'demographics',
        tabId: 'economic',
        graphList: [
            {
                graphId: 'economic',
                unit: 'count',
            },
        ],
    },
    [ReportDashboardType.ACA_CURRENT_DATA_QUALITY]: {
        pageId: 'aca',
        tabId: 'aca-data-quality',
        graphList: [
            {
                graphId: 'data-quality',
                unit: 'count',
            },
        ],
    },
};

export interface DemographicsSettings {
    dataMin: number;
    dataMax: number;
    xLabel: string;
}

export const DemographicsSettings = {
    [ReportDashboardType.DEMOGRAPHICS_ECONOMIC]: {
        dataMin: -100,
        dataMax: 100,
        xLabel: 'Time Until Optimal Intervention Time',
    },
    [ReportDashboardType.DEMOGRAPHICS_HEALTH]: {
        dataMin: 0,
        dataMax: 100,
        xLabel: 'Health Index',
    },
    [ReportDashboardType.DEMOGRAPHICS_EFFECTIVE_AGE]: {
        dataMin: 0,
        dataMax: 100,
        xLabel: 'Effective Age',
    },
    [ReportDashboardType.DEMOGRAPHICS_CALENDAR_AGE]: {
        dataMin: 0,
        dataMax: 100,
        xLabel: 'Calendar Age',
    },
};
export class RequestTemplate {
    static Analyzer = {
        pageId: '',
        tabId: '',
        filterList: [],
        groupList: [],
        sensitivityList: [],
        inputList: [
            {
                fieldKey: 'eol_period',
                dataType: 'integer',
                value: 10,
            },
        ],
        graphList: [],
    };

    static configureReportRequest(type: ReportDashboardType, groupList, filterList): AnalyzerRequest {
        let _template = ReportDashboardTypeTemplates[type];

        return {...RequestTemplate.Analyzer, ..._template, groupList, filterList};
    }
}
