import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../common/api/http.service';
import {AvailabilityData, ForecasterRequest, ForecasterResponse} from '../../../interfaces/engin/forecaster';

@Injectable()
export class ForecasterApi {
    private readonly prefix = 'forecaster';

    constructor(private api: HttpService) {}

    getForecasterData(request: ForecasterRequest, studyId: number): Observable<ForecasterResponse[]> {
        return this.api.post(`${this.prefix}/data/${studyId}`, request);
    }

    getStationAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData> {
        return this.api.post(`${this.prefix}/page/availability/station`, request);
    }

    getLineAvailabilityData(request: ForecasterRequest): Observable<AvailabilityData> {
        return this.api.post(`${this.prefix}/page/availability/line`, request);
    }
}
