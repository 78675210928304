import {Component} from '@angular/core';
import {EMPTY_CHART_COLOR} from '../empty-chart-config';

@Component({
    selector: 'ngx-empty-area-chart',
    templateUrl: './area-chart.component.html',
    styleUrls: ['./area-chart.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyAreaChartComponent {
    textBackGroundColor = EMPTY_CHART_COLOR.color;
    barColor = EMPTY_CHART_COLOR.color;
    transparent = EMPTY_CHART_COLOR.transparent;

    xAxisDataTextStyle = {
        borderRadius: 3,
        borderColor: this.textBackGroundColor,
        backgroundColor: this.textBackGroundColor,
        color: this.transparent,
    };

    options = {
        xAxis: {
            type: 'category',
            data: [
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
                {
                    value: 'empty String',
                    textStyle: this.xAxisDataTextStyle,
                },
            ],
        },
        yAxis: {
            type: 'value',
            splitLine: {
                show: true,
            },
            axisLabel: {
                color: this.transparent,
                backgroundColor: this.textBackGroundColor,
                borderRadius: 6,
                fontSize: 20,
                margin: 10,
                formatter: '{ee}',
            },
        },
        series: [
            {
                data: [
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                    Math.floor(Math.random() * 100),
                ],
                type: 'line',
                stack: 1,
                areaStyle: {
                    color: this.barColor,
                },
                itemStyle: {
                    color: this.barColor,
                },
                smooth: true,
            },
        ],
        animation: false, // disable to loading animation
    };
}
