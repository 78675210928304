import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {StudiesStore} from '@store/common/studies.store';
import {combineLatest} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';

export class SensitivityConfiguration {
    params: SensitivityParam[];
}

export class SensitivityParam {
    param: string;
    value: number;
}

@Injectable({providedIn: 'root'})
export class SensitivityAnalysisStore extends Unsubscribable {
    private sensitivityExecuted: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
    readonly sensitivityExecuted$: Observable<number> = this.sensitivityExecuted.asObservable();

    private sensitivityActive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly sensitivityActive$: Observable<boolean> = this.sensitivityActive.asObservable();

    readonly sensitivityId$: Observable<string> = combineLatest<Observable<number>, Observable<boolean>>([
        this.sensitivityExecuted$,
        this.sensitivityActive$,
    ]).pipe(
        map(([sensitivityExecuted, sensitivityActive]: [number, boolean]) => {
            // SensitivityId = -1 to access normal results; if enabled and completed, sensitivityId > 0
            if (sensitivityActive) {
                return sensitivityExecuted.toString();
            }
            return '-1';
        }),
    );

    private sensitivityConfiguration: BehaviorSubject<SensitivityConfiguration> =
        new BehaviorSubject<SensitivityConfiguration>(null);
    readonly sensitivityConfiguration$: Observable<SensitivityConfiguration> =
        this.sensitivityConfiguration.asObservable();

    private demandFactorOptions: {key: string; label: string}[] = [
        {key: 'ORGANIC_GROWTH', label: 'Organic Growth'},
        {key: 'LOAD_TRANSFER', label: 'Load Transfers'},
        {key: 'LLA', label: 'Large Load Additions'},
        {key: 'ELECTRIC_VEHICLE', label: 'Electric Vehicles'},
        {key: 'PHOTOVOLTAIC', label: 'Photovoltaics'},
    ];

    constructor(private studiesStore: StudiesStore) {
        super();
        const sensitivityListDefault: {param: string; value: number}[] = this.demandFactorOptions.map((item) => {
            return {
                param: item.key,
                value: 0,
            };
        });
        this.sensitivityConfiguration.next({params: sensitivityListDefault});
        // Reset sensitivity executed status whenever active study changes
        this.studiesStore.activeStudyIdLf$.pipe(takeUntil(this.unsubscribe$)).subscribe((workflowItemId) => {
            this.sensitivityExecuted.next(-1);
            this.sensitivityActive.next(false);
        });
    }

    public getSensitivityExecuted(): number {
        return this.sensitivityExecuted.getValue();
    }

    public setSensitivityExecuted(value: number) {
        this.sensitivityExecuted.next(value);
    }

    public getSensitivityActive(): boolean {
        return this.sensitivityActive.getValue();
    }

    public setSensitivityActive(val: boolean) {
        this.sensitivityActive.next(val);
    }

    public toggleSensitivityActive() {
        this.sensitivityActive.next(!this.getSensitivityActive());
    }

    public getSensitivityConfiguration(): SensitivityConfiguration {
        return this.sensitivityConfiguration.getValue();
    }

    public setSensitivityConfiguration(val: SensitivityConfiguration) {
        if (val) {
            this.sensitivityConfiguration.next(val);
        }
    }

    public resetSensitivityConfiguration() {
        const newConfig: SensitivityConfiguration = {
            ...this.sensitivityConfiguration.getValue(),
            params: this.sensitivityConfiguration.getValue().params.map((p) => {
                return {
                    ...p,
                    value: 0.0,
                };
            }),
        };
        this.sensitivityConfiguration.next(newConfig);
    }
}
