import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable} from 'rxjs';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {take, takeUntil} from 'rxjs/operators';

@Component({
    selector: 'ngx-radio-card',
    templateUrl: './radio-card.component.html',
    styleUrls: ['./radio-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioCardComponent<T> extends Unsubscribable implements OnInit {
    @Input() inputDataList$: Observable<T[]>;
    @Input() headerTitle: string;
    @Input() radioGroupTitle: string;
    @Input() optionSetting: T;
    @Input() tooltipMessage: string;
    @Input() resetToValue: BehaviorSubject<T> = new BehaviorSubject<T>(null);
    @Output() onInputChanges: EventEmitter<{current: T; previous: T}> = new EventEmitter();

    selectControl: FormControl = new FormControl(null);

    previousValue: BehaviorSubject<T> = new BehaviorSubject<T>(null);

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.selectControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((input: T) => {
            this.onInputChanges.emit({current: input, previous: this.previousValue.value});

            this.previousValue.next(input);
        });

        this.resetToValue
            .asObservable()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((value: T) => this.setDefaultOption(value));
    }

    setDefaultOption(value?: T) {
        if (value) this.selectControl.setValue(value, {emitEvent: false});
        else this.inputDataList$.pipe(take(1)).subscribe((options: T[]) => this.selectControl.setValue(options[0]));
    }
}
