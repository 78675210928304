import {Injectable} from '@angular/core';
import {DownloadURL, PeriodFilter} from '@core/interfaces/engin/load-forecast/load-forecast';
import {Observable} from 'rxjs';
import {APIResponse, SimpleMultiSeries, SimpleSeries} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {LoadProfileInfo} from '@core/interfaces/engin/load-forecast/meter';
import {DxTransformerLoadForecastApi} from '../../api/load-forecast/dx-transformer.api';
import {DxTransformerLoadForecastService, TransformerInfo} from '@core/interfaces/engin/load-forecast/dx-transformer';

@Injectable()
export class DxTransformerLoadForecastServiceImpl extends DxTransformerLoadForecastService {
    constructor(private api: DxTransformerLoadForecastApi) {
        super();
    }

    /*
     * Infrastructure: results for dx transformer(s)
     */
    public getDxTransformerInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<TransformerInfo[]> {
        return this.api.getDxTransformerInfo(workflowItemId, scenarioId, transformerId);
    }

    public getDxTransformerPivot(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        return this.api.getDxTransformerPivot(
            workflowItemId,
            scenarioId,
            transformerId,
            period,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getDxTransformerPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        period: PeriodFilter,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getDxTransformerPivotCSV(
            workflowItemId,
            scenarioId,
            transformerId,
            period,
            seasonIds,
            unit,
            sensitivityId,
        );
    }

    public getLoadProfileInfo(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<LoadProfileInfo> {
        return this.api.getLoadProfileInfo(workflowItemId, scenarioId, transformerId);
    }

    public getLoadProfileRaw(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<SimpleSeries<Date, number>> {
        return this.api.getLoadProfileRaw(workflowItemId, scenarioId, transformerId);
    }

    public getLoadProfileTypicalDay(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
        groupBy: string,
    ): Observable<SimpleMultiSeries<number>> {
        return this.api.getLoadProfileTypicalDay(workflowItemId, scenarioId, transformerId, groupBy);
    }

    public getDxTransformerInfrastructureList(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<DataSource> {
        return this.api.getDxTransformerInfrastructureList(workflowItemId, scenarioId, transformerId);
    }

    public getDxTransformerInfrastructureListCSV(
        workflowItemId: number,
        scenarioId: string,
        transformerId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.getDxTransformerInfrastructureListCSV(workflowItemId, scenarioId, transformerId);
    }
}
