import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {ChartSortOption} from './chart-data-sort.interface';

@Component({
    selector: 'ngx-chart-data-sort-popup',
    template: `
        <ng-container *ngIf="options">
            <div class="sort-container">
                <div class="sort-items">
                    <span style="font-size: 11px; color: #999;">Sort By</span>
                    <nb-radio-group [(value)]="selection" (valueChange)="selectionChanged.emit($event)">
                        <nb-radio *ngFor="let item of options" [value]="item.uniqueCode">
                            {{ item.getLabel() }}
                        </nb-radio>
                    </nb-radio-group>
                </div>
                <!--<div class="selector-arrow"></div>-->
            </div>
        </ng-container>
    `,
    styleUrls: ['./chart-data-sort.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartDataSortPopupComponent {
    @Input() options: ChartSortOption[];
    @Input() selection: string;
    @Output() selectionChanged = new EventEmitter<string>();
}
