import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {NbAuthService} from '@nebular/auth';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {BehaviorSubject, Observable} from 'rxjs';
import {ForecasterPopoutStore, OptimizerPopoutStore, PagesStore, ReportsStore, SidebarStore} from '../@store';
import html2canvas from 'html2canvas';
import {DOCUMENT} from '@angular/common';
import {Page} from '@core/interfaces/common/pages';
import {Router} from '@angular/router';
import {UsersStore} from '@store/common/users.store';
import {UsageAnalyticsService} from '@core/utils/usage-analytics.service';
import {NbDialogService} from '@nebular/theme';
import {ReportFilterDialogComponent} from '@theme/components/dialogs/report-filter-dialog/report-filter-dialog.component';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {AnalyzerControlStore} from '@store/analyzer/analyzer-control.store';

@Component({
    selector: 'ngx-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagesComponent extends Unsubscribable {
    public readonly isAuthenticated$: Observable<boolean> = this.authService.onAuthenticationChange().pipe(
        finalize(() => {
            // Keep store active across entire scope of application, so data is saved outside of modules
            //this.analyzerPopoutStore.destroy();
            this.optimizerFilterStore.destroy();
            this.forecasterFilterStore.destroy();
        }),
    );

    private assets$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    constructor(
        public pagesStore: PagesStore,
        public authService: NbAuthService,
        public sidebarStore: SidebarStore,
        private reportsStore: ReportsStore,
        private analyzerPopoutStore: AnalyzerControlStore,
        private optimizerFilterStore: OptimizerPopoutStore,
        private forecasterFilterStore: ForecasterPopoutStore,
        private router: Router,
        private usageAnalyticsService: UsageAnalyticsService,
        private dialogService: NbDialogService,
        @Inject(DOCUMENT) private document: Document,
        private usersStore: UsersStore,
    ) {
        super();
        this.reportsStore.assetClassList$.pipe(takeUntil(this.unsubscribe$)).subscribe((assets: string[]) => {
            this.assets$.next(assets);
        });

        if (!this.usersStore.currentUser.getValue()) this.usersStore.refreshUser().subscribe();
    }

    getCapture() {
        // event

        const pageContainer = this.document.getElementsByClassName('layout').item(0) as HTMLElement;
        if (pageContainer) {
            html2canvas(pageContainer).then((canvas) => {
                const data = canvas.toDataURL('image/jpeg', 1);
                const downloadLink = this.document.createElement('a');
                downloadLink['href'] = encodeURI(data);
                downloadLink['download'] = 'screenshot.jpg';
                downloadLink.click();
            });
            this.usageAnalyticsService.logDownload('screenshot', 'Screen Capture');
        }
    }

    getOutputReport(module: Page) {
        // Analyzer report has optional filter criteria by asset class
        if (module.id.toLowerCase().includes('analyzer')) {
            this.dialogService
                .open(ReportFilterDialogComponent, {
                    context: {
                        options$: this.assets$.asObservable().pipe(
                            map((data) => {
                                return data.map((o) => ({
                                    value: o,
                                    label: o,
                                }));
                            }),
                        ),
                    },
                    closeOnBackdropClick: false,
                })
                .onClose.subscribe((c) => {
                    if (c?.action == 'confirm') {
                        this.reportsStore.downloadOutputReportAnalyzer(
                            module.report,
                            this.analyzerPopoutStore.selectedSensitivityItem,
                            c.data,
                        );
                    }
                });
        } else {
            this.reportsStore.downloadOutputReportGeneric(
                module.report,
                this.analyzerPopoutStore.selectedSensitivityItem,
            );
        }
    }

    ReportingTool() {
        this.router.navigate(['/reporting-tool/report-config'], {
            // queryParams: { userId: this.userId, userName: this.userName }
        });
    }

    showReportConfigShortcut() {
        return !this.router.url.includes('reporting-tool');
    }
}
