import {Component, Input, OnInit} from '@angular/core';
import {ViewCell} from '@mominsamir/ngx-smart-table';
import {StudiesStore} from '@store/index';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {finalize, map, takeUntil} from 'rxjs/operators';
import {NbToastrService} from '@nebular/theme';
import {DefaultStudyInfo, UsersService} from '@core/interfaces/common/users';
import {Observable} from 'rxjs';

@Component({
    selector: 'ngx-default-study-selector',
    template: `
        <ng-container *ngIf="defaultWorkflowIds$ | async as defaultWorkflowIds">
            <nb-icon
                class="pointer"
                [class.disabled]="disabled | async"
                [icon]="
                    defaultWorkflowIds.includes(rowWorkflowId) ? 'checkmark-circle-2' : 'checkmark-circle-2-outline'
                "
                [status]="(disabled | async) || !defaultWorkflowIds.includes(rowWorkflowId) ? 'basic' : 'warning'"
                (click)="setDefaultCollection(rowData)"
            ></nb-icon>
        </ng-container>
    `,
    styleUrls: ['./study-selector.component.scss'],
})
export class DefaultStudySelectorComponent extends Unsubscribable implements ViewCell, OnInit {
    @Input() rowData: any;
    @Input() value: string | number;
    @Input() disabled: Observable<boolean>;

    rowWorkflowId: number;

    readonly defaultWorkflowIds$: Observable<number[]> = this.studiesStore.defaultStudyInfo$.pipe(
        takeUntil(this.unsubscribe$),
        map((defaultStudyInfo: DefaultStudyInfo) => defaultStudyInfo.defaultWorkflowList.map((w) => w.workflowId)),
    );

    constructor(
        public studiesStore: StudiesStore,
        private usersService: UsersService,
        private toastrService: NbToastrService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.rowWorkflowId = this.rowData.id;
    }

    setDefaultCollection(rowData) {
        if (rowData.status !== 'SUCCESS' && rowData.status !== 'SUCCESS_WITH_WARNING') {
            this.toastrService.danger(
                'Study collection is incomplete and cannot be set to default collection. Please ' +
                    'select a study collection which has been successfully completed.',
                'Default study collection not updated!',
                {duration: 10000},
            );
        } else {
            this.studiesStore.setStudiesLoading(true);
            this.usersService
                .updateDefaultStudyByType(this.rowData.workflowType, this.rowData.id)
                .pipe(finalize(() => this.studiesStore.setStudiesLoading(false)))
                .subscribe(() => this.studiesStore.refreshDefaultStudyInfo());
        }
    }
}
