import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '@core/backend/common/api/http.service';
import {AnalyzerRequest, AnalyzerResponse} from '@core/interfaces/engin/analyzer';
import {SimpleMultiSeries} from '@core/interfaces/system/system-common';
import {map} from 'rxjs/operators';

@Injectable()
export class ReportDataApi {
    private prefix = 'reporting/data';

    constructor(private api: HttpService) {}

    getAnalyzerAbstractGraphData(req: AnalyzerRequest, workflowItemId: number): Observable<AnalyzerResponse[]> {
        return this.api.post(`${this.prefix}/analyzer/${workflowItemId}/abstract`, req);
    }

    getAnalyzerCurrentDataQuality(req: AnalyzerRequest, workflowItemId: number): Observable<AnalyzerResponse[]> {
        return this.api.post(`${this.prefix}/analyzer/${workflowItemId}/health/data-quality`, req).pipe(
            map((res: SimpleMultiSeries<string>) => {
                return [
                    {
                        pageId: req.pageId,
                        title: '',
                        tabId: req.tabId,
                        graphId: req.graphList[0].graphId,
                        labels: res.labels,
                        unit: null,
                        data: res,
                        legend: [],
                        highlight: [],
                    },
                ];
            }),
        );
    }
}
