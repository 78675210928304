import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {NbAccessChecker} from '@nebular/security';
import {Observable, of} from 'rxjs';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {PageGroup, PagesService} from '@core/interfaces/common/pages';
import {NbToastrService} from '@nebular/theme';
import {PagesStore} from '@store/config/pages.store';
import {UsersStore} from '@store/common/users.store';

@Injectable()
export class PermissionsGuard implements CanActivate {
    constructor(
        private toastrService: NbToastrService,
        private pagesService: PagesService,
        protected pagesStore: PagesStore,
        private accessChecker: NbAccessChecker,
        private usersStore: UsersStore,
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (!this.usersStore.currentUser.getValue()) this.usersStore.refreshUser().subscribe();

        return this.pagesStore.pageGroups$.pipe(
            filter((pageGroups) => !!pageGroups),
            map((pageGroups: PageGroup[]) => {
                let currentGroup = null;
                try {
                    currentGroup = []
                        .concat(...pageGroups.map((group) => group.children))
                        .find((group) => state.url.indexOf(group.link) >= 0);
                } finally {
                }

                let currentPage = null;
                try {
                    currentPage = []
                        .concat(...pageGroups.map((group) => group.children))
                        .find((group) => {
                            return state.url.indexOf(group.link) >= 0;
                        })
                        .children.find((page) => state.url.indexOf(page.link) >= 0);
                } finally {
                }

                let currentTab = null;
                try {
                    currentTab = []
                        .concat(
                            ...[]
                                .concat(...pageGroups.map((group) => group.children))
                                .find((group) => state.url.indexOf(group.link) >= 0)
                                .children.map((page) => page.children || []),
                        )
                        .find((tab) => state.url.indexOf(tab.link) >= 0);
                } finally {
                }
                // If group exists, check group enabled
                if (currentGroup) {
                    if (!currentGroup.enabled) {
                        this.toastrService.danger('This module is locked.', 'Locked');
                        return false;
                    }
                    // If pages exist, check page enabled
                    if (currentPage) {
                        if (!currentPage.enabled) {
                            this.toastrService.danger('This page is locked.', 'Locked');
                            return false;
                        }
                        // If tabs exist, check tab enabled
                        if (currentTab) {
                            if (!currentTab.enabled) {
                                this.toastrService.danger('This tab is locked.', 'Locked');
                                return false;
                            }
                        }
                    }
                }
                return true;
            }),
            switchMap((canActivate: boolean) => {
                if (canActivate) {
                    return this.accessChecker.isGranted('view', state.url).pipe(
                        tap((isGranted: boolean) => {
                            if (!isGranted) {
                                this.toastrService.warning(
                                    'You do not have sufficient permissions to view this page.',
                                    'Access Denied',
                                );
                            }
                        }),
                    );
                } else {
                    return of(false);
                }
            }),
        );
    }
}
