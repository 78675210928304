import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {LegacyUploadsService} from '@core/interfaces/common/legacyUploads';
import {LegacyUploadsApi} from '../api/legacyUploads.api';
import {UploadFileCreate} from '@core/interfaces/common/data-file';

@Injectable()
export class LegacyUploadsServiceImpl extends LegacyUploadsService {
    constructor(private api: LegacyUploadsApi) {
        super();
    }

    processFCFile(fileData: UploadFileCreate, comment: string, purge: boolean): Observable<any> {
        return this.api.processFCFile(fileData, comment, purge);
    }
}
