import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {
    APIResponse,
    DataTableParam,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse, FilterFieldType, FilterOperatorType,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {
    EconomicsOverTimeResponse,
    Project,
    ProjectCostAnalysis,
    ProjectCreate, ProjectDto,
    ProjectUpdate,
} from '../../../../../pages/geospatial-viewer/model/project';
import {PmProjectService} from '@core/interfaces/engin/program-management/pm-project';
import {PmProjectApi} from '../../api/program-management/pm-project.api';
import {map} from 'rxjs/operators';

@Injectable()
export class PmProjectServiceImpl extends PmProjectService {
    constructor(private api: PmProjectApi) {
        super();
    }

    // Project
    getProjectById(projectId: number): Observable<APIResponse<Project>> {
        return this.api.getProjectById(projectId);
    }

    createProject(req: ProjectCreate): Observable<APIResponse<Project>> {
        return this.api.createProject(req);
    }

    updateProject(req: ProjectUpdate): Observable<APIResponse<Project>> {
        return this.api.updateProject(req);
    }

    deleteProject(projectId: number): Observable<APIResponse<boolean>> {
        return this.api.deleteProject(projectId);
    }

    getProjectList(params: Filter[]): Observable<Project[]> {
        return this.api.getProjectList(params).pipe(map((result) => result.items));
    }

    getAllProjectsByProgramId(programId: number): Observable<Project[]> {
        const filter: Filter = {
            fieldKey: 'programId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: programId,
        };
        return this.getProjectList([filter]);
    }

    // Project DTO
    getProjectDtoById(workflowItemId: number, projectId: number): Observable<APIResponse<ProjectDto>> {
        return this.api.getProjectDtoById(workflowItemId, projectId);
    }

    getProjectDtoListDataSource(workflowItemId: number): Observable<DataSource> {
        return this.api.getProjectDtoListDataSource(workflowItemId);
    }

    downloadCsvProjectDtoList(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadCsvProjectDtoList(workflowItemId, []);
    }

    downloadCsvProjectDtoListByProgramId(workflowItemId: number, programId: number): Observable<APIResponse<DownloadCSVUrl>> {
        const filter: Filter = {
            fieldKey: 'programId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: programId,
        };
        return this.api.downloadCsvProjectDtoList(workflowItemId, [filter]);
    }

    downloadCsvProjectDtoListBySegmentId(workflowItemId: number, segmentId: number): Observable<APIResponse<DownloadCSVUrl>> {
        const filter: Filter = {
            fieldKey: 'segmentId',
            fieldType: FilterFieldType.LONG,
            operator: FilterOperatorType.EQUAL,
            value: segmentId,
        };
        return this.api.downloadCsvProjectDtoList(workflowItemId, [filter]);
    }

    // Other project support
    getAssetScopeFilters(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.getAssetScopeFilters();
    }

    getAssetFilterFieldOptions(
        workflowItemId: number,
        filterOption: FilterFieldOptionRequest,
    ): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.getAssetFilterFieldOptions(workflowItemId, filterOption);
    }

    getAssetListMatchingScope(workflowItemId: number, filterParams: Filter[]): Observable<DataSource> {
        return this.api.getAssetListMatchingScope(workflowItemId, filterParams);
    }

    downloadCsvAssetListMatchingScope(
        workflowItemId: number,
        filters: DataTableParam,
    ): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadCsvAssetListMatchingScope(workflowItemId, filters);
    }

    projectNameExistsValidator(projectName: string): Observable<APIResponse<boolean>> {
        return this.api.projectNameExistsValidator(projectName);
    }

    // Project details (and project assets)
    getProjectAssetsList(workflowItemId: number, projectId: number): Observable<DataSource> {
        return this.api.getProjectAssetsList(workflowItemId, projectId);
    }

    downloadCsvProjectAssetsList(workflowItemId: number, projectId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadCsvProjectAssetsList(workflowItemId, projectId);
    }

    getEconomicsOverTime(id: number, year: number): Observable<APIResponse<EconomicsOverTimeResponse>> {
        return this.api.getEconomicsOverTime(id, year);
    }

    findProjectCostAnalysis(id: number): Observable<APIResponse<ProjectCostAnalysis>> {
        return this.api.findProjectCostAnalysis(id);
    }

    downloadCostAnalysisListDtoCsv(projectId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.downloadCostAnalysisListDtoCsv(projectId);
    }

    searchProjectId(search: string): Observable<APIResponse<string[]>> {
        return this.api.searchProjectId(search);
    }

    // Analytics
    public removeProjectOutlierByProjectId(projectId: number, workflowItemId: number): Observable<APIResponse<Boolean>> {
        return this.api.removeProjectOutlierByProjectId(projectId, workflowItemId);
    }
    public generateProjectBoundaryByProjectId(projectId: number, workflowItemId: number): Observable<APIResponse<Boolean>> {
        return this.api.generateProjectBoundaryByProjectId(projectId, workflowItemId);
    }
    public projectDetailsDtoListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.projectDetailsDtoListCsv(workflowItemId);
    }
    public projectAssetsDtoListCsv(workflowItemId: number): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.projectAssetsDtoListCsv(workflowItemId);
    }
}
