export class CustomerDemographicPopupService {
    /*
     * Render popup for customer demographic feature. Empty; TBD
     */
    getCustomPopoutFunction = (feature) => {
        const div = document.createElement('div');

        const type = feature.graphic.attributes.type;

        div.innerHTML = `<div class='container'>
                         <div class="row">
                            <div class="col">
                            <p>${type}</p>
                            </div>
                        </div>
                      </div>`;
        return div;
    };
}
