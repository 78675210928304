import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {PersistedData} from '@core/interfaces/common/persisted-data';

@Injectable()
export class PersistedDataApi {
    private readonly prefix = 'data/persisted';

    constructor(private api: HttpService) {}

    findOne(id: string): Observable<APIResponse<PersistedData>> {
        return this.api.get(`${this.prefix}/${id}`);
    }

    listFiles(): Observable<DataSource> {
        return this.api.getServerDataSource(`${this.prefix}/list`);
    }
}
