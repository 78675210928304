import {Injectable} from '@angular/core';
import {DataPreparationService, DataPrepType, DataPrepTool} from '@core/interfaces/engin/data-preparation';
import {DataPreparationApi} from '@core/backend/engin/api/data-preparation.api';
import {Observable} from 'rxjs';
import {ProcessStepError} from '@core/interfaces/engin/data-preparation';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {GuideInfo} from '@theme/components';

@Injectable()
export class DataPreparationServiceImpl extends DataPreparationService {
    constructor(private api: DataPreparationApi) {
        super();
    }

    public listActiveTools(): Observable<DataSource> {
        return this.api.listActiveTools();
    }

    public getDataPrepTool(toolId: number): Observable<APIResponse<DataPrepTool>> {
        return this.api.getDataPrepTool(toolId);
    }

    public setupDataPrepTool(toolType: DataPrepType): Observable<APIResponse<DataPrepTool>> {
        return this.api.setupDataPrepTool(toolType);
    }

    public validateNextStep(toolId: number, data: any): Observable<APIResponse<DataPrepTool>> {
        return this.api.validateNextStep(toolId, data);
    }

    public getGuideInfo(toolType: DataPrepType): Observable<APIResponse<GuideInfo[]>> {
        return this.api.getGuideInfo(toolType);
    }

    public getStepIssueLog(stepId: number, fileKey: string): Observable<APIResponse<ProcessStepError[]>> {
        return this.api.getStepIssueLog(stepId, fileKey);
    }

    public getValidation(toolId: number, stepId: number): Observable<any> {
        return this.api.getValidation(toolId, stepId);
    }

    public deleteStepDataFile(toolId: number, stepId: number, fileKey: string): Observable<APIResponse<DataPrepTool>> {
        return this.api.deleteStepDataFile(toolId, stepId, fileKey);
    }

    public deleteTool(toolId: number): Observable<any> {
        return this.api.delete(toolId);
    }
}
