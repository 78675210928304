<div class="measure-metric-widget">
    <!-- Measure -->
    <nb-select
        outline
        placeholder="Select Measure"
        [selected]="(activeMeasure$ | async)?.code"
        (selectedChange)="onMeasureSelect($event)"
        class="nb-select-in-map"
    >
        <nb-option *ngFor="let measure of measureItems" [value]="measure.code" [disabled]="measure.disabled">
            {{ getMeasureLabel(measure.code) }}
        </nb-option>
    </nb-select>

    <!-- Metric -->
    <nb-select
        outline
        placeholder="Select Metric"
        [selected]="(activeMetric$ | async)?.code"
        (selectedChange)="onMetricSelect($event)"
    >
        <nb-option *ngFor="let metric of metricItems" [value]="metric?.code" [disabled]="metric.disabled">
            {{ getMetricLabel(metric?.code) }}
        </nb-option>
    </nb-select>
</div>
