import {NbRoleProvider} from '@nebular/security';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {UsersStore} from '@store/common/users.store';

@Injectable()
export class RoleProvider extends NbRoleProvider {
    constructor(private usersStore: UsersStore) {
        super();
    }

    getRole(): Observable<string | string[]> {
        return this.usersStore.currentUser$.pipe(
            filter((value) => value !== null),
            map((currentUser) => {
                return currentUser?.role || 'user';
            }),
        );
    }
}
