import {Component, ElementRef, Input, OnInit, Optional, ViewChild} from '@angular/core';
import {NbDialogRef, NbDialogService} from '@nebular/theme';
import {FullImageDialogComponent} from '@theme/components/image-carousel/image-carousel-dialog/full-image-dialog/full-image-dialog.component';
import {FileFormat} from '@core/interfaces/engin/maintenance-planning/form-visualization';

export interface MediaEntity {
    // Add other properties if needed
    id?: number;
    position?: number;
    fileName: string;
    url: string;
    fileFormat: FileFormat;
}

@Component({
    selector: 'ngx-media-carousel-dialog',
    templateUrl: './media-carousel-dialog.component.html',
    styleUrls: ['./media-carousel-dialog.component.scss'],
})
export class MediaCarouselDialogComponent<T extends MediaEntity> implements OnInit {
    @ViewChild('thumbnailContainer', {static: false}) thumbnailContainer: ElementRef;

    @Input() mediaList: T[];
    @Input() mainMediaName: string;
    /* Enable image and/or video media */
    @Input() enableImage: boolean = true;
    @Input() enableVideo: boolean = false;
    /* Disable select to pop-up image */
    @Input() disableMediaPopup: boolean = true;

    mainMedia: T;
    private mediaPerClick = 4;
    private thumbnailMediaSize = 200.5;
    thumbnailList: T[];

    constructor(
        @Optional() private dialogRef: NbDialogRef<MediaCarouselDialogComponent<T>>,
        private dialogService: NbDialogService,
    ) {}

    ngOnInit(): void {
        this.initProcess();
    }

    initProcess() {
        let position;
        const validMedia: T[] = this.mediaList.filter((media: T) => media.url);
        const mediaList = this.addIdToObjects(validMedia);
        this.mainMedia = mediaList.find((media: T) => media.fileName === this.mainMediaName);

        this.thumbnailList = this.padArrayToMultipleOfFour(mediaList);
        this.thumbnailList = this.thumbnailList.map((media: T, index: number) => {
            if (index % 4 === 0) {
                position = index * this.thumbnailMediaSize;
            }
            return {
                ...media,
                position: position,
            };
        });
    }

    forward(): void {
        if (this.mediaList.length > 1) {
            const index = this.findCurrentImgIndex() === this.findLastIndex() ? 0 : this.findCurrentImgIndex() + 1;
            this.mainMedia = this.thumbnailList[index];
            const container = this.thumbnailContainer.nativeElement;
            container.scrollLeft = this.mainMedia.position;

            if (this.mainMedia.id === this.thumbnailList[0].id) {
                container.scrollLeft = 0;
            }
        }
    }

    back(): void {
        if (this.mediaList.length > 1) {
            const index = this.findCurrentImgIndex() === 0 ? this.findLastIndex() : this.findCurrentImgIndex() - 1;
            this.mainMedia = this.thumbnailList[index];

            const container = this.thumbnailContainer.nativeElement;
            const scrollAmount = this.mediaPerClick * this.thumbnailMediaSize;
            container.scrollLeft = this.mainMedia.position;

            if (this.mainMedia.id === this.thumbnailList[this.thumbnailList.length - 1].id) {
                const lastScrollAmount: number = (this.thumbnailList.length / this.mediaPerClick) * scrollAmount;
                container.scrollLeft = lastScrollAmount;
            }
        }
    }

    private findCurrentImgIndex() {
        return this.thumbnailList.findIndex((image) => image.id === this.mainMedia.id);
    }

    private findLastIndex() {
        const filteredList = this.thumbnailList.filter((media: T) => media.id);
        const lastMedia = filteredList[filteredList.length - 1];
        return this.thumbnailList.findIndex((media: T) => media.id === lastMedia.id);
    }

    selectMedia(media: T): void {
        this.mainMedia = media;
    }

    padArrayToMultipleOfFour<T>(array: T[]): T[] {
        const remainder = array.length % 4;
        if (remainder !== 0) {
            const paddingCount = 4 - remainder;
            for (let i = 0; i < paddingCount; i++) {
                array.push({} as T);
            }
        }
        return array;
    }

    addIdToObjects(arr: T[]): T[] {
        let id = 1;
        return arr.map((obj) => {
            // Check if the object is not empty
            if (Object.keys(obj).length !== 0) {
                return {...obj, id: id++};
            }
            return obj; // Return the empty object as it is
        });
    }

    close(): void {
        this.dialogRef.close();
    }

    // This seems unnecessary, and conflicts with click events to start/stop video; removed support from HTML for now.
    openMedia(media: string, mediaName: string): void {
        if (this.disableMediaPopup) return;

        this.dialogService.open(FullImageDialogComponent, {
            closeOnBackdropClick: false,
            context: {img: media, imgName: mediaName},
        });
    }

    imageFormats = [FileFormat.JPG, FileFormat.JPEG, FileFormat.PNG];
    videoFormats = [FileFormat.MP4, FileFormat.MOV];
    public mediaIsImage(media: T): boolean {
        return this.imageFormats.includes(media.fileFormat);
    }
    public mediaIsVideo(media: T): boolean {
        return this.videoFormats.includes(media.fileFormat);
    }
}
