import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {ChartsService, FormatsService} from '@core/utils';
import {RadioButtonOption} from '@theme/components/inline-radio-group/inline-radio-group.interface';
import {Chart} from '@core/utils/charts.service';
import {CHART_SORT_TYPE, ChartSortOption} from '@theme/components/chart-data-sort/chart-data-sort.interface';
import {ChartDataSortService} from '@theme/components/chart-data-sort/chart-data-sort.service';
import {DateType} from '@core/utils/formats.service';
import {tap} from 'rxjs/internal/operators/tap';
import {AssetDataAuditStore} from '@store/data-audit/asset-data-audit.store';
import {
    DATA_AUDIT_VIEW_OPTIONS,
    DataAuditChartResponse,
    DataAuditContextResponse,
    DataAuditHistoryResponse,
    DataMeasureContext,
    DataMeasureHistory,
} from '@core/interfaces/engin/data-audit/asset-data-audit';
import {NbToastrService} from '@nebular/theme';

@Injectable()
export class AssetDataAuditViewModel {
    constructor(
        public dataAuditStore: AssetDataAuditStore,
        public chartsService: ChartsService,
        protected toastrService: NbToastrService,
    ) {}

    // Local data for controlling view toggle
    public viewToggleOptions: RadioButtonOption[] = [
        {title: 'Data Load', value: DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD, order: 1},
        {title: 'Data Point', value: DATA_AUDIT_VIEW_OPTIONS.DATA_POINT, order: 2},
        {title: 'Asset Class ', value: DATA_AUDIT_VIEW_OPTIONS.ASSET_CLASS, order: 3},
        {title: 'Analytic', value: DATA_AUDIT_VIEW_OPTIONS.ANALYTIC, order: 4},
    ];

    // Sort options for main chart
    public metricChartSeries = new BehaviorSubject<any>(null);
    public metricChartSeries$: Observable<any> = this.metricChartSeries.asObservable();
    public sortOptions = [
        {
            code: CHART_SORT_TYPE.ASC,
            label: 'Ascending',
        },
        {
            code: CHART_SORT_TYPE.DESC,
            label: 'Descending',
        },
    ];

    // Context panel
    public contextLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    readonly contextPanelData$: Observable<DataMeasureContext[]> = this.dataAuditStore.contextData$.pipe(
        filter((data) => data !== null),
        // if data is null, add a toastr message?
        tap(() => this.contextLoading.next(true)),
        map((contextResponse: DataAuditContextResponse) => {
            // Sort the data from API to align with the viewToggleOptions order
            const copyOptions: RadioButtonOption[] = this.viewToggleOptions;
            const sortFn = function (a, b) {
                const orderA = copyOptions.filter((elem) => elem.value === a.code)[0].order;
                const orderB = copyOptions.filter((elem) => elem.value === b.code)[0].order;
                if (orderA < orderB) {
                    return -1;
                }
                if (orderA > orderB) {
                    return 1;
                }
                return 0;
            };

            const res = contextResponse.sections.sort(sortFn);
            const resPrepared = res.map((s) => {
                // Map data load items from epoch to dates
                if (s.code === DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD) {
                    return {
                        ...s,
                        data: s.data.map((d) => {
                            const date = FormatsService.prepareDateString(d.name, DateType.STRING, 'yyyy-MM-DD HH:mm');
                            return {
                                ...d,
                                name: date,
                            };
                        }),
                    };
                } else {
                    return s;
                }
            });
            return resPrepared;
        }),
        tap(() => this.contextLoading.next(false)),
    );

    public historyLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    readonly historyCharts$: Observable<any[]> = combineLatest<Observable<DataAuditHistoryResponse>, Observable<Chart>>(
        [this.dataAuditStore.recentHistoryData$, this.chartsService.lineChartMiniTrend$],
    ).pipe(
        tap(() => this.historyLoading.next(true)),
        map(([historyResponse, lineChartTemplate]: [DataAuditHistoryResponse, Chart]) => {
            if (historyResponse) {
                const filteredRes = historyResponse.measures.filter(
                    (measureHistory) => measureHistory.series.code !== 'validity',
                );
                // const res = historyResponse.measures.map(measureHistory => {
                //   const metadata = this.prepareTrendChartMetadata(measureHistory);
                //   return this.prepareTrendChartOptions(measureHistory, lineChartTemplate, metadata);
                // });
                const res = filteredRes.map((measureHistory) => {
                    const metadata = this.prepareTrendChartMetadata(measureHistory);
                    return this.prepareTrendChartOptions(measureHistory, lineChartTemplate, metadata);
                });
                return res;
            }
        }),
        tap(() => this.historyLoading.next(false)),
    );

    /*
     * Prepare metric chart in two steps
     * 1) Get data from API and sort appropriately, initialize legend, sort
     * 2) Sort chart data
     * 3) Configure chart options and legend selections
     */
    public metricLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    public legendMetricChart = new BehaviorSubject<any>(null);
    public sortMetricChart = new BehaviorSubject<ChartSortOption>(null);
    readonly metricChartDataUnsorted$ = this.dataAuditStore.metricChartData$.pipe(
        filter((data) => data !== null),
        // add a toastr message?
        tap(() => this.metricLoading.next(true)),
        map((metricChartResponse: DataAuditChartResponse) => {
            this.initMetricChartSeries(metricChartResponse);
            this.initSortOptions();
            this.initLegendSelectionArray(metricChartResponse, this.legendMetricChart);
            return metricChartResponse;
        }),
    );

    readonly metricChartDataSorted$ = combineLatest<Observable<DataAuditChartResponse>, Observable<ChartSortOption>>([
        this.metricChartDataUnsorted$,
        this.sortMetricChart.asObservable(),
    ]).pipe(
        map(([metricChartResponse, sort]: [DataAuditChartResponse, ChartSortOption]) => {
            const activeView = metricChartResponse.graphId;
            const metricChartResponseSorted = this.sortMetricChartData(activeView, metricChartResponse, sort);
            return metricChartResponseSorted;
        }),
    );

    readonly metricChart$ = combineLatest<Observable<any>, Observable<Chart>, Observable<Chart>, Observable<any>>([
        this.metricChartDataSorted$,
        this.chartsService.lineChartMultiSeriesHorizontal$,
        this.chartsService.barChartClustered$,
        this.legendMetricChart.asObservable(),
    ]).pipe(
        map(
            ([metricChartResponse, lineChartTemplate, barChartTemplate, legend]: [
                DataAuditChartResponse,
                Chart,
                Chart,
                any,
            ]) => {
                const activeView = metricChartResponse.graphId;
                const metadata = this.prepareMetricChartMetadata(metricChartResponse, lineChartTemplate, activeView);
                let chartOptions;
                switch (activeView) {
                    case DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD:
                        chartOptions = this.prepareMetricLineChartOptions(
                            metricChartResponse,
                            lineChartTemplate,
                            metadata,
                        );
                        break;
                    case DATA_AUDIT_VIEW_OPTIONS.ASSET_CLASS:
                    case DATA_AUDIT_VIEW_OPTIONS.DATA_POINT:
                    case DATA_AUDIT_VIEW_OPTIONS.ANALYTIC:
                        chartOptions = this.prepareMetricBarChartOptions(
                            metricChartResponse,
                            barChartTemplate,
                            metadata,
                        );
                        break;
                }
                const ret = this.adjustLegendSelectionForChart(chartOptions, legend);
                return ret;
            },
        ),
        tap(() => this.metricLoading.next(false)),
    );

    private initMetricChartSeries(res: DataAuditChartResponse) {
        const newSeries = [];
        const sourceData = res.series;
        sourceData
            .filter((s) => s.code !== 'count')
            .forEach((s) => {
                newSeries.push({
                    code: s.code,
                    label: s.legend,
                });
            });
        this.metricChartSeries.next(newSeries);
    }

    private initSortOptions() {
        const defaultSort = new ChartSortOption(
            {
                code: 'availability',
                label: 'Availability',
            },
            {
                code: CHART_SORT_TYPE.DESC,
                label: 'Descending',
            },
        );
        this.sortMetricChart.next(defaultSort);
    }

    private initLegendSelectionArray(res: DataAuditChartResponse, legend: any) {
        const selected = {};
        const sourceData = res.series;
        //
        sourceData.forEach((series) => {
            if (series.code) {
                selected[series.code] = true;
            }
        });
        //
        legend.next(selected);
    }

    private sortMetricChartData(activeView: string, res: DataAuditChartResponse, sort: ChartSortOption) {
        // Data load chart should not be sorted, since it will ruin the order of dates
        if (activeView === DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD) {
            const dateSort = new ChartSortOption(
                {
                    code: 'label',
                    label: 'Data Load',
                },
                {
                    code: CHART_SORT_TYPE.ASC,
                    label: 'Ascending',
                },
            );
            const sortedSeries = ChartDataSortService.sortChartData(res.series, dateSort);
            if (sortedSeries != null) {
                return {
                    ...res,
                    series: sortedSeries,
                };
            }
        }

        if (sort != null) {
            const sortedSeries = ChartDataSortService.sortChartData(res.series, sort);
            if (sortedSeries != null) {
                return {
                    ...res,
                    series: sortedSeries,
                };
            }
        }
        return res;
    }

    private adjustLegendSelectionForChart(chart: any, legend: any) {
        // Legend selections toggle needs to match series.name element
        const selected = {};
        chart.options.series.forEach((series) => {
            if (series.name && series.id) {
                if (legend === null) {
                    selected[series.name] = true;
                } else {
                    selected[series.name] = legend[series.id];
                }
            }
        });
        return {
            ...chart,
            options: {
                ...chart.options,
                legend: {
                    ...chart.options.legend,
                    selected: selected,
                },
            },
        };
    }

    private prepareTrendChartMetadata(chartData: DataMeasureHistory) {
        const numItems = chartData.series.data.length;
        const metadata = {
            title: chartData.series.legend,
            trend: chartData.trend,
            currentValue: chartData.series.data[numItems - 1],
            unit: chartData.series.unit,
        };
        return metadata;
    }

    private prepareTrendChartOptions(chartData: DataMeasureHistory, lineChart: Chart, metadata: any) {
        const trendChartOptions = {
            metadata: metadata,
            options: {
                ...lineChart.options,
                tooltip: {
                    ...lineChart.options.tooltip,
                    textStyle: {
                        color: lineChart.colorsMap['primaryFontColor'],
                    },
                    formatter: (params) => {
                        const prefix = metadata.unit.prefix[0];
                        const suffix = metadata.unit.suffix[0];
                        const date = FormatsService.prepareDateString(
                            chartData.series.labels[params.dataIndex].toString(),
                            DateType.STRING,
                            'yyyy-MM-DD hh:mm',
                        );
                        const res = chartData.series.labels[params.dataIndex]
                            ? `<span style="font-size:12px;color:#8992A3;margin-right:10px;">
                        ${date}</span>
                    <span style="font-size:14px;font-weight:bold;color:#FFFFFF;">
                        ${FormatsService.prepareValue(params.value, prefix, suffix)}</span>`
                            : '';
                        return res;
                    },
                },
                series: [
                    {
                        data: chartData.series.data,
                        type: 'line',
                        symbol: 'circle',
                        symbolSize: 6,
                        itemStyle: {
                            color: lineChart.colorsMap['data_audit'][chartData.series.code],
                        },
                        lineStyle: {
                            color: lineChart.colorsMap['data_audit'][chartData.series.code],
                        },
                        zLevel: 1,
                    },
                    {
                        type: 'line',
                        markLine: {
                            symbol: ['none', 'none'],
                            label: {show: false},
                            lineStyle: {
                                type: 'dashed',
                                color: '#eee',
                            },
                            data: [
                                {
                                    name: 'baseline 50',
                                    yAxis: 50,
                                },
                            ],
                        },
                        animation: false,
                        silent: true,
                        zLevel: 0,
                    },
                ],
            },
        };
        return trendChartOptions;
    }

    private prepareMetricChartMetadata(chartData: DataAuditChartResponse, chart: Chart, activeView: string) {
        //
        const colorsMap = chart.colorsMap;
        const legendItems = [];
        const legendColors = {};

        // Create items
        chartData.series.forEach((series) => {
            legendItems.push({
                id: series.code,
                name: series.legend,
            });
        });

        // Create unique color entries
        let defaultColorInd = 0;
        legendItems.forEach((item) => {
            const uniqueId = item.id;
            legendColors[uniqueId] = colorsMap['data_audit'][uniqueId]
                ? colorsMap['data_audit'][uniqueId]
                : colorsMap['default'][defaultColorInd++ % colorsMap['default'].length];
        });
        return {
            graphTitle: chartData.graphTitle,
            graphSubtitle: 'By ' + FormatsService.mapCodeToTitle(activeView),
            graphId: chartData.graphId,
            activeView: activeView,
            legend: {
                items: legendItems,
                colors: legendColors,
            },
        };
    }

    private prepareMetricLineChartOptions(chartData: DataAuditChartResponse, lineChart: Chart, metadata: any) {
        const dataLength = chartData.series[0].data.length;
        const dataSeries: any[] = chartData.series.map((s) => {
            return {
                name: s.legend,
                id: s.code,
                type: 'line',
                data: s.data,
                lineStyle: {
                    color: lineChart.colorsMap['data_audit'][s.code],
                    width: 2.5,
                },
                symbol: 'circle',
                symbolSize: 8,
                itemStyle: {
                    color: lineChart.colorsMap['data_audit'][s.code],
                },
                yAxisIndex: s.unit.symbol[0] === '#' ? 1 : 0,
            };
        });
        dataSeries.push(this.createGridPattern(chartData.series[0].labels.length, lineChart.colorsMap));

        // Add more granularity to the timestamp if there are duplicates
        let dAxisLabels = chartData.series[0].labels.map((e) =>
            FormatsService.prepareDateString(e.toString(), DateType.STRING, 'yyyy-MM-DD'),
        );
        if (dAxisLabels.length !== dAxisLabels.filter((value, index, self) => self.indexOf(value) === index).length) {
            dAxisLabels = chartData.series[0].labels.map((e) =>
                FormatsService.prepareDateString(e.toString(), DateType.STRING, 'yyyy-MM-DD hh'),
            );
        }
        if (dAxisLabels.length !== dAxisLabels.filter((value, index, self) => self.indexOf(value) === index).length) {
            dAxisLabels = chartData.series[0].labels.map((e) =>
                FormatsService.prepareDateString(e.toString(), DateType.STRING, 'yyyy-MM-DD hh:mm'),
            );
        }
        const lineChartOptions = {
            metadata: metadata,
            options: {
                ...lineChart.options,
                tooltip: {
                    ...lineChart.options.tooltip,
                    textStyle: {
                        color: lineChart.colorsMap['primaryFontColor'],
                    },
                    formatter: (params) => {
                        const sorted = [];
                        const countLegend = chartData.series.filter((s) => s.code === 'count')[0].legend;
                        sorted.push(params.filter((s) => s.seriesName === countLegend)[0]);
                        params.filter((s) => s.seriesName !== countLegend).forEach((s) => sorted.push(s));

                        const seriesFormat = (seriesName, value, uPrefix, uSuffix) =>
                            `<div style="padding:5px">
                <span style="font-size:11px;margin-right:10px;color:#8992A3">
                  ${seriesName}
                </span>
                <span style="float:right;font-weight:bold">
                  ${FormatsService.prepareValue(value, uPrefix, uSuffix)}
                </span>
              </div>`;

                        let res = '';
                        sorted.forEach((s) => {
                            const matchSeries = chartData.series.filter((cd) => cd.legend === s.seriesName)[0];
                            res =
                                res +
                                seriesFormat(
                                    s.seriesName,
                                    s.value,
                                    matchSeries.unit.prefix[0],
                                    matchSeries.unit.suffix[0],
                                );
                        });
                        return res;
                    },
                },
                grid: {
                    left: '7%',
                    width: '87%',
                    bottom: '15%',
                },
                xAxis: {
                    ...lineChart.options.xAxis,
                    name: 'Data Loads',
                    data: dAxisLabels,
                },
                yAxis: [
                    {
                        ...lineChart.options.yAxis[0],
                        name: 'Availability & Validity',
                    },
                    {
                        ...lineChart.options.yAxis[1],
                        name: 'Number of Assets',
                    },
                ],
                series: dataSeries,
                dataZoom: [
                    {
                        id: 'dataZoomX',
                        type: 'slider',
                        xAxisIndex: [0],
                        filterMode: 'filter',
                        zoomLock: true,
                        brushSelect: false,
                        startValue: dataLength > 9 ? dataLength - 1 : 0,
                        endValue: dataLength > 9 ? dataLength - 11 : dataLength - 1,
                        show: dataLength > 9,
                        fillerColor: lineChart.colorsMap['axisSliderHandle'],
                        backgroundColor: lineChart.colorsMap['axisSliderBackground'],
                        handleSize: '100%',
                        showDataShadow: true,
                        dataBackground: {
                            areaStyle: {
                                color: lineChart.colorsMap['axisSliderShadow'],
                            },
                        },
                        textStyle: {
                            color: lineChart.colorsMap['primaryFontColor'],
                        },
                    },
                    {
                        id: 'dataZoomX_inside',
                        type: 'inside',
                        xAxisIndex: [0],
                        zoomOnMouseWheel: false,
                        moveOnMouseMove: true,
                        moveOnMouseWheel: true,
                    },
                ],
            },
        };
        return lineChartOptions;
    }

    private prepareMetricBarChartOptions(chartData: DataAuditChartResponse, barChart: Chart, metadata: any) {
        const dataLength = chartData.series[0].data.length;
        const barChartOptions = {
            metadata: metadata,
            options: {
                ...barChart.options,
                tooltip: {
                    ...barChart.options.tooltip,
                    textStyle: {
                        color: barChart.colorsMap['primaryFontColor'],
                    },
                    formatter: (params) => {
                        const seriesFormat = (seriesName, value, uPrefix, uSuffix) =>
                            `<div style="padding:5px">
                <span style="font-size:11px;margin-right:10px;color:#8992A3">
                  ${seriesName}
                </span>
                <span style="float:right;font-weight:bold">
                  ${FormatsService.prepareValue(value, uPrefix, uSuffix)}
                </span>
              </div>`;

                        let res = '';
                        params.forEach((s) => {
                            const matchSeries = chartData.series.filter((cd) => cd.legend === s.seriesName)[0];
                            res =
                                res +
                                seriesFormat(
                                    s.seriesName,
                                    s.value,
                                    matchSeries.unit.prefix[0],
                                    matchSeries.unit.suffix[0],
                                );
                        });
                        return res;
                    },
                },
                xAxis: {
                    ...barChart.options.xAxis,
                    name: 'Availability & Validity',
                },
                yAxis: {
                    ...barChart.options.yAxis,
                    name: FormatsService.mapCodeToTitle(metadata.activeView),
                    data: chartData.series[0].labels,
                },
                series: chartData.series
                    .filter((s) => s.code !== 'count')
                    .map((s) => {
                        return {
                            name: s.legend,
                            id: s.code,
                            type: 'bar',
                            data: s.data,
                            barWidth: 8,
                            barGap: '25%',
                            itemStyle: {
                                color: barChart.colorsMap['data_audit'][s.code],
                            },
                        };
                    }),
                dataZoom: [
                    {
                        id: 'dataZoomY',
                        type: 'slider',
                        yAxisIndex: [0],
                        filterMode: 'filter',
                        zoomLock: true,
                        brushSelect: false,
                        startValue: dataLength > 9 ? dataLength - 1 : 0,
                        endValue: dataLength > 9 ? dataLength - 11 : dataLength - 1,
                        show: dataLength > 9,
                        fillerColor: barChart.colorsMap['axisSliderHandle'],
                        backgroundColor: barChart.colorsMap['axisSliderBackground'],
                        handleSize: '100%',
                        showDataShadow: true,
                        dataBackground: {
                            areaStyle: {
                                color: barChart.colorsMap['axisSliderShadow'],
                            },
                        },
                        textStyle: {
                            color: barChart.colorsMap['primaryFontColor'],
                        },
                    },
                    {
                        id: 'dataZoomY_inside',
                        type: 'inside',
                        yAxisIndex: [0],
                        zoomOnMouseWheel: false,
                        moveOnMouseMove: true,
                        moveOnMouseWheel: true,
                    },
                ],
            },
        };
        return barChartOptions;
    }

    private createGridPattern(dataLength, colors) {
        const templateOption = {
            type: 'line',
            markLine: {
                symbol: ['none', 'none'],
                label: {show: false},
                lineStyle: {
                    type: 'solid',
                    color: colors['hintFontColor'],
                    width: 0.5,
                },
                data: [
                    // push {xAxis: category index} to add vertical lines on chart
                ],
            },
        };
        for (let i = 0; i < dataLength - 1; i++) {
            templateOption.markLine.data.push({xAxis: i});
        }
        return templateOption;
    }
}
