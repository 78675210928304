<div class="message-box">
    <ng-container *ngIf="iconInHeader; else withoutLogo">
        <div class="message-header">
            <nb-icon class="message-header-icon" icon="alert-triangle"></nb-icon>
            <p class="message-header-text">{{ header }}</p>
        </div>
    </ng-container>
    <ng-template #withoutLogo>
        <div class="message-header">
            <p class="message-header-text">{{ header }}</p>
        </div>
    </ng-template>
    <div class="message-body">
        <h2 class="message-body-title">CONFIDENTIAL</h2>
        <p class="message-body-section">{{ body }}</p>
    </div>
    <div class="message-footer">
        <button fullWidth nbButton status="danger" (click)="buttonAction.emit()" class="message-action-btn">
            <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
            OK
        </button>
    </div>
</div>
