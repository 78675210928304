import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ReportType, ReportTypeLabel} from '@core/interfaces/engin/reporting/report-config';
import {ReportRequestComposer} from '../../../pages/reporting-tool/report-services/report-request-composer.service';

@Component({
    selector: 'ngx-report-type-indicator',
    templateUrl: './report-type-indicator.component.html',
    styleUrls: ['./report-type-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportTypeIndicatorComponent {
    @Input() selectedReportType: ReportType;

    reportTypes: ReportType[] = Object.keys(ReportType).map((k) => ReportType[k]);

    constructor(public reportRequestComposer: ReportRequestComposer) {}

    public getLabel(type: ReportType): string {
        return ReportTypeLabel[type];
    }
}
