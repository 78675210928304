import {Observable} from 'rxjs';
import {Report} from '@core/interfaces/common/pages';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL} from '@core/interfaces/engin/load-forecast/load-forecast';

export class ReportRequest {
    reportId: string;
    templateFlag: boolean;
    studyId: number;
    userId: string;
    sensitivityFlag: boolean;

    constructor(report: Report, studyId: number, userId: string, sensitivityFlag: boolean) {
        this.reportId = report ? report.reportId : '';
        this.templateFlag = report ? report.templateFlag : true;
        this.studyId = studyId;
        this.userId = userId;
        this.sensitivityFlag = sensitivityFlag;
    }
}

export interface OutputReportSource {
    links: [string];
}

export abstract class ReportingService {
    abstract getOutputReportGeneric(reportRequest: ReportRequest): Observable<OutputReportSource>;

    abstract getOutputReportAnalyzer(studyId: number, filters: string[]): Observable<OutputReportSource>;

    // abstract initSettingsByWorkflowItem(workflowItemId: string): Observable<LoadForecastSettings>;

    abstract getDataExtractPreview(
        workflowItemId: number,
        outputCategory: string,
        outputType: string,
    ): Observable<DataSource>;

    abstract getDataExtractCsv(
        workflowItemId: number,
        outputCategory: string,
        outputType: string,
        outputResult: string,
    ): Observable<APIResponse<DownloadURL>>;
}
