<nb-card *ngIf="vm.chartData$ | async as chart">
    <nb-card-header *ngIf="chart.metadata.title">
        <div class="chart-header">
            <div class="chart-title">{{ chart.metadata.title }}</div>
        </div>
    </nb-card-header>

    <nb-card-body>
        <ngx-chart [options]="chart?.options"></ngx-chart>
    </nb-card-body>

    <nb-card-footer class="chart-card-footer" *ngIf="vm.showFooter">
        <div *ngFor="let item of chart.metadata.values; let i = index" class="chart-footer-item">
            <div class="footer-label-title">
                <div [ngStyle]="{background: item.style.color}" class="color-point"></div>
            </div>
            <span class="footer-label-value">{{ item.name }}</span>
        </div>

        <ng-container *ngIf="!chart.metadata.values">
            <div *ngFor="let item of chart.metadata.legend; let i = index" class="chart-footer-item">
                <div class="footer-label-title">
                    <div [ngStyle]="{background: item.color}" class="color-point"></div>
                </div>
                <span class="footer-label-value">{{ item.name }}</span>
            </div>
        </ng-container>
    </nb-card-footer>
</nb-card>
