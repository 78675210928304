import {ChangeDetectionStrategy, Component, Input, OnInit, Optional} from '@angular/core';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {NbDialogRef} from '@nebular/theme';
import {AssetRegistryService, RegistryUpdateRequest} from '@core/interfaces/asset-registry';
import {BehaviorSubject, Observable} from 'rxjs';
import {finalize, map, mergeMap, take, tap} from 'rxjs/operators';
import {APIResponse, FilterFieldType} from '@core/interfaces/system/system-common';
import {AssetColumn, AssetDto, disabledFields, geographicFields, hideFields} from '@core/interfaces/common/asset';

@Component({
    selector: 'ngx-edit-asset-details-dialog',
    templateUrl: './edit-asset-details-dialog.component.html',
    styleUrls: ['./edit-asset-details-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditAssetDetailsDialogComponent extends Unsubscribable implements OnInit {
    @Input() assetId: string;
    @Input() availableColumns$: Observable<AssetColumn[]>;

    loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
    saving$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    form: FormGroup = this.fb.group({});

    saveBtnPressed = false;

    hideFields = hideFields;
    disabledFields = disabledFields;
    geographicFields = geographicFields;

    assetColumns: AssetColumn[] = [];
    assetProps: AssetDto;

    FilterFieldType = FilterFieldType;

    constructor(
        @Optional() private dialogRef: NbDialogRef<EditAssetDetailsDialogComponent>,
        private fb: FormBuilder,
        private assetRegistryService: AssetRegistryService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.assetRegistryService
            .getAsset(this.assetId)
            .pipe(
                map((res: APIResponse<AssetDto>) => res.response),
                mergeMap((assetData: AssetDto) =>
                    this.availableColumns$.pipe(
                        take(1),
                        tap((columns: AssetColumn[]) => {
                            this.assetProps = assetData;
                            this.assetColumns = columns.filter((c: AssetColumn) =>
                                Object.keys(assetData).includes(c.fieldKey),
                            );

                            this.assetColumns.forEach((column: AssetColumn) => {
                                let validators: ValidatorFn[] = [];

                                if (column.required) validators.push(Validators.required);

                                if (FilterFieldType.isNumericFieldType(column.fieldType))
                                    validators.push(Validators.pattern(/^-?\d+(\.\d+)?$/));

                                if (column.fieldType === FilterFieldType.STRING && column.charLimit)
                                    validators.push(Validators.maxLength(column.charLimit));

                                if (column.fieldType)
                                    this.form.addControl(
                                        column.fieldKey,
                                        this.fb.control(
                                            {
                                                value: assetData[column.fieldKey],
                                                disabled: this.disabledFields.includes(column.fieldKey),
                                            },
                                            validators,
                                        ),
                                    );
                            });
                        }),
                    ),
                ),
                finalize(() => this.loading$.next(false)),
            )
            .subscribe();
    }

    save() {
        if (this.form.invalid) {
            this.saveBtnPressed = true;
            this.form.markAllAsTouched();
            return;
        }

        this.saving$.next(true);

        const changedAttributes = {};

        Object.keys(this.form.value).forEach((k: string) => {
            let _value = this.form.value[k];
            let _column: AssetColumn = this.assetColumns.find((item: AssetColumn) => item.fieldKey === k);

            if (FilterFieldType.isNumericFieldType(_column.fieldType) && _value !== null)
                _value = _value === '' ? null : +_value;

            if (this.assetProps[k] !== this.form.value[k]) changedAttributes[k] = _value;
        });

        const req: RegistryUpdateRequest = {
            changedAttributes: changedAttributes,
        };

        this.assetRegistryService
            .updateAsset(this.assetId, req)
            .pipe(finalize(() => this.saving$.next(false)))
            .subscribe(() => this.dialogRef.close('success'));
    }

    close() {
        this.dialogRef.close();
    }
}
