import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
    selector: 'ngx-mini-trend',
    template: `
        <div [ngClass]="'mini-trend-container-' + status">
            <ng-container [ngSwitch]="status">
                <nb-icon *ngSwitchCase="'up'" icon="arrow-upward-outline" class="trend-icon-up"></nb-icon>
                <nb-icon *ngSwitchCase="'down'" icon="arrow-upward-outline" class="trend-icon-down"></nb-icon>
                <nb-icon *ngSwitchCase="'flat'" icon="minus-outline" class="trend-icon-flat"></nb-icon>
            </ng-container>
            <p class="value" [ngClass]="'value-' + status" *ngIf="value | unit: unit as convertedValue">
                {{ convertedValue }}
            </p>
        </div>
    `,
    styleUrls: ['./mini-trend.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiniTrendComponent {
    @Input() value;
    @Input() status;
    @Input() unit;
}
