import {Injectable} from '@angular/core';
import {ReportRenderApi} from '@core/backend/engin/api/reporting/report-render.api';
import {ReportRenderService} from '@core/interfaces/engin/reporting/report-render';
import {Observable} from 'rxjs';
import {StudyOverviewPageData, TitlePageData} from '../../../../../pages/reporting-tool/model/common-page-data';

@Injectable()
export class ReportRenderServiceImpl extends ReportRenderService {
    constructor(private api: ReportRenderApi) {
        super();
    }

    public getTitlePageContent(reportCode: string): Observable<TitlePageData> {
        return this.api.getTitlePageContent(reportCode);
    }

    public getStudyOverviewPageContent(reportCode: string, workflowItemId: number): Observable<StudyOverviewPageData> {
        return this.api.getStudyOverviewPageContent(reportCode, workflowItemId);
    }
}
