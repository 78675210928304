<button
    [class.btn-hover]="isButtonHover"
    [class.without-title]="!btnTitle"
    [class]="status"
    [size]="size"
    [status]="status"
    nbButton
>
    <nb-icon [icon]="iconDetails.icon" [pack]="iconDetails.pack || 'eva'"></nb-icon>
    {{ btnTitle }}
</button>

<nb-select
    (mouseenter)="isButtonHover = true"
    (mouseleave)="isButtonHover = false"
    [formControl]="_selectControl"
    multiple
>
    <!-- Empty label always  -->
    <nb-select-label></nb-select-label>

    <nb-option [value]="''" class="search">
        <nb-form-field>
            <input
                #filterInput
                nbInput
                fullWidth
                [formControl]="filterControl"
                (keydown.space)="$event.stopImmediatePropagation()"
                placeholder="Search..."
            />
            <button nbSuffix nbButton ghost (click)="filterControl.reset()">
                <nb-icon [icon]="'close'" pack="eva" [attr.aria-label]="'clear search field'"></nb-icon>
            </button>
        </nb-form-field>
    </nb-option>
    <nb-option
        *ngIf="options.length && !filterControl.value" (click)="toggleSelectAll()" [value]="0" class="select-filters-option">All Filters</nb-option>
    <nb-option-group
        *ngFor="let group of fieldGroups"
        [title]="group || defaultGroupTitle"
        class="select-filters-group"
    >
        <ng-container *ngFor="let _option of options$ | async">
            <nb-option
                [ngClass]="!getShouldShowOption(_option) && 'display-none'"
                (selectionChange)="onSelectChange($event)"
                *ngIf="_option.option.fieldGroup === group"
                [disabled]="_option.option.disabled"
                [value]="_option.option"
            >
                {{ _option.label }}
            </nb-option>
        </ng-container>
    </nb-option-group>
</nb-select>
