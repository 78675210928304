<nb-card class="empty-table-card">
    <div class="table">
        <div class="table-row table-row-header">
            <label *ngFor="let header of tableHeader" [ngClass]="header[0] ? 'text-col' : 'input-col'">
                {{ header }}
            </label>
        </div>
        <div class="table-row" *ngFor="let row of tableRow">
            <div class="input-col" *ngFor="let header of tableHeader">
                <label class="text-col empty-col">empty1</label>
            </div>
        </div>
    </div>
</nb-card>
