import {ChangeDetectionStrategy, Component, Input, OnInit, Optional} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {
    APIResponse,
    Filter,
    FilterFieldOption,
    FilterFieldOptionRequest,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {BehaviorSubject, Observable} from 'rxjs';
import {debounceTime, filter, finalize, skipWhile, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AssetRegistryService} from '@core/interfaces/asset-registry';
import {Unsubscribable} from '@core/interfaces/unsubscribable';
import {FormsService} from '@core/interfaces/common/forms';
import {MobileAdminStore} from '@store/mobile-admin/mobile-admin.store';

@Component({
    selector: 'ngx-form-scope-modal',
    templateUrl: './form-scope-modal.component.html',
    styleUrls: ['./form-scope-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormScopeModalComponent extends Unsubscribable implements OnInit {
    @Input() formId: number = null;

    loading: BehaviorSubject<boolean> = new BehaviorSubject(true);
    loading$: Observable<boolean> = this.loading.asObservable();

    isScopeLoaded: boolean = false;

    filterParams: BehaviorSubject<Filter[]> = new BehaviorSubject([]);
    filterParams$: Observable<Filter[]> = this.filterParams.asObservable();

    genericFilters$: Observable<TableFilterConfig[]> = this.mobileAdminStore.genericFilters$;
    selectedFilters$: Observable<Filter[]> = this.mobileAdminStore.selectedFilters$.pipe(
        tap((filters: Filter[]) => {
            //needs to be saved filters values as FilterFieldOption for FilterType.MultiSelectAutocomplete
            let _filters: Filter[] = filters.map((filter: Filter) => ({
                ...filter,
                values: filter.values.map((option: FilterFieldOption) => option.key || option),
            }));

            this.filterParams.next(_filters);
        }),
    );

    preselectedFilters$ = this.selectedFilters$.pipe(
        skipWhile(() => !this.isScopeLoaded),
        take(1),
    );
    filtersDynamicOptions$ = this.mobileAdminStore.filtersDynamicOptions$;

    assetCount$ = this.filterParams$.pipe(
        debounceTime(300),
        filter(() => this.isScopeLoaded),
        switchMap((data: Filter[]) => {
            this.loading.next(true);

            return this.assetRegistryService.getAssetCount(data).pipe(
                finalize(() => {
                    this.loading.next(false);
                }),
            );
        }),
    );

    constructor(
        @Optional() private dialogRef: NbDialogRef<FormScopeModalComponent>,
        private mobileAdminStore: MobileAdminStore,
        private assetRegistryService: AssetRegistryService,
        private formsData: FormsService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.mobileAdminStore.resetSelectedFilters();

        this.selectedFilters$.pipe(takeUntil(this.unsubscribe$)).subscribe();

        this.formsData.getFormScope(this.formId).subscribe((resp: APIResponse<Filter[]>) => {
            this.isScopeLoaded = true;
            this.mobileAdminStore.setSelectedFilters(resp.response, true);
        });
    }

    addFilter(filters: Filter[]) {
        this.mobileAdminStore.setSelectedFilters(filters);
    }

    getOptionsDynamically(data: FilterFieldOptionRequest) {
        this.mobileAdminStore.getOptionsDynamically(data);
    }

    saveFormFilters(): void {
        this.dialogRef.close(this.filterParams.value);
    }

    close(): void {
        this.dialogRef.close();
    }
}
