import {AdditionalMapLayer, MapLayerDetails} from '../../../../../../pages/geospatial-viewer/model/layers';
import {RoadMoratoriumData} from '../../../../../../pages/geospatial-viewer/model/api';
import {RoadMoratoriumFieldsService} from '../../fields/road-moratorium-fields.service';
import {RoadMoratoriumPopupService} from '../../popups/road-moratorium-popup.service';
import {RoadMoratoriumRendererService} from '../../renderers/road-moratorium-renderer.service';
import {Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Graphic from '@arcgis/core/Graphic';
import Polyline from '@arcgis/core/geometry/Polyline';

@Injectable()
export class RoadMoratoriumLayerService {
    constructor(
        private fieldsService: RoadMoratoriumFieldsService,
        private popupService: RoadMoratoriumPopupService,
        private rendererService: RoadMoratoriumRendererService,
    ) {}

    public prepareRoadMoratoriumsLayer(
        layer: AdditionalMapLayer,
        data: RoadMoratoriumData[],
        details: MapLayerDetails[],
    ): FeatureLayer {
        const roadMoratoriumLayer: FeatureLayer = new FeatureLayer({
            id: layer.code,
            outFields: ['*'],
            objectIdField: 'ObjectID',
            source: this.createGraphics(data, layer),
            renderer: this.rendererService.customLayerRenderer(details),
            fields: this.fieldsService.getCustomMapFields(),
            popupTemplate: {
                outFields: ['*'],
                content: this.popupService.getCustomPopoutFunction,
            },
        });

        return roadMoratoriumLayer;
    }

    /*
     * Create array of Graphics, type = polyline. Return Graphic[]
     */
    private createGraphics(data: RoadMoratoriumData[], layer: AdditionalMapLayer): Graphic[] {
        return data.map((closure: RoadMoratoriumData) => {
            const geometry = new Polyline({
                paths: closure.geoJsonGeometry.coordinates,
            });

            return new Graphic({
                geometry: geometry,
                attributes: {
                    // Unique identifiers
                    ObjectID: closure.id,
                    // Data
                    closureType: closure.closureType,
                    closureName: closure.closureName,
                    closureDescription: closure.closureDescription,
                    reason: closure.reason,
                    status: closure.status,
                    streetName: closure.streetName,
                    streetFrom: closure.streetFrom,
                    streetTo: closure.streetTo,
                    dateFrom: closure.dateFrom,
                    dateTo: closure.dateTo,
                },
            });
        });
    }
}
