import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'ngx-sensitive-info-disclosure-header',
    templateUrl: './sensitive-info-disclosure-header.component.html',
    styleUrls: ['./sensitive-info-disclosure-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitiveInfoDisclosureHeaderComponent {
    toggleAction = false;

    constructor() {}

    toggleContents() {
        this.toggleAction = !this.toggleAction;
    }
}
