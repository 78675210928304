import {GeoJSONGeometry, SpatialEntityData} from '../../../../../pages/geospatial-viewer/model/api';

export interface SpatialEnginData {
    // Unique identifiers
    id: string;
    classCode: string;
    // Spatial data
    geoJsonGeometry: GeoJSONGeometry;

    // Data
    data: any; // string, double, integer
    displayCategory: string;
    count: number;
}

export class EnginDataPrepareService {
    constructor() {}

    /**
     * Prepare data for point asset layer.
     * @return SpatialEnginData[]
     */
    public prepare(data: SpatialEntityData[], mapColourParser: any): SpatialEntityData[] {
        return data.map((entity: SpatialEntityData) => {
            const mapDisplayCategory = mapColourParser(entity.data);

            return {
                // Unique identifiers
                id: entity.id,
                classCode: entity.classCode,
                // Spatial data
                geoJsonGeometry: entity.geoJsonGeometryStr ? JSON.parse(entity.geoJsonGeometryStr) : null,
                // Data
                displayCategory: mapDisplayCategory,
                data: entity.data,
                count: entity.count ? entity.count : 1,
            };
        });
    }
}
