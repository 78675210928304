<nb-card class="edit-dialog">
    <nb-card-header>
        <p class="primary-header">Filter Output Report by Asset Class</p>

        <button ghost status="basic" nbButton (click)="cancel()">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body [nbSpinner]="!(options$ | async)?.length" nbSpinnerSize="medium" nbSpinnerStatus="basic">
        <form>
            <label>
                Select one or more asset classes to include in the Output Report. By default all data will be included.
            </label>

            <nb-select class="mb-3" fullWidth multiple [formControl]="selectControl" [placeholder]="'Select...'">
                <nb-select-label>
                    {{ isAllSelected ? "All" : getLabels(selectControl.value) }}
                </nb-select-label>
                <nb-option [value]="0" (click)="toggleSelectAll()">Select All</nb-option>
                <nb-option *ngFor="let option of options$ | async" [value]="option.value">
                    {{ option.label }}
                </nb-option>
            </nb-select>

            <div class="btn-container">
                <button nbButton status="basic" (click)="cancel()">
                    <nb-icon icon="close-circle-outline"></nb-icon>
                    Cancel
                </button>

                <button nbButton status="primary" (click)="confirm()">
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                    Proceed
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>
