import {ChangeDetectionStrategy, Component, Input, Optional, TemplateRef} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';

@Component({
    selector: 'ngx-customized-delete',
    templateUrl: './customized-delete.component.html',
    styleUrls: ['./customized-delete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomizedDeleteComponent {
    @Input() title = 'Delete';
    @Input() widthSize = 'medium'; //'small', 'medium', 'large'
    @Input() headerInfo: {label: string; value: string}[];
    @Input() columnHeaderInfo = false;
    @Input() data?: any;
    @Input() content?: TemplateRef<any>;
    @Input() context?: Observable<any>;

    _context;

    constructor(@Optional() public dialogRef: NbDialogRef<CustomizedDeleteComponent>) {}

    ngOnInit() {
        this._context = this.context.pipe(
            tap((context) => {
                if (context.disableDelete) {
                    this.disableDelete = context.disableDelete;
                }
            }),
            share(),
        );
    }

    disableDelete = () => false;

    confirmDelete() {
        this.dialogRef.close({confirm: true});
    }

    close() {
        this.dialogRef.close({confirm: false});
    }
}
