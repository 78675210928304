<nb-card class="nb-card">
    <nb-card-header>
        <div class="title-container">
            <div class="chart-card-header">
                <span class="primary-header">Inspection Data Pre-processing Operations</span>
            </div>
            <div>
                <button nbButton class="action-btn" status="basic" (click)="close()">
                    <nb-icon icon="close" status="basic"></nb-icon>
                </button>
            </div>
        </div>
    </nb-card-header>
    <nb-card-body class="table-card-body">
        <!-- Factors -->
        <div class="table" *ngIf="assetHealthIndexData$ | async as healthIndexData">
            <!-- No operations -->
            <div class="table-row table-row-header" *ngIf="!healthIndexData.valueMap.length">
                <div class="col-6 data-col data-text">No operations</div>
            </div>

            <!-- Operations are present, list in a table -->
            <div class="table-row table-row-header" *ngIf="healthIndexData.valueMap.length">
                <span class="factor-col">Calculated Factor</span>
                <span class="input-col">Value</span>
            </div>

            <ng-container *ngFor="let item of healthIndexData.valueMap; let i = index">
                <div class="table-row">
                    <p class="factor-col">{{ item.name }}</p>
                    <ng-container *ngIf="item.value !== null; else withItem">
                        <div class="input-col" [innerHTML]="formatValue(item.value)"></div>
                    </ng-container>
                    <ng-template #withItem>
                        <div class="input-col">
                            <div class="status-cell">
                                <span class="icon-wrapper">
                                    <nb-icon pack="eva" icon="close-circle-outline"></nb-icon>
                                </span>
                                <span class="status-label">Null</span>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </nb-card-body>
</nb-card>
