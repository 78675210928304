<nb-card class="edit-dialog">
    <nb-card-header>
        <div>
            <p class="primary-header">{{ noteData ? "Edit" : "Add" }} Note</p>
        </div>

        <button (click)="close()" ghost nbButton status="basic">
            <nb-icon icon="close" status="basic"></nb-icon>
        </button>
    </nb-card-header>

    <nb-card-body>
        <form [formGroup]="form">
            <label class="mt-0" for="note">{{ noteData ? "Edit" : "Add" }} Note</label>
            <textarea formControlName="note" fullWidth id="note" nbInput placeholder="Add note"></textarea>
            <ngx-input-error [formGroup]="form" fieldName="note"></ngx-input-error>

            <div class="btn-container">
                <button (click)="close()" nbButton status="basic">
                    <nb-icon icon="close"></nb-icon>
                    Cancel
                </button>
                <button
                    (click)="save()"
                    [disabled]="form.invalid && saveBtnPressed"
                    class="ml-3"
                    nbButton
                    status="primary"
                >
                    <nb-icon icon="checkmark-circle-2-outline"></nb-icon>
                    Save
                </button>
            </div>
        </form>
    </nb-card-body>
</nb-card>
