<div class="legend-container">
    <nb-accordion>
        <nb-accordion-item>
            <nb-accordion-item-header>Legend</nb-accordion-item-header>
            <nb-accordion-item-body>
                <div class="legend-header">
                    <p>Metric Layer</p>
                </div>
                <div class="legends" *ngIf="legendData">
                    <ng-container [ngSwitch]="valueType" *ngFor="let legend of legendData; index as i">
                        <p
                            *ngSwitchCase="ValueType.CATEGORY"
                            [ngStyle]="{'border-color': legend.color}"
                            class="legend-title"
                        >
                            {{ legend.state }}
                        </p>
                        <p
                            *ngSwitchCase="ValueType.NUMERIC"
                            [ngStyle]="{'border-color': legend.color}"
                            class="legend-title"
                        >
                            <ngx-tooltip
                                [tooltipPosition]="'top'"
                                [tooltipMessage]="legend.state"
                                [label]="getZoneNumber(i)"
                                [labelSize]="'10px'"
                            ></ngx-tooltip>
                        </p>
                    </ng-container>
                </div>
            </nb-accordion-item-body>
        </nb-accordion-item>
    </nb-accordion>
</div>
