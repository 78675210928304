import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HelpersService} from '@core/utils/helpers.service';
import {ApiType, S3Service} from '@core/interfaces/common/s3';
import {HttpEvent, HttpEventType} from '@angular/common/http';
import {MediaFieldResultDto} from '@core/interfaces/engin/maintenance-planning/form-visualization';
import {mergeMap, tap} from 'rxjs/operators';

@Component({
    selector: 'ngx-form-field-media-dialog',
    templateUrl: './upload-media-dialog.component.html',
    styleUrls: ['./upload-media-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadMediaDialogComponent implements OnInit {
    /* Enable image and/or video media */
    @Input() enableImage: boolean = true;
    @Input() enableVideo: boolean = false;
    @Input() s3service: S3Service;
    @Input() fieldId: number;
    imageFormats: string[] = ['jpeg', 'jpg', 'png'];
    videoFormats: string[] = ['mov', 'mp4'];
    fileType: string = '';

    acceptedFileTypes: string[] = [];
    completedFileList: MediaFieldResultDto[] = [];

    fileUploadForm: FormGroup = this.fb.group({
        fileList: this.fb.array([], Validators.required),
    });

    constructor(
        protected ref: NbDialogRef<UploadMediaDialogComponent>,
        private fb: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef,
        private helpersService: HelpersService,
    ) {}

    ngOnInit(): void {
        if (this.enableImage && !this.enableVideo) {
            this.acceptedFileTypes.push(...this.imageFormats);
            this.fileType = 'Images';
        }

        if (this.enableVideo && !this.enableImage) {
            this.fileType = 'Videos';
            this.acceptedFileTypes.push(...this.videoFormats);
        }

        if (this.enableImage && this.enableVideo) {
            this.fileType = 'Videos/Images';
            this.acceptedFileTypes.push(...this.videoFormats, ...this.imageFormats);
        }
    }

    getFileControl(fileName: string): AbstractControl {
        let foundControl = null;
        this.fileList.controls.forEach((fileControl) => {
            if (fileControl.value.name === fileName) {
                foundControl = fileControl;
            }
        });
        return foundControl;
    }

    onFileAdded(file) {
        const fr = new FileReader();
        fr.readAsDataURL(file.file);
        fr.onload = (e) => {
            file = {
                ...file,
                url: e.target.result,
                progress: 0,
                status: 'STARTED',
            };
            this.fileList.push(this.createItem(file));
            this.changeDetectorRef.detectChanges();
            this.s3service
                .getPresignedUrl(file.name, file.fileFormat, ApiType.MAINTENANCE_PLANNING)
                .pipe(
                    mergeMap((url) => {
                        return this.s3service.uploadToPresignedUrl(url.response.url, file.file).pipe(
                            tap((event: HttpEvent<any>) => {
                                let control = this.getFileControl(file.name);
                                switch (event.type) {
                                    case HttpEventType.UploadProgress:
                                        if (control) {
                                            control.value.progress = Math.round((event.loaded * 100) / event.total);
                                        }
                                        this.changeDetectorRef.markForCheck();
                                        break;
                                    case HttpEventType.Response:
                                        if (control) {
                                            control.value.status = 'UPLOADED';
                                        }
                                        const media: MediaFieldResultDto = {
                                            fieldId: this.fieldId,
                                            fileKey: `inspection-content/${file.file.name}`,
                                            fileName: file.file.name,
                                            fileFormat: file.fileFormat,
                                            fileSize: this.helpersService.parseFileSize(file.size + ' bytes'),
                                            formattedSize: file.size,
                                            url: file.url,
                                        };
                                        this.completedFileList.push(media);
                                        break;
                                }
                            }),
                        );
                    }),
                )
                .subscribe();
        };
    }
    createItem = (file): FormGroup => {
        return this.fb.group({
            name: file.name,
            status: file.status,
            fileFormat: file.fileFormat,
            dataOperationType: 'FULL_REFRESH',
            fileLoadType: 'SNAPSHOT',
            tableArray: [],
            progress: 0,
            size: this.helpersService.formatFileSize(file.size),
            file: file.file,
            url: file.url,
        });
    };
    get fileList() {
        return this.fileUploadForm.get('fileList') as FormArray;
    }

    removeFile(indexToRemove: number) {
        this.fileList.removeAt(indexToRemove);
    }

    save() {
        this.ref.close({contents: this.completedFileList});
    }
    cancel() {
        this.ref.close();
    }
}
