import {Injectable} from '@angular/core';
import {HttpService} from '../../common/api/http.service';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {
    AssetChangeHistory,
    AssetRegistryListRequest,
    AssetVersionComparison,
    GeospatialAssetRequest,
    RegistryUpdateRequest,
} from '@core/interfaces/asset-registry';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {GeospatialResponse} from '../../../../pages/geospatial-viewer/model/api';
import {AssetColumn, AssetDto, CoordinateLatLong} from '@core/interfaces/common/asset';
import {HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable()
export class AssetRegistryApi {
    private readonly prefix = 'assets/registry';
    private readonly prefixGeospatial = 'geospatial/registry';

    constructor(private api: HttpService) {}

    getAsset(assetId: string): Observable<APIResponse<AssetDto>> {
        return this.api.get(`${this.prefix}/${assetId}`);
    }

    updateAsset(assetId: string, req: RegistryUpdateRequest): Observable<APIResponse<AssetDto>> {
        return this.api.put(`${this.prefix}/${assetId}`, req);
    }

    deleteAsset(assetId: string): Observable<APIResponse<boolean>> {
        return this.api.delete(`${this.prefix}/${assetId}`);
    }

    getList(req: AssetRegistryListRequest): Observable<DataSource> {
        return this.api.postServerDataSource(`${this.prefix}/list`, req);
    }

    exportList(columns: any[], filterParams: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.post(`${this.prefix}/list/csv`, {columns, filterParams});
    }

    getAssetCount(filters: Filter[]): Observable<number> {
        return this.api.post(`${this.prefix}/count`, filters).pipe(map((data) => data.response));
    }

    getChangeHistory(assetId: string): Observable<APIResponse<AssetChangeHistory[]>> {
        return this.api.get(`${this.prefix}/change-history/${assetId}`);
    }

    getCompareAssetVersion(assetId: string, changeId: number): Observable<APIResponse<AssetVersionComparison>> {
        return this.api.get(`${this.prefix}/change-history/${assetId}/${changeId}`);
    }

    searchAssetId(search: string): Observable<APIResponse<string[]>> {
        return this.api.get(`${this.prefix}/search`, {
            params: new HttpParams().set('partialAssetId', search),
        });
    }

    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.get(`${this.prefix}/options/filters`);
    }

    getFilterFieldOptions(body: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.post(`${this.prefix}/options/filters/field`, body);
    }

    getAvailableColumns(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.get(`${this.prefix}/options/columns`);
    }

    getGeospatialView(geospatialRequest: GeospatialAssetRequest): Observable<APIResponse<GeospatialResponse>> {
        return this.api.post(`${this.prefixGeospatial}/geo-map`, geospatialRequest);
    }

    getAssetCoordinate(assetId: string): Observable<APIResponse<CoordinateLatLong>> {
        return this.api.get(`${this.prefixGeospatial}/${assetId}`);
    }
}
