import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {User} from '@core/interfaces/common/users';

@Component({
    selector: 'ngx-sensitive-info-disclosure-login',
    templateUrl: './sensitive-info-disclosure-login.component.html',
    styleUrls: ['./sensitive-info-disclosure-login.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SensitiveInfoDisclosureLoginComponent {
    constructor(private router: Router) {}

    goToDashboard() {
        this.router.navigateByUrl('/dashboard');
    }
}
