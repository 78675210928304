import {Directive, ElementRef, EventEmitter, HostListener, Output} from '@angular/core';

/**
 * Directive detects clicks outside the specific div and then calls a function accordingly
 *
 * ### Usage
 * <button
 *  clickOutside
 *  (clickOutside)="fn()"
 * </button>
 */
@Directive({
    selector: '[ngxClickOutside]',
})
export class ClickOutsideDirective {
    @Output() clickOutside = new EventEmitter();

    constructor(private elementRef: ElementRef) {}

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: any): void {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.clickOutside.emit(targetElement);
        }
    }
}
