import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {DashboardCardBodyNumber} from '@core/interfaces/common/pages';
import {FormatsService} from '@core/utils';
import {NbTrigger} from '@nebular/theme';

@Component({
    selector: 'ngx-dashboard-card-number',
    templateUrl: './dashboard-card-number.component.html',
    styleUrls: ['./dashboard-card-number.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardCardNumberComponent implements OnInit {
    @Input() body: DashboardCardBodyNumber;

    public readonly NbTrigger = NbTrigger;
    protected readonly FormatsService = FormatsService;

    public value;

    ngOnInit() {
        this.value =
            this.body?.value &&
            (this.body?.valueInteger
                ? FormatsService.prepareIntegerNumber(this.body?.value)
                : FormatsService.prepareValueFromUnit(this.body?.value, this.body?.unit));
    }
}
