export enum CHART_SORT_TYPE {
    ASC,
    DESC,
}

export class ChartSortOption {
    series: ChartSortOptionSeries;
    order: ChartSortOptionOrder;
    uniqueCode: string;
    label?: string;

    constructor(series: ChartSortOptionSeries, order: ChartSortOptionOrder, label?: string) {
        this.series = series;
        this.order = order;
        this.uniqueCode = series.code + order.code;
        this.label = label ? label : null;
    }

    getLabel() {
        if (this.label != null && this.label !== '') {
            return this.label;
        } else {
            return `${this.series.label} (${this.order.label})`;
        }
    }
}

export class ChartSortOptionSeries {
    code: string;
    label: string;
}

export class ChartSortOptionOrder {
    code: CHART_SORT_TYPE;
    label: string;
}
