import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {
    DataAuditChartResponse,
    DataAuditContextResponse,
    DataAuditHistoryResponse,
    DataAuditRequest,
    AssetDataAuditService,
    DataAuditStudyDataQuality,
} from '../../../../interfaces/engin/data-audit/asset-data-audit';
import {AssetDataAuditApi} from '../../api/data-audit/asset-data-audit.api';

@Injectable()
export class AssetDataAuditServiceImpl extends AssetDataAuditService {
    constructor(private api: AssetDataAuditApi) {
        super();
    }

    // Data audit tool
    getContextData(req: DataAuditRequest): Observable<DataAuditContextResponse> {
        return this.api.getContextData(req);
    }

    getRecentHistory(req: DataAuditRequest): Observable<DataAuditHistoryResponse> {
        return this.api.getRecentHistory(req);
    }

    getMetricChartByLoad(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.getMetricChartByLoad(req);
    }

    getMetricChartByAsset(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.getMetricChartByAsset(req);
    }

    getMetricChartByPoint(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.getMetricChartByPoint(req);
    }

    getMetricChartByAnalytic(req: DataAuditRequest): Observable<DataAuditChartResponse> {
        return this.api.getMetricChartByAnalytic(req);
    }

    // Data quality widget
    getWorkflowDataQuality(workflowId: string, req: DataAuditRequest): Observable<DataAuditStudyDataQuality> {
        return this.api.getWorkflowDataQuality(workflowId, req);
    }
}
