<div class="icon-container">
    <ng-container
        *ngIf="{
            widgetLoading: dataAuditStore.studyDataQualityLoading$ | async,
            dataQuality: dataQuality$ | async
        } as data"
    >
        <button
            nbButton
            size="tiny"
            [nbSpinner]="data.widgetLoading"
            nbSpinnerSize="small"
            nbSpinnerStatus="basic"
            [status]="data.dataQuality ? getButtonColor(data.dataQuality.qualityStatus) : 'basic'"
            [disabled]="
                disableWidget ||
                data.widgetLoading ||
                !data.dataQuality ||
                data.dataQuality?.qualityStatus === QUALITY_STATUS_TYPE.DISABLED
            "
            (click)="isCollapsed = !isCollapsed"
        >
            <nb-icon
                class="icon"
                [icon]="data.dataQuality ? getButtonIcon(data.dataQuality.qualityStatus) : 'alert-circle-outline'"
            ></nb-icon>
            <ng-container>
                <div class="icon-text">
                    <label class="title">Data Quality</label>
                </div>
            </ng-container>
        </button>

        <div class="displayBelow" [ngbCollapse]="!isCollapsed" *ngIf="data.dataQuality && !data.widgetLoading">
            <nb-card>
                <!-- Render each row of data -->
                <ng-container *ngFor="let row of data.dataQuality.data; let isLast = last">
                    <div class="property-box">
                        <span class="property-title">{{ row.name }}</span>
                        <span class="property-value">
                            <span class="property-text">{{ getFormattedValue(row.value, row.unit) }}</span>
                            <span class="property-icon">
                                <ng-container [ngSwitch]="row.trend">
                                    <nb-icon
                                        class="trend-icon trend-icon-up"
                                        *ngSwitchCase="'up'"
                                        icon="diagonal-arrow-right-up-outline"
                                    ></nb-icon>
                                    <nb-icon
                                        class="trend-icon trend-icon-down"
                                        *ngSwitchCase="'down'"
                                        icon="diagonal-arrow-right-down-outline"
                                    ></nb-icon>
                                    <nb-icon
                                        class="trend-icon trend-icon-flat"
                                        *ngSwitchCase="'flat'"
                                        icon="arrow-forward-outline"
                                    ></nb-icon>
                                </ng-container>
                            </span>
                        </span>
                    </div>
                    <!-- Break between items -->
                    <hr *ngIf="!isLast" class="property-divider" />
                </ng-container>
            </nb-card>
        </div>
    </ng-container>
</div>
