<div class="stepper-container">
    <div *ngFor="let step of steps" class="step">
        <div
            class="outer"
            *ngIf="getState(step) as state"
            [class]="state"
            (click)="changeCurrentStep(step.stepOrder, state)"
        >
            <div class="inner">
                <div>
                    <div class="title">{{ step.stepOrder }}. {{ step.label }}</div>
                    <div
                        class="description"
                        *ngIf="step.status !== DataPrepStatuses.IN_PROGRESS; else progress"
                        [nbTooltip]="step.shortDescription"
                    >
                        {{ step.shortDescription }}
                    </div>
                    <ng-template #progress>
                        <div class="description mb-1">Progress - {{ step.currentProgress }}%</div>
                        <nb-progress-bar status="info" size="tiny" [value]="step.currentProgress"></nb-progress-bar>
                    </ng-template>
                </div>

                <button
                    nbButton
                    outline
                    status="basic"
                    size="tiny"
                    class="ml-3"
                    (click)="openDescription($event, step)"
                >
                    <nb-icon [icon]="getIconFromState(state)" status="basic"></nb-icon>
                </button>
            </div>
        </div>
    </div>
</div>
