import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from '../../../common/api/http.service';
import {APIResponse} from '@core/interfaces/system/system-common';
import {DownloadURL, PeriodFilter, SimpleSeriesOld} from '@core/interfaces/engin/load-forecast/load-forecast';
import {HttpParams} from '@angular/common/http';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';

@Injectable()
export class SystemLoadForecastApi {
    private readonly PREFIX_SYSTEM = 'infrastructure/peakload';

    constructor(private api: HttpService) {}

    /*
     * Infrastructure: results for system
     */
    public getSystemForecastBySeasons(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(`${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/seasons`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getSystemForecastBreakdown(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByinfrastructureId=${subsystemId}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/breakdown?${params}`,
        );
    }

    public getSystemForecastBreakdownCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/breakdown/csv`, {
            params: new HttpParams()
                .set('filterByinfrastructureId', subsystemId)
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('filterByseasonId_IN', seasonIds.join('|'))
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getSystemForecastByFactors(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(`${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/factors`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('filterByseasonId_IN', seasonIds.join('|'))
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getSystemForecastPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&filterByseasonId_IN=${seasonIds.join('|')}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/pivot?${params}`,
        );
    }

    public getSystemForecastPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        subsystemId: string,
        seasonIds: string[],
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(`${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/pivot/csv`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('filterByseasonId_IN', seasonIds.join('|'))
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getSystemViolationsByInfrastructureType(
        workflowItemId: number,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld> {
        return this.api.get(`${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violations`, {
            params: new HttpParams()
                .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                .set('infrastructureType', viewBy)
                .set('seasonTiming', seasonTiming)
                .set('unit', unit)
                .set('sensitivityId', sensitivityId),
        });
    }

    public getSystemViolationsPivot(
        workflowItemId: number,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&seasonTiming=${seasonTiming}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violations/pivot?${params}`,
        );
    }

    public getSystemViolationsPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        seasonTiming: string,
        period: PeriodFilter,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violations/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('seasonTiming', seasonTiming)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getSystemViolationBreakdown(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<SimpleSeriesOld[]> {
        return this.api.get(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violation-breakdown`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('infrastructureType', viewBy)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }

    public getSystemViolationBreakdownPivot(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<DataSource> {
        let params = `filterByloadYear_GREATER_THAN_OR_EQUAL=${period.min}`;
        params = params + `&filterByloadYear_LESS_THAN_OR_EQUAL=${period.max}`;
        params = params + `&infrastructureType=${viewBy}`;
        params = params + `&unit=${unit}`;
        params = params + `&sensitivityId=${sensitivityId}`;
        return this.api.getServerDataSource(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violation-breakdown/pivot?${params}`,
        );
    }

    public getSystemViolationBreakdownPivotCSV(
        workflowItemId: number,
        scenarioId: string,
        period: PeriodFilter,
        viewBy: string,
        unit: string,
        sensitivityId: string,
    ): Observable<APIResponse<DownloadURL>> {
        return this.api.get(
            `${this.PREFIX_SYSTEM}/${workflowItemId}/scenarios/${scenarioId}/system/violation-breakdown/pivot/csv`,
            {
                params: new HttpParams()
                    .set('filterByloadYear_GREATER_THAN_OR_EQUAL', period.min)
                    .set('filterByloadYear_LESS_THAN_OR_EQUAL', period.max)
                    .set('infrastructureType', viewBy)
                    .set('unit', unit)
                    .set('sensitivityId', sensitivityId),
            },
        );
    }
}
