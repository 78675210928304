import {Observable} from 'rxjs';
import {APIResponse, Filter} from '@core/interfaces/system/system-common';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {KpiData} from '@theme/components/chart-kpi/chart-kpi.component';

export interface SegmentOption {
    id: number;
    name: string;
}

export interface Segment {
    id: number;
    name: string;
    description: string;
    ownerId: string;
    ownerName: string;
    kpiList: any[]; // need to change after merge
    programCount: number;
}

export abstract class PmSegmentService {
    // Segment
    abstract getSegmentList(params: Filter[]): Observable<Segment[]>;

    abstract getSegmentDtoList(): Observable<DataSource>;

    abstract getSegmentById(segmentId: number): Observable<APIResponse<Segment>>;

    abstract findSegmentKpis(
        segmentId: number,
        workflowItemId: number,
        sensitivityId: number,
    ): Observable<APIResponse<KpiData[]>>;

    abstract createSegment(segment): Observable<APIResponse<any>>;

    abstract editSegment(segment: Segment): Observable<APIResponse<Segment>>;

    abstract deleteSegment(segmentId: number): Observable<APIResponse<boolean>>;

    abstract searchSegmentName(segmentName: string): Observable<APIResponse<Segment[]>>;

    abstract getSegmentOptions(): Observable<APIResponse<SegmentOption[]>>;

    abstract getSegmentKpiOptions(): Observable<APIResponse<string[]>>;

    abstract validateSegmentName(programName: string): Observable<boolean>;

    abstract findAllBySegment(segmentId: number): Observable<APIResponse<Segment[]>>;

    abstract downloadAssetsListDtoCsvSegment(
        segmentId: number,
        workflowItemId: number,
    ): Observable<APIResponse<DownloadCSVUrl>>;
    abstract downloadTableCsvFile(): Observable<APIResponse<DownloadCSVUrl>>;
}
