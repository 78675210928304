<!--Use Chart Option-->
<ng-container *ngIf="chartOptions && chartOptions.series">
    <div
        class="chart-tool-container"
        *ngFor="let item of chartOptions.series; let i = index"
        (click)="onClickToggleLegend($event, i, item.id)"
    >
        <ng-container *ngIf="item.id">
            <span
                *ngIf="activeLegendSelection[item.id] && item.lineStyle"
                [ngStyle]="{background: item.lineStyle.color}"
                class="color-point"
            ></span>

            <span
                *ngIf="activeLegendSelection[item.id] && item.itemStyle && !item.lineStyle"
                [ngStyle]="{background: item.itemStyle.color}"
                class="color-point"
            ></span>

            <span *ngIf="!activeLegendSelection[item.id]" class="color-point inactive"></span>
            <span class="legend-label">{{ item.name }}</span>
        </ng-container>
    </div>
</ng-container>

<!--Use name and color only-->
<ng-container *ngIf="legendColorArray">
    <div
        class="chart-tool-container"
        *ngFor="let item of legendColorArray; let i = index"
        (click)="onClickToggleLegend($event, i, item.name)"
    >
        <span *ngIf="activeLegendSelection[item.name]" [ngStyle]="{background: item.color}" class="color-point"></span>

        <span *ngIf="!activeLegendSelection[item.name]" class="color-point inactive"></span>

        <span class="legend-label">{{ item.name }}</span>
    </div>
</ng-container>

<!---->
<!--*ngIf="!vm.activeLegendSelection.value[mainChartData.data.legend[i]]"-->
