import {BehaviorSubject} from 'rxjs';

export abstract class ReportChartViewModel {
    apiData = new BehaviorSubject<any>(null);
    apiData$ = this.apiData.asObservable();

    public abstract chartData$;
    public abstract showFooter;

    protected abstract init();
}
