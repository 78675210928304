import {Observable} from 'rxjs';
import {User} from './users';
import {Page} from './pages';

// Support
export class AboutSystemInfo {
    user: User;
    feature: Page;
    browser: any;
    enginVersion: EnginVersionInfo;

    constructor(user: User, feature: Page, browser: any, enginVersion: EnginVersionInfo) {
        this.user = user;
        this.feature = feature;
        this.browser = browser;
        this.enginVersion = enginVersion;
    }

    // User
    public getUserName() {
        return this.user && this.user.firstName && this.user.lastName
            ? `${this.user.firstName} ${this.user.lastName}`
            : 'Unknown';
    }

    public getUserEmail() {
        return this.user && this.user.email ? this.user.email : 'Unknown';
    }

    public getTenantId() {
        return this.user && this.user.activeTenantId ? this.user.activeTenantId : 'Unknown';
    }

    // Feature
    public getFeatureId() {
        return this.feature && this.feature.id ? this.feature.id : '';
    }

    // ENGIN version
    public getEnginVersion() {
        return this.enginVersion
            ? `ENGIN Version ${this.enginVersion.major}.${this.enginVersion.minor}.${this.enginVersion.patch}`
            : 'ENGIN Version unknown';
    }

    // Browser info
    public getBrowserInfo() {
        return this.browser ? `${this.browser}` : 'Unknown';
    }
}

export class EnginVersionInfo {
    major: number;
    minor: number;
    patch: number;

    constructor(major: number, minor: number, patch: number) {
        this.major = major;
        this.minor = minor;
        this.patch = patch;
    }
}

export class FeatureItem {
    code: string;
    name: string;

    constructor(code: string, name: string) {
        this.code = code;
        this.name = name;
    }
}

export abstract class SupportService {
    abstract createTicket(ticket): Observable<any>;

    abstract accessKnowledgeBase(): Observable<any>;
}
