import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService} from './http.service';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {APIResponse} from '../../../interfaces/system/system-common';
import {Snapshot} from '../../../interfaces/common/snapshot';

@Injectable()
export class SnapshotApi {
    private readonly prefix = 'data/snapshot';

    constructor(private api: HttpService) {}

    findOne = (id: string): Observable<APIResponse<Snapshot>> => this.api.get(`${this.prefix}/find/${id}`);

    findByDataSetId = (id: string): Observable<APIResponse<any>> => this.api.get(`${this.prefix}/find/dataset/${id}`);

    getDataSource = (): Observable<DataSource> => this.api.getServerDataSource(`${this.prefix}/list`);

    create = (data: Snapshot): Observable<APIResponse<Snapshot>> => this.api.post(`${this.prefix}/create`, data);

    update = (id: string, data: Snapshot): Observable<APIResponse<Snapshot>> =>
        this.api.put(`${this.prefix}/update/${id}`, data);

    delete = (snapshotId: string): Observable<APIResponse<boolean>> => this.api.delete(`${this.prefix}/${snapshotId}`);

    reversePendingDelete(snapshotId: string): Observable<APIResponse<boolean>> {
        return this.api.put(`${this.prefix}/reverse-pending-delete/${snapshotId}`, {});
    }
}
