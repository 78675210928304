import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DataSource} from '@mominsamir/ngx-smart-table/lib/lib/data-source/data-source';
import {
    APIResponse,
    Filter,
    FilterFieldOptionRequest,
    FilterFieldOptionResponse,
    TableFilterConfig,
} from '@core/interfaces/system/system-common';
import {
    AssetChangeHistory,
    AssetRegistryListRequest,
    AssetRegistryService,
    AssetVersionComparison,
    GeospatialAssetRequest,
    RegistryUpdateRequest,
} from '@core/interfaces/asset-registry';
import {AssetRegistryApi} from '@core/backend/engin/api/asset-registry.api';
import {DownloadCSVUrl} from '@core/interfaces/engin/workflow-validation';
import {GeospatialResponse} from '../../../../pages/geospatial-viewer/model/api';
import {AssetColumn, AssetDto, CoordinateLatLong} from '@core/interfaces/common/asset';

@Injectable()
export class AssetRegistryServiceImpl extends AssetRegistryService {
    constructor(private api: AssetRegistryApi) {
        super();
    }

    getAsset(assetId: string): Observable<APIResponse<AssetDto>> {
        return this.api.getAsset(assetId);
    }

    updateAsset(assetId: string, req: RegistryUpdateRequest): Observable<APIResponse<AssetDto>> {
        return this.api.updateAsset(assetId, req);
    }

    deleteAsset(assetId: string): Observable<APIResponse<any>> {
        return this.api.deleteAsset(assetId);
    }

    getList(req: AssetRegistryListRequest): Observable<DataSource> {
        return this.api.getList(req);
    }

    exportList(columns?: any[], filterParams?: Filter[]): Observable<APIResponse<DownloadCSVUrl>> {
        return this.api.exportList(columns, filterParams);
    }

    getAssetCount(filters: Filter[]): Observable<number> {
        return this.api.getAssetCount(filters);
    }

    getChangeHistory(assetId: string): Observable<APIResponse<AssetChangeHistory[]>> {
        return this.api.getChangeHistory(assetId);
    }

    getCompareAssetVersion(assetId: string, changeId: number): Observable<APIResponse<AssetVersionComparison>> {
        return this.api.getCompareAssetVersion(assetId, changeId);
    }

    searchAssetId(search: string): Observable<APIResponse<string[]>> {
        return this.api.searchAssetId(search);
    }

    getFilterOptions(): Observable<APIResponse<TableFilterConfig[]>> {
        return this.api.getFilterOptions();
    }

    getFilterFieldOptions(body: FilterFieldOptionRequest): Observable<APIResponse<FilterFieldOptionResponse>> {
        return this.api.getFilterFieldOptions(body);
    }

    getAvailableColumns(): Observable<APIResponse<AssetColumn[]>> {
        return this.api.getAvailableColumns();
    }

    getGeospatialView(geospatialRequest: GeospatialAssetRequest): Observable<APIResponse<GeospatialResponse>> {
        return this.api.getGeospatialView(geospatialRequest);
    }

    getAssetCoordinate(assetId: string): Observable<APIResponse<CoordinateLatLong>> {
        return this.api.getAssetCoordinate(assetId);
    }
}
