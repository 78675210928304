import {Component, Input} from '@angular/core';
import {FormatsService} from '../../../@core/utils';
import {DATA_AUDIT_VIEW_OPTIONS, DataMeasureContext} from '../../../@core/interfaces/engin/data-audit/asset-data-audit';

@Component({
    selector: 'ngx-nested-accordion',
    templateUrl: './nested-accordion.component.html',
    styleUrls: ['./nested-accordion.component.scss'],
})
export class NestedAccordionComponent {
    @Input() expandedSection: string = DATA_AUDIT_VIEW_OPTIONS.DATA_LOAD;
    @Input() accordionData: DataMeasureContext[];

    getSelectedNumber(array) {
        return array == null ? 0 : array.length;
    }

    mapCodeToTitle(code: string) {
        return FormatsService.mapCodeToTitle(code);
    }
}
