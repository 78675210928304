<div class="notes">
    <div *ngIf="assetNoteData$ | async as noteData" class="notes-container">
        <div *ngFor="let note of noteData" [class.pointer-none]="(pendingNotes$ | async)?.[note.id]" class="note">
            <div class="note-header">
                <span>
                    <strong>
                        {{ (note.updatedOn ? note.updatedOn : note.createdOn) | localizeTime }}
                    </strong>
                    last edit by {{ note.lastUpdatedByName }}
                </span>

                <nb-icon
                    [nbContextMenuPlacement]="'bottom'"
                    [nbContextMenu]="getActions(note)"
                    icon="more-vertical"
                    nbContextMenuTag="context-menu-note-action"
                    status="basic"
                ></nb-icon>
            </div>
            <div class="note-message">{{ note.note }}</div>
        </div>
    </div>

    <div class="btn-container">
        <button (click)="addNote()" fullWidth nbButton status="primary">
            <nb-icon icon="plus"></nb-icon>
            add note
        </button>
    </div>
</div>
