import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'ngx-empty-table',
    templateUrl: './table.component.html',
    styleUrls: ['./table.component.scss', '../common-theme-empty-charts.scss'],
})
export class EmptyTableComponent implements OnInit {
    @Input() columns: any[];
    @Input() settings: any;
    @Input() header = false;

    private defaultColumns = [
        {
            code: 'col1',
            title: 'Asset ID',
            width: '25%',
        },
        {
            code: 'col2',
            title: 'Asset Class Code',
            width: '25%',
        },
        {
            code: 'col3',
            title: 'Asset Subclass Code',
            width: '25%',
        },
        {
            code: 'col4',
            title: 'Plant',
            width: '25%',
        },
    ];

    constructor() {}

    dynamicColumn = {};
    dynamicData = [];

    ngOnInit() {
        if (this.settings && this.settings.columns) {
            Object.keys(this.settings.columns).forEach((key) => {
                Object.assign(this.dynamicColumn, {
                    [key]: {
                        title: this.settings.columns[key].title,
                        width: this.settings.columns[key].width,
                        sort: false,
                        filter: false,
                    },
                });
            });
        } else {
            if (!this.columns) this.columns = this.defaultColumns;

            this.columns.map((data) => {
                // from study-asset page
                if (data.code) {
                    Object.assign(this.dynamicColumn, {
                        [data.code]: {
                            title: data.title,
                            width: data.width,
                            sort: false,
                            filter: false,
                        },
                    });
                } else {
                    // from other pages.
                    if (data['title']) {
                        Object.assign(this.dynamicColumn, {
                            [data.title]: {
                                title: data.title,
                                width: data.width,
                                sort: false,
                                filter: false,
                            },
                        });
                    }
                }
            });
        }

        const tempObject = {};
        Object.keys(this.dynamicColumn).map((header) => {
            Object.assign(tempObject, {[header]: 'empty string'});
            this.dynamicData.push(tempObject);
        });
    }

    _settings = {
        actions: {
            add: false,
            delete: false,
            edit: false,
        },
        editable: false,
        pager: {
            display: false,
        },
        columns: this.dynamicColumn,
    };
}
