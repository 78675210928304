import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {PagesApi} from '../api/pages.api';
import {DashboardCardBody, PageGroup, PagesService} from '@core/interfaces/common/pages';
import {WorkflowInfo} from '@core/interfaces/common/users';

@Injectable()
export class PagesServiceImpl extends PagesService {
    constructor(private api: PagesApi) {
        super();
    }

    getPages(): Observable<PageGroup[]> {
        return this.api.getPages();
    }

    getDashboardCardBodyData(activeWorkflows?: WorkflowInfo[]): Observable<DashboardCardBody[]> {
        return this.api.getDashboardCardBodyData(activeWorkflows);
    }
}
