import {Pipe, PipeTransform} from '@angular/core';
import {groupBy} from 'lodash';
@Pipe({
    name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
    transform(collection: Array<any>, property: string): any {
        return groupBy(collection, property);
    }
}
